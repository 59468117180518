import { useMemo, useState } from 'react';
import ApprovedIcon from '../../assets/icons/ApprovedIcon';
import DataInputIcon from '../../assets/icons/DataInputIcon';
import WarningIcon from '../../assets/icons/WarningIcon';
import XMarkSquareIcon from '../../assets/icons/XMarkSquareIcon';
import Button from '../../components/commons/Button';
import CardHeader from '../../components/commons/CardHeader';
import DataUpload from '../../components/commons/DataUpload';
import Table, { TableConfigs } from '../../components/commons/Table';
import PartnerConnection from '../../components/data-inputs/overview/PartnerConnection';
import { useYearFilter } from '../../contexts/app-filter-context';
import { useUser } from '../../contexts/auth-context';
import { useOperationFiles } from '../../hooks/data-entry/use-operation-files';
import { useAllFieldPolygon } from '../../hooks/field-polygon/use-field-polygon';

const Status = ({ status }: { status: boolean | null | undefined }) => {
  let Icon = WarningIcon;
  if (status) {
    Icon = ApprovedIcon;
  } else if (status === false) {
    Icon = XMarkSquareIcon;
  }

  return (
    <span className="flex justify-center">
      <Icon />
    </span>
  );
};

export default function DataInputs() {
  const year = useYearFilter();
  const user = useUser();
  const { data: fields, isFetching: isFetchingFields } = useAllFieldPolygon(
    user?.id ?? '',
    year ?? ''
  );
  const { data: opFiles, isFetching: isFetchingFiles } = useOperationFiles(
    user?.id ?? ''
  );

  const fileTableData =
    opFiles?.map((fileData) => ({
      file: fileData.file_id,
      file_type: fileData.operation_type,
      provider: fileData.provider,
      field: fileData.field_name,
      file_date: fileData.FieldOperationStartDate,
      processed: 'converted' in fileData ? fileData.converted === 'yes' : false,
    })) || [];

  const fieldTableData =
    fields?.map((field) => ({
      field: field.field,
      planting_intentions: field.crop2,
      planting_data: true,
      ferilizer_applications: false,
      chemical_applications: false,
      harvest_data: false,
      crop_insurance: false,
      soil_samples: false,
      aph: false,
    })) || [];

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const fieldTableConfigs: TableConfigs = useMemo(
    () => ({
      tableFixed: true,
      cols: [
        {
          key: 'field',
          name: 'Field',
        },
        {
          key: 'planting_intentions',
          name: 'Planting Intentions',
        },
        {
          key: 'planting_data',
          name: 'Planting Data',
          type: 'element',
          element: (item) => <Status status={item.planting_data} />,
        },
        {
          key: 'ferilizer_applications',
          name: 'Fertilizer Applications',
          type: 'element',
          element: (item) => <Status status={item.ferilizer_applications} />,
        },
        {
          key: 'chemical_applications',
          name: 'Chemical Applications',
          type: 'element',
          element: (item) => <Status status={item.chemical_applications} />,
        },
        {
          key: 'harvest_data',
          name: 'Harvest Data',
          type: 'element',
          element: (item) => <Status status={item.harvest_data} />,
        },
        {
          key: 'crop_insurance',
          name: 'Crop Insurance',
          type: 'element',
          element: (item) => <Status status={item.crop_insurance} />,
        },
        {
          key: 'soil_samples',
          name: 'Soil Samples',
          type: 'element',
          element: (item) => <Status status={item.soil_samples} />,
        },
        {
          key: 'aph',
          name: 'APH',
          type: 'element',
          element: (item) => <Status status={item.aph} />,
        },
      ],
    }),
    []
  );

  const fileTableConfigs: TableConfigs = useMemo(
    () => ({
      tableFixed: true,
      cols: [
        {
          key: 'file_type',
          name: 'File Type',
        },
        {
          key: 'field',
          name: 'Field',
        },
        {
          key: 'provider',
          name: 'Partner',
        },
        {
          key: 'file_date',
          name: 'File Date',
        },
        {
          key: 'processed',
          name: 'Processed',
          type: 'element',
          element: (item) => <Status status={item.processed} />,
        },
      ],
    }),
    []
  );

  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="card overflow-hidden col-span-12">
        <div className="px-4 p-3">
          <CardHeader title="Data Summary By Field" icon={<DataInputIcon />} />
        </div>
        <Table
          configs={fieldTableConfigs}
          pagination={{ size: 10 }}
          data={fieldTableData}
          tableFixed
          loading={isFetchingFields}
        />
      </div>
      <div className="card p-4 col-span-6">
        <CardHeader title={'Operation File Status'} icon={<DataInputIcon />} />
        <div>
          <Table
            configs={fileTableConfigs}
            pagination={{ size: 10 }}
            data={fileTableData}
            tableFixed
            loading={isFetchingFiles}
          />
        </div>
      </div>
      <div className="col-span-6 flex flex-col gap-8">
        <div className="card p-4">
          <CardHeader
            title={'Connected Partner Status'}
            icon={<DataInputIcon />}
            className="mb-5"
          />
          <PartnerConnection />
        </div>
        <div className="card p-4">
          <CardHeader
            title={'Upload Files'}
            icon={<DataInputIcon />}
            className="mb-5"
          />
          <div className="flex w-full flex-col whitespace-nowrap justify-between items-center rounded-lg bg-base-1000 border border-base-900 p-2">
            <div className="flex whitespace-nowrap justify-between items-center w-full">
              <p className="text-md-bold">Upload Precision Files</p>
              <Button
                onClick={() => setIsUploadingFiles(!isUploadingFiles)}
                className="!w-fit"
                color="primary"
              >
                Upload Files
              </Button>
            </div>
            {isUploadingFiles && <DataUpload />}
          </div>
        </div>
      </div>
    </div>
  );
}
