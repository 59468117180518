import { Link } from 'react-router-dom';
import { SupportedCrops } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import {
  getHedgedFuturePriceByCrop,
  useFarmRevenues,
} from '../../../hooks/farm/use-farm-revenues';
import { usePriceTable } from '../../../pages/dashboard/hooks/use-price-table';
import CropIcon from '../../commons/CropIcon';
import BasisItem from './BasisItem';
import HedgedBasisItem from './HedgedBasisItem';
import HedgedFuturePriceItem from './HedgedFuturePriceItem';
import PriceItem from './PriceItem';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { useMemo } from 'react';

function PriceRow({
  cropName,
  hedgedFuturePrice,
}: {
  cropName: string;
  hedgedFuturePrice: { total: number; percent: number };
}) {
  const {
    selectedSymbol,
    setSelectedSymbol,
    selectedBasisId,
    setSelectedBasisId,
    userSettings,
  } = usePriceTable(cropName);

  const settings = useMemo(
    () => userSettings.find((item) => item.crop === cropName),
    [userSettings, cropName, selectedSymbol, selectedBasisId]
  );

  return (
    <div className="flex space-x-4" key={cropName}>
      <span className="self-center w-8">
        <CropIcon cropName={cropName} colored={false} />
      </span>
      <div className="flex-1 grid grid-cols-12 gap-x-8 gap-y-4">
        <div className="col-span-12 lg:col-span-3">
          <h4 className="display-sm-bold block lg:hidden mb-1">Price</h4>
          {settings && settings.contract && settings.contract.length > 0 && (
            <PriceItem
              symbol={selectedSymbol}
              cropName={cropName}
              onChange={setSelectedSymbol}
              defaultSymbol={settings?.contract}
            />
          )}
        </div>
        <div className="col-span-12 lg:col-span-3">
          <h4 className="display-sm-bold block lg:hidden mb-1">Basis</h4>
          {settings && settings.basis && settings.basis.length > 0 && (
            <BasisItem
              cropName={cropName}
              symbol={selectedSymbol}
              basisId={selectedBasisId}
              onChange={setSelectedBasisId}
              defaultBasisId={settings?.basis}
            />
          )}
        </div>
        <div className="col-span-12 lg:col-span-3">
          <h4 className="display-sm-bold block lg:hidden mb-1">
            Hedged Future Price
          </h4>
          <HedgedFuturePriceItem {...hedgedFuturePrice} />
        </div>
        <div className="col-span-12 lg:col-span-3">
          <h4 className="display-sm-bold block lg:hidden mb-1">Hedged Basis</h4>
          <HedgedBasisItem />
        </div>
      </div>
    </div>
  );
}

function PriceBlockCard() {
  const year = useYearFilter();
  const user = useControlledUser();

  const { data: farmRevenueHook } = useFarmRevenues(user?.id || '', year || '');

  const msgRevenue: any = useWebSocketDataV2('Farm_Revenue');

  const farmRevenue: any = useMemo(() => {
    if (!farmRevenueHook) return [];
    const updatedFarmRevenue: any = farmRevenueHook.map((revenue) => {
      if (
        msgRevenue &&
        msgRevenue.data &&
        msgRevenue.data.user_id_crop === revenue.user_id_crop
      ) {
        return {
          ...revenue,
          revenue: msgRevenue.data.revenue || revenue.revenue,
          revenue_chg: msgRevenue.data.revenue_chg || revenue.revenue_chg,
          revenue_per_acre:
            msgRevenue.data.revenue_per_acre || revenue.revenue_per_acre,
          revenue_per_acre_chg:
            msgRevenue.data.revenue_per_acre_chg ||
            revenue.revenue_per_acre_chg,
          hedged_price: msgRevenue.data.hedged_price || revenue.hedged_price,
        };
      }
      return revenue;
    });
    return updatedFarmRevenue;
  }, [farmRevenueHook, msgRevenue]);

  const hedgedFuturePriceByCrop = useMemo(
    () => getHedgedFuturePriceByCrop(farmRevenue),
    [farmRevenue]
  );

  return (
    <div className="card p-6 space-y-6">
      {/* Headers */}
      <div className="lg:flex hidden">
        <div className="w-12"></div>
        <div className="flex-1 grid grid-cols-12 gap-x-8 gap-y-4">
          <div className="col-span-12 lg:col-span-3">
            <Link to="price">
              <h4 className="display-sm-bold">Price</h4>
            </Link>
          </div>
          <div className="col-span-12 lg:col-span-3">
            <h4 className="display-sm-bold">Basis</h4>
          </div>
          <div className="col-span-12 lg:col-span-3">
            <h4 className="display-sm-bold">Hedged Future Price</h4>
          </div>
          <div className="col-span-12 lg:col-span-3">
            <h4 className="display-sm-bold">Hedged Basis</h4>
          </div>
        </div>
      </div>

      {/* Rows */}
      {SupportedCrops.map((crop) => (
        <PriceRow
          key={crop}
          cropName={crop}
          hedgedFuturePrice={hedgedFuturePriceByCrop?.['Corn']}
        />
      ))}
    </div>
  );
}

export default PriceBlockCard;
