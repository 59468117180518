import clsx from 'clsx';
interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function IconButton(props: IconButtonProps) {
  const { children, className, ...rest } = props;

  return (
    <button
      className={clsx(
        'border border-block-fill rounded min-h-1 min-w-1 p-1.5',
        className
      )}
      style={{
        backgroundImage:
          'linear-gradient(313.91deg, #222C34 53.63%, #3E4951 98.05%',
      }}
      {...rest}
    >
      {children}
    </button>
  );
}
