import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Currency from './Currency';

interface FluctuationProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: number;
  context?: string;
  currency?: string;
  contextClassname?: string;
  unit?: string;
}

function Fluctuation({
  value,
  context,
  className,
  currency,
  contextClassname,
  unit,
  ...rest
}: FluctuationProps) {
  if (typeof value !== 'number') return null;

  const isPositive = value >= 0;
  const isNegative = value < 0;

  return (
    <span className={clsx('flex items-center space-x-2', className)}>
      <span
        className={clsx(
          'flex items-center',
          isPositive && 'text-primary',
          isNegative && 'text-red'
        )}
      >
        {isPositive ? (
          <ArrowUpIcon className="size-4" />
        ) : isNegative ? (
          <ArrowDownIcon className="size-4" />
        ) : (
          ''
        )}
        {currency ? (
          <Currency value={value} currency={currency} />
        ) : (
          <p className="!leading-none">
            {value.toFixed(2)}
            {unit}
          </p>
        )}
      </span>
      {/* unit/time... */}
      <p className={clsx('text-xs-regular !leading-none', contextClassname)}>
        {context}
      </p>
    </span>
  );
}

export default Fluctuation;
