import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import {
  createFieldPolygonApi,
  deleteFieldPolygonApi,
  duplicateFieldPolygonApi,
  getAllFieldPolygonApi,
  getPartnerFieldGeometry,
  getPartnerFieldsApi,
  updateFieldPolygonApi,
} from '../../api/field-polygon-api';
import {
  FieldData,
  FieldPolygonCreatePayload,
  FieldPolygonDuplicatePayload,
  FieldPolygonRes,
  FieldPolygonUpdatePayload,
} from '../../types';

export const useAllFieldPolygon = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field_polygon', userId, year],
    queryFn: () => getAllFieldPolygonApi(userId, year),
    refetchOnWindowFocus: false,
  });

  return { data: data?.data as FieldPolygonRes[], ...rest };
};

export const usePartnerFields = (
  userId: string,
  partner_type?: string,
  enabled = true
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['partner_field_names', userId, partner_type],
    queryFn: () => getPartnerFieldsApi(userId, partner_type),
    enabled: enabled && !!userId,
    refetchOnWindowFocus: false,
  });

  return { data: data?.data as FieldData[], ...rest };
};

export const usePartnerFieldGeometry = (
  userId: string,
  partner_type?: string,
  field_index?: string,
  enabled = true
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['partner_field_detail', userId, field_index, partner_type],
    queryFn: () => getPartnerFieldGeometry(userId, field_index, partner_type),
    enabled: enabled && !!userId && !!field_index,
    refetchOnWindowFocus: false,
  });

  return { data: JSON.parse(data?.data?.geojson ?? '{}'), ...rest };
};

// Mutations
export const useUpdateFieldPolygon = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['field_polygon/update'],
    mutationFn: (payload: FieldPolygonUpdatePayload) =>
      updateFieldPolygonApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field_polygon'],
      });
    },
  });

  return mutation;
};

export const useDuplicateFieldPolygon = () => {
  const mutation = useMutation({
    mutationKey: ['field_polygon/duplicate'],
    mutationFn: (payload: FieldPolygonDuplicatePayload) =>
      duplicateFieldPolygonApi(payload),
    onSuccess: ({ data }, payload) => {},
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {},
  });

  return mutation;
};

export const useCreateFieldPolygon = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['field_polygon/create'],
    mutationFn: (payload: FieldPolygonCreatePayload) =>
      createFieldPolygonApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Created successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field_polygon'],
      });
    },
  });
};

export const useDeleteFieldPolygon = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['field_polygon/delete'],
    mutationFn: ({
      user_id_field_index,
      crop_year,
      boundary = false,
    }: {
      user_id_field_index: string;
      crop_year: string;
      boundary?: boolean;
    }) =>
      deleteFieldPolygonApi({
        user_id_field_index,
        crop_year,
        boundary,
      }),
    onSuccess: () => {
      enqueueSnackbar('Deleted successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Error', {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['field_polygon'],
      });
    },
  });
};
