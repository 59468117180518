import clsx from 'clsx';

export default function StyledCard({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'card shadow-md shadow-black/50 overflow-hidden',
        className
      )}
    >
      <h1 className="text-center display-md-bold py-4 bg-base-1100 !font-medium">
        {title}
      </h1>
      <div className="p-8">{children}</div>
    </div>
  );
}
