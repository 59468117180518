import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import Modal from '../../../components/commons/modal';
import Table, { TableConfigs } from '../../../components/commons/Table';
import { useMainUser } from '../../../contexts/auth-context';
import { useDebounce } from '../../../hooks/use-debounce';
import {
  useConnectMembers,
  useDisconnectMembers,
  usePartnerMembers,
} from '../../../hooks/user/use-partner-members';
import {
  useApproveStaffRequest,
  useStaffs,
} from '../../../hooks/user/use-users';
import { MemberData, StaffRes } from '../../../types';

export default function StaffList() {
  const user = useMainUser();
  const { data } = useStaffs(!!user?.is_advisor_client);
  const { mutate: approveStaff, isPending: isApproving } =
    useApproveStaffRequest();

  const tableConfigs: TableConfigs = {
    cols: [
      {
        key: 'user_id',
        name: 'User ID',
      },

      {
        key: 'client_name',
        name: 'Username',
      },
      {
        key: 'client_email',
        name: 'Email',
      },
    ],
  };

  const extraActions = (item: StaffRes) => {
    return (
      <div className="flex flex-col space-y-2 w-full">
        {item?.approved && (
          <>
            <Link to={`/user/${item.user_id}/app/dashboard`}>
              <Button color="primary" size="sm">
                Dashboard
              </Button>
            </Link>
            <Link to={`/user/${item.user_id}/app/data-inputs`}>
              <Button color="primary" size="sm">
                Enter Client Data
              </Button>
            </Link>
            <Button
              color="primary"
              size="sm"
              onClick={() => setAddMemberModal(item)}
            >
              Connect Member Account
            </Button>
          </>
        )}
        {!item?.approved && (
          <Button
            color="primary"
            size="sm"
            onClick={() => approveStaff({ memberId: item.user_id })}
            loading={isApproving}
            disabled={isApproving}
          >
            Approve
          </Button>
        )}
      </div>
    );
  };

  // connect member modal
  const [addMemberModal, setAddMemberModal] = useState<StaffRes>();
  const [selectedMembers, setSelectedMembers] = useState<MemberData[]>();
  const [searchText, setSearchText] = useState('');
  const debouceSearchText = useDebounce(searchText, 500);

  const { mutateAsync: connectMember, isPending: isConnecting } =
    useConnectMembers();
  const { mutateAsync: disconnectMember, isPending: isDisconnecting } =
    useDisconnectMembers();
  const { data: members, isFetching } = usePartnerMembers({
    searchText: debouceSearchText,
    partner: user?.network_partner || '',
  });

  const handleConnectMember = async () => {
    if (!selectedMembers?.length || !addMemberModal) return;
    try {
      await connectMember({
        uniqueid: selectedMembers[0].uniqueid,
        name: selectedMembers[0].name,
        user_id: addMemberModal.user_id,
      });
      setAddMemberModal(undefined);
      enqueueSnackbar('Member connected successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to connect member', { variant: 'error' });
    }
  };

  const handleDisconnectMember = async (item: MemberData) => {
    if (!item) return;
    try {
      await disconnectMember({
        uniqueid: item.uniqueid,
        name: item.name,
      });
      enqueueSnackbar('Member disconnected successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to disconnect member', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!addMemberModal) {
      setSelectedMembers([]);
      setSearchText('');
    }
  }, [addMemberModal]);

  return (
    <div>
      <Table
        configs={tableConfigs}
        data={data}
        extraActions={extraActions}
        subRow={(item: StaffRes) =>
          item?.members?.length > 0 ? (
            <div>
              <p className="mb-2 text-left">Connected Members</p>
              <div className="rounded overflow-hidden border border-base-1000">
                <Table
                  configs={{
                    cols: [
                      {
                        key: 'nameid',
                        name: 'Account Number',
                        className: 'w-1/4',
                      },
                      {
                        key: 'name',
                        name: 'Name',
                      },
                    ],
                  }}
                  data={item.members || []}
                  extraActions={(item: MemberData) => (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDisconnectMember(item)}
                      loading={isDisconnecting}
                      disabled={isDisconnecting}
                    >
                      Disconnect
                    </Button>
                  )}
                />
              </div>
            </div>
          ) : null
        }
      />

      <Modal
        onClose={{
          handler: () => {
            setAddMemberModal(undefined);
          },
          btnText: 'Cancel',
        }}
        onConfirm={{
          handler: handleConnectMember,
          btnText: 'Add',
        }}
        title="Connect Member Account"
        open={!!addMemberModal}
        loading={isConnecting}
      >
        <TextInput
          placeholder="Search members"
          name="searchText"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <br />
        <div className="rounded-lg border border-base-1000 overflow-hidden">
          <Table
            noDataTitle="Search Query Did Not Return Any Member Accounts. Please Try A Different Name."
            configs={{
              cols: [
                {
                  key: 'nameid',
                  name: 'Account Number',
                  className: 'w-1/4',
                },
                {
                  key: 'name',
                  name: 'Name',
                },
                { key: 'nameidtypedesc', name: 'Type' },
              ],
            }}
            data={members}
            indexKey="uniqueid"
            checkedRows={selectedMembers?.map((m: MemberData) => m.uniqueid)}
            onRowCheck={(item: MemberData, checked) => {
              if (checked) {
                setSelectedMembers([item]);
              } else {
                setSelectedMembers([]);
              }
            }}
            pagination={{ size: 10 }}
            loading={isFetching}
          />
        </div>
      </Modal>
    </div>
  );
}
