import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createExpenseApi,
  deleteExpenseApi,
  getExpensesApi,
  updateExpenseApi,
} from '../../api/expense-api';
import {
  CreateExpensePayload,
  ExpenseItem,
  UpdateExpensePayload,
} from '../../types';
import { enqueueSnackbar } from 'notistack';

export const useExpenses = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['expense', userId, year],
    queryFn: () => getExpensesApi(userId, year),
    enabled: !!userId && !!year,
  });

  return {
    data: data?.data as ExpenseItem[],
    ...rest,
  };
};

export const useCreateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateExpensePayload[]) => createExpenseApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: () => {},
  });
};

export const useUpdateExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateExpensePayload) => updateExpenseApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Expense updated successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to update expense', { variant: 'error' });
    },
  });
};

export const useDeleteExpense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      year,
      expenseId,
    }: {
      userId: string;
      year: string;
      expenseId: string;
    }) => deleteExpenseApi({ userId, year, expenseId }),
    onSuccess: () => {
      enqueueSnackbar('Expense deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['expense'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete expense', { variant: 'error' });
    },
  });
};
