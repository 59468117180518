import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useDeletePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import Checkbox from '../../../commons/Checkbox';
import NumberDisplay from '../../../commons/NumberDisplay';
import Select from '../../../commons/Select';
import Table, { TableConfigs } from '../../../commons/Table';
import TableLayout from '../../commons/TableLayout';

interface SeedingRateListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionRes[];
  onSelect: (zone: string) => void;
  loading: boolean;
  setIsEditing: (value: boolean) => void;
  selectedProductByZone: Record<string, PrescriptionProductRes>;
  setSelectedProductByZone: (
    zone: string,
    value: PrescriptionProductRes
  ) => void;
}

export default function SeedingRateList({
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
  selectedProductByZone,
  setSelectedProductByZone,
}: SeedingRateListProps) {
  const user = useUser();
  const year = useYearFilter();
  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Select Zone',
        key: 'id',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => (
          <Checkbox
            name="id"
            label={`Zone ${item.agrivar_zone1}`}
            checked={selectedZone.agrivar_zone === item.agrivar_zone}
            onChange={(checked: boolean) =>
              checked && onSelect(item.agrivar_zone)
            }
            rounded
          />
        ),
      },
      { name: 'Acres', key: 'area', unit: 'acres' },
      {
        name: 'Seeding Rate',
        key: 'product.seeding_rate',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const selectedProduct = selectedProductByZone[item.agrivar_zone];
          return (
            <NumberDisplay value={Number(selectedProduct?.seeding_rate) ?? 0} />
          );
        },
      },
      {
        name: 'Historical Average Seeding Rate',
        key: 'average_seeding_rate',
        type: 'number',
      },
      {
        name: 'Yield (Bu) Per 1000 Seeds',
        key: 'yield_per_1000_seeds',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const selectedProduct = selectedProductByZone[item.agrivar_zone];
          let result;
          const seedingRate = parseFloat(selectedProduct?.seeding_rate);
          const zoneYield = parseFloat(item.zone_yield);
          if (!isNaN(seedingRate) && seedingRate !== 0) {
            result = zoneYield / (seedingRate / 1000);
            result = parseFloat(result.toFixed(2));
          } else {
            result = 0;
          }
          return <NumberDisplay value={Number(result)} />;
        },
      },
      {
        name: 'Variety',
        key: 'variety',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const options = item.products.map((product) => ({
            value: product.variety,
            label: product.variety,
          }));
          if (!options.length) return <></>;
          return (
            <Select
              className="w-52"
              name="variety"
              options={options}
              onChange={(e) =>
                setSelectedProductByZone(
                  item.agrivar_zone,
                  item.products.find(
                    (product) => product.variety === e.target.value
                  ) as PrescriptionProductRes
                )
              }
            />
          );
        },
      },
      { name: 'Cost Per Acre', key: 'cost_per_acre', type: 'number' },
    ],
  };

  const { mutate: deleteSeeding } = useDeletePrescriptionProduct();

  const rowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionWithProducts) => {
      deleteSeeding({
        uniqueId: selectedProductByZone[item.agrivar_zone].uniqueid,
        userId: user?.id ?? '',
        year: year ?? '',
        zone: item.agrivar_zone,
      });
    },
    note: {
      select: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionWithProducts, value: string) => {},
      onSaveAll: (item: FieldPrescriptionWithProducts, value: string) => {},
      title: 'Add Zone Note',
    },
  };

  return (
    <TableLayout title={'Seeding and Varieties'}>
      <Table
        configs={tableConfigs}
        data={data}
        loading={loading}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
