import { IconProps } from '../../types';

export default function SatelliteIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0781 5.4856C16 5.4856 20.0781 9.55975 20.0781 14.4768C20.0781 15.1324 19.5625 15.6007 18.9531 15.6007C18.2969 15.6007 17.8281 15.1324 17.8281 14.4768C17.8281 10.7773 14.7812 7.73341 11.0781 7.73341C10.4219 7.73341 9.95312 7.26511 9.95312 6.6095C9.95312 6.00072 10.4219 5.4856 11.0781 5.4856ZM11.4531 0.615356C18.8594 0.615356 24.9531 6.70316 24.9531 14.1022C24.9531 14.9451 24.25 15.6007 23.4531 15.6007C22.6094 15.6007 21.9531 14.9451 21.9531 14.1022C21.9531 8.34219 17.2188 3.61243 11.4531 3.61243C10.6094 3.61243 9.95312 2.95682 9.95312 2.11389C9.95312 1.3178 10.6094 0.615356 11.4531 0.615356ZM9.76562 16.8183L15.2969 22.2973C15.625 22.6251 15.5781 23.2339 15.1562 23.468C11.5 25.4349 6.85938 24.8729 3.8125 21.7822C0.71875 18.7383 0.15625 14.1022 2.125 10.4495C2.3125 10.028 2.92188 9.98121 3.25 10.309L8.73438 15.788L10 14.5236C9.95312 14.3832 9.95312 14.2895 9.95312 14.149C9.95312 13.3061 10.6094 12.6505 11.4531 12.6505C12.25 12.6505 12.9531 13.3061 12.9531 14.149C12.9531 14.9451 12.25 15.6476 11.4531 15.6476C11.3125 15.6476 11.1719 15.6007 11.0781 15.5539L9.76562 16.8183Z"
        fill={fill}
      />
    </svg>
  );
}
