import { useEffect, useState } from 'react';
import { SupportedCrops, defaultsSettings } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { useBasis } from '../../../hooks/basis/use-basis';
import {
  useSetUserRealTimeSettings,
  useUserRealTimeSettings,
} from '../../../hooks/user/use-user-real-time-settings';
import { BasisRes, UserSettingsRealTimeSetPayload } from '../../../types';
import { getDefaultSymbols, groupBy } from '../../../utils';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';

export const usePriceTable = (cropName: string) => {
  const defaultSymbols = getDefaultSymbols();
  const [selectedSymbol, setSelectedSymbol] = useState<string>('');
  const [selectedBasisId, setSelectedBasisId] = useState<string>('');

  const user = useControlledUser();

  const year = useYearFilter();
  const { data: defaultBasis } = useBasis(
    user?.id || '',
    year || '',
    Object.values(defaultSymbols).map((symbol) => symbol)
  );

  const { data: userSettings } = useUserRealTimeSettings(
    user?.id || '',
    year || ''
  );
  const { mutate: setUserRealTimeSettings } = useSetUserRealTimeSettings();

  const queryClient = useQueryClient();

  useEffect(() => {
    const saveDefaultSettings = async () => {
      // if userSettings is not available then save the default settings
      if (!userSettings) {
        await queryClient.invalidateQueries([
          'user/settings/real-time',
        ] as InvalidateQueryFilters);
        if (user?.id && year && defaultBasis) {
          const defaultBasisGroupByCrop = groupBy(
            defaultBasis,
            'crop'
          ) as Record<string, BasisRes[]>;
          const userSettingsPayload: UserSettingsRealTimeSetPayload[] = [];
          SupportedCrops.forEach((crop) => {
            userSettingsPayload.push({
              user_id: user?.id,
              year: year,
              crop: crop,
              basis: defaultBasisGroupByCrop[crop].sort(
                (a, b) => parseFloat(a?.distance) - parseFloat(b?.distance)
              )?.[0].basis_id,
              contract: defaultSymbols?.[crop],
              trading_days: defaultsSettings.tradingDays.toString(),
              var_setting_1: defaultsSettings.vaRSettings[0].toString(),
              var_setting_2: defaultsSettings.vaRSettings[1].toString(),
            });
          });
          setUserRealTimeSettings(userSettingsPayload);
        }
      }
    };

    saveDefaultSettings();
  }, [
    defaultBasis,
    defaultSymbols,
    setUserRealTimeSettings,
    user?.id,
    userSettings,
    year,
  ]);

  return {
    selectedSymbol,
    setSelectedSymbol,
    selectedBasisId,
    setSelectedBasisId,
    userSettings,
  };
};
