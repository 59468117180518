import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ContractOptions,
  MonthOptions,
  StorageTypes,
} from '../../../../constants/risk-management';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../../hooks/field/use-field-polygons';
import { useUpdateStorage } from '../../../../hooks/storage/use-storage';
import { StorageItem } from '../../../../types/storage';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import TextInput from '../../../commons/inputs/TextInput';
import Select from '../../../commons/Select';

export default function EditStorageForm({
  data,
  onCancel,
}: {
  data: StorageItem;
  onCancel: () => void;
}) {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({
    defaultValues: {
      ...data,
      contractMonth: data.contract_date?.slice(0, 3),
      contractYear: data.contract_date?.slice(-2),
    },
  });

  const { mutateAsync: update, isPending } = useUpdateStorage();

  // set contract_date
  const contractMonth = form.watch('contractMonth');
  const contractYear = form.watch('contractYear');
  useEffect(() => {
    if (contractMonth && contractYear) {
      form.setValue('contract_date', `${contractMonth} '${contractYear}`);
    }
  }, [contractMonth, contractYear]);

  // Field options
  const { data: allFields } = useFieldPolygonsByYear(
    user?.id ?? '',
    year ?? ''
  );
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}`,
    }));
  }, [allFields]);

  const handleSubmit = async ({
    contractMonth,
    contractYear,
    ...values
  }: any) => {
    try {
      await update(values);
      form.reset();
      onCancel();
      enqueueSnackbar(`Storage updated successfully`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to update storage`, { variant: 'error' });
    }
  };
  return (
    <div>
      <CardHeader title={`Edit Storage`} className="mb-4" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="grid grid-cols-3 gap-4"
        >
          <TextInput name="storageName" label="Storage Name" required />
          <Select
            name="storageType"
            label="Type"
            options={StorageTypes}
            required
          />
          <Select
            label="Exchange and Futures Contract"
            name="contract"
            options={ContractOptions}
            required
          />
          {form.watch('contract') === 'Other' && (
            <TextInput name="contract_other" label="Other" required />
          )}
          <div className="flex items-end gap-2">
            <Select
              name="contractMonth"
              options={MonthOptions}
              label="Contract Month/Year"
              required
            />
            <Select
              name="contractYear"
              label="Year"
              hiddenLabel
              options={getYearOptions(10, 5, false)}
              required
            />
          </div>
          <TextInput
            name="bushels"
            label="Number of Bushels"
            type="number"
            required
          />
          <TextInput
            name="cost"
            label="Storage Cost (weekly per bushel)"
            type="number"
            required
          />
          <Select
            label="Field Applied"
            name="fieldApplied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
            required
          />
          <div className="flex justify-end space-x-3 col-span-3">
            <Button className="!w-fit" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="!w-fit"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
