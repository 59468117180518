import { IconProps } from '../../types';

export default function SquareIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97684 -0.5H7.69113V2.92857H5.97684C5.01256 2.92857 4.26256 3.73214 4.26256 4.64286V6.35714H0.833984V4.64286C0.833984 1.80357 3.08398 -0.5 5.97684 -0.5ZM4.26256 8.07143V14.9286H0.833984V8.07143H4.26256ZM24.834 8.07143V14.9286H21.4054V8.07143H24.834ZM21.4054 6.35714V4.64286C21.4054 3.73214 20.6018 2.92857 19.6911 2.92857H17.9768V-0.5H19.6911C22.5304 -0.5 24.834 1.80357 24.834 4.64286V6.35714H21.4054ZM24.834 18.3571C24.834 21.1964 22.5304 23.5 19.6911 23.5H17.9768V20.0714H19.6911C20.6018 20.0714 21.4054 19.3214 21.4054 18.3571V16.6429H24.834V18.3571ZM4.26256 18.3571C4.26256 19.3214 5.01256 20.0714 5.97684 20.0714H7.69113V23.5H5.97684C3.08398 23.5 0.833984 21.1964 0.833984 18.3571V16.6429H4.26256V18.3571ZM9.40541 23.5V20.0714H16.2626V23.5H9.40541ZM9.40541 -0.5H16.2626V2.92857H9.40541V-0.5Z"
        fill={fill}
      />
    </svg>
  );
}
