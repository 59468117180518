import { IconProps } from '../../types';

const BillIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.5H18V21.75C18 22.995 16.995 24 15.75 24H2.25C1.005 24 0 22.99 0 21.75V2.25C0 1.005 1.005 0 2.25 0H10.5V6C10.5 6.83 11.17 7.5 12 7.5ZM3 4.125C3 4.33 3.17 4.5 3.375 4.5H7.125C7.33 4.5 7.5 4.33 7.5 4.125V3.375C7.5 3.17 7.33 3 7.125 3H3.375C3.17 3 3 3.17 3 3.375V4.125ZM3.375 7.5H7.125C7.33 7.5 7.5 7.33 7.5 7.125V6.375C7.5 6.17 7.33 6 7.125 6H3.375C3.17 6 3 6.17 3 6.375V7.125C3 7.33 3.17 7.5 3.375 7.5ZM9.26 14.85L8.96 14.775C7.89 14.445 7.915 14.255 7.95 14.085C8.015 13.72 8.73 13.63 9.37 13.73C9.63 13.77 9.92 13.86 10.195 13.955C10.68 14.125 11.22 13.865 11.39 13.375C11.56 12.885 11.3 12.35 10.81 12.18C10.47 12.065 10.195 11.99 9.94 11.935V11.625C9.94 11.11 9.52 10.685 9 10.685C8.48 10.685 8.06 11.105 8.06 11.625V11.905C7.01 12.13 6.27 12.785 6.1 13.765C5.755 15.775 7.655 16.33 8.47 16.57L8.74 16.65C10.115 17.045 10.09 17.175 10.05 17.415C9.985 17.78 9.27 17.87 8.63 17.77C8.305 17.72 7.89 17.57 7.52 17.44L7.31 17.365C6.82 17.195 6.285 17.45 6.115 17.94C5.945 18.43 6.2 18.965 6.685 19.135L6.885 19.205C7.25 19.335 7.655 19.465 8.06 19.555V19.87C8.06 20.385 8.48 20.81 9 20.81C9.52 20.81 9.94 20.39 9.94 19.87V19.575C10.99 19.35 11.73 18.705 11.9 17.725C12.25 15.695 10.305 15.135 9.26 14.84V14.85ZM18 6H12V0L18 6Z"
        fill={fill}
      />
    </svg>
  );
};

export default BillIcon;
