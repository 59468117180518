import { CreateExpensePayload, UpdateExpensePayload } from '../types';
import apiClient from './api-client';

export const getExpensesApi = (userId: string, year: string) => {
  return apiClient.get(`/expense?userId=${userId}&year=${year}`);
};

export const createExpenseApi = (payload: CreateExpensePayload[]) => {
  return apiClient.post('/expense', payload);
};

export const updateExpenseApi = (payload: UpdateExpensePayload) => {
  return apiClient.post(`expense/update`, payload);
};

export const deleteExpenseApi = ({
  userId,
  year,
  expenseId,
}: {
  userId: string;
  year: string;
  expenseId: string;
}) => {
  return apiClient.delete(
    `expense?userId=${userId}&year=${year}&expenseId=${expenseId}`
  );
};
