import { CreateSoilSamplePayload, UpdateSoilSamplePayload } from '../types';
import apiClient, { apiClientWithoutToken } from './api-client';

export const getSoilSamplesApi = (userId: string) => {
  return apiClient.get(`/soil/samples?userId=${userId}`);
};

export const createSoilSampleApi = (payload: CreateSoilSamplePayload) => {
  return apiClient.post('/soil/samples', payload);
};

export const deleteSoilSampleApi = ({
  date,
  user_id_legal_description_other_zone,
}: {
  user_id_legal_description_other_zone: string;
  date: string;
}) => {
  return apiClient.delete(
    `soil/samples?user_id_legal_description_other_zone=${user_id_legal_description_other_zone}&date=${date}`
  );
};

export const updateSoilSampleApi = (payload: UpdateSoilSamplePayload) => {
  return apiClient.post(`soil/samples/update`, payload);
};

export const getBLMApi = (lat: string, lon: string) => {
  return apiClientWithoutToken.get(
    'https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/3/query?',
    {
      params: {
        geometry: `${lon},${lat}`,
        geometryType: 'esriGeometryPoint',
        inSR: 4326,
        outSR: 4326,
        spatialRel: 'esriSpatialRelIntersects',
        distance: 1,
        units: 'esriSRUnit_Meter',
        outFields: 'TWNSHPNO,TWNSHPDIR,RANGENO,RANGEDIR,FRSTDIVNO',
        returnGeometry: 'false',
        returnTrueCurves: 'false',
        returnIdsOnly: 'false',
        returnCountOnly: 'false',
        returnZ: 'false',
        returnM: 'false',
        returnDistinctValues: 'false',
        returnExtentOnly: 'false',
        featureEncoding: 'esriDefault',
        f: 'pjson',
      },
    }
  );
};
