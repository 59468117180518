import type { MapboxOverlayProps } from '@deck.gl/mapbox';
import { MapboxOverlay } from '@deck.gl/mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map, useControl, Popup, LngLat, MapMouseEvent } from 'react-map-gl';
import DrawCircle from '../../../components/mapbox-draw/draw/circle';
import DrawRectangle from '../../../components/mapbox-draw/draw/rectangle';
import { MAP_STYLE } from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import {
  useCreateStudy,
  useHistoricalInsights,
  useStudyData,
  useUpdateStudy,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useCenter } from '../map/useCenter';
import { useDateSet } from '../map/useDateSet';
import { useViewBounds } from '../map/useViewBounds';
import { useLayers } from './useLayers';
import { getTooltip } from './helpers';
import DeckGL from '@deck.gl/react';
import { useWData } from './useWData';
import { useS3Keys } from './useS3Keys';
import {
  EditableGeoJsonLayer,
  DrawPolygonMode,
  FeatureCollection,
  DrawSquareMode,
  DrawRectangleMode,
  GeoJsonEditMode,
  ModifyMode,
  DrawCircleFromCenterMode,
  ViewMode,
  ElevationMode,
  DrawLineStringMode,
  DrawPolygonByDraggingMode,
  TransformMode,
  Feature,
} from '@deck.gl-community/editable-layers';
import * as turf from '@turf/turf';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';

function DeckGLOverlay(props: MapboxOverlayProps) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export default function MapSection() {
  const user = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [drawMode, setDrawMode] = useState<string>('draw_polygon');

  const [drawing, setDrawing] = useState<boolean>(false);

  const {
    viewState,
    setViewState,
    year,
    crop,
    relationship,
    map,
    drawRef,
    varietyYieldPerformance,
    newCenter,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setSelectionGridIndicies,
    selectedStudyIndex,
    selectedDrawMode,
    deck,
    creatingStudy,
    studyDetails,
    setStudyDetails,
    createStudy,
    setCreateStudy,
    setCreatingStudy,
    trigger,
    setTrigger,
    rgb,
    myFeatureCollection,
    setMyFeatureCollection,
    saveEditingStudy,
    setSaveEditingStudy,
    editMode,
    setEditMode,
    setModalMode,
  } = useHistoricalInsights();

  // add an event listener for the delet key to delete selected feature indexes from
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedFeatureIndexes([]);
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        // remove the last feature
        let newFeatures = myFeatureCollection.features.slice(0, -1);
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFeatureIndexes, myFeatureCollection.features]);

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    userId: user?.id || '',
    crop,
    year,
    relationship,
  });

  const { dateSet } = useDateSet(s3Keys);
  const { wData, loading: wDataLoading } = useWData({
    userId: user?.id || '',
    crop,
    year,
    s3Keys,
    relationship,
  });

  const { mutate: updateStudyMutation } = useUpdateStudy();
  const { mutate: createStudyMutation } = useCreateStudy();

  const { center, setCenter } = useCenter(wData);
  const { viewBounds } = useViewBounds(wData);

  const { layers, setLayers, hoverInfo } = useLayers({
    wData,
    relationship,
    featureCollection: myFeatureCollection,
    rgb,
  });

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  useEffect(() => {
    if (center) {
      setViewState((prev: any) => ({
        ...prev,
        longitude: center.lon,
        latitude: center.lat,
      }));
    }
  }, [center]);

  useEffect(() => {
    if (newCenter.length > 1) {
      setCenter({ lon: newCenter[0], lat: newCenter[1] });
    }
  }, [newCenter]);

  // Create the draw control once and store it in a ref
  const drawControl = useRef<MapboxDraw | null>(null);

  const drawModes = [
    ViewMode,
    DrawPolygonMode,
    DrawSquareMode,
    DrawRectangleMode,
    DrawCircleFromCenterMode,
    DrawPolygonByDraggingMode,
    ModifyMode,
    TransformMode,
  ];

  const layer = new EditableGeoJsonLayer({
    id: 'geojson-layer',
    mode: drawModes[selectedDrawMode],
    selectedFeatureIndexes: selectedFeatureIndexes,
    data: myFeatureCollection,
    modeConfig: { enableSpapping: true },
    onEdit: ({ updatedData, editType }: any) => {
      setMyFeatureCollection(updatedData);
      console.log('editType', editType);
      if (editType === 'updateTentativeFeature') {
        setModalMode('drawingShape');
      } else if (editType === 'addFeature') {
        setModalMode('drawingCompleted');
      }
    },
    onClick: (info) => {
      if (
        selectedDrawMode !== 0 &&
        selectedDrawMode !== 6 &&
        selectedDrawMode !== 7
      ) {
        return;
      }
      console.log('clicked info', info);
      if (selectedFeatureIndexes.includes(info.index)) {
        console.log('removing index', info.index);
        setSelectedFeatureIndexes(
          selectedFeatureIndexes.filter((index) => index !== info.index)
        );
        return;
      }
      console.log('adding index', info.index);
      setSelectedFeatureIndexes([...selectedFeatureIndexes, info.index]);
    },
  });

  const calculateTotalArea = () => {
    try {
      const data: any = myFeatureCollection;
      if (data!.features.length > 0) {
        let area = 0;
        data!.features.forEach((feature: any) => {
          area += turf.area(feature);
        });
        return area;
      }
      return 0;
    } catch (e) {
      console.log('Error calculating total area:', e);
      return 0;
    }
  };

  const calculateAverages = () => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    let avgLat = 0;
    let avgLon = 0;
    let num = 0;
    let x = 0;
    let yieldVar = 0;
    let yieldPerformance = 0;

    wData?.map((data, index) => {
      data.lon.forEach((lon: number, lonIndex: number) => {
        const lat = data.lat[lonIndex];
        // ensure point is in a feature
        if (
          !myFeatureCollection.features.some((feature: any) =>
            turf.booleanPointInPolygon(
              [lon, lat],
              turf.polygon(feature.geometry.coordinates)
            )
          )
        ) {
          return;
        }
        let bin = data[relationship][lonIndex];
        if (typeof bin !== 'object') {
          bin = [bin];
        }
        x += bin.reduce((a: number, b: number) => a + b, 0) / bin.length;
        min = Math.min(min, x);
        max = Math.max(max, x);
        avgLat += data.lat[lonIndex];
        avgLon += lon;
        num += 1;
        yieldVar += parseFloat(data.yield[lonIndex]);
        yieldPerformance += parseFloat(data.yield_performance[lonIndex]);
      });
    });
    return {
      xbin: `${min.toFixed(2)} - ${max.toFixed(2)}`,
      avgLat: (avgLat / num).toFixed(2),
      avgLon: (avgLon / num).toFixed(2),
      x: (x / num).toFixed(2),
      yieldVar: (yieldVar / num).toFixed(2),
      yield_performance: (yieldPerformance / num).toFixed(2),
    };
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    async function createStudyFunc() {
      if (createStudy) {
        if (!studyDetails.name) {
          alert('Please enter a study name');
          setCreateStudy(false);
          return;
        }
        console.log('creating study', studyDetails);
        createStudyMutation(
          { ...studyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study added');
              queryClient.invalidateQueries([
                'historical-insights/createStudy',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error creating study:', error);
              alert(`Failed to create study. Please try again. ${error}`);
            },
          }
        );
        setCreateStudy(false);
      }
    }
    createStudyFunc();
  }, [createStudy]);

  // write a use effect for saveEditingStudy
  useEffect(() => {
    async function saveEditingStudyFunc() {
      if (saveEditingStudy != null && editMode === 'save') {
        const area = calculateTotalArea();
        const avgs = calculateAverages();
        const polygons = myFeatureCollection.features.map((feature: any) => {
          return feature?.geometry.coordinates;
        });

        const saveStudyDetails = {
          ...saveEditingStudy,
          area,
          ...avgs,
          polygons,
          userId: user?.id || '',
        };

        updateStudyMutation(
          { ...saveStudyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study saved');
              queryClient.invalidateQueries([
                'historical-insights/study-data',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error saving study:', error);
              alert(`Failed to save study. Please try again. ${error}`);
            },
          }
        );
        setSaveEditingStudy(null);
        setEditMode('');
      }
    }
    saveEditingStudyFunc();
  }, [saveEditingStudy, editMode]);

  useEffect(() => {
    if (creatingStudy) {
      const area = calculateTotalArea();
      const avgs = calculateAverages();
      const polygons = myFeatureCollection.features.map((feature: any) => {
        return feature?.geometry.coordinates;
      });

      setStudyDetails({
        ...studyDetails,
        area,
        ...avgs,
        userId: user?.id || '',
        crop,
        year,
        relationship,
        polygons,
      });
      // setCreatingStudy(false)
    }
  }, [creatingStudy]);

  const studies: any = useStudyData(user?.id ?? '', year);

  const [mouseMovePopup, setMouseMovePopup] = useState<LngLat>();

  // Handle mouse move
  const handleMouseMove = (event: MapMouseEvent) => {
    setMouseMovePopup(event.lngLat);
  };

  useEffect(() => {
    if (!studies.data) return;
    let allFeatures: any = [];
    for (let i = 0; i < studies.data.length; i++) {
      const study = studies.data[i];
      if (selectedStudyIndex === study.user_id_type_crop_year + study.x_index) {
        const polygons = study.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        allFeatures = allFeatures.concat(features);
      }
    }
    setMyFeatureCollection({
      type: 'FeatureCollection',
      features: allFeatures,
    });
    setTrigger(trigger + 1);
  }, [selectedStudyIndex]);

  useEffect(() => {
    if (myFeatureCollection.features.length < 1) {
      console.log('no features');
      return;
    }
    setTimeout(() => {
      let index = myFeatureCollection.features.length - 1;
      if (index < 0) {
        return;
      }
      const updatedIndexes = [...selectedFeatureIndexes, index];
      // filter duplicates
      const updatedIndexesSet = new Set(updatedIndexes);
      console.log('updatedIndexesSet', Array.from(updatedIndexesSet));
      setSelectedFeatureIndexes(Array.from(updatedIndexesSet));
    }, 10);
  }, [myFeatureCollection.features]);

  return (
    <div className="relative h-[500px] w-full rounded-2xl overflow-hidden">
      <DeckGL
        layers={[layers, layer]}
        viewState={viewState}
        controller={true}
        onViewStateChange={handleViewStateChange}
        getTooltip={(info: any) => getTooltip(info, relationship)}
        ref={deck}
      >
        <Map
          reuseMaps
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle={MAP_STYLE}
          ref={map}
        />
      </DeckGL>
    </div>
  );
}
