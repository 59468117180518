import { useQuery } from '@tanstack/react-query';
import { getFuturesContractsApi } from '../../api/contracts-api';
import { ContractRes } from '../../types';
import { convertDate, groupBy, sortByDate } from '../../utils';

export const useFuturesContracts = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['contracts/futures'],
    queryFn: getFuturesContractsApi,
  });

  return { data: data?.data as ContractRes[] | [], ...rest };
};

// helpers
export const getPriceOptions = (contracts: ContractRes[], cropName: string) => {
  if (!contracts) return [];
  const contractWithDate = contracts.map((contract) => ({
    ...contract,
    date: convertDate(contract.month),
  }));
  const data = groupBy(contractWithDate, 'crop');
  const sortedData = sortByDate(data[cropName]);
  return sortedData?.map((item: ContractRes) => {
    return { value: item.symbol, label: item.month };
  });
};
