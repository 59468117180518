import { IconProps } from '../../types';

const PhotoUploadIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5151 6.61523C10.5151 7.45898 11.1714 8.11523 12.0151 8.11523H18.0151V22.3652C18.0151 23.6309 16.9839 24.6152 15.7651 24.6152H2.26514C0.999512 24.6152 0.0151367 23.6309 0.0151367 22.3652V2.86523C0.0151367 1.64648 0.999512 0.615234 2.26514 0.615234H10.5151V6.61523ZM4.51514 11.1152C3.67139 11.1152 3.01514 11.8184 3.01514 12.6152C3.01514 13.459 3.67139 14.1152 4.51514 14.1152C5.31201 14.1152 6.01514 13.459 6.01514 12.6152C6.01514 11.8184 5.31201 11.1152 4.51514 11.1152ZM14.9214 21.2402C15.0151 21.0059 15.0151 20.6777 14.8745 20.4902L10.8901 14.4902C10.7495 14.2559 10.5151 14.1152 10.2339 14.1152C9.99951 14.1152 9.76514 14.2559 9.62451 14.4902L7.51514 17.6777L6.85889 16.7402C6.71826 16.5059 6.53076 16.3652 6.24951 16.3652C5.96826 16.3652 5.73389 16.5059 5.64014 16.7402L3.10889 20.4902C2.96826 20.7246 2.96826 21.0059 3.06201 21.2402C3.20264 21.4746 3.48389 21.6152 3.76514 21.6152H14.2651C14.4995 21.6152 14.7808 21.4746 14.9214 21.2402ZM12.0151 0.615234L18.0151 6.61523H12.0151V0.615234Z"
        fill={fill}
      />
    </svg>
  );
};

export default PhotoUploadIcon;
