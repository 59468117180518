import { IconProps } from '../../types';

const FarmBudgetIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3.75C12 4.17188 11.625 4.5 11.25 4.5C9.14062 4.5 7.5 2.85938 7.5 0.75C7.5 0.375 7.82812 0 8.25 0C10.3125 0 12 1.6875 12 3.75ZM4.875 0.75C5.48438 0.75 6 1.26562 6 1.875C6 2.53125 5.48438 3 4.875 3H2.625C1.96875 3 1.5 2.53125 1.5 1.875C1.5 1.26562 1.96875 0.75 2.625 0.75H4.875ZM6.375 4.125C6.98438 4.125 7.5 4.64062 7.5 5.25C7.5 5.90625 6.98438 6.375 6.375 6.375H1.125C0.46875 6.375 0 5.90625 0 5.25C0 4.64062 0.46875 4.125 1.125 4.125H6.375ZM1.5 8.625C1.5 8.01562 1.96875 7.5 2.625 7.5H4.875C5.48438 7.5 6 8.01562 6 8.625C6 9.28125 5.48438 9.75 4.875 9.75H2.625C1.96875 9.75 1.5 9.28125 1.5 8.625ZM12.75 0.75C12.75 0.375 13.0781 0 13.5 0C15.5625 0 17.25 1.6875 17.25 3.75C17.25 4.17188 16.875 4.5 16.5 4.5C14.3906 4.5 12.75 2.85938 12.75 0.75ZM22.5 3.75C22.5 4.17188 22.125 4.5 21.75 4.5C19.6406 4.5 18 2.85938 18 0.75C18 0.375 18.3281 0 18.75 0C20.8125 0 22.5 1.6875 22.5 3.75ZM18.75 10.5C18.3281 10.5 18 10.1719 18 9.75C18 7.6875 19.6406 6 21.75 6C22.125 6 22.5 6.375 22.5 6.75C22.5 8.85938 20.8125 10.5 18.75 10.5ZM16.5 6C16.875 6 17.25 6.375 17.25 6.75C17.25 8.85938 15.5625 10.5 13.5 10.5C13.0781 10.5 12.75 10.1719 12.75 9.75C12.75 7.6875 14.3906 6 16.5 6ZM8.25 10.5C7.82812 10.5 7.5 10.1719 7.5 9.75C7.5 7.6875 9.14062 6 11.25 6C11.625 6 12 6.375 12 6.75C12 8.85938 10.3125 10.5 8.25 10.5ZM9 18C9 16.3594 10.3125 15 12 15C13.6406 15 15 16.3594 15 18C15 19.6875 13.6406 21 12 21C10.3125 21 9 19.6875 9 18ZM21.75 12C22.9688 12 24 13.0312 24 14.25V21.75C24 23.0156 22.9688 24 21.75 24H2.25C0.984375 24 0 23.0156 0 21.75V14.25C0 13.0312 0.984375 12 2.25 12H21.75ZM2.25 19.1719C3.5625 19.3594 4.64062 20.3906 4.82812 21.75H19.125C19.3125 20.3906 20.3906 19.3594 21.75 19.1719V16.875C20.3906 16.6875 19.3125 15.6094 19.125 14.25H4.82812C4.64062 15.6094 3.5625 16.6875 2.25 16.875V19.1719Z"
        fill={fill}
      />
    </svg>
  );
};

export default FarmBudgetIcon;
