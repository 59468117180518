import { IconProps } from '../../types';

export default function ChartIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5596 1.36908C12.5596 0.970052 12.8257 0.615356 13.269 0.615356C18.7225 0.615356 23.2005 5.09338 23.2005 10.5468C23.2005 10.9459 22.8458 11.2562 22.4468 11.2562H12.5596V1.36908ZM0.5 12.675C0.5 7.31023 4.49032 2.87654 9.6334 2.16715C10.0768 2.07847 10.4315 2.43317 10.4315 2.8322V13.3844L17.348 20.3453C17.6584 20.6556 17.614 21.1433 17.3037 21.365C15.5302 22.6065 13.402 23.3159 11.1409 23.3159C5.24405 23.3159 0.5 18.5718 0.5 12.675ZM23.8212 13.3844C24.2202 13.3844 24.5749 13.7391 24.4863 14.1381C24.1759 16.621 22.9788 18.8378 21.2053 20.4783C20.9393 20.7 20.5403 20.7 20.2743 20.434L13.269 13.3844H23.8212Z"
        fill={fill}
      />
    </svg>
  );
}
