import { Ref, useEffect } from 'react';

export function useOutsideClick(
  refs: Ref<HTMLElement>[],
  callback: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      // Check if the click is outside the refs and not on its children
      if (
        !refs.some((ref: any) => {
          return ref.current && ref.current.contains(event.target);
        })
      ) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, refs]);
}
