import React from 'react';
import HorizontalBar from '../../../components/historical-insights/Bar';
import PercentageHorizontalBar from '../../../components/historical-insights/PercentageBar';
import {
  useHistoricalInsights,
  useTableData,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useUser } from '../../../contexts/auth-context';

function YieldCharts() {
  const user = useUser();
  const { year, relationship, crop } = useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const tableLength = tableData?.data?.length * 200;

  return (
    <div className={`flex flex-col w-full h-full gap-4`}>
      {/* yield & variety */}
      <div className="w-full p-2 pb-4 card flex flex-col h-[250px]">
        <div className="px-6 pt-2 flex justify-between w-full">
          <p className="text-xl ">
            {relationship.charAt(0).toUpperCase() + relationship.slice(1)} &
            Yield
          </p>
          <p className="text-xl ">{year}</p>
        </div>
        <HorizontalBar />
      </div>

      <div className="w-full p-2 card pb-4 flex flex-col h-[230px]">
        <div className="flex px-6 pt-2 justify-between w-full">
          <p className="text-xl">
            {relationship.charAt(0).toUpperCase() + relationship.slice(1)} &
            Yield Performance
          </p>
          <p className="text-xl">{year}</p>
        </div>
        <PercentageHorizontalBar />
      </div>
    </div>
  );
}

export default YieldCharts;
