import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import Select from '../../../components/commons/Select';
import { CropOptions } from '../../../constants';
import { useUpdateFieldPolygon } from '../../../hooks/field-polygon/use-field-polygon';
import { getCrop2, getYearOptions } from '../../../utils';
import { FeatureData } from './helpers';
interface EditFIBFormProps {
  onCancel: () => void;
  data: any;
  activeFeatureData?: FeatureData;
  isFetchingFeatureData?: boolean;
  afterSubmit?: () => void;
}

export default function EditFIBForm({
  onCancel,
  data,
  activeFeatureData,
  isFetchingFeatureData,
  afterSubmit,
}: EditFIBFormProps) {
  const form = useForm({
    defaultValues: {
      field: data.field,
      crop: data.crop,
      crop_year: data.crop_year,
      area: data.area,
      section: data.section,
      township: data.township,
      townshipdir: data.townshipdir,
      rangeno: data.rangeno,
      rangedir: data.rangedir,
      geojson: data.geojson,
      legals_json: data.legals_json,
    },
  });
  const { mutateAsync: update, isPending } = useUpdateFieldPolygon();

  const crop = form.watch('crop');
  const crop2 = getCrop2(crop);

  const handleSubmit = async ({
    crop_year,
    ...values
  }: {
    [key: string]: any;
  }) => {
    // dont send crop_year from in the updates
    const updates = Object.entries(values).map(([key, value]) => ({
      key,
      value,
    }));

    try {
      await update({
        new_crop_year: crop_year,
        crop_year: data.crop_year, // keep crop_year from the data
        user_id_field_index: data.user_id_field_index, // keep user_id_field_index from the data
        updates: [...updates, { key: 'crop2', value: crop2 }],
      });
    } catch (error) {
      console.error(error);
    }
    afterSubmit?.();
  };

  useEffect(() => {
    if (activeFeatureData) {
      form.reset({
        ...form.getValues(),
        area: activeFeatureData.area,
        section: activeFeatureData.section,
        township: activeFeatureData.township,
        townshipdir: activeFeatureData.townshipdir,
        rangeno: activeFeatureData.rangeno,
        rangedir: activeFeatureData.rangedir,
        geojson: activeFeatureData.geojson,
        legals_json: activeFeatureData.legals_json,
      });
    }
  }, [activeFeatureData]);

  return (
    <div className="p-4 bg-base-800">
      <p className="text-xl-bold flex items-center space-x-2 mb-2">
        <span>Edit Fields</span>
        <span>
          <InformationCircleIcon className="size-6 fill-secondary" />
        </span>
      </p>
      <FormProvider {...form}>
        <form
          className="rounded bg-base-1000"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <table className="w-full border-separate border-spacing-0">
            <thead>
              <tr className="border border-base-1100">
                <th className="py-2 px-4 border border-base-1100 border-b-base-900 rounded-tl">
                  Field Name
                </th>
                <th className="py-2 px-4 border border-base-1100 border-b-base-900">
                  Crop
                </th>
                <th className="py-2 px-4 border border-base-1100 border-b-base-900 rounded-tr">
                  Year
                </th>
              </tr>
            </thead>
            <tbody className="bg-base-1100">
              <tr>
                <td className="border border-base-900 p-2 px-4 w-auto">
                  <TextInput name="field" label="Field Name" />
                </td>
                <td className="border border-base-900 p-2 px-4 w-[400px]">
                  <Select
                    name="crop"
                    label="Edit Field Crop"
                    options={[
                      {
                        label: 'Select',
                        value: '',
                      },
                      ...CropOptions,
                    ]}
                  />
                </td>
                <td className="border border-base-900 p-2 px-4 w-[250px]">
                  <Select
                    name="crop_year"
                    label="Change Year"
                    options={[
                      {
                        label: 'Select',
                        value: '',
                      },
                      ...getYearOptions(),
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="bg-base-1100 p-4">
            <p>
              The fields below are auto populated based upon the information you
              have provided. *Please make any necessary corrections.
            </p>
            <div className="flex space-x-8 mt-6">
              <TextInput name="area" label="Area" />
              <TextInput name="section" label="Section" />
              <TextInput name="township" label="Township" />
              <TextInput name="townshipdir" label="Townshipdir" />
              <TextInput name="rangeno" label="Rangeno" />
              <TextInput name="rangedir" label="Rangedir" />
            </div>
          </div>

          <div className="flex p-4 justify-end rounded-bl rounded-br space-x-4">
            <Button className="!w-36" onClick={onCancel} disabled={isPending}>
              Cancel
            </Button>
            <Button
              className="!w-36"
              type="submit"
              color="primary"
              disabled={isPending || isFetchingFeatureData}
              loading={isPending || isFetchingFeatureData}
            >
              Apply
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
