import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getUserRealTimeSettingsApi,
  setUserRealTimeSettingsApi,
  updateUserRealTimeSettingsApi,
} from '../../api/user-api';
import {
  UserRealTimeSettingsRes,
  UserSettingRealTimeUpdatePayload,
  UserSettingsRealTimeSetPayload,
} from '../../types';

export const useUserRealTimeSettings = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['user/settings/real-time'],
    queryFn: () => getUserRealTimeSettingsApi(userId, year),
    enabled: !!userId && !!year,
  });

  return { data: (data?.data as UserRealTimeSettingsRes[]) || [], ...rest };
};

export const useSetUserRealTimeSettings = () => {
  const mutation = useMutation({
    mutationKey: ['user/settings/real-time'],
    mutationFn: (payload: UserSettingsRealTimeSetPayload[]) =>
      setUserRealTimeSettingsApi(payload),
  });

  return mutation;
};

export const useUpdateUserRealTimeSettings = () => {
  const mutation = useMutation({
    mutationKey: ['user/settings/real-time/update'],
    mutationFn: (payload: UserSettingRealTimeUpdatePayload) =>
      updateUserRealTimeSettingsApi(payload),
  });

  return mutation;
};
