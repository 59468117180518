import CardHeader from '../../commons/CardHeader';
import Currency from '../../commons/Currency';
import NumberDisplay from '../../commons/NumberDisplay';

export default function CurrentRiskMGTStrategiesCard() {
  return (
    <div className="card p-6 h-full space-y-8">
      <CardHeader title="With Current Risk Mgt. Strategies" tooltip="demo" />
      <div className="space-y-4">
        <p>
          <NumberDisplay value={0.5} numberStyle="percent" /> Chance of Earning
          Revenue of <Currency value={333333} />
        </p>
        <p>
          <NumberDisplay
            value={0.1}
            numberStyle="percent"
            className="text-red"
          />{' '}
          Chance of Earning Revenue Less than{' '}
          <Currency value={111111} className="text-red" />
        </p>
        <p>
          <NumberDisplay
            value={0.1}
            numberStyle="percent"
            className="text-primary"
          />{' '}
          Chance of Earning Revenue More than{' '}
          <Currency value={444444} className="text-primary" />
        </p>
      </div>

      <p className="display-xs-bold">Revenue Probability Distribution</p>

      <svg
        width="356"
        height="146"
        viewBox="0 0 356 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.15625 119.23C6.15625 117.021 7.94711 115.23 10.1562 115.23H26.1562C28.3654 115.23 30.1562 117.021 30.1562 119.23V145.23H6.15625V119.23Z"
          fill="#F42727"
        />
        <path
          d="M46.1562 104.23C46.1562 102.021 47.9471 100.23 50.1562 100.23H66.1562C68.3654 100.23 70.1562 102.021 70.1562 104.23V145.23H46.1562V104.23Z"
          fill="#FFB71B"
        />
        <path
          d="M86.1562 59.2305C86.1562 57.0213 87.9471 55.2305 90.1562 55.2305H106.156C108.365 55.2305 110.156 57.0213 110.156 59.2305V145.23H86.1562V59.2305Z"
          fill="#FFB71B"
        />
        <path
          d="M126.156 34.2305C126.156 32.0213 127.947 30.2305 130.156 30.2305H146.156C148.365 30.2305 150.156 32.0213 150.156 34.2305V145.23H126.156V34.2305Z"
          fill="#FFB71B"
        />
        <path
          d="M166.156 4.23047C166.156 2.02133 167.947 0.230469 170.156 0.230469H186.156C188.365 0.230469 190.156 2.02133 190.156 4.23047V145.23H166.156V4.23047Z"
          fill="white"
        />
        <path
          d="M206.156 34.2305C206.156 32.0213 207.947 30.2305 210.156 30.2305H226.156C228.365 30.2305 230.156 32.0213 230.156 34.2305V145.23H206.156V34.2305Z"
          fill="#FFB71B"
        />
        <path
          d="M246.156 59.2305C246.156 57.0213 247.947 55.2305 250.156 55.2305H266.156C268.365 55.2305 270.156 57.0213 270.156 59.2305V145.23H246.156V59.2305Z"
          fill="#FFB71B"
        />
        <path
          d="M286.156 104.23C286.156 102.021 287.947 100.23 290.156 100.23H306.156C308.365 100.23 310.156 102.021 310.156 104.23V145.23H286.156V104.23Z"
          fill="#FFB71B"
        />
        <path
          d="M326.156 119.23C326.156 117.021 327.947 115.23 330.156 115.23H346.156C348.365 115.23 350.156 117.021 350.156 119.23V145.23H326.156V119.23Z"
          fill="#80BC00"
        />
      </svg>
    </div>
  );
}
