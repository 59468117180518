import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { cloneElement, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { theme } from 'twin.macro';
import MoveIcon from '../../../assets/icons/MoveIcon';
import UndoIcon from '../../../assets/icons/UndoIcon';
import ZoomInIcon from '../../../assets/icons/ZoomInIcon';
import ZoomOutIcon from '../../../assets/icons/ZoomOutIcon';
import Select, { AwaitSelect } from '../../../components/commons/Select';
import SwitchInput from '../../../components/commons/inputs/SwitchInput';
import { MapContext } from '../../../contexts/map-context';
import LikelihoodExpectedRevenue from './LikelihoodExpectedRevenue';
import MapSection from './MapSection';
import RevenueByField from './RevenueByField';
import RevenuePerAcre from './RevenuePerAcre';
import { tabs } from './helpers';
import MapToolbar from './MapToolbar';
import { useUser } from '../../../contexts/auth-context';
import { FeatureCollection } from '@deck.gl-community/editable-layers';
import { mapParamsToTitles } from '../../../constants';
import RelationshipLineChart from './RelationshipLineChart';
import { XCircleIcon } from '@heroicons/react/24/solid';

function Map() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [isShowPoints, setIsShowPoints] = useState(false);
  const [selectedArea, setSelectedArea] = useState('all');
  const [page, setPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [selectedStudyIndex, setSelectedStudyIndex] = useState(0);
  const [studies, setStudies] = useState<string[]>([]);
  const [selectedDrawMode, setSelectedDrawMode] = useState<number>(0);
  const [creatingStudy, setCreatingStudy] = useState<boolean>(false);
  const [studyDetails, setStudyDetails] = useState<any>({});
  const [modalMode, setModalMode] = useState<string>('');
  const user = useUser();
  const [viewState, setViewState] = useState({
    longitude: user?.lon ? parseFloat(user?.lon) : -98.381933,
    latitude: user?.lon ? parseFloat(user?.lat) : 44.704991,
    zoom: 12,
    pitch: 30,
    bearing: 0,
  });
  const [createStudy, setCreateStudy] = useState<boolean>(false);
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<
    number[]
  >([]);
  const [selectedChart, setSelectedChart] = useState<string>('crop');
  const [editMode, setEditMode] = useState<string>('');
  const [saveEditingStudy, setSaveEditingStudy] = useState<any>(null);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<string>('');

  const drawModes = [
    'Move',
    'Polygon',
    'Square',
    'Rectangle',
    'Circle',
    'Drag & Draw',
    'Edit',
    'Transform',
  ];

  const [myFeatureCollection, setMyFeatureCollection] =
    useState<FeatureCollection>({
      type: 'FeatureCollection',
      features: [
        /* insert features here */
      ],
    });
  const { type: typeParam = '' } = useParams();

  const url = window.location.pathname;

  return (
    <MapContext.Provider
      value={{
        modalMode,
        setModalMode,
        selectedArea,
        setSelectedArea,
        selectedFieldIndex,
        setSelectedFieldIndex,
        selectedChart,
        saveEditingStudy,
        setSaveEditingStudy,
        editMode,
        setEditMode,
        trigger,
        setTrigger,
        myFeatureCollection,
        setMyFeatureCollection,
        selectedFeatureIndexes,
        setSelectedFeatureIndexes,
        createStudy,
        setCreateStudy,
        viewState,
        setViewState,
        studyDetails,
        setStudyDetails,
        creatingStudy,
        setCreatingStudy,
        selectedDrawMode,
        setSelectedDrawMode,
        studies,
        setStudies,
        selectedStudyIndex,
        setSelectedStudyIndex,
        selectedZone,
        selectedCrop,
        setSelectedZone,
        setSelectedCrop,
        selectedField,
        setSelectedField,
        selectedDate,
        setSelectedDate,
        page,
        setPage,
      }}
    >
      <div>
        <TabGroup
          className="flex flex-col items-center space-y-8"
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <div
            className={clsx(
              'flex flex-col items-center gap-4 w-full',
              'xl:flex-row justify-between '
            )}
          >
            <div className="flex items-center space-x-4 min-w-[350px]">
              <h4 className="display-sm-bold">Area Selection</h4>
              <Select
                className="rounded-lg w-[205px]"
                size="lg"
                value={selectedArea.toLowerCase()}
                options={[
                  {
                    label: 'All Fields',
                    value: 'all',
                  },
                  {
                    label: 'All Corn Fields',
                    value: 'corn',
                  },
                  {
                    label: 'All Soybean Fields',
                    value: 'soybeans',
                  },
                  {
                    label: 'All Wheat Fields',
                    value: 'wheat',
                  },
                ]}
                name="areaSelection"
                onChange={(e) => setSelectedArea(e.target.value)}
              />
            </div>

            {/* Tab list */}
            <TabList className="card px-3 py-3 w-fit flex justify-between whitespace-nowrap space-x-4 !rounded-lg">
              {Object.keys(tabs).map((key) => (
                <Tab key={key}>
                  {({ hover }) => (
                    <NavLink
                      to={`${url
                        .split('/')
                        .slice(0, url.split('/').length - 1)
                        .join('/')}/${key}`}
                      className={clsx(
                        'text-base-900',
                        'hover:text-base-000',
                        'flex items-center space-x-2 text-md-regular'
                      )}
                    >
                      {({ isActive }) => (
                        <>
                          {cloneElement(
                            tabs[key].icon({
                              fill:
                                isActive || hover
                                  ? theme`colors.base.000`
                                  : theme`colors.base.900`,
                            })
                          )}
                          <p className={isActive ? 'text-base-000' : ''}>
                            {tabs[key].label}
                          </p>
                        </>
                      )}
                    </NavLink>
                  )}
                </Tab>
              ))}
            </TabList>

            {/* Switch */}
            <div>
              <p className="text-base-800 mb-2">
                Show or hide points on the map.
              </p>
              <div className="flex justify-center items-center space-x-3 whitespace-nowrap text-md-bold">
                <p
                  className={clsx(
                    !isShowPoints ? 'text-base-000' : 'text-base-900'
                  )}
                >
                  Hide
                </p>
                <SwitchInput
                  onChange={setIsShowPoints}
                  enabled={isShowPoints}
                />
                <p
                  className={clsx(
                    isShowPoints ? 'text-base-000' : 'text-base-900'
                  )}
                >
                  Show
                </p>
              </div>
            </div>
          </div>

          <div className="w-full gap-4 flex">
            <div className="w-1/3 flex flex-col">
              <div className="w-[100%] flex-col py-2 ">
                <p className="display-sm-bold">
                  Expected{' '}
                  {
                    mapParamsToTitles[
                      (typeParam as keyof typeof mapParamsToTitles) || 'revenue'
                    ]
                  }{' '}
                  per Acre By
                </p>
                <div className="flex items-center mt-4 mb-4">
                  <input
                    type="radio"
                    value="text"
                    name="display"
                    defaultChecked={selectedChart === 'crop'}
                    className="custom-radio"
                    onChange={() => setSelectedChart('crop')}
                  />
                  <span className="mr-4 ml-2">Crop</span>
                  <input
                    type="radio"
                    value="text"
                    name="display"
                    defaultChecked={selectedChart === 'field'}
                    className="custom-radio"
                    onChange={() => setSelectedChart('field')}
                  />
                  <span className="mr-4 ml-2">Field</span>
                  <input
                    type="radio"
                    value="text"
                    name="display"
                    defaultChecked={selectedChart === 'zone'}
                    className="custom-radio"
                    onChange={() => setSelectedChart('zone')}
                  />
                  <span className="mr-4 ml-2">Zone</span>
                </div>
              </div>
              <div className="space-y-8">
                {/* Charts */}
                <div className="card p-6">
                  <RelationshipLineChart
                    date={selectedDate}
                    selectedChart={selectedChart}
                    crop={selectedArea}
                    relationship={typeParam}
                  />
                </div>

                <div className="flex space-y-0 flex-col gap-4">
                  {selectedChart === 'crop' && (
                    <div className="card p-6">
                      <RevenuePerAcre />
                    </div>
                  )}
                  {selectedChart === 'field' && (
                    <div className="card p-6 ">
                      <RevenueByField crop={selectedArea} />
                    </div>
                  )}
                  {selectedChart === 'zone' && (
                    <div className="card p-6">
                      <LikelihoodExpectedRevenue crop={selectedArea} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-2/3 flex flex-col">
              <div className="w-full relative flex justify-center items-center">
                {editMode && (
                  <div className="flex items-center gap-2 absolute top-[130px] cursor-pointer z-[99] bg-darkerGray px-4 py-2 rounded-md">
                    <p>Editing Study: {saveEditingStudy?.name}</p>
                    <XCircleIcon
                      onClick={() => {
                        setEditMode('');
                        setMyFeatureCollection({
                          type: 'FeatureCollection',
                          features: [],
                        });
                      }}
                      className="h-5 w-5 cursor-pointer text-white"
                    />
                  </div>
                )}
                {modalMode === 'drawingModeSelected' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>
                        Click point to start drawing{' '}
                        {drawModes[selectedDrawMode]}
                      </p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {modalMode === 'drawingShape' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      {drawModes[selectedDrawMode] === 'Polygon' ? (
                        <p>Click first point again to close Polygon</p>
                      ) : (
                        <p>Draw {drawModes[selectedDrawMode]}</p>
                      )}
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}

                {modalMode === 'editPolygons' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>Edit Polygon Points</p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {modalMode === 'transformPolygons' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>Transform Polygons</p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}

                {modalMode === 'drawingCompleted' && (
                  <>
                    <div
                      className={`cursor-pointer flex gap-2 items-center absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p className="text-center">
                        {drawModes[selectedDrawMode]} Finished
                      </p>
                      <div
                        onClick={() => setModalMode('drawingModeSelected')}
                        className="bg-primary text-white px-2 py-1 rounded-md"
                      >
                        Ok
                      </div>
                      <div
                        onClick={() => {
                          let newFeatures = myFeatureCollection.features.slice(
                            0,
                            -1
                          );
                          setMyFeatureCollection({
                            type: 'FeatureCollection',
                            features: newFeatures,
                          });
                          setModalMode('drawingModeSelected');
                        }}
                        className="bg-darkGray text-white px-2 py-1 rounded-md text-center"
                      >
                        Delete Shape
                      </div>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {editMode && (
                  <div
                    onClick={() => setEditMode('save')}
                    className="absolute bottom-4 cursor-pointer z-[99] bg-primary px-4 py-2 rounded-md"
                  >
                    <p>Save Study</p>
                  </div>
                )}
                <MapSection
                  selectedArea={selectedArea}
                  setSelectedDate={setSelectedDate}
                />
              </div>

              <MapToolbar />
            </div>
          </div>
        </TabGroup>
      </div>
    </MapContext.Provider>
  );
}

export default Map;
