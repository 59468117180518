import { groupBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import {
  useDeleteHedge,
  useHedges,
  useUpdateHedge,
} from '../../../../hooks/hedge/use-hedge';
import { HedgeItem } from '../../../../types/hedge';
import Button from '../../../commons/Button';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import TextInput from '../../../commons/inputs/TextInput';
import Modal from '../../../commons/modal';
import Table, { RowActions, TableConfigs } from '../../../commons/Table';
import TableLayout from '../../commons/TableLayout';
import EditHedgeForm from './EditHedgeForm';

interface HedgingListProps {}

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'hedge_name',
      name: 'Name',
    },
    {
      key: 'position',
      name: 'Position',
    },
    {
      key: 'hedge_type',
      name: 'Type',
    },
    {
      key: 'contract_date',
      name: (
        <>
          <p>Futures</p>
          <p className="text-xs-bold">(Underlying)</p>
        </>
      ),
    },
    {
      key: 'crop',
      name: (
        <>
          <p>Exchange</p>
          <p className="text-xs-bold">(Crop)</p>
        </>
      ),
    },
    {
      key: 'price',
      name: 'Trade Price',
    },
    {
      key: 'strike',
      name: 'Strike',
    },
    {
      key: 'dateTrade',
      name: 'Date Trade',
    },
    {
      key: 'quantity',
      name: (
        <>
          <p className="whitespace-nowrap">Qty. Hedged</p>
          <p className="text-xs-bold">(or Marketed)</p>
        </>
      ),
    },
    {
      key: 'fieldApplied',
      name: 'Field Applied',
    },
    {
      key: 'delta',
      name: 'Delta',
      total: true,
    },
    {
      key: 'valuation',
      name: 'Valuation',
      type: 'fluctuation',
      total: true,
    },
    {
      key: 'p_and_l',
      name: 'Profit/Loss',
      type: 'fluctuation',
      total: true,
    },
    { key: 'exit_date', name: 'Exit Date' },
    { key: 'exit_price', name: 'Exit Price' },
  ],
};

const Crops = {
  Corn: {
    name: 'Corn Hedging',
    tooltip: 'Corn Hedging Tooltip',
  },
  Soybeans: {
    name: 'Soybeans Hedging',
    tooltip: 'Soybeans Hedging Tooltip',
  },
  Wheat: {
    name: 'Wheat Hedging',
    tooltip: 'Wheat Hedging Tooltip',
  },
};

export default function HedgingList(props: HedgingListProps) {
  const user = useUser();
  const year = useYearFilter();
  const { data, isFetching } = useHedges(user?.id ?? '', year ?? '');
  const dataGroupByCrop = groupBy(data, 'crop');
  const [exitHedge, setExitHedge] = useState<HedgeItem | undefined>();

  const form = useForm();

  const { mutate: deleteHedge } = useDeleteHedge();
  const { mutateAsync: exit } = useUpdateHedge();

  const rowActions: RowActions = {
    delete(item) {
      deleteHedge(item);
    },
    edit: {
      onSelect: (item) => {},
      form: ({ item, onClose }) => {
        return <EditHedgeForm data={item} onCancel={onClose} />;
      },
    },
  };

  const handleExit = async (values: any) => {
    const payload = {
      ...exitHedge,
      ...values,
      isExited: true,
    };
    try {
      await exit(payload);
      form.reset();
      setExitHedge(undefined);
      enqueueSnackbar('Hedge exited successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to exit hedge', { variant: 'error' });
    }
  };

  const extraActions = (item: HedgeItem) => {
    return (
      <div className="flex space-x-2 mx-auto w-fit">
        {!item.isExited && (
          <Button color="danger" size="sm" onClick={() => setExitHedge(item)}>
            Exit
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <TableLayout title="Current Hedging">
        {Object.entries(Crops).map(([crop, { name, tooltip }]) => (
          <Table
            key={crop}
            configs={tableConfigs}
            data={dataGroupByCrop[crop]}
            title={{ name: name, tooltip: tooltip }}
            hasTotalRow
            loading={isFetching}
            indexKey="user_id_unique_hedge_name"
            rowActions={rowActions}
            extraActions={extraActions}
          />
        ))}
      </TableLayout>
      <Modal
        onClose={{
          handler: () => {
            setExitHedge(undefined);
            form.reset();
          },
          btnText: 'Cancel',
        }}
        onConfirm={{
          handler: form.handleSubmit(handleExit),
          btnText: 'Exit',
        }}
        title="Exit Hedge"
        open={!!exitHedge}
        loading={form.formState.isSubmitting}
        disabled={!form.formState.isValid || form.formState.isSubmitting}
      >
        <FormProvider {...form}>
          <form className="flex space-x-4">
            <ComboDatePicker name="exit_date" label="Exit Date" required />
            <TextInput
              name="exit_price"
              label="Exit Price"
              type="number"
              required
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
