import React, { useState, ChangeEvent } from 'react';
import DataInputIcon from '../../assets/icons/DataInputIcon';
import Select from './Select';
import Button from './Button'; // Ensure you have this import
import { useUser } from '../../contexts/auth-context';
import { Form, FormProvider, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { Buffer } from 'buffer';
import { uploadZip } from '../../s3';

const DataUpload: React.FC = () => {
  const [fileUpload, setFileUpload] = useState({ loading: false });
  const [isDragOver, setIsDragOver] = useState(false);
  const user = useUser();
  const methods = useForm();
  const storageKey = methods.watch('storageKey', 'Seeding');

  const handleUploadFile = async (files: FileList) => {
    if (files && files.length > 0) {
      setFileUpload({ ...fileUpload, loading: true });
      try {
        for (const file of files) {
          await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = function (event) {
              if (event.target!.result) {
                const buffer = Buffer.from(
                  new Uint8Array(event.target!.result as ArrayBuffer)
                );
                uploadZip(
                  buffer,
                  file.name,
                  user?.id ?? '',
                  storageKey,
                  (response) => {
                    resolve(null);
                  }
                );
              } else {
                fileUpload.loading = false;
                reject('Could not read file contents');
              }
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
          });
        }
        enqueueSnackbar('Uploaded!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error Uploading File!', { variant: 'error' });
        console.error(error);
      } finally {
        setFileUpload({ ...fileUpload, loading: false });
      }
    }
  };

  const handleInputOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      handleUploadFile(files);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    handleUploadFile(files);
  };

  let bgColor = 'bg-blue-dark';

  return (
    <FormProvider {...methods}>
      <Form className="w-full">
        <div className={`flex w-full flex-col rounded-lg p-2 ${bgColor}`}>
          <div className="flex items-center mt-4 w-full justify-center gap-x-4">
            <p>Storage Type:</p>
            <Select
              options={[
                { value: 'Seeding', label: 'Seeding' },
                { value: 'Harvest', label: 'Harvest' },
                { value: 'Application', label: 'Application' },
                { value: 'Guidance', label: 'Guidance' },
                { value: 'Boundaries', label: 'Boundaries' },
                { value: 'Mixed', label: 'Mixed' },
                { value: 'Other', label: 'Other' },
              ]}
              name="storageKey"
            />
          </div>
          <div
            className={`border-2 flex w-full ${isDragOver && 'border-orange'} justify-center items-center cursor-pointer border-dashed rounded-lg mt-4 h-[100px] w-full`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <Button
              className="py-1 cursor-pointer rounded-lg relative max-w-max h-fit"
              disabled={fileUpload.loading}
              type="button"
              color="primary"
            >
              <input
                type="file"
                name="file"
                className="absolute top-0 left-0 opacity-0 h-full cursor-pointer"
                onChange={handleInputOnchange}
                disabled={fileUpload.loading}
                multiple
              />
              {fileUpload.loading ? 'Uploading...' : 'Choose or drop files'}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default DataUpload;
