import clsx from 'clsx';

interface CurrencyProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: number;
  currency?: string;
  symbol?: string;
  locales?: string;
}

function Currency({
  value,
  currency = 'USD',
  symbol,
  className,
  locales = 'en-US',
  ...rest
}: CurrencyProps) {
  if (typeof value !== 'number') return null;

  const isZero = value === 0;
  const formatedValue = value.toLocaleString(locales, {
    style: 'currency',
    currency,
  });
  return (
    <span className={clsx(className)} {...rest}>
      {isZero ? formatedValue : formatedValue.replace(/(\.|,)00$/g, '')}
    </span>
  );
}

export default Currency;
