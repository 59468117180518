import { IconProps } from '../../types';

const BillPaidIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="18"
      height="25"
      viewBox="0 0 18 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 0.5H10.5V6.5C10.5 7.33 11.17 8 12 8H18V22.25C18 23.495 16.995 24.5 15.75 24.5H2.25C1.005 24.5 0 23.49 0 22.25V2.75C0 1.505 1.005 0.5 2.25 0.5ZM3.375 3.5C3.17 3.5 3 3.67 3 3.875V4.625C3 4.83 3.17 5 3.375 5H7.125C7.33 5 7.5 4.83 7.5 4.625V3.875C7.5 3.67 7.33 3.5 7.125 3.5H3.375ZM3 6.875V7.625C3 7.83 3.17 8 3.375 8H7.125C7.33 8 7.5 7.83 7.5 7.625V6.875C7.5 6.67 7.33 6.5 7.125 6.5H3.375C3.17 6.5 3 6.67 3 6.875ZM9 21.31C11.795 21.31 14.06 19.045 14.06 16.25C14.06 13.455 11.795 11.19 9 11.19C6.205 11.19 3.94 13.455 3.94 16.25C3.94 19.045 6.205 21.31 9 21.31ZM12 0.5V6.5H18L12 0.5ZM6.84995 15.9656C6.54024 15.6674 6.042 15.6674 5.73229 15.9656C5.42257 16.2639 5.42257 16.7437 5.73229 17.0419L7.53671 18.7796C7.84643 19.0735 8.34466 19.0735 8.65438 18.7796L12.2677 15.3C12.5774 15.0017 12.5774 14.5219 12.2677 14.2237C11.958 13.9254 11.4598 13.9254 11.15 14.2237L8.09779 17.163L6.84995 15.9613V15.9656Z"
        fill={fill}
      />
    </svg>
  );
};

export default BillPaidIcon;
