import { useState } from 'react';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import ChemicalList from './ChemicalList';
import AddChemicalForm from './AddChemicalForm';
import ChemicalInfo from './ChemicalInfo';

export default function AddChemicals() {
  const [list, setList] = useState([
    {
      id: 1,
      name: 'Zone 1',
      product: 'Product 1',
      brand: 'Brand 1',
      lbs: 100,
      unit: 'Tonne',
      costPerUnit: 235.12,
      ratePerAcre: 123,
      totalUnits: 0.15,
      totalCost: 1234.99,
      applicationPeriod: 'Period 1',
    },
    {
      id: 2,
      name: 'Zone 2',
      product: 'Product 2',
      brand: 'Brand 2',
      lbs: 200,
      unit: 'Tonne',
      costPerUnit: 123.12,
      ratePerAcre: 123,
      totalUnits: 0.15,
      totalCost: 1234.99,
      applicationPeriod: 'Period 2',
    },
    {
      id: 3,
      name: 'Zone 3',
      product: 'Product 3',
      brand: 'Brand 3',
      lbs: 300,
      unit: 'Toone',
      costPerUnit: 345.12,
      ratePerAcre: 123,
      totalUnits: 0.15,
      totalCost: 1234.99,
      applicationPeriod: 'Period 3',
    },
    {
      id: 4,
      name: 'Zone 4',
      product: 'Product 4',
      brand: 'Brand 4',
      lbs: 400,
      unit: 'Tonne',
      costPerUnit: 456.12,
      ratePerAcre: 123,
      totalUnits: 0.15,
      totalCost: 1234.99,
      applicationPeriod: 'Period 4',
    },
  ]);
  const [selectedZone, setSelectedZone] = useState(1);
  return (
    <DisclosureContentLayout
      left={
        <FlipLayout>
          {({ side, setSide }) => (
            <div>
              {side === 'front' && (
                <ChemicalInfo onFlip={() => setSide('back')} />
              )}
              {side === 'back' && <AddChemicalForm />}
            </div>
          )}
        </FlipLayout>
      }
      right={
        <ChemicalList
          data={list}
          zone={list.find((item) => item.id === selectedZone) ?? {}}
          onSelect={setSelectedZone}
        />
      }
    />
  );
}
