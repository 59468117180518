import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import { theme } from 'twin.macro';
import CalendarIcon from '../../../../../assets/icons/CalendarIcon';
import SpinningIcon from '../../../../../assets/icons/SpinningIcon';
import {
  useSetYearFilter,
  useYearFilter,
} from '../../../../../contexts/app-filter-context';
import YearCalendar from '../../../../commons/calendar/YearCalendar';
import { useYears } from '../../../../../hooks/use-years';

const YearSelect = forwardRef(
  ({ years }: { years: number[] }, ref: ForwardedRef<HTMLElement>) => {
    const { isPending, isFetched } = useYears();
    const year = useYearFilter();
    const setYear = useSetYearFilter();
    const [hovered, setHovered] = useState(false);

    const defaultYear = useMemo(() => {
      let currentYear = new Date().getFullYear();
      if (years.includes(currentYear)) return currentYear;
      return years[years.length - 1];
    }, [years]);

    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <PopoverButton
              className={clsx(
                'group flex w-full items-center gap-2 rounded-lg group hover:text-primary mb-4',
                open && 'text-primary',
                isPending && 'cursor-not-allowed'
              )}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <div
                className={clsx(
                  'w-[3px] h-[full] bg-base-000 rounded-xl self-stretch',
                  'opacity-0',
                  'group-hover:bg-primary',
                  'group-hover:opacity-100',
                  open && 'opacity-100 bg-primary'
                )}
              ></div>
              <CalendarIcon
                fill={hovered || open ? theme`colors.primary` : '#A3B4C2'}
              />
              <span className="flex items-center justify-between space-x-2 w-full">
                <p>Change Year</p>
                {isPending && <SpinningIcon />}
              </span>
            </PopoverButton>

            <PopoverPanel
              anchor="right start"
              className="shadow-lg z-20"
              ref={ref}
            >
              {years.length > 0 && (
                <YearCalendar
                  availableYears={years}
                  defaultYear={year ? parseInt(year) : defaultYear}
                  onChange={(year) => setYear?.(year.toString())}
                />
              )}
            </PopoverPanel>
          </>
        )}
      </Popover>
    );
  }
);

export default YearSelect;
