import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getTextractApi } from '../../api/textract-api';
import { REACT_APP_AWS_BUCKET } from '../../constants';

export const useTextract = () => {
  return useMutation({
    mutationKey: ['textract'],
    mutationFn: ({
      documentName,
      type,
      userId,
    }: {
      documentName: string;
      type: string;
      userId?: string;
    }) =>
      getTextractApi({
        s3BucketName: REACT_APP_AWS_BUCKET,
        documentName,
        type,
        userId,
      }),
    onSuccess: () => {
      enqueueSnackbar('Get textract successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to get textract', { variant: 'error' });
    },
  });
};
