import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createWatchListItemApi,
  deleteWatchListItemApi,
  getWatchListApi,
} from '../../api/market-api';
import { WatchListItemPayload } from '../../types';

export const useCreateWatchList = () => {
  const mutation = useMutation<unknown, unknown, WatchListItemPayload>({
    mutationKey: ['market/watch-list'],
    mutationFn: (payload) => createWatchListItemApi(payload),
  });

  return mutation;
};

export const useDeleteWatchList = () => {
  const mutation = useMutation<unknown, unknown, unknown>({
    mutationKey: ['market/watch-list'],
    mutationFn: (payload) => deleteWatchListItemApi(payload),
  });

  return mutation;
};

export const useWatchList = (user_id: string) => {
  const res = useQuery({
    queryKey: ['market/watch-list'],
    queryFn: () => getWatchListApi(user_id),
    enabled: !!user_id,
  });
  return { data: res.data?.watchList };
};
