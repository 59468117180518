import { IconProps } from '../../types';

export default function DownloadIcon({ fill = '#FFB71B' }: IconProps) {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.479473 5.11975C0.479473 2.58603 2.49707 0.615356 4.98387 0.615356L16.9956 0.615356C19.4824 0.615356 21.5 2.58603 21.5 5.11976L21.5 8.12269C21.5 8.92034 20.7962 9.62415 19.9985 9.62415C19.154 9.62415 18.4971 8.92034 18.4971 8.12269L18.4971 5.11976C18.4971 4.27518 17.7933 3.61829 16.9956 3.61829L4.98387 3.61829C4.1393 3.61829 3.4824 4.27518 3.4824 5.11975L3.4824 8.12269C3.4824 8.92034 2.77859 9.62415 1.98094 9.62415C1.13636 9.62415 0.479473 8.92034 0.479473 8.12269L0.479473 5.11975ZM11.7874 24.2635C11.3651 24.7327 10.5674 24.7327 10.1921 24.2635L3.81085 17.5069C3.4824 17.1784 3.43548 16.7092 3.57625 16.2869C3.76393 15.8646 4.18622 15.63 4.6085 15.63L7.9868 15.63L7.9868 9.62415C7.9868 8.77958 8.64369 8.12269 9.48827 8.12269L12.4912 8.12269C13.2889 8.12269 13.9927 8.77958 13.9927 9.62415L13.9927 15.63L17.371 15.63C17.7933 15.63 18.2155 15.8646 18.4032 16.2869C18.544 16.7092 18.4971 17.1784 18.1686 17.5069L11.7874 24.2635Z"
        fill={fill}
      />
    </svg>
  );
}
