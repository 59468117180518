import FarmExpenses from '../../pages/data-inputs/farm-expenses';

const financialsRoutes = [
  {
    path: 'financials',
    children: [
      {
        index: true,
        element: <FarmExpenses />,
      },
      {
        path: 'farm-expenses',
        element: <FarmExpenses />,
      },
    ],
  },
];

export default financialsRoutes;
