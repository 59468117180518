import { createContext, useContext } from 'react';
import { MarketContextProps } from '../types';

export const MarketContext = createContext<MarketContextProps | undefined>(
  undefined
);

export const useMarketContext = (): MarketContextProps => {
  const context = useContext(MarketContext);
  if (context === undefined) {
    throw new Error('useMarketContext must be used within a MarketProvider');
  }
  return context;
};
