import Currency from '../../commons/Currency';

function HedgedBasisItem() {
  return (
    <div>
      <Currency value={0} />
      <p className="text-primary text-sm-regular">
        for 0.00% of expected production
      </p>
    </div>
  );
}

export default HedgedBasisItem;
