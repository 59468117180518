import { S3 } from 'aws-sdk';
import type { Readable as StreamReadable } from 'stream';
import { Buffer } from 'buffer';
import { AWS_FILES_BUCKET } from '../constants';

let AWS: typeof import('aws-sdk') | undefined;

// Dynamically import the AWS SDK and the stream module
(async () => {
  try {
    const awsModule = await import('aws-sdk');
    AWS = awsModule;
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    });
  } catch (error) {
    console.error('Error loading AWS SDK or Stream module:', error);
  }
})();

export async function uploadFile(
  file: Buffer | Uint8Array | Blob | StreamReadable | string,
  fileName: string,
  type: string,
  callback: (data?: any) => void
): Promise<void> {
  if (!AWS) {
    console.error('AWS SDK not loaded yet');
    callback(new Error('AWS SDK not loaded yet'));
    return;
  }
  const s3 = new AWS.S3();
  const params: S3.PutObjectRequest = {
    Bucket: AWS_FILES_BUCKET,
    Key: fileName,
    Body: file,
    ContentType: type,
    ACL: 'private',
  };

  s3.upload(params, (err, data) => {
    if (err) {
      console.error('Upload error:', err);
      callback(err);
      return;
    }
    callback(data);
  });
}

export function uploadPhoto(
  file: Buffer | Uint8Array | Blob | StreamReadable | string,
  fileName: string,
  type: string,
  callback: (data: unknown) => void
): void {
  if (!AWS) {
    console.log('AWS SDK not loaded yet');
    return;
  }

  if (
    type === 'image/jpeg' ||
    type === 'image/jpg' ||
    type === 'image/png' ||
    type === 'image/tiff'
  ) {
    console.log(`Uploading photo with file format: ${type}`);
    uploadFile(file, fileName, type, callback);
  } else {
    console.log(
      `Error: Wrong file format. Received file of type ${type}. Please upload a file of type PNG, JPG, JPEG, or TIFF.`
    );
  }
}

export function uploadZip(
  file: Buffer | Uint8Array | Blob | StreamReadable | string,
  fileName: string,
  userId: string,
  storageKey: string,
  callback: (data: unknown) => void
): void {
  if (!AWS) {
    console.log('AWS SDK not loaded yet');
    return;
  }
  console.log('File value: ', file);
  console.log('AWS Details: ', AWS.config);

  const key = `${userId}/${storageKey}/${fileName}`;
  uploadFile(file, key, 'application/zip', (response) => {
    console.log('S3 Upload Response: ', response);
    callback(response);
  });
}
