import { useEffect, useState } from 'react';
import { HISTORICAL_BUCKET, PARQUET_BUCKET } from '../../../constants';
import { getwxData } from '../../../utils';

export const useWData = ({
  userId,
  crop,
  year,
  s3Keys,
  relationship,
}: {
  userId: string;
  crop: string;
  year: string;
  s3Keys: string[];
  relationship: string;
}) => {
  const [wData, setWData] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!crop || !userId || !year || !relationship) return;
      const skey = `${userId}_${crop}_${year}_${relationship}`;
      const skeys = s3Keys.filter(
        (key) => key.includes(skey) && key !== `${skey}_.parquet`
      );
      const promises = skeys.map(async (key) => {
        const wData = await getwxData(HISTORICAL_BUCKET, key);
        return wData;
      });

      const wData = await Promise.all(promises);
      setWData(wData);
      setLoading(false);
    };

    fetchData();
  }, [crop, s3Keys, relationship, userId, year]);

  return {
    wData,
    loading,
  };
};
