import queryString from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MyJDScopes,
  useGetJDToken,
} from '../../../../hooks/john-deere/use-john-deere';
import SpinningIcon from '../../../../assets/icons/SpinningIcon';

const JohnDeereCallbackPage = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem('JD_user_id') || false;
  const { code } = queryString.parse(window.location.search);
  const { mutate: getJDToken } = useGetJDToken();

  useEffect(() => {
    if (code) {
      getJDToken({
        userId: userId,
        code: code as string,
        clientId: process.env.REACT_APP_MY_JOHN_DEERE_CLIENT_ID!,
        clientSecret: process.env.REACT_APP_MY_JOHN_DEERE_CLIENT_SECRET!,
        tokenEndpoint:
          'https://signin.johndeere.com/oauth2/aus78tnlaysMraFhC1t7/v1/token',
        callbackUrl: process.env.REACT_APP_MY_JOHN_DEERE_CALLBACK_URL!,
        orgConnectionCompletedUrl: process.env.REACT_APP_MAIN_APP_URL!,
        scopes: MyJDScopes,
      });
    }
  }, []);

  if (!userId) {
    navigate('/');
    return <></>;
  }

  return (
    <div className="flex justify-center items-center space-x-2">
      <p>Connecting</p>
      <SpinningIcon />
    </div>
  );
};

export default JohnDeereCallbackPage;
