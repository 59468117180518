import { useQuery } from '@tanstack/react-query';
import { getUserProfileApi } from '../../api/user-api';
import { UserProfileRes } from '../../types';

export const useUserProfile = (enabled: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users/me'],
    queryFn: getUserProfileApi,
    enabled,
  });

  return {
    data: data?.data as UserProfileRes | undefined,
    ...rest,
  };
};
