import { IconProps } from '../../types';

const RiskManagementIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2936 10.7425C25.1847 11.5867 25.1847 13.0405 24.2936 13.8847L14.5388 23.6394C13.6947 24.5305 12.2409 24.5305 11.3967 23.6394L1.64193 13.8847C0.750868 13.0405 0.750868 11.5867 1.64193 10.7425L11.3498 0.940861C12.194 0.0966987 13.6478 0.0966987 14.5388 0.940861L24.2936 10.7425ZM11.8657 7.41277V12.6653C11.8657 13.275 12.3346 13.7909 12.9912 13.7909C13.6009 13.7909 14.1168 13.275 14.1168 12.6653V7.41277C14.1168 6.7562 13.6009 6.28722 12.9912 6.28722C12.3346 6.28722 11.8657 6.7562 11.8657 7.41277ZM12.9912 18.2931C13.7885 18.2931 14.492 17.5896 14.492 16.7924C14.492 15.9482 13.7885 15.2916 12.9912 15.2916C12.1471 15.2916 11.4905 15.9482 11.4905 16.7924C11.4905 17.5896 12.1471 18.2931 12.9912 18.2931Z"
        fill={fill}
      />
    </svg>
  );
};

export default RiskManagementIcon;
