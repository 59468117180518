import {
  ArcElement,
  Chart,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Tooltip,
} from 'chart.js';
import { useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { shortCurrency } from '../../../utils';

ChartJS.register(ArcElement, Tooltip, Legend);

const secondaryColor = 'rgba(255, 183, 27, 1)';
const gaugeNeedle = {
  id: 'gaugeNeedle',
  // @ts-ignore
  afterDatasetsDraw: function (chart, args, plugins) {
    const { ctx, data } = chart;
    const firstDatasetMeta = chart.getDatasetMeta(0).data[0];
    ctx.save();
    const xCenter = firstDatasetMeta.x;
    const yCenter = firstDatasetMeta.y;
    const circleR = chart.chartArea.height * 0.32;

    // mimix the rounded border

    // needle
    const needleHeight = circleR * 1.3;
    const needleWidth = circleR * 1.5;
    const cir = firstDatasetMeta.circumference / Math.PI;

    ctx.beginPath();
    ctx.strokeStyle = secondaryColor;
    ctx.fillStyle = secondaryColor;
    ctx.translate(xCenter, yCenter);
    ctx.rotate((-120 * Math.PI) / 180);
    ctx.rotate((cir + 1) * Math.PI);
    ctx.moveTo(-needleWidth / 2, 0);
    ctx.lineTo(0, needleHeight);
    ctx.lineTo(needleWidth / 2, 0);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
    ctx.restore();

    // center circle
    ctx.beginPath();
    ctx.fillStyle = secondaryColor;
    ctx.arc(xCenter, yCenter, circleR, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    // inner circle
    const innerCircleR = circleR * 0.8;
    ctx.beginPath();
    ctx.fillStyle = '#1b2227';
    ctx.arc(xCenter, yCenter, innerCircleR, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    // center text
    const {
      chartArea: { height },
    } = chart;

    const fontSize = (height / 7).toFixed(2);
    const value = data.datasets[0].data[0];
    ctx.fillStyle = secondaryColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `${fontSize}px RobotoCondensed`;
    ctx.fillText(shortCurrency(value), xCenter, yCenter + 2);

    // steps on the peripheral
    const MAX = data.datasets[0].maxValue;
    // 7 steps
    const steps = [
      0,
      MAX / 6,
      (MAX / 6) * 2,
      (MAX / 6) * 3,
      (MAX / 6) * 4,
      (MAX / 6) * 5,
      MAX,
    ];
    const stepFontSize = (height / 12).toFixed(2);
    ctx.font = `${stepFontSize}px RobotoCondensed`;
    ctx.fillStyle = '#fff';
    const outerRadius = firstDatasetMeta.outerRadius;
    steps.forEach((step, index) => {
      const angle = (150 + 240 * (step / MAX)) * (Math.PI / 180);
      let x = xCenter + (outerRadius + circleR * 0.5) * Math.cos(angle);
      let y = yCenter + (outerRadius + circleR * 0.5) * Math.sin(angle);
      if (index === 0) {
        y = yCenter + (outerRadius + circleR * 0.1) * Math.sin(angle);
      }
      if (index === 6) {
        x = xCenter + (outerRadius + circleR * 0.7) * Math.cos(angle);
        y = yCenter + (outerRadius + circleR * 0.1) * Math.sin(angle);
      }
      ctx.fillText(shortCurrency(step, 0), x, y);
    });
  },
};

const dot = {
  id: 'dot',
  afterDatasetsDraw: function (chart: any, args: any, plugins: any) {
    const firstDatasetMeta = chart.getDatasetMeta(0).data[0];
    const { ctx } = chart;
    const x = firstDatasetMeta.x;
    const y = firstDatasetMeta.y;
    const angle = Math.PI / 180;
    const outerRadius = firstDatasetMeta.outerRadius;
    const innerRadius = firstDatasetMeta.innerRadius;
    const endAngle = firstDatasetMeta.endAngle;
    const radius = (outerRadius - innerRadius) / 2;
    const xCoor = (innerRadius + radius) * Math.cos(endAngle);
    const yCoor = (innerRadius + radius) * Math.sin(endAngle);

    if (chart.data.datasets[0].data[1] === 0) return;
    ctx.save();
    // dot
    const dotGradient = ctx.createLinearGradient(
      xCoor - radius,
      yCoor - radius,
      xCoor + radius,
      yCoor + radius
    );
    dotGradient.addColorStop(0, 'rgba(255, 183, 27, 1)');
    dotGradient.addColorStop(1, 'rgba(255, 153, 0, 1)');
    ctx.translate(x, y);
    ctx.beginPath();
    ctx.arc(xCoor, yCoor, radius + 2, 0, angle * 360, false);
    ctx.fillStyle = dotGradient;
    ctx.shawdowOffsetX = 5;
    ctx.shawdowOffsetY = 5;
    ctx.shadowBlur = 10;
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.fill();

    // border
    const borderGradient = ctx.createLinearGradient(
      xCoor + radius,
      yCoor + radius,
      xCoor - radius,
      yCoor - radius
    );
    borderGradient.addColorStop(0, 'rgba(216, 120, 7, 1)');
    borderGradient.addColorStop(1, 'rgba(241, 160, 19, 1)');
    ctx.beginPath();
    ctx.arc(xCoor, yCoor, radius, 0, angle * 360, false);
    ctx.fillStyle = borderGradient;
    ctx.shadowColor = 'transparent';
    ctx.fill();

    ctx.restore();
  },
};

const plugins = [gaugeNeedle, dot];

export default function VARChart({
  nRVAR,
  hVAR,
  vAR,
}: {
  nRVAR: number;
  hVAR: number;
  vAR: number;
}) {
  // get width and height of the chart
  const chartRef = useRef<Chart<'doughnut', number[], unknown> | null>(null);
  const width = chartRef.current?.chartArea?.width ?? 300;

  const options: ChartOptions = {
    responsive: true,
    aspectRatio: 2,
    layout: {
      padding: {
        top: width * 0.1,
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data = useMemo(() => {
    const borderRadius = Number.MAX_VALUE;
    const firstValue = hVAR;
    const secondValue = vAR - hVAR;
    const thirdValue = vAR - nRVAR;
    const fourthValue = vAR * 1.2 - vAR;
    return {
      datasets: [
        {
          // @TODO Paul you need to check this, i'm not sure if this is correct
          data: [firstValue, secondValue, thirdValue, fourthValue],
          backgroundColor: [
            'rgba(255, 183, 27, 1)',
            'rgba(128, 188, 0, 1)',
            'rgba(244, 39, 39, 1)',
            'rgba(92, 114, 132, 1)',
          ],
          borderColor: [
            'rgba(255, 183, 27, 1)',
            'rgba(128, 188, 0, 1)',
            'rgba(244, 39, 39, 1)',
            'rgba(92, 114, 132, 1)',
          ],
          borderRadius: [
            {
              outerStart: borderRadius,
              outerEnd: 0,
              innerStart: borderRadius,
              innerEnd: 0,
            },
            {
              outerStart: 0,
              outerEnd: 0,
              innerStart: 0,
              innerEnd: 0,
            },
            { outerStart: 0, outerEnd: 0, innerStart: 0, innerEnd: 0 },
            {
              outerStart: 0,
              outerEnd: borderRadius,
              innerStart: 0,
              innerEnd: borderRadius,
            },
          ],
          borderWidth: 1,
          circumference: 240,
          rotation: -120,
          cutout: '80%',
          maxValue: firstValue + secondValue + thirdValue + fourthValue,
        },
      ],
    };
  }, [nRVAR, hVAR, vAR]);

  return (
    <div className="relative w-full h-full">
      <Doughnut
        ref={chartRef}
        data={data}
        // @ts-ignore
        options={options}
        plugins={plugins}
      />
    </div>
  );
}
