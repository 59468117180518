import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import {
  ContractOptions,
  HedgeTypes,
} from '../../../constants/risk-management';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useBasis } from '../../../hooks/basis/use-basis';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useGetStrikeList } from '../../../hooks/hedge/use-hedge';
import { useMarketBidAsk } from '../../../hooks/market/use-market';
import { BasisRes } from '../../../types';
import { AddHedgePayload } from '../../../types/hedge';
import { convertToContractSymbolByCrop } from '../../../utils';

export const convertToContractSymbol = ({
  contract,
  contractDate,
  hedgeType,
  hedgeItem,
}: {
  contract: string;
  contractDate: string;
  hedgeType: string;
  hedgeItem: any;
}) => {
  if (!contractDate || !contract) return null;

  let root = null;
  let month = null;
  let symbol = null;

  switch (contract) {
    case 'CBOT Corn':
      root = 'ZC';
      break;
    case 'CBOT Soybeans':
      root = 'ZS';
      break;
    case 'CBOT Wheat':
      root = 'ZW';
      break;
    case 'KCHRW Wheat':
      root = 'KE';
      break;
    default:
      break;
  }

  const monthCodes: { [key: string]: string } = {
    Jan: 'F',
    Feb: 'G',
    Mar: 'H',
    Apr: 'J',
    May: 'K',
    Jun: 'M',
    Jul: 'N',
    Aug: 'Q',
    Sep: 'U',
    Oct: 'V',
    Nov: 'X',
    Dec: 'Z',
  };

  month = monthCodes[contractDate?.slice(0, 3)] || null;

  if (!root || !month) {
    symbol = hedgeItem['uniqueKey'];
  } else if (hedgeType === 'Futures') {
    symbol = root + month + contractDate?.slice(-1);
  } else if (hedgeType === 'Put' || hedgeType === 'Call') {
    let val = hedgeItem['strike']?.toString().padStart(4, '0');
    const optionTypeCode = hedgeType === 'Put' ? 'P' : 'C';
    symbol = `O${root}${month}${contractDate?.slice(-1)} ${optionTypeCode}${val}`;
  } else {
    symbol = hedgeItem['uniqueKey'];
  }

  return symbol;
};

export const getAddHedgePayload = ({
  formValues,
  userId,
}: {
  formValues: any;
  userId: string;
}): AddHedgePayload => {
  const unique_hedge_name =
    formValues.unique_hedge_name ??
    `${formValues.hedge_name}-${nanoid(5)}-${formValues.hedgeType}`;

  const user_id_unique_hedge_name =
    formValues.user_id_unique_hedge_name ?? `${userId}-${unique_hedge_name}`;

  return {
    ...formValues,
    unique_hedge_name,
    user_id_unique_hedge_name,
    user_id: `${userId}_${formValues.crop_year}`,
    user_id_crop: `${userId}_${formValues.crop_year}-${formValues.crop}`,
    underlying_contract:
      formValues.symbol?.slice(0, 1) === 'O'
        ? formValues.symbol?.slice(1, 5)
        : formValues.symbol,
  };
};

export const PositionOptions = [
  { label: 'Buy', value: 'Buy' },
  { label: 'Sell', value: 'Sell' },
];

export const getSymbolByContractDateCrop = ({
  contractDate,
  crop,
}: {
  contractDate: string;
  crop: string;
}) => {
  return convertToContractSymbolByCrop(crop, contractDate);
};

export const useContractPriceCrop = ({
  value,
  type,
  basisList,
}: {
  value: string;
  type: string;
  basisList?: BasisRes[];
}) => {
  const [result, setResult] = useState<{
    contract?: string;
    crop?: string;
    price?: string;
  }>({
    contract: '',
    crop: '',
    price: '',
  });

  useEffect(() => {
    if (value) {
      if ([HedgeTypes.Basis, HedgeTypes.Cash].includes(type)) {
        const contract = basisList?.find((basis) => basis.basis_id === value);
        setResult({
          contract: contract?.location,
          crop: contract?.crop,
          price:
            HedgeTypes.Basis === type ? contract?.basis : contract?.cashprice,
        });
      } else {
        let crop = '';
        if (value.includes('Corn')) {
          crop = 'Corn';
        } else if (value.includes('Soybean')) {
          crop = 'Soybeans';
        } else if (value.includes('Wheat')) {
          crop = 'Wheat';
        } else {
          crop = '';
        }

        setResult({
          contract: value,
          crop: crop,
        });
      }
    }
  }, [basisList, type, value]);

  return result;
};

export const useStrikeOptions = ({
  contractDate,
  crop,
  dateTrade,
}: {
  contractDate: string;
  crop: string;
  dateTrade: string;
}) => {
  const cropMonthTradeDate = useMemo(() => {
    return `${crop}-${contractDate}-${moment(dateTrade).format('YYYY-MM-DD')}`;
  }, [crop, contractDate, dateTrade]);
  const { data: strikeList, ...rest } = useGetStrikeList(cropMonthTradeDate);
  const strikeOptions = useMemo(() => {
    return strikeList?.map((item) => {
      return {
        label: item.strike,
        value: item.strike,
      };
    });
  }, [strikeList]);

  return { strikeOptions, ...rest };
};

export const useHedgeForm = ({ form, type }: { form: any; type: string }) => {
  const user = useUser();
  const year = useYearFilter();

  const crop = form.watch('crop');
  const dateTrade = form.watch('dateTrade');
  const contractDate = form.watch('contract_date');
  const formContract = form.watch('contract');
  const strike = form.watch('strike');
  const uniqueKey = form.watch('uniqueKey');
  const symbol = form.watch('symbol');
  const contractMonth = form.watch('month');
  const contractYear = form.watch('year');

  // set symbol
  useEffect(() => {
    if ([HedgeTypes.Basis, HedgeTypes.Cash].includes(type)) {
      form.setValue(
        'symbol',
        getSymbolByContractDateCrop({ contractDate, crop })
      );
    } else {
      form.setValue(
        'symbol',
        convertToContractSymbol({
          contract: formContract,
          contractDate,
          hedgeType: type,
          hedgeItem: { strike, uniqueKey },
        })
      );
    }
  }, [formContract, contractDate, crop, strike, type, uniqueKey]);

  // Set contract date
  useEffect(() => {
    if (contractMonth && contractYear) {
      form.setValue('contract_date', `${contractMonth} '${contractYear}`);
    }
  }, [contractMonth, contractYear]);

  // commodity options
  const { data: basisList } = useBasis(
    user?.id ?? '',
    year ?? '',
    symbol ? [symbol] : []
  );
  const byBasisOptions = useMemo(() => {
    return basisList?.map((basis) => ({
      label: basis.location,
      value: basis.basis_id,
    }));
  }, [basisList]);
  const contractOptions = [HedgeTypes.Basis, HedgeTypes.Cash].includes(type)
    ? byBasisOptions
    : ContractOptions;

  // set price in case of cash/basis
  const {
    price,
    contract,
    crop: cropFromContract,
  } = useContractPriceCrop({ value: formContract, type, basisList });

  useEffect(() => {
    if ([HedgeTypes.Cash, HedgeTypes.Basis].includes(type)) {
      if (price) {
        form.setValue('price', price);
      }
    }
    if (contract) {
      form.setValue('contract', contract);
    }
  }, [price, contract, type, cropFromContract, formContract]);

  // set price in case of other types
  const { data: bidAsk, isFetching: isFetchingPrice } = useMarketBidAsk(symbol);
  useEffect(() => {
    if (
      ![HedgeTypes.Cash, HedgeTypes.Basis].includes(type) &&
      bidAsk.trade_price &&
      !isFetchingPrice
    ) {
      form.setValue('price', bidAsk.trade_price);
    }
  }, [bidAsk.trade_price, isFetchingPrice, type]);

  // set crop by contract
  useEffect(() => {
    if (cropFromContract) {
      form.setValue('crop', cropFromContract, {});
    }
  }, [cropFromContract]);

  // Field options
  const { data: allFields } = useFieldPolygonsByYear(
    user?.id ?? '',
    year ?? ''
  );
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}`,
    }));
  }, [allFields]);

  // get strike options
  const { strikeOptions } = useStrikeOptions({
    contractDate,
    crop,
    dateTrade,
  });

  return {
    contractOptions,
    strikeOptions,
    fieldOptions,
    isFetchingPrice,
  };
};
