export default function SoybeansIconBigColored() {
  return (
    <svg
      width="150"
      height="151"
      viewBox="0 0 150 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_163_4000)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.837 64.1641C118.702 72.5908 115.433 80.9283 109.696 85.906C114.391 92.0902 116.248 99.3819 114.709 105.559C113.166 111.733 108.403 116.059 101.872 117.218C103.328 126.722 98.6837 134.663 90.1487 137.259C81.6147 139.853 70.9175 136.574 63.1604 128.983C73.2744 126.714 68.048 105.071 85.0135 104.829C79.1181 93.9544 82.3541 82.5912 92.3009 79.2479C77.4952 59.0863 98.2458 49.2486 83.351 23.2394C83.033 22.8303 82.945 22.3343 83.1188 21.9549C83.2938 21.5798 83.6987 21.3874 84.1762 21.4511C84.6504 21.5193 85.117 21.8339 85.381 22.2738C88.5906 25.9515 91.5647 29.5765 93.7334 33.8943C101.002 48.3941 110.268 54.2076 114.837 64.1652V64.1641Z"
          fill="#5E8A00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.866 64.1641C105.572 72.5908 100.159 80.9283 93.1447 85.906C96.2498 92.0902 96.2377 99.3819 93.1117 105.559C89.9846 111.733 84.1112 116.059 77.286 117.218C76.3012 126.722 69.6136 134.663 60.4118 137.259C51.211 139.853 38.804 140.403 32.9988 132.813C43.6926 130.544 46.5776 105.071 63.6059 104.828C60.4998 93.9533 66.6527 82.5901 77.4599 79.2468C67.8289 59.0852 91.1069 49.2475 82.8844 23.2383C82.6742 22.8292 82.7127 22.3332 82.9856 21.9538C83.2552 21.5787 83.7129 21.3863 84.1717 21.45C84.6306 21.5182 85.0124 21.8328 85.1642 22.2727C87.4319 25.9504 89.4774 29.5754 90.5348 33.8932C94.0855 48.393 101.853 54.2065 103.866 64.1641Z"
          fill="#80BC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.081 91.9846C73.949 87.7229 77.2939 84.1585 81.5576 84.0232C85.8245 83.8901 89.3906 87.2346 89.5227 91.4963C89.6415 95.3863 88.7128 99.1366 86.9315 102.394C85.1611 105.644 82.5126 108.443 79.1809 110.447C75.5202 112.643 70.7724 111.454 68.5784 107.795C66.3844 104.136 67.5749 99.3907 71.2345 97.1977C72.1214 96.6599 72.8454 95.879 73.3526 94.9552C73.8499 94.038 74.1107 93.0196 74.0799 91.9857L74.081 91.9846Z"
          fill="#C4E088"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.0164 64.2311C80.0843 60.4324 81.5983 55.7847 85.3999 53.849C89.2047 51.9178 93.8546 53.4322 95.7911 57.232C97.545 60.7052 98.2998 64.4929 98.0765 68.197C97.8531 71.8901 96.6483 75.5481 94.4829 78.7804C92.104 82.3173 87.3034 83.261 83.7571 80.8821C80.2174 78.4999 79.2734 73.7004 81.6566 70.159C82.2321 69.3001 82.5556 68.2839 82.6194 67.227C82.6843 66.1888 82.4841 65.155 82.0175 64.2311H82.0164Z"
          fill="#C4E088"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.9869 115.233C59.6098 111.291 64.1233 109.408 68.0667 111.029C72.0157 112.649 73.9006 117.161 72.2732 121.107C70.7878 124.702 68.4089 127.744 65.448 129.989C62.5014 132.224 58.943 133.698 55.081 134.163C50.8448 134.671 46.997 131.645 46.4931 127.406C45.9891 123.171 49.0139 119.327 53.2534 118.823C54.2854 118.698 55.2669 118.281 56.1075 117.641C56.935 117.013 57.5929 116.187 57.9869 115.232V115.233Z"
          fill="#C4E088"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.0322 27.3405C82.2022 21.0684 79.6561 17.2004 77.3972 15.7321C76.8019 15.1031 77.5986 13.9285 78.5228 14.0044C81.1217 15.6937 83.9176 19.8608 86.9126 26.4926L85.0322 27.3416V27.3405Z"
          fill="#80BC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_163_4000">
          <rect
            width="83.5153"
            height="125"
            fill="white"
            transform="translate(33 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
