import { IconProps } from '../../types';

const ZoneIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.125C0 0.515625 0.46875 0 1.125 0H22.875C23.4844 0 24 0.515625 24 1.125C24 1.78125 23.4844 2.25 22.875 2.25H1.125C0.46875 2.25 0 1.78125 0 1.125ZM6 4.5C6.79688 4.5 7.5 5.20312 7.5 6V9C7.5 9.84375 6.79688 10.5 6 10.5H3C2.15625 10.5 1.5 9.84375 1.5 9V6C1.5 5.20312 2.15625 4.5 3 4.5H6ZM6 18C6.79688 18 7.5 18.7031 7.5 19.5V22.5C7.5 23.3438 6.79688 24 6 24H3C2.15625 24 1.5 23.3438 1.5 22.5V19.5C1.5 18.7031 2.15625 18 3 18H6ZM9 6C9 5.20312 9.65625 4.5 10.5 4.5H13.5C14.2969 4.5 15 5.20312 15 6V9C15 9.84375 14.2969 10.5 13.5 10.5H10.5C9.65625 10.5 9 9.84375 9 9V6ZM13.5 18C14.2969 18 15 18.7031 15 19.5V22.5C15 23.3438 14.2969 24 13.5 24H10.5C9.65625 24 9 23.3438 9 22.5V19.5C9 18.7031 9.65625 18 10.5 18H13.5ZM16.5 6C16.5 5.20312 17.1562 4.5 18 4.5H21C21.7969 4.5 22.5 5.20312 22.5 6V9C22.5 9.84375 21.7969 10.5 21 10.5H18C17.1562 10.5 16.5 9.84375 16.5 9V6ZM21 18C21.7969 18 22.5 18.7031 22.5 19.5V22.5C22.5 23.3438 21.7969 24 21 24H18C17.1562 24 16.5 23.3438 16.5 22.5V19.5C16.5 18.7031 17.1562 18 18 18H21ZM22.875 13.5C23.4844 13.5 24 14.0156 24 14.625C24 15.2812 23.4844 15.75 22.875 15.75H1.125C0.46875 15.75 0 15.2812 0 14.625C0 14.0156 0.46875 13.5 1.125 13.5H22.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default ZoneIcon;
