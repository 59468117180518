import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  connectMembersApi,
  disconnectMembersApi,
  getPartnerMembersApi,
} from '../../api/user-api';

export const usePartnerMembers = ({
  searchText,
  partner,
}: {
  searchText: string;
  partner: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['users/members', searchText, partner],
    queryFn: () => getPartnerMembersApi({ searchText, partner }),
    enabled: !!partner && !!searchText,
  });

  return { data: data?.data as [], ...rest };
};

export const useConnectMembers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: {
      uniqueid: string;
      name: string;
      user_id: string;
    }) => connectMembersApi(payload),
    mutationKey: ['connect', 'users/members'],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users/advisors'] });
    },
  });
};

export const useDisconnectMembers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { uniqueid: string; name: string }) =>
      disconnectMembersApi(payload),
    mutationKey: ['disconnect', 'users/members'],
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users/advisors'] });
    },
  });
};
