import { useEffect, useState } from 'react';
import { getDateSet } from '../../../utils';

export const useDateSet = (s3Keys: string[]) => {
  const [dateSet, setDateSet] = useState<any[]>([]);

  useEffect(() => {
    const result = getDateSet(s3Keys);
    setDateSet(result);
  }, [s3Keys]);

  return {
    dateSet,
  };
};
