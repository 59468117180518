import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import StyledCard from '../../../components/main/StyledCard';
import { NETWORK_PARTNER, NETWORK_PARTNER_LOGO } from '../../../constants';
import { useRequestUserRegistration } from '../../../hooks/user/use-users';

const validateSchema = yup.object({
  email: yup.string().email().required().label('Email'),
});

export default function SignUpPage() {
  const params = useParams();
  const networkParam =
    (params['networkPartner'] as keyof typeof NETWORK_PARTNER) ??
    NETWORK_PARTNER.Agrivar;
  const networkPartner = NETWORK_PARTNER[networkParam];

  const {
    mutate: sendRequest,
    isPending,
    isSuccess,
  } = useRequestUserRegistration();
  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validateSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const handleSignupRequest = (values: { email: string }) => {
    sendRequest({
      ...values,
      network_partner: networkPartner,
      requesting_status: 'client',
    });
  };

  const disabled = !isValid || isPending;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        {!isSuccess ? (
          <div className="max-w-screen-sm w-full">
            <img
              src={
                NETWORK_PARTNER_LOGO[
                  networkPartner as keyof typeof NETWORK_PARTNER_LOGO
                ]
              }
              alt={networkPartner}
              width={'70%'}
              className="mx-auto mb-4"
            />
            <StyledCard title="Sign Up">
              <form
                onSubmit={handleSubmit(handleSignupRequest)}
                className="flex flex-col w-full space-y-8 pb-8 px-8"
              >
                <TextInput
                  label="Email*"
                  name="email"
                  placeholder="Enter email"
                  className="!bg-base-000 !text-block-fill"
                  required
                />

                <div className="flex flex-col space-y-5">
                  <Button
                    type="submit"
                    size="lg"
                    className="!font-normal !rounded-md"
                    disabled={disabled}
                    color="primary"
                    loading={isPending}
                  >
                    Submit
                  </Button>
                  <Link to="/login">
                    <Button
                      type="button"
                      size="lg"
                      className="!font-normal !rounded-md"
                    >
                      Return to Login
                    </Button>
                  </Link>
                </div>
              </form>
            </StyledCard>
          </div>
        ) : (
          <div className="max-w-screen-md w-full">
            <StyledCard
              title="Your Request Was Successfully Submitted"
              className="text-center text-lg-regular"
            >
              <p>Your account request is being processed.</p>
              <br />
              <p>
                Once your account is approved, you will receive an email from
                'no-reply@agrivar.ag' with a temporary password to sign into
                your new AgriVaR account. You will reset your password after
                completing this initial sign in.
              </p>
              <br />
              <p>
                If you do not see the email, be sure to check your spam or junk
                folders as well.
              </p>
              <Link to="/login">
                <Button
                  color="primary"
                  className="!w-fit mx-auto mt-8 !font-normal !rounded-md"
                  size="lg"
                >
                  Go Back to Sign In
                </Button>
              </Link>
            </StyledCard>
          </div>
        )}
      </div>
    </FormProvider>
  );
}
