import { IconProps } from '../../types';

export default function SupportIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95312 10.5L9 15.75C9 16.5938 8.29688 17.25 7.5 17.25H6.75C5.0625 17.25 3.75 15.9375 3.75 14.25V12.0469C3.75 10.3594 5.0625 9 6.75 9H7.45312C8.25 9 8.95312 9.70312 8.95312 10.5ZM12 0C18.7031 0 23.7656 5.625 24 12V18.75C24 21.0469 22.125 22.875 19.875 22.875H14.6719C14.2969 23.5781 13.5469 24 12.75 24H11.2031C9.84375 24 8.76562 22.7812 9 21.3281C9.23438 20.25 10.2188 19.5 11.2969 19.5H12.75C13.5469 19.5 14.2969 19.9688 14.6719 20.625H19.875C20.9062 20.625 21.75 19.8281 21.75 18.75V12C21.75 6.65625 17.3438 2.29688 12 2.29688C6.60938 2.29688 2.25 6.65625 2.25 12V13.875C2.25 14.5312 1.64062 15 1.07812 15C0.46875 15 0 14.5312 0 13.875V12C0.1875 5.625 5.25 0 12 0ZM17.25 17.25H16.5C15.6562 17.25 15 16.5938 15 15.75V10.5469C15 9.70312 15.6562 9 16.5 9H17.25C18.8906 9 20.25 10.3594 20.25 12.0469V14.25C20.25 15.9375 18.8906 17.25 17.25 17.25Z"
        fill={fill}
      />
    </svg>
  );
}
