import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/Table';
import { commodityOptions } from './PriceChart';
import {
  CropToCode,
  MonthToCode,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import {
  useBasis,
  useCompanyLocationOptions,
} from '../../../hooks/basis/use-basis';
import { useBasisPrices } from '../../../hooks/market/use-basis';
import { useUser } from '../../../contexts/auth-context';
import { basisChartOptions } from '../../../constants/chartConfigs';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  TimeScale
);

function BasisChart() {
  const basisTableConfigs: TableConfigs = {
    cols: [
      {
        key: 'date',
        name: 'Date',
      },
      {
        key: 'company',
        name: 'Company',
      },
      {
        key: 'city',
        name: 'City',
      },
      {
        key: 'state',
        name: 'State',
      },
      {
        key: 'basis',
        name: 'Basis',
      },
      {
        key: 'basis_chg',
        name: 'Basis Change',
        type: 'element',
        element: (item: any) => {
          if (item.change > 0) {
            return (
              <div className="flex">
                <span className="text-green flex gap-2">
                  <ArrowUpIcon className="h-4 w-4 text-green" />
                  {item.basis_chg}
                </span>
              </div>
            );
          } else {
            return (
              <span className="text-red flex items-center gap-2">
                <ArrowDownIcon className="h-4 w-4 text-red" />
                {item.basis_chg}
              </span>
            );
          }
        },
      },
      {
        key: 'facility_type',
        name: 'Facility Type',
      },
    ],
  };

  const user = useUser();

  const methods = useForm();
  const crop = methods.watch('crop', 'Corn');
  const month = methods.watch('month', 'december');
  const year = methods.watch('year', '2024');
  const companyLocation = methods.watch('companyLocation', '');

  const [highlightedRows, setHighlightedRows] = useState<
    { basis_id: string; time: Date }[]
  >([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const basisID = useMemo(() => {
    return (
      user?.id +
      '-' +
      CropToCode[crop] +
      MonthToCode[month] +
      year.slice(-1) +
      '-' +
      companyLocation
    );
  }, [crop, month, year, companyLocation]);

  const symbol = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year[3];
  }, [crop, month, year]);

  const basis = useBasis(user?.id ?? '', year, [symbol]);
  const basisPrices = useBasisPrices(basisID);
  const companyLocationOptions = useCompanyLocationOptions(
    user?.id!,
    year,
    symbol
  );

  const { setRoots } = useWebsocketContext();
  const msgBasis: any = useWebSocketDataV2('Basis');

  const chartData = useMemo(() => {
    const datasetsMap: { [key: string]: any[] } = {};
    if (!basisPrices.data) return { datasets: [] };
    basisPrices.data.forEach((item: any) => {
      if (!datasetsMap[item.location]) {
        datasetsMap[item.location] = [];
      }
      datasetsMap[item.location].push({
        x: new Date(item.Date),
        y: parseFloat(item.basis),
        company: item.company,
      });
    });

    const datasets = Object.keys(datasetsMap).map((location) => ({
      label: location,
      data: datasetsMap[location],
      borderColor: 'rgba(255,99,132,0.2)',
      backgroundColor: 'rgba(255,99,132,0.2)',
      fill: false,
    }));

    return { datasets };
  }, [basisPrices.data]);

  useEffect(() => {
    setRoots([CropToCode[crop]]);
  }, [crop]);

  useEffect(() => {
    let updatedData =
      basis.data?.map((item: any) => {
        let newBasis = {};

        if (msgBasis.data && msgBasis.data.basis_id === item.basis_id) {
          newBasis = msgBasis.data;

          setHighlightedRows((prev) => [
            ...prev.filter((row) => row.basis_id !== item.basis_id),
            { basis_id: item.basis_id, time: new Date() },
          ]);
        }

        return {
          date: moment(item.date).format('YYYY-MM-DD'),
          commodity: item.commodity,
          crop_year: item.crop_year,
          company: item.company,
          city: item.city,
          state: item.state,
          basis: item.basis,
          cashprice: item.cashprice,
          basis_chg: item.basis_chg,
          rawchange: item.rawchange,
          facility_type: item.facility_type,
          basis_id: item.basis_id,
          ...newBasis,
        };
      }) ?? [];

    setTableData(updatedData);
  }, [basis.data, msgBasis]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setHighlightedRows((prev) =>
        prev.filter((row) => now.getTime() - row.time.getTime() < 5000)
      );
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <p className="text-lg pb-2 text-darkGray">Contract</p>
          <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 pb-6 rounded-md">
            <Select
              options={commodityOptions}
              defaultValue={'Corn'}
              name="crop"
              onChange={(e) => {

              }}
            />

            <Select
              options={
                cropToMonthOptions[crop as keyof typeof cropToMonthOptions]
              }
              name="month"
              defaultValue={'december'}
            />

            <Select options={yearOptions} name="year" defaultValue={'2024'} />
            {companyLocationOptions && companyLocationOptions.length > 0 && (
              <Select
                options={companyLocationOptions}
                name="companyLocation"
                defaultValue={companyLocationOptions[0].value}
              />
            )}
          </div>
          </div>
        </form>
      </FormProvider>
      <div className="card p-6 pt-4">
        <Line data={chartData} options={basisChartOptions(symbol)} />
      </div>

      <div className="card mt-4 relative overflow-hidden">
        <div className="pl-6 flex gap-2 text-xl-bold w-full py-4 px-6.5">
          {commodityOptions.find((item: any) => crop === item.value)?.icon}
          All {crop} Basis Data
        </div>
        <div className="overflow-x-auto">
          {basis && basis.data && basis.data.length > 0 && (
            <Table
              configs={basisTableConfigs}
              data={tableData}
              highlights={highlightedRows.map((row) => row.basis_id)}
              indexKey="date"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default BasisChart;
