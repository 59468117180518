import { createContext, useContext } from 'react';
import { MapContextProps } from '../types';

export const MapContext = createContext<MapContextProps | undefined>(undefined);

export const useMapContext = (): MapContextProps => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapContext must be used within a MapProvider');
  }
  return context;
};
