import { IconProps } from '../../types';

export default function SatelliteIcon2({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 0.615234C22.7917 0.615234 24 1.86523 24 3.2819C24 4.74023 22.7917 5.94857 21.3333 5.94857C19.8333 5.94857 18.6667 4.7819 18.6667 3.2819C18.6667 1.82357 19.8333 0.615234 21.3333 0.615234ZM9.33333 3.2819C14.4583 3.2819 18.6667 7.49023 18.6667 12.6152C18.6667 17.7819 14.4583 21.9486 9.33333 21.9486C4.16667 21.9486 0 17.7819 0 12.6152C0 7.49023 4.16667 3.2819 9.33333 3.2819ZM13.3333 14.6569V13.9486C13.3333 13.2402 12.7083 12.6152 12 12.6152H9.29167C8.875 12.6152 8.5 12.4902 8.20833 12.1986C7.70833 11.6986 7.66667 10.9069 8.125 10.3652L10.2083 7.99023C10.5 7.5319 10.6667 7.0319 10.6667 6.49023C10.6667 5.86523 10.125 5.32357 9.5 5.2819H9.33333C6.33333 5.2819 3.79167 7.07357 2.625 9.61523C2.29167 10.4069 2.83333 11.2819 3.70833 11.2819H4.75C5.125 11.2819 5.45833 11.4486 5.70833 11.6986L8 13.9486V15.2819C8 16.0319 8.58333 16.6152 9.33333 16.6152V19.2819C9.33333 19.4486 9.375 19.6152 9.54167 19.7819C9.66667 19.9069 9.875 19.9486 10.0417 19.9486C10.2083 19.9069 10.3333 19.9069 10.4583 19.8652C10.8333 19.8236 11.1667 19.5736 11.3333 19.2402L13.0417 15.8652C13.2083 15.4902 13.3333 15.0736 13.3333 14.6569Z"
        fill={fill}
      />
    </svg>
  );
}
