import { IconProps } from '../../types';

export default function CalendarIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.125 3H14.875V1.125C14.875 0.515625 15.3438 0 16 0C16.6094 0 17.125 0.515625 17.125 1.125V3H19C20.6406 3 22 4.35938 22 6V21C22 22.6875 20.6406 24 19 24H4C2.3125 24 1 22.6875 1 21V6C1 4.35938 2.3125 3 4 3H5.875V1.125C5.875 0.515625 6.34375 0 7 0C7.60938 0 8.125 0.515625 8.125 1.125V3ZM3.25 11.625H7V9H3.25V11.625ZM3.25 13.875V16.875H7V13.875H3.25ZM9.25 13.875V16.875H13.75V13.875H9.25ZM16 13.875V16.875H19.75V13.875H16ZM19.75 9H16V11.625H19.75V9ZM19.75 19.125H16V21.75H19C19.375 21.75 19.75 21.4219 19.75 21V19.125ZM13.75 19.125H9.25V21.75H13.75V19.125ZM7 19.125H3.25V21C3.25 21.4219 3.57812 21.75 4 21.75H7V19.125ZM13.75 9H9.25V11.625H13.75V9Z"
        fill={fill}
      />
    </svg>
  );
}
