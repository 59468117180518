import DeckGL, { WebMercatorViewport } from 'deck.gl';
import { useEffect, useMemo, useState } from 'react';
import Map from 'react-map-gl';
import { useParams } from 'react-router-dom';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { MAP_STYLE } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import TimelineSlider from './TimelineSlider';
import { useCenter } from './useCenter';
import { useDateIndex } from './useDateIndex';
import { useDateSet } from './useDateSet';
import { useLayers } from './useLayers';
import { useS3Keys } from './useS3Keys';
import { useViewBounds } from './useViewBounds';
import { useWData } from './useWData';
import { getTooltip } from './helpers';
import { useMapContext } from '../../../contexts/map-context';
import {
  DrawCircleFromCenterMode,
  DrawPolygonByDraggingMode,
  DrawPolygonMode,
  DrawRectangleMode,
  DrawSquareMode,
  EditableGeoJsonLayer,
  FeatureCollection,
  ModifyMode,
  TransformMode,
  ViewMode,
} from '@deck.gl-community/editable-layers';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import * as turf from '@turf/turf';
import { useCreateStudy, useStudyData } from '../../../hooks/map/use-map';

interface MapSectionProps {
  selectedArea: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
}

export default function MapSection({
  selectedArea,
  setSelectedDate,
}: MapSectionProps) {
  const { type: typeParam = '' } = useParams();
  // temporary
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;

  const [loadedView, setLoadedView] = useState(false);
  //
  const user = useUser();

  const year = useYearFilter();
  const { mutate: createStudyMutation } = useCreateStudy();

  const [probabilityRange, setProbabilityRange] = useState<[number, number]>();

  const [fieldRange, setFieldRange] = useState();
  const [zone, setZone] = useState();
  const [zoneRange, setZoneRange] = useState();

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    userId: user?.id || '',
    year: year || '',
    type,
  });

  const { dateSet } = useDateSet(s3Keys);

  const { selectedDateIndex, dateIndex, setSelectedDateIndex, setDateIndex } =
    useDateIndex(dateSet.length - 1);

  useEffect(() => {
    setSelectedDate(dateSet[selectedDateIndex]);
  }, [selectedDateIndex]);

  const { wData, loading: wDataLoading } = useWData({
    date: dateSet[selectedDateIndex],
    userId: user?.id || '',
    year: year || '',
    s3Keys,
    type,
  });

  const { center } = useCenter(wData);

  const { viewBounds } = useViewBounds(wData);

  const {
    myFeatureCollection,
    studyDetails,
    setStudyDetails,
    creatingStudy,
    setMyFeatureCollection,
    viewState,
    setViewState,
    setSelectedFeatureIndexes,
    selectedFeatureIndexes,
    selectedDrawMode,
    selectedField,
    setSelectedField,
    selectedCrop,
    selectedZone,
    createStudy,
    setCreateStudy,
    setCreatingStudy,
    selectedStudyIndex,
    trigger,
    setTrigger,
    setModalMode,
  } = useMapContext();

  const studies: any = useStudyData(user?.id ?? '', year!);

  const { layers, hoverInfo } = useLayers({
    wData,
    selectedArea,
    probabilityRange,
    field: selectedField,
    fieldRange,
    zone: selectedZone,
    zoneRange,
    type,
    crop: selectedCrop,
    featureCollection: myFeatureCollection,
  });

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  const loading = s3KeysLoading || wDataLoading;

  useEffect(() => {
    if (center && !loadedView) {
      setViewState((prev: any) => ({
        ...prev,
        longitude: center.lon,
        latitude: center.lat,
      }));
    }
  }, [center]);

  const calculateTotalArea = () => {
    try {
      const data: any = myFeatureCollection;
      if (data!.features.length > 0) {
        let area = 0;
        data!.features.forEach((feature: any) => {
          area += turf.area(feature);
        });
        return area.toFixed(2);
      }
      return 0;
    } catch (e) {
      console.log('Error calculating total area:', e);
      return 0;
    }
  };

  const calculateAverages = () => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    let avgLat = 0;
    let avgLon = 0;
    let num = 0;
    let x = 0;

    wData?.map((data, index) => {
      data.lon.forEach((lon: number, lonIndex: number) => {
        const bin = data['value'][lonIndex];
        x += bin;
        min = Math.min(min, bin);
        max = Math.max(max, bin);
        avgLat += data.lat[lonIndex];
        avgLon += lon;
        num += 1;
      });
    });
    return {
      xbin: `${min.toFixed(2)} - ${max.toFixed(2)}`,
      avgLat: (avgLat / num).toFixed(2),
      avgLon: (avgLon / num).toFixed(2),
      x: (x / num).toFixed(2),
    };
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (creatingStudy) {
      const area = calculateTotalArea();
      const avgs = calculateAverages();
      const polygons = myFeatureCollection.features.map((feature: any) => {
        return feature?.geometry.coordinates;
      });

      setStudyDetails({
        ...studyDetails,
        area,
        ...avgs,
        userId: user?.id || '',
        year,
        type,
        polygons,
        crop: selectedArea,
      });
      // setCreatingStudy(false)
    }
  }, [creatingStudy]);

  // add an event listener for the delet key to delete selected feature indexes from
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        let newFeatures = myFeatureCollection.features.filter(
          (feature, index) => {
            return !selectedFeatureIndexes.includes(index);
          }
        );
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
        setSelectedFeatureIndexes([]);
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        // remove the last feature
        let newFeatures = myFeatureCollection.features.slice(0, -1);
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: newFeatures,
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedFeatureIndexes, myFeatureCollection.features]);

  useEffect(() => {
    if (viewBounds && !loadedView) {
      try {
        let viewportWebMercator = new WebMercatorViewport(viewState);
        const { longitude, latitude, zoom } = viewportWebMercator.fitBounds(
          viewBounds,
          {
            padding: 50,
          }
        );
        setViewState((prev: any) => ({
          ...prev,
          longitude,
          latitude,
          zoom,
          transitionDuration: loading ? 0 : 1500,
        }));
        setLoadedView(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [loading, viewBounds]);

  useEffect(() => {
    setDateIndex(dateSet.length - 1);
  }, [dateSet]);

  useEffect(() => {
    async function createStudyFunc() {
      if (createStudy) {
        if (!studyDetails.name) {
          alert('Please enter a study name');
          setCreateStudy(false);
          return;
        }
        createStudyMutation(
          { ...studyDetails },
          {
            onSuccess: () => {
              setCreatingStudy(false);
              alert('Study added');
              queryClient.invalidateQueries([
                'historical-insights/createStudy',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error creating study:', error);
              alert(`Failed to create study. Please try again. ${error}`);
            },
          }
        );
        setCreateStudy(false);
      }
    }
    createStudyFunc();
  }, [createStudy]);

  const drawModes = [
    ViewMode,
    DrawPolygonMode,
    DrawSquareMode,
    DrawRectangleMode,
    DrawCircleFromCenterMode,
    DrawPolygonByDraggingMode,
    ModifyMode,
    TransformMode,
  ];

  useEffect(() => {
    if (!studies.data) return;
    let allFeatures: any = [];
    for (let i = 0; i < studies.data.length; i++) {
      const study = studies.data[i];
      if (selectedStudyIndex === study.user_id_type_crop_year + study.x_index) {
        const polygons = study.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        allFeatures = allFeatures.concat(features);
      }
    }
    setMyFeatureCollection({
      type: 'FeatureCollection',
      features: allFeatures,
    });
    setTrigger(trigger + 1);
  }, [selectedStudyIndex]);

  const layer = useMemo(
    () =>
      new EditableGeoJsonLayer({
        id: 'geojson-layer',
        mode: drawModes[selectedDrawMode],
        selectedFeatureIndexes: selectedFeatureIndexes,
        data: myFeatureCollection,
        modeConfig: { enableSpapping: true },
        onEdit: ({ updatedData, editType }) => {
          setMyFeatureCollection(updatedData);
          if (editType === 'updateTentativeFeature') {
            setModalMode('drawingShape');
          } else if (editType === 'addFeature') {
            setModalMode('drawingCompleted');
          }
        },
        onClick: (info) => {
          if (
            selectedDrawMode !== 0 &&
            selectedDrawMode !== 6 &&
            selectedDrawMode !== 7
          ) {
            return;
          }
          if (selectedFeatureIndexes.includes(info.index)) {
            setSelectedFeatureIndexes(
              selectedFeatureIndexes.filter((index) => index !== info.index)
            );
            return;
          }
          setSelectedFeatureIndexes([...selectedFeatureIndexes, info.index]);
        },
      }),
    [selectedDrawMode, selectedFeatureIndexes, myFeatureCollection]
  );

  useEffect(() => {
    if (myFeatureCollection.features.length < 1) {
      return;
    }
    setTimeout(() => {
      let index = myFeatureCollection.features.length - 1;
      if (index < 0) {
        return;
      }
      const updatedIndexes = [...selectedFeatureIndexes, index];
      // filter duplicates
      const updatedIndexesSet = new Set(updatedIndexes);
      setSelectedFeatureIndexes(Array.from(updatedIndexesSet));
    }, 10);
  }, [myFeatureCollection.features]);

  return (
    <div className="w-full space-y-9">
      <TimelineSlider
        value={dateIndex}
        onChange={(value) => {
          setDateIndex(parseInt(value));
        }}
        min={0}
        max={dateSet.length - 1}
        dateSet={dateSet}
      />

      <div className="relative min-h-[550px] rounded-3xl overflow-hidden">
        {loading && (
          <span className="absolute w-full h-full bg-white/30 z-10 flex items-center justify-center"></span>
        )}
        <DeckGL
          layers={[layers, layer]}
          viewState={viewState}
          controller={true}
          onViewStateChange={handleViewStateChange}
          getTooltip={getTooltip}
        >
          <Map
            reuseMaps
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={MAP_STYLE}
          />
        </DeckGL>
      </div>
    </div>
  );
}
