import { IconProps } from '../../types';

export default function ArrowDownCircleIcon({ fill = '#A3B4C2' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1471_313)">
        <path
          d="M0 12C0 5.37 5.37 0 12 0C18.63 0 24 5.37 24 12C24 18.63 18.63 24 12 24C5.37 24 0 18.63 0 12ZM6.22 13.28L11.47 18.53C11.76 18.82 12.24 18.82 12.53 18.53L17.78 13.28C18 13.06 18.06 12.75 17.94 12.46C17.82 12.17 17.55 12 17.25 12L14.25 12V7.5C14.25 6.67 13.58 6 12.75 6H11.25C10.42 6 9.75 6.67 9.75 7.5V12H6.75C6.45 12 6.17 12.18 6.06 12.46C5.95 12.74 6.01 13.06 6.22 13.28Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1471_313">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
