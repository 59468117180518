import { IconProps } from '../../types';

const MapIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88655 23.7796C5.44548 20.7283 0 13.452 0 9.32094C0 4.3449 3.99022 0.307739 9.0132 0.307739C13.9892 0.307739 18.0264 4.3449 18.0264 9.32094C18.0264 13.452 12.534 20.7283 10.0929 23.7796C9.52958 24.4838 8.44988 24.4838 7.88655 23.7796ZM9.0132 12.3253C10.6562 12.3253 12.0176 11.0109 12.0176 9.32094C12.0176 7.67791 10.6562 6.31654 9.0132 6.31654C7.32323 6.31654 6.0088 7.67791 6.0088 9.32094C6.0088 11.0109 7.32323 12.3253 9.0132 12.3253Z"
        fill={fill}
      />
    </svg>
  );
};

export default MapIcon;
