import { IconProps } from '../../types';

const FileUploadIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0151 0.615234L18.0151 6.61523H12.0151V0.615234ZM10.5151 6.61523C10.5151 7.45898 11.1714 8.11523 12.0151 8.11523H18.0151V22.3652C18.0151 23.6309 16.9839 24.6152 15.7651 24.6152H2.26514C0.999512 24.6152 0.0151367 23.6309 0.0151367 22.3652V2.86523C0.0151367 1.64648 0.999512 0.615234 2.26514 0.615234H10.5151V6.61523ZM13.5151 16.7871C13.9839 16.3652 13.9839 15.6621 13.5151 15.1934L9.76514 11.4434C9.34326 11.0215 8.64014 11.0215 8.21826 11.4434L4.46826 15.1934C3.99951 15.6621 3.99951 16.3652 4.46826 16.7871C4.89014 17.2559 5.59326 17.2559 6.01514 16.7871L7.89014 14.959V19.7402C7.89014 20.3965 8.35889 20.8652 9.01514 20.8652C9.62451 20.8652 10.1401 20.3965 10.1401 19.7402V14.959L11.9683 16.7871C12.1558 17.0215 12.437 17.1152 12.7651 17.1152C13.0464 17.1152 13.3276 17.0215 13.5151 16.7871Z"
        fill={fill}
      />
    </svg>
  );
};

export default FileUploadIcon;
