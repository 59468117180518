import queryString from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetClimateToken } from '../../../../hooks/climate/use-climate';
import SpinningIcon from '../../../../assets/icons/SpinningIcon';

const ClimateCallbackPage = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem('climate_user_id') || false;
  const { code } = queryString.parse(window.location.search);
  const { mutate: getClimateToken } = useGetClimateToken();

  useEffect(() => {
    if (code) {
      getClimateToken({
        userId: userId,
        code: code as string,
        clientId: process.env.REACT_APP_CLIMATE_CLIENT_ID!,
        clientSecret: process.env.REACT_APP_CLIMATE_CLIENT_SECRET!,
        tokenEndpoint: 'https://api.climate.com/api/oauth/token',
        callbackUrl: process.env.REACT_APP_CLIMATE_CALLBACK_URL!,
      });
    }
  }, []);

  if (!userId) {
    navigate('/');
  }

  return (
    <div className="flex justify-center items-center space-x-2">
      <p>Connecting</p>
      <SpinningIcon />
    </div>
  );
};

export default ClimateCallbackPage;
