import { IconProps } from '../../types';

const SproutIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3.80774C8.76562 3.80774 13.5 8.54211 13.5 14.3077V20.3077C13.5 21.1515 12.7031 21.8077 11.9531 21.8077C11.1562 21.8077 10.5 21.1515 10.5 20.3077V14.3077C4.6875 14.3077 0 9.57336 0 3.80774H3ZM21 0.807739H24C24 6.24524 19.875 10.7452 14.5781 11.2609C14.0625 9.38586 13.0781 7.65149 11.8125 6.24524C13.5938 3.01086 17.0156 0.807739 21 0.807739Z"
        fill={fill}
      />
    </svg>
  );
};

export default SproutIcon;
