import React, { useEffect, useMemo, useRef, useState } from 'react';
import CardHeader from '../../../components/commons/CardHeader';
import { useTableData } from '../../../hooks/map/use-map';
import { useUser } from '../../../contexts/auth-context';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { Bar } from 'react-chartjs-2';
import tinycolor from 'tinycolor2';
import { useParams } from 'react-router-dom';
import { mapParamsToTitles } from '../../../constants';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import { useMapContext } from '../../../contexts/map-context';
import SpinningIcon from '../../../assets/icons/SpinningIcon';

function LikelihoodExpectedRevenue({ crop }: { crop: string }) {
  const user = useUser();
  const year = useYearFilter();
  const cropSet = useRef(new Set<string>(['Corn', 'Soybeans']));

  const {
    selectedDate: date,
    selectedZone: filteredZone,
    setSelectedZone,
  } = useMapContext();
  const { type: typeParam = '' } = useParams();

  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop,
    type!,
    'agrivar_zone2',
    year!,
    date,
    'zone'
  );
  const [selectedCrops, setSelectedCrops] = useState<Set<string>>(
    new Set(['Corn', 'Soybeans'])
  );
  const [hiddenDatasets, setHiddenDatasets] = useState<Set<string>>(new Set());
  const [selectedChartZone, setSelectedChartZone] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  type DataType = {
    crop: string;
    x_bin: string;
    area: string;
    agrivar_zone2: string;
  };

  const cropColors: { [key: string]: string } = {
    Soybeans: 'rgba(174, 154, 125, 1)',
    Corn: 'rgba(255, 183, 27, 1)',
    Other: 'rgba(244, 39, 39, 1)',
    Wheat: 'rgba(98,162,235,1)',
  };

  const labels: string[] = useMemo(
    () =>
      Array.from(
        new Set(tableData?.data?.map((dataItem: DataType) => dataItem.x_bin))
      ).sort((a: any, b: any): number => {
        let numA = parseInt(a.split('-')[0]);
        let numB = parseInt(b.split('-')[0]);
        return numA - numB;
      }) as string[],
    [tableData?.data]
  );

  const zones = useMemo(() => {
    if (!tableData?.data) return {};

    const zoneData: { [key: string]: { data: number[]; crop: string } } = {};

    tableData.data.forEach((dataItem: DataType) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!zoneData[dataItem.agrivar_zone2]) {
        zoneData[dataItem.agrivar_zone2] = {
          data: Array(labels.length).fill(0),
          crop: dataItem.crop,
        };
      }

      const index = labels.indexOf(dataItem.x_bin);
      if (index !== -1) {
        zoneData[dataItem.agrivar_zone2].data[index] = area;
      }
    });

    return zoneData;
  }, [tableData?.data, labels]);

  // Get unique zone names from the data
  const uniqueZones = useMemo(
    () =>
      Object.keys(zones).sort((a, b) => {
        const zoneA = zones[a];
        const zoneB = zones[b];
        if (!zoneA || !zoneB) return 0;
        const comp = zoneA.crop.localeCompare(zoneB.crop);
        // if the crops are the same, sort by zone name
        if (comp === 0) return a.localeCompare(b);
        return comp;
      }),
    [zones]
  );

  // Set the first zone as the default selected zone
  useEffect(() => {
    if (uniqueZones.length > 0 && !selectedChartZone) {
      setSelectedChartZone(uniqueZones[0]);
    }
  }, [uniqueZones, selectedChartZone]);

  const filteredZones = useMemo(() => {
    return uniqueZones.filter((zoneName) =>
      zoneName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueZones, searchTerm]);

  const paginatedZones = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredZones.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredZones, currentPage]);

  const datasets = useMemo(() => {
    const zoneToDisplay = filteredZone || selectedChartZone;

    if (!zones[zoneToDisplay]) return [];

    const zoneCrop = zones[zoneToDisplay]?.crop;

    return [
      {
        label: zoneToDisplay,
        data: zones[zoneToDisplay].data,
        backgroundColor:
          cropColors[zoneCrop || ''] || 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 20,
      },
    ];
  }, [zones, filteredZone, selectedChartZone, cropColors]);

  const options: any = {
    indexAxis: 'x',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Revenue Per Acre ($)',
          font: { size: 20 },
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            let value =
              context.raw !== null ? `$${context.raw.toFixed(2)}` : 'N/A';
            let crop = zones[selectedChartZone]?.crop || 'Unknown Crop';
            return `${label} (${crop}): ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="p-0 col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={`Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Zone`}
        />
        {filteredZone && (
          <div
            className="card max-w-max flex gap-2 px-4 py-2 rounded-md"
            onClick={() => setSelectedZone('')}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p>Show All Zones</p>
          </div>
        )}
      </div>

      <div className="h-[250px]">
        <Bar data={{ labels, datasets }} options={options} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="mt-4 flex gap-4 w-full flex-wrap">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search zones..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 w-full p-2 text-black border rounded"
          />
          {paginatedZones.map((zoneName) => (
            <div
              key={zoneName}
              className={`cursor-pointer px-4 flex items-center py-2 rounded-md ${zoneName === selectedChartZone ? 'bg-gray-200' : 'bg-gray-100'}`}
              onClick={() => {
                setSelectedChartZone(zoneName);
                setSelectedZone(zoneName);
              }}
              style={{
                backgroundColor:
                  cropColors[zones[zoneName]?.crop] ||
                  'rgba(255, 99, 132, 0.2)',
              }}
            >
              <p>{zoneName}</p>
            </div>
          ))}
        </div>
        {/* Pagination Controls */}
        <div className="flex items-center justify-between mt-4">
          <div
            onClick={() => {
              if (currentPage === 1) {
                return;
              }
              setCurrentPage((prev) => Math.max(prev - 1, 1));
            }}
          >
            <ArrowLeftCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
          <p>
            {currentPage}/{Math.ceil(filteredZones.length / itemsPerPage)}
          </p>
          <div
            onClick={() => {
              if (currentPage * itemsPerPage >= filteredZones.length) {
                return;
              }
              setCurrentPage((prev) => prev + 1);
            }}
          >
            <ArrowRightCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LikelihoodExpectedRevenue;
