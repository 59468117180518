import { Button } from '@headlessui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Select from '../../../components/commons/Select';
import YearSlider from './YearSlider';
import {
  areaSelectionOptions,
  mapRelationshipOptions,
} from '../../../constants/selectCommonConfigs';
import {
  useHistoricalInsights,
  useUserCropYears,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useUser } from '../../../contexts/auth-context';
import {
  getAreaAndYieldByCrop,
  useFarmYield,
} from '../../../hooks/farm/use-farm-yield';
import { useEffect, useMemo } from 'react';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { useForm, useWatch } from 'react-hook-form';
import { useSetYearFilter } from '../../../contexts/app-filter-context';

function MapSelections() {
  const user = useUser();
  const methods = useForm();

  const setYearFilter = useSetYearFilter();

  const relationshipSelect = methods.watch('relationshipSelection', 'variety');
  const {
    setRgb,
    year,
    setYear,
    varietyYieldPerformance,
    setVarietyYieldPerformance,
    relationship,
    setRelationship,
    crop,
    setCrop,
  } = useHistoricalInsights();
  const years = useUserCropYears('45', crop);
  const { data: res } = useFarmYield(user?.id || '', year || '');

  const msgYield: any = useWebSocketDataV2('Farm_Yield');

  const farmYieldData: any = useMemo(() => {
    if (!res) return [];
    const updatedRes = res.map((farmYield) => {
      if (msgYield && msgYield.data && msgYield.data.crop === farmYield.crop) {
        return {
          ...farmYield,
          area: msgYield.data.area || farmYield.area,
          yield: msgYield.data.yield || farmYield.yield,
          yield_chg: msgYield.data.yield_chg || farmYield.yield_chg,
          yield_chg14: msgYield.data.yield_chg14 || farmYield.yield_chg14,
          yield_per_acre_chg: msgYield.data.yield_chg || farmYield.yield_chg,
        };
      }
      return farmYield;
    });
    return getAreaAndYieldByCrop(updatedRes);
  }, [msgYield.data, res]);

  useEffect(() => {
    setRelationship(relationshipSelect);
  }, [relationshipSelect]);

  return (
    <div className="w-full mb-4 p-2 px-4 card">
      <div className="flex items-center justify-center pb-0 space-x-8">
        <p className="">Year</p>
        {years?.data && years?.data?.length > 0 && (
          <Select
            options={years?.data
              ?.map((year: string) => ({
                value: year as string,
                label: year as string,
              }))
              .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value))}
            name="yearSelection"
            defaultValue={year}
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              setYearFilter?.(e.target.value);
            }}
          />
        )}

        <p>Area Selection</p>
        <Select
          options={areaSelectionOptions}
          name="areaSelection"
          value={crop}
          onChange={(e) => setCrop(e.target.value)}
        />
        <p className="text-yellow">
          {farmYieldData[crop]?.totalArea || 0} Acres
        </p>

        <p>Map Relationship</p>
        <Select
          options={mapRelationshipOptions}
          name="relationshipSelection"
          onChange={(e) => {
            setRelationship(e.target.value);
            setRgb([0, 0, 0]);
          }}
          value={relationship}
        />

        <p>Display:</p>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            defaultChecked
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('x')}
          />
          <span>
            {relationship.slice(0, 1).toUpperCase()}
            {relationship.slice(1, relationship.length)}
          </span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('yield')}
          />
          <span>Yield</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="radio"
            value="text"
            name="display"
            className="custom-radio"
            onChange={() => setVarietyYieldPerformance('yieldPerformance')}
          />
          <span>Yield Performance</span>
        </label>

        <Button className="card items-center gap-4 flex flex-row  px-4 py-0">
          <ArrowDownTrayIcon className="w-6 h-6" />
          <p>Download Charts</p>
        </Button>
      </div>
    </div>
  );
}

export default MapSelections;
