import MapboxDraw, { modes } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl-style-switcher/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { memo, useEffect, useMemo, useRef } from 'react';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import { PolygonStyles } from '../../../constants';
// @ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;

interface FieldMapProps {
  drawRef: React.MutableRefObject<any>;
  geometry?: Geometry;
  loading?: boolean;
}

const DEFAULT_CENTER: mapboxgl.LngLatLike | undefined = [-98.48155, 44.640713];

function PullPreviewMap({ drawRef, geometry, loading = false }: FieldMapProps) {
  const selectedFieldBounds = useMemo(() => {
    if (geometry) {
      const geoJSON: Feature<Geometry, GeoJsonProperties> = {
        id: '0',
        type: 'Feature',
        properties: {},
        geometry: geometry,
      };

      return turf.bbox(geoJSON);
    }
  }, [geometry]);

  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);

  // Init map with draw control
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: DEFAULT_CENTER,
      zoom: 10,
    });

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: 'static',
      styles: PolygonStyles,
      userProperties: true,
      modes: {
        static: StaticMode,
      },
    });

    map.current.addControl(drawRef.current, 'top-left');
  }, []);

  // Render all features
  useEffect(() => {
    if (map.current && drawRef.current && geometry) {
      const geoJSON = geometry;
      const geoJSON1 = {
        id: '0',
        type: 'Feature',
        properties: {},
        geometry: geoJSON,
      };
      const feat = {
        type: 'FeatureCollection',
        features: [{ ...geoJSON1 }],
      };
      drawRef.current.add(feat);
    }
  }, [geometry]);

  // Handle fit bounds
  useEffect(() => {
    if (map.current && drawRef.current && selectedFieldBounds) {
      map.current.fitBounds(selectedFieldBounds, {
        padding: 100,
        duration: 500,
      });
    }
  }, [selectedFieldBounds?.toString()]);

  return (
    <div className="h-[468px] rounded-2xl relative overflow-hidden">
      <div ref={mapContainer} className="h-full w-full"></div>
      {loading && (
        <div className="loading w-full h-full bg-gray-base/50 absolute top-0 flex justify-center items-center">
          <SpinningIcon />
        </div>
      )}
    </div>
  );
}

export default memo(PullPreviewMap);
