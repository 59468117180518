import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getFarmVAR14DaysChangeChartData,
  useFarmRevenues14Change,
} from '../../../hooks/farm/use-farm-revenues-14-change';
import SingleLineChart from '../../commons/charts/SingleLineChart';

export default function VAR14ChangeChart({
  width = 240,
  height = 150,
}: {
  width?: number;
  height?: number;
}) {
  const user = useUser();
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();
  const { data: revenue14DaysChange } = useFarmRevenues14Change(
    user?.id || '',
    year || ''
  );
  const { hvar, hnvar, tradeDates } =
    getFarmVAR14DaysChangeChartData(revenue14DaysChange);
  const dataToView = incomeView === 'revenue' ? hvar : hnvar;

  return (
    <SingleLineChart
      data={dataToView}
      labels={tradeDates}
      width={width}
      height={height}
      stepSize={1000}
      scales={{
        x: {
          display: false,
        },
      }}
    />
  );
}
