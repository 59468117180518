import { useEffect, useState } from 'react';
import { getLatestKeys } from '../../../utils';
import { HISTORICAL_BUCKET, PARQUET_BUCKET } from '../../../constants';

export const useS3Keys = ({
  userId,
  crop,
  year,
  relationship,
}: {
  userId: string;
  year: string;
  crop: string;
  relationship: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [s3Keys, setS3Keys] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!userId || !year || !crop || !relationship) return;
      const prefix = `${userId}_${crop}_${year}_${relationship}`;
      const s3Keys = await getLatestKeys(HISTORICAL_BUCKET, prefix);
      setS3Keys(s3Keys);
      setLoading(false);
    };

    fetchData();
  }, [relationship, crop, userId, year]);

  return {
    loading,
    s3Keys,
  };
};
