import { IconProps } from '../../types';

export default function SettingIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8711 7.82812C23.0586 8.25 22.918 8.67188 22.5898 9L20.5742 10.8281C20.6211 11.2031 20.668 11.625 20.668 12C20.668 12.4219 20.6211 12.8438 20.5742 13.2188L22.5898 15.0469C22.918 15.375 23.0586 15.7969 22.8711 16.2188C22.6836 16.7812 22.4492 17.2969 22.168 17.8125L21.9336 18.1875C21.6055 18.7031 21.2773 19.2188 20.9023 19.6875C20.6211 20.0156 20.1523 20.1094 19.7305 19.9688L17.1523 19.1719C16.4961 19.6406 15.793 20.0156 15.0898 20.3438L14.4805 23.0156C14.3867 23.4375 14.0586 23.7656 13.6367 23.8594C12.9805 23.9531 12.3242 24 11.6211 24C10.9648 24 10.3086 23.9531 9.65234 23.8594C9.23047 23.7656 8.90234 23.4375 8.80859 23.0156L8.19922 20.3438C7.49609 20.0156 6.79297 19.6406 6.13672 19.1719L3.55859 19.9688C3.13672 20.1094 2.66797 20.0156 2.38672 19.6875C2.01172 19.2188 1.68359 18.7031 1.35547 18.1875L1.12109 17.8125C0.839841 17.2969 0.605466 16.7812 0.417966 16.2188C0.230466 15.7969 0.371091 15.375 0.699216 15.0469L2.71484 13.2188C2.66797 12.8438 2.66797 12.4219 2.66797 12C2.66797 11.625 2.66797 11.2031 2.71484 10.8281L0.699216 9C0.371091 8.67188 0.230466 8.25 0.417966 7.82812C0.605466 7.26562 0.839841 6.75 1.12109 6.23438L1.35547 5.85938C1.68359 5.34375 2.01172 4.82812 2.38672 4.35938C2.66797 4.03125 3.13672 3.9375 3.55859 4.07812L6.13672 4.875C6.79297 4.40625 7.49609 3.98438 8.19922 3.70312L8.80859 1.03125C8.90234 0.609375 9.23047 0.28125 9.65234 0.1875C10.3086 0.09375 10.9648 0 11.668 0C12.3242 0 12.9805 0.09375 13.6367 0.1875C14.0586 0.28125 14.3867 0.609375 14.4805 1.03125L15.0898 3.70312C15.793 3.98438 16.4961 4.40625 17.1523 4.875L19.7305 4.07812C20.1523 3.9375 20.6211 4.03125 20.9023 4.35938C21.2773 4.82812 21.6055 5.34375 21.9336 5.85938L22.168 6.23438C22.4492 6.75 22.6836 7.26562 22.8711 7.82812ZM11.668 15.75C13.7305 15.75 15.418 14.1094 15.418 12C15.418 9.9375 13.7305 8.25 11.668 8.25C9.55859 8.25 7.91797 9.9375 7.91797 12C7.91797 14.1094 9.55859 15.75 11.668 15.75Z"
        fill={fill}
      />
    </svg>
  );
}
