import { useEffect, useState } from 'react';
import { getLatestKeys } from '../../../utils';
import { PARQUET_BUCKET } from '../../../constants';

export const useS3Keys = ({
  userId,
  year,
  type,
}: {
  userId: string;
  year: string;
  type: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [s3Keys, setS3Keys] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!userId || !year) return;
      const prefix = `${userId}_${year}_${type}`;
      const s3Keys = await getLatestKeys(PARQUET_BUCKET, prefix);
      setS3Keys(s3Keys);
      setLoading(false);
    };

    fetchData();
  }, [type, userId, year]);

  return {
    loading,
    s3Keys,
  };
};
