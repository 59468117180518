import DataInputs from '../../pages/data-inputs';
import FarmExpenses from '../../pages/data-inputs/farm-expenses';
import FieldInfoBoundary from '../../pages/data-inputs/field-info-boundary';
import ClimateCallbackPage from '../../pages/data-inputs/partner/callback/climate';
import JohnDeereCallbackPage from '../../pages/data-inputs/partner/callback/john-deere';
import SoilSamples from '../../pages/data-inputs/soil-samples';

const dataInputRoutes = [
  {
    path: 'data-inputs',
    children: [
      {
        index: true,
        element: <DataInputs />,
      },
      {
        path: 'farm-expenses',
        element: <FarmExpenses />,
      },
      {
        path: 'soil-samples',
        element: <SoilSamples />,
      },
      {
        path: 'field-info-and-boundary',
        element: <FieldInfoBoundary />,
      },
      {
        path: 'jd/callback',
        element: <JohnDeereCallbackPage />,
      },
      {
        path: 'climate/callback',
        element: <ClimateCallbackPage />,
      },
    ],
  },
];

export default dataInputRoutes;
