import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSetIncomeViewFilter } from '../../../../../contexts/app-filter-context';
import SwitchInput from '../../../../commons/inputs/SwitchInput';

interface IncomeViewSwitchProps {
  onChange?: (enabled: boolean) => void;
}

function IncomeViewSwitch(props: IncomeViewSwitchProps) {
  const setIncomeView = useSetIncomeViewFilter();
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    setIncomeView?.(enabled ? 'revenue' : 'net-income');
  }, [enabled, setIncomeView]);

  return (
    <div className="flex justify-between items-center space-x-3 whitespace-nowrap">
      <p className={clsx(!enabled ? 'text-base-000' : 'text-base-900')}>
        Net income
      </p>
      <SwitchInput size="lg" enabled={enabled} onChange={setEnabled} />
      <p className={clsx(enabled ? 'text-base-000' : 'text-base-900')}>
        Revenue
      </p>
    </div>
  );
}

export default IncomeViewSwitch;
