import { yupResolver } from '@hookform/resolvers/yup';
import { Feature, Geometry } from '@loaders.gl/schema';
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AddFIBForm from '../../../components/data-inputs/field-info-boundary/AddFIBForm';
import FIBList from '../../../components/data-inputs/field-info-boundary/FIBList';
import FIBMap from '../../../components/data-inputs/field-info-boundary/FIBMap';
import {
  enhanceFieldItem,
  getFeatureData,
  getFieldData,
  getNewFieldIndex,
} from '../../../components/data-inputs/field-info-boundary/helpers';
import PullPreviewMap from '../../../components/data-inputs/field-info-boundary/PullPreviewMap';
import { CropColors } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useAllFieldPolygon,
  useCreateFieldPolygon,
} from '../../../hooks/field-polygon/use-field-polygon';
import {
  EnhancedFieldPolygon,
  FieldPolygonCreatePayload,
} from '../../../types';

const validateSchema = yup.object({
  field: yup.string().required().label('Field Name'),
  crop: yup.string().required().label('Crop'),
  year: yup.string().required().label('Year'),
  addFieldOption: yup.string().nullable(),
  partnerSingle: yup.string(),
  partnerAll: yup.string(),
  isPullAll: yup.boolean(),
  partnerFieldIndex: yup.string(),
});

export default function FieldInfoBoundary() {
  const queryClient = useQueryClient();
  //  use ref to store selected/created/updated feature, prevent from rerender
  const activeFeature = useRef<any>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const [rerenderKey, setRerenderKey] = useState<number>(0); // use for reset form
  const [checkedField, setCheckedField] = useState<EnhancedFieldPolygon[]>([]);
  const [selectedField, setSelectedField] = useState<EnhancedFieldPolygon>();
  const [drawMode, setDrawMode] = useState<string>('simple_select');
  const [partnerFieldGeometry, setPartnerFieldGeometry] = useState<Geometry>();
  const [isPending, setIsPending] = useState<boolean>(false);

  const year = useYearFilter();
  const user = useUser();
  const { mutateAsync: create, isSuccess } = useCreateFieldPolygon();

  const { data: allFields, isFetching } = useAllFieldPolygon(
    user?.id ?? '',
    year ?? ''
  );

  const fpList = useMemo(() => {
    return allFields?.map((field) => enhanceFieldItem(field));
  }, [allFields, isFetching]);

  const allFeatures = useMemo(() => {
    return fpList?.map((field) => {
      const geoJSON = JSON.parse(field.geojson);
      return {
        ...geoJSON,
        features: geoJSON.features.map((feat: any, index: number) => {
          return {
            ...feat,
            id: field.user_id_field_index,
            properties: {
              ...feat.properties,
              field,
              crop_color: CropColors[field.crop2],
            },
          };
        }),
      };
    });
  }, [fpList]);

  const form = useForm({
    defaultValues: {
      field: '',
      crop: '',
      year: '',
    },
    resolver: yupResolver(validateSchema),
    mode: 'all',
  });

  // Reset selected field when year changed
  useEffect(() => {
    setSelectedField(undefined);
    activeFeature.current = null;
  }, [year]);

  // Reset active feature data when active feature changed
  useEffect(() => {
    setActiveFeatureData(undefined);
  }, [activeFeature.current]);

  const handleSelectFieldOnMap = useCallback((feature?: Feature) => {
    if (feature) {
      const field = feature.properties?.field;
      setSelectedField(field);
      activeFeature.current = feature;
    } else {
      setSelectedField(undefined);
      activeFeature.current = null;
    }
  }, []);

  // Handle delete field on list
  const handleOnDelete = (fieldId: string) => {
    if (drawRef.current) {
      drawRef.current.delete(fieldId);
      activeFeature.current = null;
    }
  };

  // Handle delete drawed feature (not related to any field) on map
  const handleDeleteFeature = useCallback((event?: DrawDeleteEvent) => {
    handleReset();
  }, []);

  const handleCreateFeature = useCallback((event: DrawCreateEvent) => {
    activeFeature.current = event.features[0];
  }, []);

  const [activeFeatureData, setActiveFeatureData] = useState<any>();
  const [isFetchingFeatureData, setIsFetchingFeatureData] =
    useState<boolean>(false);
  const handleUpdateFeature = useCallback(async (event: DrawUpdateEvent) => {
    setIsFetchingFeatureData(true);
    activeFeature.current = event.features[0];
    const data = await getFeatureData(event.features[0]);
    setActiveFeatureData(data);
    setIsFetchingFeatureData(false);
  }, []);

  const handleReset = useCallback(() => {
    if (drawRef.current) {
      drawRef.current.deleteAll();
    }
    activeFeature.current = null;
    form.reset();
    setRerenderKey(new Date().getTime());
  }, []);

  const handleSubmitByDraw = useCallback(
    async (values: any) => {
      setIsPending(true);
      const newFieldIndex = getNewFieldIndex(
        allFields?.map((field) => parseInt(field.field_index)) ?? []
      );

      if (!activeFeature.current || !user || !year) return;
      drawRef.current?.changeMode('simple_select');

      try {
        const payload: FieldPolygonCreatePayload = {
          ...(await getFieldData({
            feature: activeFeature.current,
            crop: values.crop,
            year,
            field: values.field,
            userId: user.id,
            field_index: newFieldIndex.toString(),
          })),
        };
        await create(payload);
      } catch (error) {
        console.error(error);
      }
      // Clear everything
      handleReset();
      setIsPending(false);
    },
    [allFields, create, handleReset, user, year]
  );

  const handleSetDrawMode = useCallback((mode: string) => {
    setDrawMode(mode);
  }, []);

  const handleCancelEditForm = useCallback(() => {
    activeFeature.current = null;
    setSelectedField(undefined);
    drawRef.current?.changeMode('simple_select');
    setActiveFeatureData(undefined);
    queryClient.invalidateQueries({ queryKey: ['field_polygon'] });
  }, []);

  const handleAfterSubmitEditForm = useCallback(() => {
    activeFeature.current = null;
    setSelectedField(undefined);
    drawRef.current?.changeMode('simple_select');
    setActiveFeatureData(undefined);
  }, []);

  return (
    <div className="grid grid-cols-12 gap-8">
      {/* Map */}
      <div className="col-span-12">
        {!isEmpty(partnerFieldGeometry) ? (
          <PullPreviewMap drawRef={drawRef} geometry={partnerFieldGeometry} />
        ) : (
          <FIBMap
            features={allFeatures}
            selectedField={selectedField}
            onCreateFeature={handleCreateFeature}
            onUpdateFeature={handleUpdateFeature}
            onSelectFeature={handleSelectFieldOnMap}
            onSubmitByDraw={handleSubmitByDraw}
            onDeleteFeature={handleDeleteFeature}
            drawRef={drawRef}
            drawMode={drawMode}
            setDrawMode={handleSetDrawMode}
            isPendingSaveDraw={isPending}
            activeFeature={activeFeature.current}
            form={form}
            loading={isFetching}
          />
        )}
      </div>
      {/* Add field form */}
      <div className="col-span-12 xl:col-span-3">
        <AddFIBForm
          key={rerenderKey}
          drawRef={drawRef}
          activeFeature={activeFeature.current}
          setPartnerFieldGeometry={setPartnerFieldGeometry}
          drawMode={drawMode}
          setDrawMode={handleSetDrawMode}
          onSubmitByDraw={handleSubmitByDraw}
          isPendingSaveDraw={isPending}
          onCancel={handleReset}
          form={form}
        />
      </div>
      {/* Field list */}
      <div className="col-span-12 xl:col-span-9">
        {/* <p className="display-md-bold text-center mb-8">
          You've Successfully Pulled 5 Partner Field Boundaries and Information
          From Climate!
        </p> */}
        <FIBList
          data={fpList || []}
          loading={isFetching}
          checkedField={checkedField}
          onCheck={setCheckedField}
          selectedField={selectedField}
          onSelect={setSelectedField}
          onDeleteField={handleOnDelete}
          activeFeatureData={activeFeatureData}
          isFetchingFeatureData={isFetchingFeatureData}
          onCancel={handleCancelEditForm}
          afterSubmit={handleAfterSubmitEditForm}
        />
      </div>
    </div>
  );
}
