import { CloudArrowDownIcon } from '@heroicons/react/24/solid';
import { UseFormReturn } from 'react-hook-form';
import { CompleteRegisterValueTypes } from '.';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';
import { SignUpSections } from '../../../constants';

export default function UserTermsConditions({
  onNext,
  form,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes, any, undefined>;
}) {
  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard title="User Terms and Conditions" className="relative">
        <div className="space-y-4 max-h-[500px] overflow-y-auto custom-scrollbar p-4 bg-base-1000 rounded-lg">
          <p>
            BETA TEST AGREEMENT This BETA TEST Agreement (“Agreement”) is
            entered into between AgriVaR LLC (“AgriVaR”) and the customer
            identified below (“Customer”) as of the last date signed below.
          </p>
          <p>
            1. License Grant. Subject to the terms and conditions hereof,
            AgriVaR grants to Customer, for the BETA Test Period identified
            below, a limited non-exclusive, non-transferable, revocable license
            to use the AgriVaR software products identified below (“Software”)
            in object code format solely for the purpose of Customer's internal
            evaluation of the Software and not for general commercial use.
          </p>
          <p>
            2. No Services. AgriVaR shall be under no obligation to provide
            Customer with any services except as explicitly set forth in section
            5 herein.
          </p>
          <p>
            3. Termination. The licenses granted hereunder terminate on
            expiration of the BETA Test Period. Additionally, either Party may
            terminate this Agreement upon written notice to the other Party at
            any time. Within five days after termination, Customer will (i)
            return to AgriVaR the Software and all copies thereof in the form
            provided by AgriVaR or (ii) upon request by AgriVaR destroy the
            Software and all copies thereof and certify in writing that it has
            been destroyed. Sections 2 through 10 shall survive termination of
            this Agreement.
          </p>
          <p>
            4. Proprietary Rights; Confidentiality; Restrictions. Customer
            acknowledges that the Software contains confidential information and
            trade secrets of AgriVaR and its licensors. Customer will not: copy
            (except as strictly necessary to use the Software in accordance with
            the terms of section 1 hereof), distribute, sell, sublicense or
            otherwise transfer or make available the Software or any portion
            thereof to any third party; remove from view any copyright legend,
            trademark or confidentiality notice appearing on the Software or
            Software output; a modify, adapt, translate, reverse engineer,
            decompile or derive the source code for the Software, or authorize a
            third party to do any of the foregoing. Customer will reproduce all
            of AgriVaR's and its licensors' copyright notices and any other
            proprietary rights notices on all copies of the Software that
            Customer makes hereunder. Customer will not use the Software or any
            documentation provided therewith for any purpose other than
            Customer's internal evaluation and the provision of feedback to
            AgriVaR, and not to disclose to any third party without the prior
            written approval of AgriVaR, the Software, its features, feedback
            (as defined in Section 8), related technical information identified
            as confidential or the results of any performance or functional
            evaluation or test of the Software (the “Confidential Information”).
            Customer will use no less than all reasonable efforts to protect the
            Confidential Information from unauthorized use or disclosure.
            Customer may disclose Confidential Information only to those of its
            employees who have a bona fide need to know such information for
            Customer's evaluation of the Software and who have first executed a
            written agreement that contains use and nondisclosure restrictions
            at least as protective as those set forth herein. Customer will
            immediately report any violation of this provision to AgriVaR and
            shall employ all reasonable means to mitigate any damages or losses
            that AgriVaR may incur as a result of any such violation. It is
            further understood and agreed that money damages would not be a
            sufficient remedy for any unauthorized disclosure or threat of
            unauthorized disclosure of Confidential Information by Customer and
            that AgriVaR will be entitled to equitable relief, including
            injunction and specific performance, as a remedy for any such
            unauthorized or threat of unauthorized disclosure of Confidential
            Information. Such remedies will not be deemed to be the exclusive
            remedies for an unauthorized disclosure or threat of unauthorized
            disclosure by Customer, but will be in addition to all other
            remedies available at law or in equity. Customer's rights in the
            Software will be limited to those expressly granted in Section 1.
            AgriVaR and its licensors reserve all rights and licenses in and to
            the Software not expressly granted to Customer hereunder.
          </p>
          <p>
            5. Personal Data Use. AgriVaR shall collect, hold, process, and
            provide certain personal data to a necessary third party as a
            requisite for accessing third party data, where such holding,
            processing and provisioning shall be in compliance with all
            applicable privacy laws, and with the understanding that AgriVaR
            will not process such personal data in such a way as to cause said
            third party to breach said applicable laws.
          </p>
          <p>
            6. Personal Data Use Consent. Customer consents that said third
            party may process the personal data with the understanding that it
            may use personal information to, inter alia, authenticate Customer;
            administer/supply services necessary for the Beta-Testing of the
            AgriVaR Software; and comply with applicable regulatory and legal
            requirements. Third party shall maintain commercially reasonable
            administrative, technical and physical safeguards to protect the
            confidentiality and security of personal data consistent with
            applicable laws, rules or regulations. Should Customer choose not to
            provide the necessary personal data, AgriVaR may be unable to
            facilitate your participation in the Beta-Test.
          </p>
          <p>
            7. Data Capture. Customer consents to the capture of client-side
            interactions with the Software by AgriVaR, including but not limited
            to, clicks, pageview, form submissions, and change events.
          </p>
          <p>
            8. Delivery and Installation. AgriVaR will provide the Software via
            electronic delivery. Customer will be responsible for installing the
            Software. AgriVaR may provide reasonable assistance in connection
            with such installation.
          </p>
          <p>
            9. Warranty. THE SOFTWARE IS PROVIDED “AS-IS.” AGRIVAR DISCLAIMS ALL
            OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT
            AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE IN
            TRADE.
          </p>
          <p>
            10. Acknowledgment of Beta Software. Customer acknowledges and
            agrees that: (a) the Software is not an official product and has not
            been commercially released for sale by AgriVaR; (b) the Software may
            not operate properly, be in final form or fully functional; (c) the
            Software may contain errors, design flaws or other problems; (d) it
            may not be possible to make the Software fully functional; (e) the
            information obtained using the Software may not be accurate and may
            not accurately correspond to information extracted from any database
            or other source; (f) use of the Software may result in unexpected
            results, loss of data or communications, project delays or other
            unpredictable damage or loss; (g) AgriVaR is under no obligation to
            release a commercial version of the Software; and (h) AgriVaR has
            the right unilaterally to abandon development of the Software, at
            any time and without any obligation or liability to Customer.
            Customer acknowledges and agrees that it should not rely on the
            Software for any reason. Customer is solely responsible for
            maintaining and protecting all data and information that is
            retrieved, extracted, transformed, loaded, stored or otherwise
            processed by the Software. Customer will be responsible for all
            costs and expenses required to backup and restore any data and
            information that is lost or corrupted as a result of Customer's use
            of the Software.
          </p>
          <p>
            11. Limitation of Liability. EXCEPT AS OTHERWISE REQUIRED BY LAW,
            THE LIABILITY OF AGRIVAR AND ITS LICENSORS TO CUSTOMER OR ANY THIRD
            PARTY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE USE
            OF THE SOFTWARE, OR FOR ANY ERROR OR DEFECT IN THE SOFTWARE, OR FOR
            THE PROVISION OF TECHNICAL SUPPORT INSTALLATION, TRAINING OR OTHER
            SERVICES IN CONNECTION THEREWITH, HOWEVER CAUSED, AND ON ANY THEORY
            OF LIABILITY, INCLUDING CONTRACT, STRICT LIABILITY, NEGLIGENCE OR
            OTHER TORT, SHALL BE LIMITED TO DIRECT DAMAGES NOT TO EXCEED ANY
            FEES PAID TO AGRIVAR HEREUNDER. IN NO EVENT WILL AGRIVAR OR ITS
            LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE
            OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS,
            BUSINESS, REVENUE, DATA OR DATA USE, EVEN IF ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS WILL APPLY
            NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY REMEDY.
            The limitations of liability set forth in this Agreement reflect the
            allocation of risk negotiated and agreed to by the Parties. The
            Parties would not enter into this Agreement without these
            limitations on its liability. These limitations will apply
            notwithstanding any failure of essential purpose of any limited
            remedy.
          </p>
          <p>
            12. Feedback. Customer will provide reasonable feedback to AgriVaR
            concerning the features and functionality of the Software. If
            Customer provides feedback to AgriVaR, all such feedback will be the
            sole and exclusive property of AgriVaR. Customer hereby irrevocably
            transfers and assigns to AgriVaR and agrees to irrevocably assign
            and transfer to AgriVaR all of Customer's right, title, and interest
            in and to all feedback including all intellectual property rights
            therein (collectively, “Intellectual Property Rights”). Customer
            will not earn or acquire any rights or licenses in the Software or
            in any AgriVaR Intellectual Property Rights on account of this
            Agreement or Customer's performance under this Agreement, even if
            AgriVaR incorporates any feedback into the Software.
          </p>
          <p>
            13. Audit. AgriVaR may, at any time during the term of this
            Agreement, upon reasonable written notice and during Customer's
            normal business hours, audit Customer's use of the Software at
            Customer's premises, as reasonably necessary to confirm that
            Customer is using the Software in accordance with the terms and
            conditions of this Agreement. AgriVaR may use a third-party
            organization reasonably acceptable to Customer to assist AgriVaR in
            conducting such an audit. Customer will cooperate with AgriVaR in
            such audit and will promptly make available to AgriVaR all
            information and materials reasonably required by AgriVaR to conduct
            such an audit.
          </p>
          <p>
            14. General. Customer may not assign or otherwise transfer, by
            operation of law or otherwise, any of its rights under this
            Agreement without AgriVaR's prior written consent, and any attempted
            assignment without such consent will be null and of no effect. This
            Agreement constitutes the entire agreement between the Parties and
            supersedes any and all prior agreements, communications and
            understandings with respect to the evaluation of the Software and
            shall be construed in accordance with the laws of the State of South
            Dakota (excluding its body of law controlling conflicts of law). The
            Parties expressly agree that the United Nations Convention on
            Contracts for the International Sale of Goods will not apply. Any
            legal action or proceeding arising under this Agreement will be
            brought exclusively in the federal or state courts located in the
            State of South Dakota and the Parties hereby irrevocably consent to
            the personal jurisdiction and venue therein. If any provision of
            this Agreement is held invalid or unenforceable by a court of
            competent jurisdiction, such provision will be construed so as to be
            enforceable to the maximum extent permissible by law, and the
            remaining provisions of the Agreement will remain in full force and
            effect. The waiver of any breach or default will not constitute a
            waiver of any other right hereunder or of any subsequent breach or
            default. All notices required or permitted under this Agreement will
            be in writing and delivered in person, by confirmed facsimile
            transmission or by overnight delivery service and in each instance
            will be deemed given upon receipt. All communications will be sent
            to the addresses set forth below or to such other address as may be
            specified by either Party to the other in accordance with this
            Section. AgriVaR AgriVaR LLC 120 22nd Avenue, Suite 132 Brookings,
            South Dakota 57006
          </p>
        </div>
        <Checkbox
          name="user_terms_conditions"
          rounded
          label="I have read, understand, and agree to the Terms of Service"
          className="mt-5"
        />
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal !w-fit mt-5 mx-auto"
          disabled={!form.watch('user_terms_conditions')}
          onClick={() => onNext(SignUpSections.CMETermsConditions)}
        >
          Continue
        </Button>
        <button className="flex items-center space-x-2 text-[#343E44] mx-auto mt-4">
          <CloudArrowDownIcon className="size-6" />
          <span>Download Terms</span>
        </button>
        <p className="text-right absolute right-6 bottom-4">1/3</p>
      </StyledCard>
    </div>
  );
}
