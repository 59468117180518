export function calculateTotalUnit({
  seedingRate,
  seedsPerUnit,
}: {
  seedingRate: number;
  seedsPerUnit: number;
}) {
  return seedingRate / seedsPerUnit;
}

export function calculateCostPerAcre({
  seedingRate,
  pricePerUnit,
  seedsPerUnit,
}: {
  seedingRate: number;
  pricePerUnit: number;
  seedsPerUnit: number;
}) {
  const totalUnitPerAcre = calculateTotalUnit({ seedingRate, seedsPerUnit });
  return totalUnitPerAcre * pricePerUnit;
}

export function calculateTotalCost({
  seedingRate,
  numberOfAcres,
  pricePerUnit,
  seedsPerUnit,
}: {
  seedingRate: number;
  pricePerUnit: number;
  numberOfAcres: number;
  seedsPerUnit: number;
}) {
  const pricePerAcre = calculateCostPerAcre({
    seedingRate,
    pricePerUnit,
    seedsPerUnit,
  });
  return pricePerAcre * numberOfAcres;
}
