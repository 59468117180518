import { useMemo, useState } from 'react';
import Button from '../../../components/commons/Button';
import CropIcon from '../../../components/commons/CropIcon';
import Disclosure from '../../../components/commons/Disclosure';
import Select, { AwaitSelect } from '../../../components/commons/Select';
import PrescriptionMapContainer from '../../../components/dashboard/prescription/PrescriptionMapContainer';
import AddChemicals from '../../../components/dashboard/prescription/add-chemicals';
import AddFertilizer from '../../../components/dashboard/prescription/add-fertilizer';
import AddMicronutrient from '../../../components/dashboard/prescription/add-micronutrients';
import SetSeedingRateAndVarieties from '../../../components/dashboard/prescription/set-seeding-rate-and-varieties';
import SetYieldGoal from '../../../components/dashboard/prescription/set-yield-goal';
import Subtotal from '../../../components/dashboard/prescription/subtotals';
import { SupportedCrops } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useFieldPrescription } from '../../../hooks/field/use-field-prescription';

// @TODO add context for prescription
export const exportItems = [
  {
    value: '',
    label: 'Export Prescription',
  },
  {
    value: 'Seeding',
    label: 'Seeding',
  },
  {
    value: 'Fertilzer',
    label: 'Fertilzer',
  },
  {
    value: 'Email Seeding',
    label: 'Email me a link to my seeding prescription file',
  },
  {
    value: 'Email Fertilzer',
    label: 'Email me a link to my fertilzer prescription file',
  },
  {
    value: 'jd_export',
    label: 'JD Operations Center',
  },
  {
    value: 'climate_export',
    label: 'Climate',
  },
];

const selectLabelClasses =
  'absolute z-[1] top-[50%] -translate-y-[50%] left-4 text-xl-bold';

export default function Prescription() {
  const [crop, setCrop] = useState(SupportedCrops[0]);
  const year = useYearFilter();
  const user = useUser();
  const userIdYear = `${user?.id}_${year}`;
  const { data: fields, isLoading } = useFieldPolygonsByYear(
    user?.id || '',
    year || ''
  );

  const fieldOptions = fields?.map((field) => ({
    value: field.field_index,
    label: field.field,
    icon: <CropIcon cropName={field.crop2} />,
  }));

  const [selectedField, setSelectedField] = useState(fieldOptions?.[0]?.value);
  const { data, isFetching } = useFieldPrescription(userIdYear, selectedField);

  const filteredPrescriptions = useMemo(() => {
    return (
      data
        ?.filter((item) => item.crop === crop)
        ?.sort(
          (a, b) => parseInt(a.agrivar_zone1) - parseInt(b.agrivar_zone1)
        ) || []
    );
  }, [data, crop]);

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="relative">
          <p className={selectLabelClasses}>Field:</p>
          {fieldOptions?.length > 0 && (
            <AwaitSelect
              options={fieldOptions}
              name={'field'}
              className="rounded-xl px-5 py-3 pl-[72px] text-xl-bold"
              isLoading={isLoading}
              onChange={(e) => setSelectedField(e.target.value)}
              defaultValue={fieldOptions[0].value}
            />
          )}
        </div>

        <div className="relative">
          <p className={selectLabelClasses}>Crop:</p>
          <Select
            options={SupportedCrops.map((crop) => ({
              value: crop,
              label: crop,
              icon: <CropIcon cropName={crop} />,
            }))}
            name={'crop'}
            defaultValue={SupportedCrops[0]}
            className="rounded-xl px-5 py-3 pl-[72px] text-xl-bold"
            onChange={(e) => setCrop(e.target.value)}
          />
        </div>

        <Button className="!w-fit shadow-lg bg-base-900" size="lg">
          Reset
        </Button>

        <Select
          options={exportItems}
          name={'crop'}
          className="rounded-xl px-5 py-3 text-xl-bold"
        />
      </div>

      {/* Map */}
      <PrescriptionMapContainer
        prescriptions={filteredPrescriptions}
        crop={crop}
        field={selectedField}
      />

      {/* Disclosures */}
      <Disclosure title="Set Yield Goal">
        <SetYieldGoal
          prescriptions={filteredPrescriptions}
          loading={isFetching}
          crop={crop}
          field={selectedField}
        />
      </Disclosure>
      <Disclosure title="Set Seeding Rate and Varieties">
        <SetSeedingRateAndVarieties
          presctiptions={filteredPrescriptions}
          loading={isFetching}
          crop={crop}
          field={selectedField}
        />
      </Disclosure>
      <Disclosure title="Add Fertilizer">
        <AddFertilizer
          crop={crop}
          field={selectedField}
          presctiptions={filteredPrescriptions}
          loading={isFetching}
        />
      </Disclosure>
      <Disclosure title="Add Chemicals">
        <AddChemicals />
      </Disclosure>
      <Disclosure title="Add Micronutrients">
        <AddMicronutrient />
      </Disclosure>
      <Disclosure title="Prescription Subtotals">
        <Subtotal />
      </Disclosure>
    </div>
  );
}
