import * as Auth from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetIsAuthenticated } from '../../contexts/auth-context';
import { getNextLoginFlowRoute } from '../../utils';

export const useVerifySMS = () => {
  const navigate = useNavigate();
  const setIsAuthenticated = useSetIsAuthenticated();
  const [isLoading, setLoading] = useState(false);

  const verify = async (code: string) => {
    setLoading(true);
    try {
      const { isSignedIn, nextStep } = await Auth.confirmSignIn({
        challengeResponse: code,
      });

      if (isSignedIn) {
        setIsAuthenticated?.(true);
        enqueueSnackbar('Verify SMS successfully!', {
          variant: 'success',
        });
      }

      const nextRoute = getNextLoginFlowRoute(nextStep.signInStep);
      navigate(nextRoute, { replace: true });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to verify SMS', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  return { verify, isLoading };
};
