import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import { parseDate } from '@internationalized/date';
import clsx from 'clsx';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DatePicker,
  DateSegment,
  DateValue,
  Dialog,
  Group,
  Heading,
  Popover,
} from 'react-aria-components';
import { useFormContext } from 'react-hook-form';
import TriangleDownIcon from '../../../assets/icons/TriangleDownIcon';
import { get } from 'lodash';

interface ComboDatePickerProps {
  options?: {
    label: string;
    value: string;
  }[];
  name: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (value: any) => void;
  value?: string;
  required?: boolean;
}

function convertToISODate(dateString: string): string {
  const [month, day, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export default function ComboDatePicker({
  options,
  name,
  className,
  disabled,
  label,
  onChange: onChangeProp,
  required = false,
  ...props
}: ComboDatePickerProps) {
  const selectable = options;
  const formContext = useFormContext();
  const error = get(formContext?.formState.errors, name);

  if (!formContext) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }
  const selected = formContext?.watch(name);
  const { onChange } =
    formContext?.register(name, {
      required: required ? `${label} is required` : false,
    }) || {};

  const handleOnChange = (value: DateValue) => {
    onChangeProp?.(value?.toString());
    onChange?.({ target: { value: value?.toString(), name } });
  };

  const selectedObj = options?.find((op) => op.value === selected);

  const formattedDate =
    selected && selected.includes('/') ? convertToISODate(selected) : selected;

  return (
    <Field className="space-y-1 w-full">
      {label && (
        <Label>
          {label} {required && <span className="text-secondary">*</span>}
        </Label>
      )}
      <div className="relative mt-1">
        {selectable && (
          <Listbox
            disabled={disabled}
            onChange={(value: { value: string; label: string }) =>
              onChange?.({ target: { value: value.value?.toString(), name } })
            }
            value={selectedObj}
          >
            <ListboxButton
              className={clsx(
                'w-full h-[41px] flex justify-end items-center pr-4 bg-base-1000 rounded',
                {
                  'border border-red': error,
                }
              )}
              style={{
                boxShadow:
                  '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
              }}
            >
              <span aria-hidden="true">
                <TriangleDownIcon className="size-6" />
              </span>
            </ListboxButton>
            <ListboxOptions
              anchor={{ to: 'bottom end', gap: 4 }}
              className={clsx(
                'max-h-60 w-[var(--button-width)]',
                'overflow-auto bg-block-fill border border-base-1000 shadow-lg z-10',
                'custom-scrollbar rounded p-2 shadow-lg empty:invisible'
              )}
            >
              {options?.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ focus, selected }) => {
                    return clsx(
                      focus ? 'bg-base-900' : '',
                      selected && 'bg-base-1000',
                      'cursor-default select-none relative py-2 pl-3 pr-4 flex items-center border-b border-base-1000 text-base-000',
                      'last:border-0'
                    );
                  }}
                >
                  {option.label}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        )}
        <DatePicker
          isDisabled={disabled}
          aria-label={name}
          onChange={handleOnChange}
          className={clsx({
            'absolute inset-y-0 z-10 left-4 flex items-center w-[80%]':
              selectable,
            'w-full px-4 py-2 bg-base-1000 rounded border border-base-1000':
              !selectable,
          })}
          style={{
            ...(!selectable
              ? {
                  boxShadow:
                    '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
                }
              : {}),
          }}
          value={selected ? parseDate(formattedDate) : null}
        >
          <Group className="flex justify-between w-full">
            <DateInput
              className={clsx('flex w-full', { 'text-base-700': disabled })}
            >
              {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            <Button>
              <CalendarDaysIcon className="size-5" />
            </Button>
          </Group>
          <Popover>
            <Dialog className="bg-block-fill p-4 rounded border border-base-1000 shadow-lg">
              <Calendar>
                <header className="flex justify-between px-3 mb-4">
                  <Button slot="previous">
                    <ChevronLeftIcon className="size-6" />
                  </Button>
                  <Heading className="text-secondary text-lg-bold" />
                  <Button slot="next">
                    <ChevronRightIcon className="size-6" />
                  </Button>
                </header>
                <CalendarGrid>
                  <CalendarGridHeader>
                    {(day) => (
                      <CalendarHeaderCell className="size-10">
                        {day}
                      </CalendarHeaderCell>
                    )}
                  </CalendarGridHeader>
                  <CalendarGridBody>
                    {(date) => (
                      <CalendarCell
                        date={date}
                        className={clsx(
                          'size-10 rounded-full flex items-center justify-center',
                          'hover:bg-base-900',
                          'data-[selected]:bg-primary',
                          'data-[outside-month]:text-base-800 data-[outside-month]:hover:bg-transparent'
                        )}
                      />
                    )}
                  </CalendarGridBody>
                </CalendarGrid>
              </Calendar>
            </Dialog>
          </Popover>
        </DatePicker>
      </div>
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}
