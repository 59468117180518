import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import {
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { convertToFloat, customRoundUp } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import KnobContainer from '../knob';

export default function SeedingRateInfo({
  onFlip,
  selectedZone,
  onChange,
  onUpdate,
  crop,
  loading,
  selectedProduct,
}: {
  onFlip: () => void;
  selectedZone: FieldPrescriptionWithProducts;
  onChange?: (value: number) => void;
  onUpdate: (value: number) => void;
  crop: string;
  loading: boolean;
  selectedProduct: PrescriptionProductRes;
}) {
  console.log(selectedProduct?.seeding_rate);
  const seedingRate = convertToFloat(selectedProduct?.seeding_rate ?? 0);
  const historicalAvg = convertToFloat(selectedZone?.average_seeding_rate);
  const agriVarRec = convertToFloat(selectedZone?.recommended_seeding_rate);

  const marks = useMemo(
    () => [
      {
        name: 'Average Seeding Rate',
        value: historicalAvg,
        color: 'bg-blue',
      },
      {
        name: 'AgriVaR Seeding Rate',
        value: agriVarRec,
        color: 'bg-red',
      },
    ],
    [historicalAvg, agriVarRec]
  );

  const max = useMemo(
    () => customRoundUp(Number(selectedZone.recommended_seeding_rate)),
    [selectedZone.agrivar_zone]
  );

  return (
    <div className="space-y-6">
      <CardHeader title="Seeding Rate And Varieties" tooltip="seeding rate" />
      <KnobContainer
        name="seedingRate"
        id={`${selectedZone.agrivar_zone1}-${crop}`}
        value={seedingRate}
        marks={marks}
        min={0}
        max={max}
        onChange={onChange}
        onUpdate={onUpdate}
        loading={loading}
      />

      {/* Chart */}
      {/* <SeedingRateChart selectedZone={selectedZone} crop={crop} /> */}

      <Button
        size="lg"
        color="primary"
        startIcon={<PlusCircleIcon className="size-6" />}
        shadow
        onClick={onFlip}
      >
        Add Additional Seeding
      </Button>
    </div>
  );
}
