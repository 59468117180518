import { IconProps } from '../../types';
const HistoricalInsightsIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 1.55774V3.80774H15V1.55774C15 1.18274 15.3281 0.807739 15.75 0.807739H18.75C19.125 0.807739 19.5 1.18274 19.5 1.55774ZM2.95312 6.85461C3 6.01086 3.65625 5.30774 4.45312 5.30774H9V20.3077C9 21.1515 8.29688 21.8077 7.5 21.8077H1.5C0.65625 21.8077 0 21.1515 0 20.3077V18.2452C0.140625 12.1984 2.85938 11.214 2.95312 6.85461ZM21 6.85461C21.0938 11.214 23.8125 12.1984 24 18.2452V20.3077C24 21.1515 23.2969 21.8077 22.5 21.8077H16.5C15.6562 21.8077 15 21.1515 15 20.3077V5.30774H19.5C20.2969 5.30774 20.9531 6.01086 21 6.85461ZM10.5 12.8077V5.30774H13.5V12.8077H10.5ZM8.25 0.807739C8.625 0.807739 9 1.18274 9 1.55774V3.80774H4.45312L4.5 1.55774C4.5 1.18274 4.82812 0.807739 5.25 0.807739H8.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default HistoricalInsightsIcon;
