import { IconProps } from '../../types';

const FlipIcon = ({ fill = '#F9FAFB' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1417_5485)">
        <path
          d="M11.2589 5.97975C7.94895 6.05975 5.32895 8.82975 5.40895 12.1397C5.47895 14.8897 6.92895 15.4597 6.95895 16.4297C6.97895 17.1197 6.42895 17.6497 5.78895 17.6597C4.46895 17.6897 3.06895 14.6597 2.99895 12.1997C2.87895 7.55975 6.55895 3.68975 11.1989 3.56975L11.1589 1.02975C11.1589 0.739748 11.2689 0.489747 11.4389 0.309747C11.7989 -0.0602525 12.4389 -0.140253 12.8789 0.279747L16.8189 4.08975C17.1089 4.36975 17.1089 4.81975 16.8389 5.10975L13.0289 9.03975C12.5989 9.47975 11.9589 9.41975 11.5889 9.05975C11.4089 8.88975 11.2889 8.63975 11.2889 8.34975L11.2489 5.94975H11.2689L11.2589 5.97975Z"
          fill={fill}
        />
        <path
          d="M13.0496 18.0201C16.3596 17.9401 18.9796 15.1701 18.8996 11.8601C18.8296 9.11006 17.3796 8.54006 17.3496 7.57006C17.3296 6.88006 17.8796 6.35006 18.5196 6.34006C19.8396 6.31006 21.2396 9.34007 21.3096 11.8001C21.4296 16.4401 17.7496 20.3101 13.1096 20.4301L13.1496 22.9701C13.1496 23.2601 13.0396 23.5101 12.8696 23.6901C12.5096 24.0601 11.8696 24.1401 11.4296 23.7201L7.4896 19.9101C7.1996 19.6301 7.1996 19.1801 7.4696 18.8901L11.2796 14.9601C11.7096 14.5201 12.3496 14.5801 12.7196 14.9401C12.8996 15.1101 13.0196 15.3601 13.0196 15.6501L13.0596 18.0501H13.0396L13.0496 18.0201Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1417_5485">
          <rect
            width="18.31"
            height="24"
            fill="white"
            transform="translate(3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FlipIcon;
