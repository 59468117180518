import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import InsuranceOptions from './InsuranceOptions';
import InsuranceList from './InsuranceList';

export default function Insurance() {
  return (
    <DisclosureContentLayout
      left={<InsuranceOptions />}
      right={<InsuranceList />}
    />
  );
}
