import { useQuery } from '@tanstack/react-query';
import { getAllFuturesApi, getPricesApi } from '../../api/market-api';
import { AllFuturesRecord } from '../../types';

export const useAllFutures = (root: string) => {
  const res = useQuery({
    queryKey: ['market/futures', root],
    queryFn: () => getAllFuturesApi(root),
    enabled: !!root,
  });

  return { data: res!.data?.data.data as AllFuturesRecord[] };
};

export const usePrices = (symbol: string) => {
  const res = useQuery({
    queryKey: ['market/prices', symbol],
    queryFn: () => getPricesApi(symbol),
    enabled: !!symbol,
  });

  return { data: res.data };
};
