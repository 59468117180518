import * as turf from '@turf/turf';
import { Feature } from 'geojson';
import { Coordinate } from 'mapbox-gl';
import { DefaultGeoJSONValue } from '../../../constants';
import { EnhancedFieldPolygon, FieldPolygonRes } from '../../../types';
import { getArea, getCrop2, getGeoJSON, getPositionInfo } from '../../../utils';

export type FeatureData = {
  area: string;
  geojson: string;
  legals_json: string;
  section: any;
  townshipdir: any;
  township: any;
  rangedir: any;
  rangeno: any;
};

export const DeafaultGeoJSONValue = {
  type: 'FeatureCollection',
  features: [
    {
      id: '0',
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [] as Coordinate[],
      },
    },
  ],
};

export const enhanceFieldItem = (
  field: FieldPolygonRes
): EnhancedFieldPolygon => {
  // adding a few properties to field item for ease of interact with map
  const coordinates = JSON.parse(field?.geojson || '{}')?.features?.[0]
    ?.geometry?.coordinates;
  const geoJSON = JSON.parse(
    field?.geojson || JSON.stringify(DeafaultGeoJSONValue)
  );
  return {
    ...field,
    hasBound: coordinates?.length > 0,
    features: geoJSON?.features?.map((feat: any) => {
      return {
        ...feat,
        id: field.user_id_field_index,
        properties: {
          ...feat.properties,
          user_id_field_index: field.user_id_field_index,
        },
      };
    }),
  };
};

export const getNewFieldIndex = (fieldIndexList: number[]) => {
  return Math.max(0, ...fieldIndexList) + 1;
};

export const getFeatureData = async (feature: Feature) => {
  const geojson = JSON.stringify(getGeoJSON(feature) ?? DefaultGeoJSONValue);

  const area = feature ? getArea(feature) : '0';

  const center = turf.center(feature);
  const fieldInfo = await getPositionInfo({
    geometry: `${center?.geometry?.coordinates?.[0]}, ${center?.geometry?.coordinates?.[1]}`,
  });

  const section = fieldInfo?.features[0]?.attributes?.FRSTDIVNO;
  const townshipdir = fieldInfo?.features[0]?.attributes?.TWNSHPDIR;
  const township = fieldInfo?.features[0]?.attributes?.TWNSHPNO;
  const rangedir = fieldInfo?.features[0]?.attributes?.RANGEDIR;
  const rangeno = fieldInfo?.features[0]?.attributes?.RANGENO;
  const legals_json = `${section}-${township}${townshipdir}-${rangeno}${rangedir}`;

  return {
    area,
    geojson,
    legals_json,
    section,
    townshipdir,
    township,
    rangedir,
    rangeno,
  };
};

export const getFieldData = async ({
  feature,
  crop,
  year,
  field,
  userId,
  field_index,
}: {
  feature: Feature;
  userId: string;
  crop: string;
  year: string;
  field: string;
  field_index: string;
}) => {
  const crop2 = crop ? getCrop2(crop) : '';
  const user_id = `${userId}_${year}`;
  const user_id2 = `${userId}`;
  const user_id_crop = `${userId}_${year}-${crop}`;
  const user_id_field_index = `${userId}_${field_index}`;
  const crop_year = year;
  const plssid = null;
  const other = '';

  const featureData = await getFeatureData(feature);

  return {
    user_id,
    user_id2,
    user_id_crop,
    user_id_field_index,
    field_index,
    field,
    crop,
    crop2,
    crop_year,
    plssid,
    other,
    ...featureData,
  };
};
