import { IconProps } from '../../types';

const WeatherIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9854 7.81768C15.4642 7.81768 17.4286 9.87562 17.4286 12.3077C17.4286 14.7866 15.4642 16.7978 12.9854 16.7978C10.4597 16.7978 8.49529 14.6931 8.49529 12.261C8.49529 9.82885 10.4597 7.81768 12.9854 7.81768ZM24.8185 16.5639C25.0992 17.0317 24.8653 17.5929 24.3508 17.6865L19.2527 18.6219L18.3173 23.72C18.2238 24.2345 17.6625 24.4683 17.2416 24.1877L12.9854 21.2411L8.68238 24.1877C8.26144 24.4683 7.70018 24.2345 7.60663 23.72L6.67121 18.6219L1.57312 17.6865C1.05863 17.5929 0.824775 17.0317 1.1054 16.6107L4.052 12.3077L1.1054 8.05154C0.824775 7.63059 1.05863 7.06934 1.57312 6.97579L6.67121 6.04036L7.55986 0.942277C7.65341 0.427791 8.21466 0.193934 8.68238 0.474562L12.9854 3.42116L17.2416 0.427791C17.6625 0.147162 18.2238 0.38102 18.3173 0.895505L19.2527 5.99359L24.3508 6.92902C24.8653 7.02257 25.0992 7.58382 24.8185 8.05154L21.8719 12.3077L24.8185 16.5639ZM12.9854 18.2945C16.2594 18.2945 18.9253 15.6285 18.9253 12.3077C18.9253 9.08051 16.2126 6.36776 12.9854 6.36776C9.71135 6.36776 6.99861 9.03374 6.99861 12.3077C6.99861 15.5817 9.66458 18.2945 12.9854 18.2945Z"
        fill={fill}
      />
    </svg>
  );
};

export default WeatherIcon;
