import { useQueries, useQuery } from '@tanstack/react-query';
import { getMarketBidAskApi } from '../../api/market-api';
import { BidAskRes } from '../../types';

export const useMarketBidAsk = (symbol: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['market/bid-ask', symbol],
    queryFn: () => getMarketBidAskApi(symbol),
    enabled: !!symbol,
  });

  return { data: (data?.data?.items?.[0] as BidAskRes) || {}, ...rest };
};

export const useMultipleMarketBidAsk = (symbols: string[]) => {
  const res = useQueries({
    queries: symbols.map((symbol) => ({
      queryKey: ['market/bid-ask', symbol],
      queryFn: () => getMarketBidAskApi(symbol),
      enabled: !!symbol,
    })),
  });

  const data = res.map((item) => item.data?.data?.items?.[0]);

  return { data: data as BidAskRes[] };
};
