import { IconProps } from '../../types';

const DollarLockIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1001 9H17.3501V6.75C17.3501 3.02 14.3251 0 10.6001 0C6.8751 0 3.8501 3.02 3.8501 6.75V9H3.1001C1.4451 9 0.100098 10.345 0.100098 12V21C0.100098 22.655 1.4451 24 3.1001 24H18.1001C19.7551 24 21.1001 22.655 21.1001 21V12C21.1001 10.345 19.7551 9 18.1001 9ZM13.6201 18.41C13.4501 19.39 12.7051 20.035 11.6601 20.26V20.555C11.6601 21.07 11.2401 21.495 10.7201 21.495C10.2001 21.495 9.7801 21.075 9.7801 20.555V20.24C9.3751 20.15 8.9701 20.02 8.6051 19.89L8.4051 19.82C7.9151 19.645 7.6601 19.11 7.8351 18.625C8.0101 18.135 8.5451 17.885 9.0301 18.05L9.2401 18.125C9.6101 18.255 10.0251 18.405 10.3501 18.455C10.9951 18.555 11.7051 18.465 11.7701 18.1C11.8101 17.86 11.8351 17.73 10.4601 17.335L10.1901 17.255C9.3801 17.015 7.4751 16.46 7.8201 14.45C7.9901 13.47 8.7301 12.815 9.7801 12.59V12.31C9.7801 11.795 10.2001 11.37 10.7201 11.37C11.2401 11.37 11.6601 11.79 11.6601 12.31V12.62C11.9151 12.675 12.1901 12.75 12.5301 12.865C13.0201 13.035 13.2801 13.57 13.1101 14.06C12.9401 14.55 12.4051 14.805 11.9151 14.64C11.6401 14.545 11.3551 14.45 11.0901 14.415C10.4501 14.315 9.7351 14.405 9.6701 14.77C9.6351 14.94 9.6101 15.13 10.6801 15.46L10.9801 15.535C12.0251 15.83 13.9701 16.39 13.6201 18.42V18.41ZM14.3501 9H6.8501V6.75C6.8501 4.68 8.5301 3 10.6001 3C12.6701 3 14.3501 4.68 14.3501 6.75V9Z"
        fill={fill}
      />
    </svg>
  );
};

export default DollarLockIcon;
