import { useMemo } from 'react';
import { FieldPrescriptionRes } from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import NumberDisplay from '../../../commons/NumberDisplay';
import Table, { TableConfigs } from '../../../commons/Table';

import TableLayout from '../../commons/TableLayout';

interface YieldGoalListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionRes[];
  onSelect: (zone: string) => void;
  loading?: boolean;
  setIsEditing: (value: boolean) => void;
}

export default function YieldGoalList({
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
}: YieldGoalListProps) {
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Select Zone',
          key: 'zone',
          type: 'element',
          element: (item: FieldPrescriptionRes) => (
            <Checkbox
              name="zone"
              label={`Zone ${item.agrivar_zone1}`}
              checked={item.agrivar_zone === selectedZone.agrivar_zone}
              onChange={(checked: boolean) => {
                checked && onSelect(item.agrivar_zone);
              }}
              rounded
            />
          ),
        },
        { name: 'Acres', key: 'area', unit: 'acres' },
        {
          name: 'Yield Goal',
          key: 'user_adjusted_yield || zone_yield',
          type: 'element',
          element: (item) => {
            return (
              <NumberDisplay
                value={convertToFloat(
                  item?.user_adjusted_yield || item.zone_yield
                )}
              />
            );
          },
        },
        {
          name: 'Yield Goal Probability',
          key: 'var',
          type: 'element',
          element: (item) => {
            return (
              <NumberDisplay value={convertToFloat(item.yield_probability)} />
            );
          },
        },
        {
          name: 'Average Yield',
          key: 'var',
          type: 'element',

          element: (item) => {
            return (
              <span className="flex justify-center space-x-1">
                <NumberDisplay value={convertToFloat(item.average_yield)} />
              </span>
            );
          },
        },
        {
          name: 'Yield Risk',
          key: 'var',
          type: 'element',
          element: (item) => {
            return (
              <span className="flex justify-center space-x-1">
                <NumberDisplay value={convertToFloat(item.sigma)} />
                {/* <Fluctuation value={0} currency="USD" context="1 day" /> */}
              </span>
            );
          },
        },
        {
          name: 'Current Yield Estimate',
          key: 'var',
          type: 'element',
          element: (item) => {
            return (
              <span className="flex justify-center space-x-1">
                <NumberDisplay value={convertToFloat(item.predicted_yield)} />
                {/* <Fluctuation value={0} currency="USD" context="1 day" /> */}
              </span>
            );
          },
        },
        {
          name: 'Revenue',
          key: 'var',
          type: 'element',
          element: (item) => {
            return (
              <span className="flex justify-center space-x-1">
                <NumberDisplay value={convertToFloat(item.revenue_per_acre)} />
                {/* <Fluctuation value={0} currency="USD" context="1 day" /> */}
              </span>
            );
          },
        },
      ],
    };
  }, [onSelect, selectedZone.agrivar_zone]);

  const rowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionRes) => {},
    note: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string) => {},
      onSaveAll: (item: FieldPrescriptionRes, value: string) => {},
      title: 'Add Zone Note',
    },
  };

  return (
    <TableLayout title={'Zone Yield Goals'}>
      <Table
        configs={tableConfigs}
        data={data}
        loading={loading}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
