import { nanoid } from 'nanoid';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ContractOptions,
  MonthOptions,
  StorageTypes,
} from '../../../../constants/risk-management';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../../hooks/field/use-field-polygons';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import TextInput from '../../../commons/inputs/TextInput';
import Select from '../../../commons/Select';
import { useCreateStorage } from '../../../../hooks/storage/use-storage';
import { enqueueSnackbar } from 'notistack';

export default function AddStorageForm({ onCancel }: { onCancel: () => void }) {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({
    defaultValues: {
      user_id: user?.id && year ? `${user.id}_${year}` : null,
      uniqueKey: nanoid(),
      contract_other: '',
    } as any,
  });

  const { mutateAsync: create, isPending } = useCreateStorage();

  // set unique_storage_name
  const storageName = form.watch('storageName');
  useEffect(() => {
    if (storageName && user?.id) {
      form.setValue(
        'unique_storage_name',
        `${storageName}-${user?.id}-${form.watch('uniqueKey')}`
      );
    }
  }, [storageName, user?.id]);

  // set contract_date
  const contractMonth = form.watch('contractMonth');
  const contractYear = form.watch('contractYear');
  useEffect(() => {
    if (contractMonth && contractYear) {
      form.setValue('contract_date', `${contractMonth} '${contractYear}`);
    }
  }, [contractMonth, contractYear]);

  // Field options
  const { data: allFields } = useFieldPolygonsByYear(
    user?.id ?? '',
    year ?? ''
  );
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}`,
    }));
  }, [allFields]);

  const handleSubmit = async ({
    contractMonth,
    contractYear,
    ...values
  }: any) => {
    try {
      await create(values);
      form.reset();
      onCancel();
      enqueueSnackbar(`Storage added successfully`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to add storage`, { variant: 'error' });
    }
  };
  return (
    <div>
      <CardHeader title={`Add Storage`} className="mb-8" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <TextInput name="storageName" label="Storage Name" required />
          <Select
            name="storageType"
            label="Type"
            options={StorageTypes}
            required
          />
          <Select
            label="Exchange and Futures Contract"
            name="contract"
            options={ContractOptions}
            required
          />
          {form.watch('contract') === 'Other' && (
            <TextInput name="contract_other" label="Other" required />
          )}
          <div className="flex items-end gap-2">
            <Select
              name="contractMonth"
              options={MonthOptions}
              label="Contract Month/Year"
              required
            />
            <Select
              name="contractYear"
              label="Year"
              hiddenLabel
              options={getYearOptions(10, 5, false)}
              required
            />
          </div>
          <TextInput
            name="bushels"
            label="Number of Bushels"
            type="number"
            required
          />
          <TextInput
            name="cost"
            label="Storage Cost (weekly per bushel)"
            type="number"
            required
          />
          <Select
            label="Field Applied"
            name="fieldApplied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
            required
          />
          <div className="flex justify-between space-x-3">
            <Button className="w-full" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="w-full"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Apply
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
