import { CreateLeasePayload, UpdateLeasePayload } from '../types/lease';
import apiClient from './api-client';

export const getLeasesApi = (userId: string, year: string) => {
  return apiClient.get(`/risk/leases?user_id=${userId}&year=${year}`);
};

export const createLeaseApi = (payload: CreateLeasePayload) => {
  return apiClient.post('risk/leases', payload);
};

export const updateLeaseApi = (payload: UpdateLeasePayload) => {
  return apiClient.put('risk/leases', payload);
};

export const deleteLeaseApi = ({
  userId,
  year,
  leaseId,
}: {
  userId: string;
  year: string;
  leaseId: string;
}) => {
  return apiClient.delete(
    `risk/leases?user_id=${userId}&year=${year}&unique_lease_id=${leaseId}`
  );
};
