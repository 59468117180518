import {
  ArrowsPointingOutIcon,
  BookmarkIcon,
  CubeTransparentIcon,
  EyeIcon,
  EyeSlashIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useEffect, useMemo } from 'react';
import CircleIcon from '../../../assets/icons/CircleIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import Select from '../../../components/commons/Select';

import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import MapZoomInIcon from '../../../assets/icons/MapZoomInIcon';
import MapZoomOutIcon from '../../../assets/icons/MapZoomOutIcon';
import RectangleIcon from '../../../assets/icons/RectangleIcon';
import SquareIcon from '../../../assets/icons/SquareIcon';
import Disclosure from '../../../components/commons/Disclosure';
import TextInput from '../../../components/commons/inputs/TextInput';
import Table, { TableConfigs } from '../../../components/commons/Table';
import TableLayout from '../../../components/dashboard/commons/TableLayout';
import { mapTabOptions } from '../../../constants/selectCommonConfigs';
import { createMapStudyTableConfig } from '../../../constants/tableConfigs';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import {
  useCreateNote,
  useDeleteStudy,
  useStudyData,
  useStudyNote,
} from '../../../hooks/map/use-map';
import { capitalizeFirst } from '../../../utils';

function MapToolbar() {
  const navigate = useNavigate();

  const {
    setStudies,
    selectedStudyIndex,
    studyDetails,
    setStudyDetails,
    setSelectedStudyIndex,
    setSelectedDrawMode,
    selectedDrawMode,
    creatingStudy,
    setCreatingStudy,
    viewState,
    setViewState,
    createStudy,
    setCreateStudy,
    myFeatureCollection,
    setMyFeatureCollection,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setEditMode,
    setSaveEditingStudy,
    editMode,
    setModalMode,
  } = useMapContext();

  const { type: typeParam = '' } = useParams();
  const { mutate: deleteStudy } = useDeleteStudy();
  // temporary
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;

  const queryClient = useQueryClient();

  const { mutate: createNote } = useCreateNote();

  const studyTableConfig = (relationship: string): TableConfigs => {
    return {
      cols: [
        {
          key: 'name',
          name: 'Name',
        },
        {
          key: 'year',
          name: 'Year',
        },
        {
          key: 'crop',
          name: 'Crop',
        },
        {
          key: 'type',
          name: 'Type',
        },
        {
          key: 'area',
          name: 'Acres',
        },
        {
          key: 'lon',
          name: 'lon',
        },
        {
          key: 'lat',
          name: 'lat',
        },
        {
          key: 'x_value',
          name: 'Value',
        },
        {
          key: 'x_bin',
          name: `Value Range`,
        },
        {
          key: '',
          name: 'View Study',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                if (
                  selectedStudyIndex ===
                  item.user_id_type_year + item.x_index
                ) {
                  setSelectedStudyIndex(-1);
                  return;
                }
                setSelectedStudyIndex(item.user_id_type_year + item.x_index);
              }}
              style={{
                backgroundColor:
                  selectedStudyIndex === item.user_id_type_year + item.x_index
                    ? 'gray'
                    : '#80bc00',
              }}
              className={`w-full h-8 justify-center items-center flex rounded-md`}
            >
              {selectedStudyIndex === item.user_id_type_year + item.x_index ? (
                <p>Hide</p>
              ) : (
                <p>View</p>
              )}
            </div>
          ),
        },
      ],
    };
  };

  const [all, setAll] = React.useState(true); // show all study data
  const [studyNotePk, setStudyNotePk] = React.useState<string | null>(null);
  const [studyNodeXIndex, setStudyNodeXIndex] = React.useState<string | null>(
    null
  );

  const studyNoteData = useStudyNote(studyNotePk ?? '', studyNodeXIndex ?? '');

  const studyNote = useMemo(() => {
    return studyNoteData.data?.note ?? '';
  }, [studyNoteData]);

  const rowActions = {
    edit: {
      onSelect: (item: any) => {
        if (editMode == 'edit') {
          setEditMode('');
          setMyFeatureCollection({
            type: 'FeatureCollection',
            features: [],
          });
          return;
        }
        setEditMode('edit');

        // remove all polygons and redraw study polyogns on map
        const polygons = item.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: features,
        });
        setSaveEditingStudy(item);
      },
    },
    delete: (item: any) => {
      deleteStudy(item, {
        onSuccess: () => {
          alert('Study Deleted');
          // invalidate the query
          queryClient.invalidateQueries([
            'historical-insights/study-data',
          ] as InvalidateQueryFilters);
        },
        onError: (error: unknown, _variables: any, _context: unknown) => {
          console.log('Error deleting study:', error);
          alert(`Failed to delete study. Please try again. ${error}`);
        },
      });
    },
    note: {
      onSelect: (item: any) => {
        //
        setStudyNotePk(item.user_id_type_year);
        setStudyNodeXIndex(item.x_index);
      },
      onSave: (item: any, value: string) => {
        createNote(
          {
            user_id_type_year: item.user_id_type_year,
            xIndex: item.x_index,
            note: value,
          },
          {
            onSuccess: () => {
              alert('Note Saved');
              // invalidate the query
              queryClient.invalidateQueries([
                'historical-insights/createNode',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error saving note:', error);
              alert(`Failed to saven note. Please try again. ${error}`);
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  const user = useUser();

  const year = useYearFilter();
  const studies = useStudyData(user?.id ?? '', year!);

  useEffect(() => {
    if (!studies.data) {
      return;
    }
    setStudies(studies.data);
  }, [studies]);

  const formattedTableData = useMemo(() => {
    return studies.data
      ?.map((data: any) => {
        return {
          ...data,
          x_value: parseFloat(data.x_value).toFixed(2),
          area: parseFloat(data.area).toFixed(2),
          lon: parseFloat(data.lon).toFixed(2),
          lat: parseFloat(data.lat).toFixed(2),
        };
      })
      .filter((data: any) => {
        if (all) {
          return true;
        }
        return data.type === type;
      });
  }, [studies.data, type, all]);

  console.log('studies', studies);

  return (
    <div className="w-full flex flex-col gap-2 ">
      <div className="justify-center mt-4 w-full flex gap-4 mb-0">
        <div className="flex w-1/3 flex-col gap-4 items-start card px-6 py-2">
          <p className="text-xl w-full text-center border-b-2">View</p>
          <div
            onClick={() => {
              setModalMode('');
              setSelectedDrawMode(0);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <ArrowsPointingOutIcon
              fill={selectedDrawMode === 0 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 0 ? 'text-yellow' : 'white'}>
              Move
            </p>
          </div>
          <div
            onClick={() => {
              setViewState({ ...viewState, zoom: viewState.zoom + 1 });
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <MapZoomInIcon fill="white" />
            <p>Zoom In</p>
          </div>
          <div
            onClick={() =>
              setViewState({ ...viewState, zoom: viewState.zoom - 1 })
            }
            className="flex cursor-pointer gap-2 items-center"
          >
            <MapZoomOutIcon fill="white" />
            <p>Zoom Out</p>
          </div>
        </div>

        <div className="flex flex-col  gap-4 w-1/3 items-start  card px-6 py-2">
          <p className="text-xl w-full border-b-2 text-center">Draw</p>
          <div
            className="flex cursor-pointer gap-2 items-center"
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(1);
            }}
          >
            <PolygonIcon
              fill={selectedDrawMode === 1 ? '#eab208' : 'white'}
              className="w-2 h-2 text-black"
              fontSize={16}
            />
            <p
              className={`${selectedDrawMode === 1 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Polygon
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(3);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <RectangleIcon
              fill={selectedDrawMode === 3 ? '#eab208' : 'white'}
              className="text-white"
            />
            <p
              className={`${selectedDrawMode === 3 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Rectangle
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(2);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <SquareIcon
              fill={selectedDrawMode === 2 ? '#eab208' : 'white'}
              className={`h-4 w-4 ${selectedDrawMode === 2 ? 'text-yellow' : 'text-darkGray'} `}
            />
            <p
              className={`${selectedDrawMode === 2 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Square
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(4);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <CircleIcon
              fill={selectedDrawMode === 4 ? '#eab208' : 'white'}
              className="h-4 w-4 text-darkGray"
            />
            <p
              className={`${selectedDrawMode === 4 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Circle
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(5);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <PaintBrushIcon
              fill={selectedDrawMode === 5 ? '#eab208' : 'white'}
              className="h-6 w-6 text-white"
            />
            <p
              className={`${selectedDrawMode === 5 ? 'text-yellow' : 'text-white'}`}
            >
              Drag & Draw Polygon-String
            </p>
          </div>
        </div>
        <div className="flex w-1/3 flex-col gap-4 items-start card px-6 py-2">
          <p className="text-xl text-center w-full border-b-2">Edit</p>
          <div
            onClick={() => {
              setModalMode('editPolygons');
              setSelectedDrawMode(6);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <PencilSquareIcon
              fill={selectedDrawMode === 6 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 6 ? 'text-yellow' : 'white'}>
              Edit Polygons
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('transformPolygons');
              setSelectedDrawMode(7);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <CubeTransparentIcon
              fill={selectedDrawMode === 7 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 7 ? 'text-yellow' : 'white'}>
              Transform Polygon
            </p>
          </div>

          <div
            onClick={() => {
              let newFeatures = myFeatureCollection.features.filter(
                (feature, index) => {
                  return !selectedFeatureIndexes.includes(index);
                }
              );
              setMyFeatureCollection({
                type: 'FeatureCollection',
                features: newFeatures,
              });
              setSelectedFeatureIndexes([]);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <TrashIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Delete Polygons</p>
          </div>

          <div
            onClick={() => {
              setSelectedFeatureIndexes([]);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <EyeSlashIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Unselect All</p>
          </div>
          <div
            onClick={() => {
              // make an array from 0 - myFeatureCollection.features.length
              let indexes = Array.from(
                Array(myFeatureCollection.features.length).keys()
              );
              setSelectedFeatureIndexes(indexes);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <EyeIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Selected All</p>
          </div>
        </div>
      </div>
      <Disclosure className="mt-2" title="Custom Studies">
        {creatingStudy ? (
          <div className="card cursor-pointer justify-center items-center flex p-4 px-6 w-full">
            <p className="text-xl ">Save {capitalizeFirst(type)} Study</p>
            <TextInput
              name="studyName"
              placeholder="Study Name"
              className="mt-4"
              onChange={(e) =>
                setStudyDetails({ ...studyDetails, name: e.target.value })
              }
            />
            <div
              style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
              className="rounded-xl w-full mt-4 justify-center items-center flex"
            >
              <TableLayout title={'Custom Studies'}>
                <Table
                  configs={createMapStudyTableConfig(type)}
                  data={[studyDetails]}
                />
              </TableLayout>
            </div>

            <div className="flex gap-4 mt-4">
              <div
                onClick={() => setCreateStudy(true)}
                className="cursor-pointer bg-primary flex gap-2 rounded-xl px-4 py-2"
              >
                <BookmarkIcon color={'white'} className="w-6 h-6" />
                <p>Save</p>
              </div>
              <div
                onClick={() => setCreatingStudy(false)}
                className="flex gap-2 card px-4 py-2"
              >
                <XCircleIcon color={'darkGray'} className="w-6 h-6" />
                <p>Cancel</p>
              </div>
            </div>
          </div>
        ) : (
          studies &&
          studies.data && (
            <div className="space-y-0 w-full cursor-pointer p-0 mt-0">
              <div className="flex items-center px-2 gap-4 py-2 bg-darkerGray">
                <Select
                  options={mapTabOptions}
                  value={all ? 'all' : typeParam}
                  name="tab"
                  defaultValue="all"
                  className="max-w-[160px]"
                  onChange={(e) => {
                    if (e.target.value === 'all') {
                      setAll(true);
                      return;
                    }
                    setAll(false);
                    const url = window.location.pathname;
                    // set url to the selected tab
                    const newUrl = `${url
                      .split('/')
                      .slice(0, url.split('/').length - 1)
                      .join('/')}/${e.target.value}`;
                    // Update the URL without reloading the page
                    navigate(newUrl);
                  }}
                />

                {creatingStudy ? (
                  <div
                    onClick={() => {
                      setCreatingStudy(false);
                    }}
                    className="flex cursor-pointer gap-2 items-center"
                  >
                    <XCircleIcon
                      className="w-6 h-6 text-darkGray"
                      fontSize={16}
                    />
                    <p>Remove Study Filter</p>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      if (myFeatureCollection.features.length === 0) {
                        alert('Please draw a polygon to create a study');
                        return;
                      }
                      setCreatingStudy(true);
                    }}
                    className="flex p-2 min-w-[240px] rounded-md bg-primary cursor-pointer gap-2 items-center"
                  >
                    <PlusCircleIcon
                      className="w-7 h-7 text-white"
                      fontSize={16}
                    />
                    <p className="text-white">Create Study From Selection</p>
                  </div>
                )}
              </div>
              <Table
                configs={studyTableConfig(type)}
                data={formattedTableData ?? []}
                pagination={{ size: 5 }}
                rowActions={rowActions}
                initNote={studyNote}
              />
            </div>
          )
        )}
      </Disclosure>
    </div>
  );
}

export default MapToolbar;
