import clsx from 'clsx';
import { useMemo } from 'react';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import Currency from '../../../components/commons/Currency';
import Fluctuation from '../../../components/commons/Fluctuation';
import NumberDisplay from '../../../components/commons/NumberDisplay';
import PageHeader from '../../../components/commons/PageHeader';
import { TableConfigs } from '../../../components/commons/Table';
import {
  useIncomeViewFilter,
  useYearFilter,
} from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useFieldPerformance } from '../hooks/use-field-performance';

const netIncomeTableConfigs: TableConfigs = {
  cols: [
    {
      key: 'field',
      name: 'Field',
    },
    {
      key: 'netIncome',
      name: 'Expected Net Income',
      type: 'currency',
    },
    {
      key: 'netIncome1DayChange',
      name: 'Expected Net Income 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'netIncome14DayChange',
      name: 'Expected Net Income 14 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'netIncomePerAcre',
      name: 'Net Income Per Acre',
      type: 'currency',
    },
    {
      key: 'netIncomePerAcre1DayChange',
      name: 'Net Income Per Acre 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'var',
      name: 'VaR',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'var1DayChange',
      name: 'VaR 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'hedgedPrice',
      name: 'Hedged Price',
      type: 'number',
    },
    {
      key: 'percentHedged',
      name: 'Hedged Price for Expected Production %',
      type: 'fluctuation',
      unit: '%',
    },
    {
      key: 'yield',
      name: 'Expected Yield',
      type: 'number',
    },
    {
      key: 'yield14DayChange',
      name: 'Expected Yield14 Day Change',
      type: 'fluctuation',
    },
  ],
};

const revenueTableConfigs: TableConfigs = {
  cols: [
    {
      key: 'field',
      name: 'Field',
    },
    {
      key: 'revenue',
      name: 'Expected Revenue',
      type: 'currency',
    },
    {
      key: 'revenue1DayChange',
      name: 'Expected Revenue 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'revenue14DayChange',
      name: 'Expected Revenue 14 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'revenuePerAcre',
      name: 'Revenue Per Acre',
      type: 'currency',
    },
    {
      key: 'revenuePerAcre1DayChange',
      name: 'Revenue Per Acre 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'var',
      name: 'VaR',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'var1DayChange',
      name: 'VaR 1 Day Change',
      type: 'fluctuation',
      currency: 'USD',
    },
    {
      key: 'hedgedPrice',
      name: 'Hedged Price',
      type: 'number',
    },
    {
      key: 'percentHedged',
      name: 'Hedged Price for Expected Production %',
      type: 'fluctuation',
      unit: '%',
    },
    {
      key: 'yield',
      name: 'Expected Yield',
    },
    {
      key: 'yield14DayChange',
      name: 'Expected Yield14 Day Change',
      type: 'fluctuation',
    },
  ],
};

function FieldPerformancePage() {
  const year = useYearFilter();
  const incomeView = useIncomeViewFilter();
  const user = useUser();
  const { data } = useFieldPerformance(user?.id || '', year || '');

  const configsToUse = useMemo(() => {
    return incomeView === 'revenue'
      ? revenueTableConfigs
      : netIncomeTableConfigs;
  }, [incomeView]);

  return (
    <div className="space-y-8">
      <PageHeader title="Field Performance" />

      <div className="flex space-x-4 justify-center items-center">
        <h4 className="display-md-regular">Print</h4>
        <DownloadIcon />
      </div>

      {/* Table */}
      <div className="overflow-auto">
        <table className="border-separate border-spacing-0">
          <thead>
            <tr>
              {configsToUse.cols.map((col) => (
                <th
                  className={clsx(
                    'py-5 px-5',
                    'first:pl-6 first:rounded-tl-3xl',
                    'last:pr-6 last:rounded-tr-3xl',
                    'bg-base-1000 border border-base-1000',
                    'text-sm-regular'
                  )}
                  key={col.key}
                >
                  {col.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row: Record<string, any>, index) => (
              <tr
                key={index}
                className={clsx(
                  'group',
                  'odd:bg-block-fill',
                  'even:bg-base-1100'
                )}
              >
                {configsToUse.cols.map((col) => (
                  <td
                    key={col.key}
                    className={clsx(
                      'group-last:first:rounded-bl-3xl',
                      'group-last:last:rounded-br-3xl',
                      'group-last:border-b',
                      'py-2 px-5',
                      'first:border-l last:border-r border-base-1000',
                      'last:pr-6'
                    )}
                  >
                    {col.type === 'currency' && (
                      <Currency value={row[col.key] ?? 0} />
                    )}
                    {col.type === 'fluctuation' && (
                      <Fluctuation
                        value={row[col.key] ?? 0}
                        unit={col.unit}
                        currency={col.currency}
                      />
                    )}
                    {col.type === 'number' && (
                      <NumberDisplay value={row[col.key] ?? 0} />
                    )}
                    {!col.type && row[col.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FieldPerformancePage;
