import { InformationCircleIcon } from '@heroicons/react/24/solid';
import CropIcon from '../../../components/commons/CropIcon';
import Currency from '../../../components/commons/Currency';
import Fluctuation from '../../../components/commons/Fluctuation';
import NumberDisplay from '../../../components/commons/NumberDisplay';
import PageHeader from '../../../components/commons/PageHeader';
import { SupportedCrops } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getAreaAndYield,
  getAreaAndYieldByCrop,
  useFarmYield,
} from '../../../hooks/farm/use-farm-yield';

function ExpectedAreaAndYieldPage() {
  const year = useYearFilter();
  const user = useUser();
  const { data: res } = useFarmYield(user?.id || '', year || '');

  const totalAreaAndYield = getAreaAndYield(res);
  const byCrop = getAreaAndYieldByCrop(res);

  return (
    <div className="space-y-8">
      <PageHeader title="Expected Area & Yield" tooltip="demo" />

      {/* Totals */}
      <div className="flex flex-col items-center space-y-3">
        <Currency
          value={totalAreaAndYield.totalYield || 0}
          className="text-secondary display-xl-bold"
        />
        <Fluctuation
          value={totalAreaAndYield.yieldChange14 || 0}
          context="Change"
        />
      </div>

      {/* Blocks */}
      <div className="grid grid-cols-12 gap-8">
        {SupportedCrops.map((crop) => (
          <div className="card p-12 col-span-12 lg:col-span-4 flex flex-col items-center">
            <CropIcon cropName={crop} size="large" />
            <span className="display-md-bold flex space-x-2 mt-4">
              <p>Area:</p>
              <InformationCircleIcon className="size-4 fill-secondary" />
              <NumberDisplay
                value={byCrop[crop]?.totalArea || 0}
                unit="acre"
                numberStyle="unit"
                unitDisplay="long"
              />
            </span>

            <span className="flex flex-col items-center mt-8 space-y-2">
              <span className="display-md-bold flex space-x-2">
                <p>Expected Yield:</p>
                <InformationCircleIcon className="size-4 fill-secondary" />
                <NumberDisplay value={byCrop[crop]?.totalYield || 0} />
              </span>
              <Fluctuation
                value={byCrop[crop]?.yieldChange14 || 0}
                context="14 Day Change"
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExpectedAreaAndYieldPage;
