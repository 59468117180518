import clsx from 'clsx';
import { Feature, Geometry } from 'geojson';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { theme } from 'twin.macro';
import ArrowLeftRightIcon from '../../../assets/icons/ArrowLeftRightIcon';
import CircleIcon from '../../../assets/icons/CircleIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import SquareIcon from '../../../assets/icons/SquareIcon';
import { CropOptions } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useCheckConnectedUserClimate,
  usePullBoundariesFromClimate,
} from '../../../hooks/climate/use-climate';
import {
  useAllFieldPolygon,
  useCreateFieldPolygon,
  usePartnerFieldGeometry,
  usePartnerFields,
} from '../../../hooks/field-polygon/use-field-polygon';
import {
  useCheckConnectedUserJD,
  usePullBoundariesFromJD,
} from '../../../hooks/john-deere/use-john-deere';
import { getCrop2, getYearOptions } from '../../../utils';
import Button from '../../commons/Button';
import CardHeader from '../../commons/CardHeader';
import RadioInput from '../../commons/inputs/RadioInput';
import TextInput from '../../commons/inputs/TextInput';
import Select, { AwaitSelect } from '../../commons/Select';
import { getNewFieldIndex } from './helpers';

interface FIBFormProps {
  drawRef: React.MutableRefObject<MapboxDraw | null>;
  activeFeature: Feature;
  setPartnerFieldGeometry: (geometry: Geometry | undefined) => void;
  drawMode: string;
  setDrawMode: (mode: string) => void;
  onSubmitByDraw: (values: any) => void;
  form: any;
  isPendingSaveDraw: boolean;
  onCancel: () => void;
}

const GET_PARTNER_BOUNDARY = 'GET_PARTNER_BOUNDARY';
const DRAW = 'DRAW';

const DrawModes = [
  {
    label: 'Polygon',
    value: 'draw_polygon',
    icon: <PolygonIcon />,
  },
  {
    label: 'Circle',
    value: 'draw_circle',
    icon: <CircleIcon />,
  },
  {
    label: 'Square',
    value: 'draw_rectangle',
    icon: <SquareIcon />,
  },
];

export default function AddFIBForm({
  drawRef,
  activeFeature,
  setPartnerFieldGeometry,
  setDrawMode,
  onSubmitByDraw,
  form,
  isPendingSaveDraw,
  onCancel,
}: FIBFormProps) {
  const user = useUser();
  const year = useYearFilter();
  const { data: allFieldPolygons } = useAllFieldPolygon(
    user?.id ?? '',
    year ?? ''
  );
  const { mutate: create, isPending } = useCreateFieldPolygon();

  const field = form.watch('field');
  const crop = form.watch('crop');
  const yearValue = form.watch('year');
  const isValid = !!field && !!crop && !!yearValue;

  const addFieldOption = form.watch('addFieldOption');
  const partnerSingle = form.watch('partnerSingle');

  const isPullAll = form.watch('isPullAll');
  const partnerAll = form.watch('partnerAll');

  const newFieldIndex = getNewFieldIndex(
    allFieldPolygons?.map((field) => parseInt(field.field_index)) ?? []
  );
  const crop2 = getCrop2(crop);

  // Handle create field polygon by drawing
  const handleChangeDrawMode = (mode: string) => {
    if (drawRef.current) {
      setDrawMode(mode);
      drawRef.current.changeMode(mode);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  //
  // Handle create by field from partner
  const partnerType = partnerSingle === 'climate' ? 'climate' : '';

  const { data: partnerFields, isFetching: isLoadingPartnerFields } =
    usePartnerFields(user?.id ?? '', partnerType);

  const partnerFieldOptions = partnerFields?.map((field) => ({
    label: field.field,
    value: field.field_index,
  }));

  const partnerFieldIndex = form.watch('partnerFieldIndex');
  const selectedPartnerField = partnerFields?.find(
    (field) => field.field_index === partnerFieldIndex
  );

  useEffect(() => {
    form.setValue('field', selectedPartnerField?.field ?? '');
  }, [selectedPartnerField]);

  const { data: partnerFieldGeometry, isFetched } = usePartnerFieldGeometry(
    user?.id ?? '',
    partnerType,
    partnerFieldIndex
  );

  useEffect(() => {
    // set for preview map
    if (isFetched) {
      setPartnerFieldGeometry(partnerFieldGeometry);
    }
  }, [isFetched, JSON.stringify(partnerFieldGeometry)]);

  const handleSubmitByPartner = (values: any) => {
    if (!selectedPartnerField || !user || !year) return;

    const section = selectedPartnerField?.section || '';
    const townshipdir = selectedPartnerField?.townshipdir || '';
    const township = selectedPartnerField?.township || '';
    const rangedir = selectedPartnerField?.rangedir || '';
    const rangeno = selectedPartnerField?.rangeno || '';
    const other = selectedPartnerField?.other || '';
    const area = selectedPartnerField?.area || '0';
    const legals_json = `${section}-${township}${townshipdir}-${rangeno}${rangedir}`;
    const geoJSON = {
      id: '0',
      type: 'Feature',
      properties: {},
      geometry: partnerFieldGeometry,
    };
    const feature = {
      type: 'FeatureCollection',
      features: [{ ...geoJSON }],
    };

    const payload = {
      user_id: `${user.id}_${values.year}`,
      user_id2: `${user.id}`,
      user_id_crop: `${user.id}_${values.year}-${values.crop}`,
      user_id_field_index: `${user.id}-${newFieldIndex}`,
      field_index: newFieldIndex.toString(),
      field: values.field,
      crop: values.crop,
      crop2: crop2,
      crop_year: year,
      plssid: null,
      legals_json: legals_json,
      geojson: JSON.stringify(feature),
      area,
      rangedir,
      rangeno,
      section,
      townshipdir,
      township,
      other,
    };

    create(payload);
    setPartnerFieldGeometry(undefined);
  };

  // Pull all
  const [enablePullAllJD, setEnablePullAllJD] = useState(false);
  const [enablePullAllClimate, setEnablePullAllClimate] = useState(false);

  const { isConnected: isClimateConnected } = useCheckConnectedUserClimate(
    user?.id ?? ''
  );
  const { isConnected: isJDConnected } = useCheckConnectedUserJD(
    user?.id ?? ''
  );
  const disabledPullAll =
    partnerAll === 'johnDeere' ? !isJDConnected : !isClimateConnected;

  const { isSuccess: isSuccessPullAllFromJD, isError: isErrorPullAllFromJD } =
    usePullBoundariesFromJD(user?.id ?? '', enablePullAllJD);
  const {
    isSuccess: isSuccessPullAllFromClimate,
    isError: isErrorPullAllFromClimate,
  } = usePullBoundariesFromClimate(user?.id ?? '', enablePullAllClimate);

  const handlePullAllFromPartner = () => {
    if (partnerAll === 'johnDeere') {
      setEnablePullAllJD(true);
    } else {
      setEnablePullAllClimate(true);
    }
  };

  useEffect(() => {
    if (isSuccessPullAllFromJD) {
      enqueueSnackbar(
        'It will take a few minutes to pull the boundaries. Please refresh later.',
        { variant: 'info', autoHideDuration: 10000 }
      );
      setEnablePullAllJD(false);
    }
    if (isErrorPullAllFromJD) {
      enqueueSnackbar('Error pulling, please try again later.', {
        variant: 'error',
      });
      setEnablePullAllJD(false);
    }
  }, [isSuccessPullAllFromJD, isErrorPullAllFromJD]);

  useEffect(() => {
    if (isSuccessPullAllFromClimate) {
      enqueueSnackbar(
        'It will take a few minutes to pull the boundaries. Please refresh later.',
        { variant: 'info', autoHideDuration: 10000 }
      );
      setEnablePullAllJD(false);
    }
    if (isErrorPullAllFromClimate) {
      enqueueSnackbar('Error pulling, please try again later.', {
        variant: 'error',
      });
      setEnablePullAllJD(false);
    }
  }, [isSuccessPullAllFromClimate, isErrorPullAllFromClimate]);

  return (
    <FormProvider {...form}>
      <form className="space-y-8">
        <div className="card space-y-6 p-4">
          <CardHeader title="Add New Field Info & Boundary" />
          <TextInput name="field" label="Field Name" required />
          <Select
            required
            options={[
              {
                label: 'Please Select Intended Crop',
                value: '',
              },
              ...CropOptions,
            ]}
            name="crop"
            label="Field Crop"
          />
          <Select
            required
            options={[
              {
                label: 'Please Select the Crop Year',
                value: '',
              },
              ...getYearOptions(),
            ]}
            name="year"
            label="Year"
            defaultValue={'2024'}
          />
          <p>
            Please select how would you prefer to add a new field boundary to
            the map?
          </p>
          <div
            className={clsx(
              'flex items-center space-x-2',
              addFieldOption === DRAW ? 'text-base-000' : 'text-base-800'
            )}
          >
            <RadioInput id={DRAW} name="addFieldOption" value={DRAW} />
            <label htmlFor={DRAW} className="flex items-center space-x-2">
              <PolygonIcon
                fill={
                  addFieldOption !== DRAW
                    ? theme`colors.base.800`
                    : theme`colors.base.000`
                }
              />
              <p>Draw Field Boundary</p>
            </label>
          </div>
          <div
            className={clsx(
              'flex items-center space-x-2',
              addFieldOption === GET_PARTNER_BOUNDARY
                ? 'text-base-000'
                : 'text-base-800'
            )}
          >
            <RadioInput
              id={GET_PARTNER_BOUNDARY}
              name="addFieldOption"
              value={GET_PARTNER_BOUNDARY}
            />
            <label
              htmlFor={GET_PARTNER_BOUNDARY}
              className="flex items-center space-x-2"
            >
              <ArrowLeftRightIcon
                fill={
                  addFieldOption !== GET_PARTNER_BOUNDARY
                    ? theme`colors.base.800`
                    : theme`colors.base.000`
                }
              />
              <p>Get Partner Boundary</p>
            </label>
          </div>

          {/* Get options */}
          {addFieldOption === GET_PARTNER_BOUNDARY && (
            <div className="bg-base-1100 p-2 border border-base-1000 rounded-lg space-y-4">
              <div
                className={clsx(
                  'flex items-center space-x-2',
                  partnerSingle === 'johnDeere'
                    ? 'text-base-000'
                    : 'text-base-800'
                )}
              >
                <RadioInput
                  id="johnDeere"
                  name="partnerSingle"
                  value="johnDeere"
                />
                <label
                  htmlFor="johnDeere"
                  className="flex items-center space-x-2"
                >
                  <ArrowLeftRightIcon
                    fill={
                      partnerSingle !== 'johnDeere'
                        ? theme`colors.base.800`
                        : theme`colors.base.000`
                    }
                  />
                  <p>Pull a John Deere Boundary</p>
                </label>
              </div>

              <div
                className={clsx(
                  'flex items-center space-x-2',
                  partnerSingle === 'climate'
                    ? 'text-base-000'
                    : 'text-base-800'
                )}
              >
                <RadioInput id="climate" name="partnerSingle" value="climate" />
                <label
                  htmlFor="climate"
                  className="flex items-center space-x-2"
                >
                  <ArrowLeftRightIcon
                    fill={
                      partnerSingle !== 'climate'
                        ? theme`colors.base.800`
                        : theme`colors.base.000`
                    }
                  />
                  <p>Pull a Climate Boundary</p>
                </label>
              </div>
            </div>
          )}
          {/* Add by draw */}
          {addFieldOption === DRAW && (
            <div className="space-y-4">
              <p>Please Choose Your Boundary Drawing Shape Option</p>
              <div className="flex space-x-3">
                {DrawModes.map((mode) => (
                  <Button
                    className="!px-2"
                    color="primary"
                    startIcon={mode.icon}
                    name={mode.value}
                    onClick={() => handleChangeDrawMode(mode.value)}
                    disabled={!isValid}
                  >
                    {mode.label}
                  </Button>
                ))}
              </div>
              <div className="flex space-x-3">
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={!activeFeature || isPendingSaveDraw}
                  loading={isPendingSaveDraw}
                  type="button"
                  onClick={form.handleSubmit(onSubmitByDraw)}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
          {addFieldOption === GET_PARTNER_BOUNDARY && (
            <>
              {/* <Button color="primary" disabled={!partnerSingle}>
                {partnerSingle === "johnDeere"
                  ? "Pull John Deere Boundary"
                  : "Pull A Climate Boundary"}
              </Button> */}
              {partnerSingle && (
                <AwaitSelect
                  name="partnerFieldIndex"
                  options={partnerFieldOptions}
                  isLoading={isLoadingPartnerFields}
                />
              )}
              <div className="flex space-x-3">
                <Button
                  onClick={() => {
                    form.reset();
                    setPartnerFieldGeometry(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={!partnerFieldIndex || isPending}
                  loading={isPending}
                  onClick={form.handleSubmit(handleSubmitByPartner)}
                >
                  Add
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Pull all */}
        <div className="card p-6 space-y-6">
          <CardHeader title="Pull All Partner Boundaries" />
          <p className="text-md-regular">
            You can add all of your previously established boundaries at once
            from a specific partner.
          </p>

          {!isPullAll && (
            <Button onClick={() => form.setValue('isPullAll', true)}>
              Choose a Partner
            </Button>
          )}

          {isPullAll && (
            <div className="bg-base-1100 p-2 border border-base-1000 rounded-lg space-y-4">
              <div
                className={clsx(
                  'flex items-center space-x-2',
                  partnerAll === 'johnDeere' ? 'text-base-000' : 'text-base-800'
                )}
              >
                <RadioInput
                  id="all-johnDeere"
                  name="partnerAll"
                  value="johnDeere"
                />
                <label
                  htmlFor="all-johnDeere"
                  className="flex items-center space-x-2"
                >
                  <ArrowLeftRightIcon
                    fill={
                      partnerAll !== 'johnDeere'
                        ? theme`colors.base.800`
                        : theme`colors.base.000`
                    }
                  />
                  <p>Pull a John Deere Boundary</p>
                </label>
              </div>

              <div
                className={clsx(
                  'flex items-center space-x-2',
                  partnerAll === 'climate' ? 'text-base-000' : 'text-base-800'
                )}
              >
                <RadioInput
                  id="all-climate"
                  name="partnerAll"
                  value="climate"
                />
                <label
                  htmlFor="all-climate"
                  className="flex items-center space-x-2"
                >
                  <ArrowLeftRightIcon
                    fill={
                      partnerAll !== 'climate'
                        ? theme`colors.base.800`
                        : theme`colors.base.000`
                    }
                  />
                  <p>Pull a Climate Boundary</p>
                </label>
              </div>
            </div>
          )}
          {isPullAll && (
            <Button
              color="primary"
              disabled={!partnerAll || disabledPullAll}
              onClick={handlePullAllFromPartner}
            >
              {!partnerAll
                ? 'Choose a Partner'
                : partnerAll && partnerAll === 'johnDeere'
                  ? 'Pull All John Deere Boundaries'
                  : 'Pull All Climate Boundaries'}
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
