import { IconProps } from '../../types';

const CornIcon = ({ fill = '#FFB71B' }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56099 17.994L2.37918 21.1758L4.50026 23.2973C6.25759 25.0547 9.10656 25.0547 10.8639 23.2973L15.1065 19.0547L19.6842 18.1392C19.9856 18.0788 20.0991 17.6897 19.8675 17.4881C15.7425 13.9013 9.48533 14.07 5.56099 17.994ZM9.42349 13.9679C10.1463 10.7916 9.38642 7.34626 7.12664 4.74704C6.92552 4.51548 6.53692 4.62941 6.4765 4.93031L5.56054 9.50906L1.31799 13.7517C-0.439332 15.5091 -0.439332 18.3586 1.31799 20.116L4.50026 16.9336C5.9243 15.5095 7.62159 14.5252 9.42349 13.9679ZM23.9966 2.73895V2.58888C24.0567 1.58394 23.3066 0.7139 22.2867 0.624357C21.5071 0.5489 20.8021 0.953892 20.4572 1.61389C19.9922 1.1644 19.3167 0.969372 18.6567 1.16394C18.0268 1.34434 17.5472 1.82394 17.3673 2.42388C16.8572 2.09434 16.1973 2.01889 15.5968 2.2739C14.9972 2.54438 14.6073 3.08388 14.5018 3.66936C13.9618 3.41435 13.3023 3.41435 12.7468 3.74388C12.1913 4.05895 11.8619 4.62941 11.8169 5.21387C11.2623 5.00437 10.6023 5.06435 10.0774 5.43939C9.92451 5.5472 9.79888 5.67985 9.68781 5.82233C11.0158 8.22373 11.4573 10.9815 11.0148 13.6242C13.6581 13.1855 16.4171 13.6069 18.8185 14.9429C18.8982 14.865 18.9792 14.7886 19.0468 14.694C19.4217 14.1689 19.4817 13.5235 19.2712 12.9689C19.8712 12.9235 20.4412 12.5939 20.7567 12.0385C21.0862 11.4834 21.1017 10.8234 20.8313 10.2835C21.4312 10.178 21.9707 9.803 22.2412 9.20298C22.5112 8.60296 22.4357 7.94342 22.1062 7.43248C22.6907 7.25301 23.1857 6.78795 23.3812 6.15798C23.5762 5.49798 23.3812 4.82296 22.9607 4.35753C23.5471 4.05895 23.9666 3.45893 23.9966 2.73895Z"
        fill={fill}
      />
    </svg>
  );
};

export default CornIcon;
