import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/auth-context';
import { useYearFilter } from '../../contexts/app-filter-context';

export const useWebSocket = () => {
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const year = useYearFilter();
  const userIdYear = `${user?.id}_${year}`;
  const marketRoot = undefined;

  useEffect(() => {
    if (!user?.username) return;
    if (!year) return;

    const ws = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}?userName=${user?.username}&userId=${userIdYear}&marketRoot=${marketRoot}`
    );

    ws.onmessage = (me: MessageEvent<any>) => {
      const data = JSON.parse(me.data);
      // console.log('web hook data:', data)
      const event = data?.event || false;
      if (!event) return;

      const records = data?.data?.Records || false;
      if (!records) return;

      queryClient.setQueryData(['websocket', event], records[0].dynamodb);
    };

    return () => {
      ws.close();
    };
  }, [marketRoot, queryClient, user?.username, userIdYear, year]);
};
