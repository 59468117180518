import { useQuery } from '@tanstack/react-query';
import { getFertilizerProductsApi } from '../../api/fertilizer-api';
import { FertilizerProductRes } from '../../types';

export const useFertilizerProducts = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['fertilizer-products'],
    queryFn: () => getFertilizerProductsApi(),
  });

  return { data: data?.data as FertilizerProductRes[], ...rest };
};
