export default function SoybeansIconColored() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_163_6892)">
        <path
          d="M4.40294 19.558H4.4116C4.23616 19.2114 4.13328 18.8183 4.13328 18.4035C4.13328 16.9924 5.28341 15.8422 6.69453 15.8422C6.7411 15.8422 6.78442 15.8466 6.83099 15.8509C6.78009 15.6451 6.74977 15.4361 6.74977 15.2141C6.74977 13.803 7.89556 12.6572 9.31102 12.6572C9.36192 12.6572 9.41824 12.6615 9.46914 12.6658C9.42257 12.4731 9.39658 12.2727 9.39658 12.067C9.39658 10.6558 10.5467 9.51003 11.9578 9.51003C12.116 9.51003 12.2697 9.52303 12.4192 9.55335C12.3845 9.38224 12.364 9.20246 12.364 9.02269C12.364 8.6079 12.4625 8.21911 12.6379 7.87256C10.8207 8.79201 8.25945 10.4165 5.90721 13.085C3.5907 15.709 3.10769 18.2064 3.14993 19.9381C3.50948 19.6987 3.94159 19.558 4.40294 19.558Z"
          fill="#4D8F00"
        />
        <path
          d="M15.3575 7.53369C14.7933 7.67448 14.2161 7.78169 13.6811 7.85859C13.3941 8.16182 13.2197 8.56794 13.2197 9.02171C13.2197 9.53071 13.4417 9.9834 13.797 10.2964C14.3655 9.33902 14.9005 8.38492 15.3575 7.53369Z"
          fill="#68C100"
        />
        <path
          d="M11.9586 10.3645C11.0175 10.3645 10.2529 11.1258 10.2529 12.067C10.2529 12.8922 10.8432 13.5853 11.6251 13.7391C12.1763 12.9474 12.7665 12.0247 13.3394 11.0749C13.0319 10.6428 12.5272 10.3645 11.9586 10.3645Z"
          fill="#68C100"
        />
        <path
          d="M8.80217 16.843C9.26352 16.3935 9.63606 16.06 9.8884 15.8466C10.183 15.5943 10.5382 15.1925 10.9281 14.6878C10.7104 14.0034 10.0682 13.5117 9.31117 13.5117C8.36572 13.5117 7.60547 14.2731 7.60547 15.2142C7.60547 15.9798 8.10581 16.6253 8.79892 16.843H8.80325H8.80217Z"
          fill="#68C100"
        />
        <path
          d="M6.69496 16.6968C5.74951 16.6968 4.98926 17.4624 4.98926 18.4025C4.98926 19.0956 5.39971 19.6901 5.99427 19.9544C6.73828 18.9927 7.48662 18.1621 8.13641 17.496C7.8375 17.0173 7.30251 16.6968 6.69496 16.6968Z"
          fill="#68C100"
        />
        <path
          d="M5.36415 20.8024C5.11615 20.563 4.77393 20.4136 4.40246 20.4136C3.62813 20.4136 3 21.0374 3 21.8117C3 22.4149 3.38013 22.9283 3.91512 23.12C4.36456 22.2947 4.85623 21.5161 5.36524 20.8024H5.36415Z"
          fill="#68C100"
        />
        <path
          d="M10.4444 16.4965C8.51348 18.1383 5.77028 21.0959 4.13389 24.5744C4.00394 24.8506 4.318 25.1235 4.56384 24.9405C7.12401 23.0377 12.5649 18.709 15.5117 15.238C16.7777 13.7457 17.4319 12.1548 17.7567 10.7523C17.1752 10.0343 16.4691 8.92641 16.2861 7.62683C14.6529 10.7361 11.9032 15.2554 10.4444 16.4954V16.4965Z"
          fill="#4D8F00"
        />
        <path
          d="M20.3453 1.28368L19.6154 1.03243C19.3121 0.928464 18.9818 1.08333 18.8703 1.38332C18.4674 2.4663 18.1219 3.64459 16.3058 4.18391C12.5825 5.29072 11.2363 7.22276 11.2363 7.22276C11.2363 7.22276 15.6592 7.08089 17.2989 5.79756C16.3393 8.52884 19.1367 10.9829 19.1367 10.9829C19.1367 10.9829 19.5536 7.38088 19.1789 5.28856C18.9158 3.82003 19.676 3.19731 20.5846 2.24646C20.8814 1.93564 20.7525 1.4223 20.3464 1.2826L20.3453 1.28368Z"
          fill="#4D8F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_163_6892">
          <rect
            width="17.7447"
            height="24"
            fill="white"
            transform="translate(3 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
