import { IconProps } from '../../types';

export default function LogoutIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5044 22.5205C1.97067 22.5205 0 20.5029 0 18.0161V6.0044C0 3.5176 1.97067 1.5 4.5044 1.5H7.50733C8.30499 1.5 9.0088 2.20381 9.0088 3.00147C9.0088 3.84604 8.30499 4.50293 7.50733 4.50293H4.5044C3.65982 4.50293 3.00293 5.20674 3.00293 6.0044V18.0161C3.00293 18.8607 3.65982 19.5176 4.5044 19.5176H7.50733C8.30499 19.5176 9.0088 20.2214 9.0088 21.0191C9.0088 21.8636 8.30499 22.5205 7.50733 22.5205H4.5044ZM23.6481 11.2126C24.1173 11.6349 24.1173 12.4326 23.6481 12.8079L16.8915 19.1891C16.563 19.5176 16.0938 19.5645 15.6716 19.4238C15.2493 19.2361 15.0147 18.8138 15.0147 18.3915V15.0132H9.0088C8.16422 15.0132 7.50733 14.3563 7.50733 13.5117V10.5088C7.50733 9.71114 8.16422 9.00733 9.0088 9.00733H15.0147V5.62903C15.0147 5.20674 15.2493 4.78446 15.6716 4.59677C16.0938 4.45601 16.563 4.50293 16.8915 4.83138L23.6481 11.2126Z"
        fill={fill}
      />
    </svg>
  );
}
