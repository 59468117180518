import { useQuery } from '@tanstack/react-query';
import { getBasisAPI } from '../../api/basis-api';
import { BasisRes } from '../../types';
import { useMemo } from 'react';

export const useBasis = (userId: string, year: string, symbols: string[]) => {
  const { data, ...rest } = useQuery({
    queryKey: ['basis', userId, year, symbols],
    queryFn: () => getBasisAPI(userId, year, symbols),
    enabled: !!userId && !!year && !!symbols[0],
  });

  return { data: data?.data as BasisRes[] | [], ...rest };
};

// helpers
export const getBasisOptions = (data: BasisRes[]) => {
  return (
    data
      ?.sort((a, b) => a.company.localeCompare(b.company))
      ?.map((item) => ({
        label: `${item.company} - ${item.location}`,
        value: item.basis_id,
      })) || []
  );
};
export const useCompanyLocationOptions = (
  userId: string,
  year: string,
  symbol: string
) => {
  const basis = useBasis(userId, year, [symbol]);

  const companyLocationOptions = useMemo(() => {
    const options: any = [];
    basis.data?.forEach((item: any) => {
      if (item.symbol === symbol) {
        // Filter by matching symbol
        const key = `${item.company}-${item.location}`;
        if (!options.some((option: any) => option.value === key)) {
          options.push({
            value: key,
            label: `${item.company}-${item.location}`,
          });
        }
      }
    });
    return options;
  }, [basis.data, symbol]);

  return companyLocationOptions;
};
