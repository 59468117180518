import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { ThemeColors, cropToMonthOptions } from '../../../constants';
import { monthMapToString } from '../../../constants/dateConfigs';
import { useUser } from '../../../contexts/auth-context';
import { useMarketContext } from '../../../contexts/market-context';
import { useCreateAlert } from '../../../hooks/market/use-alerts';
import Button from '../../commons/Button';
import TextInput from '../../commons/inputs/TextInput';
import Select from '../../commons/Select';

interface EditAlertProps {
  alert: AlertRecord;
  isUpdatingEditAlert: boolean;
  setIsUpdatingEditAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Alert {
  id: string;
  userId: string;
  crop: string;
  condition: string;
  price: string;
  change: string;
  status: string;
  type: string;
  date: string;
  dateRange: string;
  priceRange: string;
  alertVia: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
}

interface AlertRecord {
  userId: string;
  crop: string;
  price: string;
  status: string;
  dateRange: string;
  priceRange: string;
  alertVia?: string;
  date: string;
  type: string;
  strikePrice?: string;
  optionType?: string;
  basisLocation?: string;
  basisCompany?: string;
  basisValue?: string;
  month1?: string;
  month2?: string;
  year1?: string;
  year2?: string;
  commodity?: string;
}

const typeOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'futures', label: 'Futures' },
  { value: 'options', label: 'Options' },
  { value: 'basis', label: 'Basis' },
  { value: 'spread', label: 'Spread' },
];

const basisLocations = [
  { value: 'location1', label: 'Location 1' },
  { value: 'location2', label: 'Location 2' },
  { value: 'location3', label: 'Location 3' },
];

const basisCompanies = [
  { value: 'company1', label: 'Company 1' },
  { value: 'company2', label: 'Company 2' },
  { value: 'company3', label: 'Company 3' },
];

const commodityOptions = [
  { value: 'Corn', label: 'Corn' },
  { value: 'HRW Wheat', label: 'HRW Wheat' },
  { value: 'SRW Wheat', label: 'SRW Wheat' },
  { value: 'Soybeans', label: 'Soybeans' },
];

const yearOptions = [
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
];

const EditAlert: React.FC<EditAlertProps> = ({
  alert,
  isUpdatingEditAlert,
  setIsUpdatingEditAlert,
}) => {
  const methods = useForm();
  const { register, handleSubmit, control, watch } = methods;

  const [alerts, setAlerts] = useState<Alert[]>([]);
  const user = useUser();
  const queryClient = useQueryClient();
  const commodity = useRef<string>('Corn');

  const { currentTab, trigger, setTrigger } = useMarketContext();
  const contractType = useRef<string>('basis');
  const { mutate: createAlert } = useCreateAlert(user?.id || '');

  const contractTypeValue = watch('type', alert.type);

  const onSubmit = (data: any) => {
    if (data.price.includes('$') || data.price.length === 0) {
      window.alert(
        'Invalid price format. Please enter a valid price without the "$" symbol.'
      );
      return;
    }

    const date = new Date(Date.parse(data.month + ' 1, 2020')); // Using any non-leap year
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');

    const newAlert: AlertRecord = {
      userId: user?.id ?? '',
      crop: data.crop,
      price: data.price,
      status: 'active', // Default status
      type: contractType.current,
      date: `${data.year}-${monthNumber}`,
      dateRange: data.triggerPoint,
      priceRange: data.timing,
      alertVia: data.alertVia, // Get the selected notification method
    };
    if (data.strikePrice) {
      newAlert.strikePrice = data.strikePrice;
    }
    if (data.callPut) {
      newAlert.optionType = data.callPut;
    }

    if (data.basisLocation) {
      newAlert.basisLocation = data.basisLocation;
    }
    if (data.company) {
      newAlert.basisCompany = data.company;
    }
    if (data.basisValue) {
      newAlert.basisValue = data.basisValue;
    }
    if (data.month1) {
      newAlert.month1 = data.month1;
    }
    if (data.month2) {
      newAlert.month2 = data.month2;
    }
    if (data.year1) {
      newAlert.year1 = data.year1;
    }
    if (data.year2) {
      newAlert.year2 = data.year2;
    }

    // Call the createAlert mutation
    createAlert(newAlert, {
      onSuccess: () => {
        // Invalidate and refetch alerts
        queryClient.invalidateQueries([
          'market/alerts',
          user?.id ?? '',
        ] as InvalidateQueryFilters);
        setIsUpdatingEditAlert(false);
      },
      onError: (error: Error) => {
        console.error('Error creating alert:', error);
        window.alert('Failed to create alert. Please try again.');
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="card p-6 space-y-4"
      >
        <div className="flex items-center space-x-2">
          <CalendarDaysIcon className="h-6 w-6 mr-2" />
          <h2 className="text-xl text-base-bold">
            Edit Alert: {alert.commodity} {alert.date} {alert.type}
          </h2>
        </div>

        <div className="w-full">
          <label htmlFor="contractType" className="block mb-2">
            Contract Type
          </label>
          <Select
            options={typeOptions}
            defaultValue={alert.type}
            {...register('type', { required: true })}
            onChange={(e) => {
              contractType.current = e.target.value;
              setTrigger(trigger + 1);
            }}
          />
        </div>

        <div className="flex flex-col gap-4">
          {contractTypeValue === 'spread' ? (
            <>
              <div className="grid grid-cols-3 gap-x-2 w-full flex">
                <div className="w-full">
                  <label htmlFor="crop" className="block mb-2">
                    Commodity
                  </label>
                  <Select
                    name="crop"
                    defaultValue={alert.crop}
                    options={commodityOptions}
                    onChange={(e) => {
                      commodity.current = e.target.value;
                      setTrigger(trigger + 1);
                    }}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="month1" className="block mb-2">
                    Month 1
                  </label>
                  <Select
                    name="month1"
                    defaultValue={alert.month1}
                    options={
                      cropToMonthOptions[
                        commodity.current as keyof typeof cropToMonthOptions
                      ]
                    }
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="year1" className="block mb-2">
                    Year 1
                  </label>
                  <Select
                    name="year1"
                    defaultValue={alert.year1}
                    options={yearOptions}
                  />
                </div>
              </div>
              <div className="grid gap-x-2 grid-cols-3 w-full flex">
                <div className="w-full">
                  <label htmlFor="month2" className="block mb-2">
                    Month 2
                  </label>
                  <Select
                    name="month2"
                    defaultValue={alert.month2}
                    options={
                      cropToMonthOptions[
                        commodity.current as keyof typeof cropToMonthOptions
                      ]
                    }
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="year2" className="block mb-2">
                    Year 2
                  </label>
                  <Select
                    name="year2"
                    defaultValue={alert.year2}
                    options={yearOptions}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-3 gap-x-2 w-full flex">
              <div className="w-full">
                <label htmlFor="crop" className="block mb-2">
                  Commodity
                </label>
                <Select
                  name="crop"
                  defaultValue={alert.crop}
                  options={commodityOptions}
                  onChange={(e) => {
                    commodity.current = e.target.value;
                    setTrigger(trigger + 1);
                  }}
                />
              </div>
              <div className="w-full">
                <label htmlFor="month" className="block mb-2">
                  Month
                </label>
                <Select
                  name="month"
                  defaultValue={monthMapToString[alert.date.split('-')[1]]}
                  options={
                    cropToMonthOptions[
                      commodity.current as keyof typeof cropToMonthOptions
                    ]
                  }
                />
              </div>
              <div className="w-full">
                <label htmlFor="year" className="block mb-2">
                  Year
                </label>
                <Select
                  name="year"
                  defaultValue={alert.date.split('-')[0]}
                  options={yearOptions}
                />
              </div>
            </div>
          )}

          {contractTypeValue === 'options' && (
            <div>
              <div className="w-full mb-2">
                <label htmlFor="callPut" className="block mb-2">
                  Call/Put
                </label>
                <Select
                  name="callPut"
                  defaultValue={alert.optionType}
                  options={[
                    { value: 'call', label: 'Call' },
                    { value: 'put', label: 'Put' },
                  ]}
                />
              </div>
              <div className="w-full">
                <label htmlFor="strikePrice" className="block mb-2">
                  Strike Price
                </label>
                <TextInput
                  name="strikePrice"
                  defaultValue={alert.strikePrice}
                  placeholder="Enter strike price"
                />
              </div>
            </div>
          )}

          {contractTypeValue === 'basis' && (
            <div className="gap-x-2 flex">
              <div className="w-full">
                <label htmlFor="basisLocation" className="block mb-2">
                  Location
                </label>
                <Select
                  name="basisLocation"
                  defaultValue={alert.basisLocation}
                  options={basisLocations}
                />
              </div>
              <div className="w-full">
                <label htmlFor="basisCompany" className="block mb-2">
                  Company
                </label>
                <Select
                  name="basisCompany"
                  defaultValue={alert.basisCompany}
                  options={basisCompanies}
                />
              </div>
              <div className="w-full">
                <label htmlFor="basisValue" className="block mb-2">
                  Basis Value
                </label>
                <TextInput
                  name="basisValue"
                  defaultValue={alert.basisValue}
                  placeholder="Enter basis value"
                />
              </div>
            </div>
          )}

          <div className="grid grid-cols-3 w-full flex gap-x-2">
            <div>
              <label htmlFor="timing" className="block mb-2">
                Timing
              </label>
              <Select
                name="timing"
                defaultValue={alert.priceRange}
                options={[
                  { value: 'below', label: 'Below' },
                  { value: 'above', label: 'Above' },
                  { value: 'at', label: 'At' },
                ]}
              />
            </div>
            <div>
              <label htmlFor="triggerPoint" className="block mb-2">
                Trigger Point
              </label>
              <Select
                name="triggerPoint"
                defaultValue={alert.dateRange}
                options={[{ value: 'last', label: 'Last' }]}
              />
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block mb-2">
                Price
              </label>
              <TextInput
                name="price"
                defaultValue={alert.price}
                placeholder="Enter price"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <span>Notify via:</span>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="text"
              {...register('alertVia', { required: true })}
              defaultChecked={alert.alertVia === 'text'}
              className="custom-radio"
            />
            <span>Text</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="email"
              defaultChecked={alert.alertVia === 'email'}
              {...register('alertVia', { required: true })}
              className="custom-radio"
            />
            <span>Email</span>
          </label>
        </div>

        <div className="flex space-x-4">
          <Button
            onClick={() => setIsUpdatingEditAlert(false)}
            type="button"
            className={`bg-[${ThemeColors.lightGray}]`}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </div>
      </form>

      {alerts.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg text-base-semibold">Current Alerts</h3>
          <ul>
            {alerts.map((alert) => (
              <li key={alert.id} className="flex justify-between p-2 border-b">
                <span>
                  {alert.crop} - {alert.condition} - {alert.price}
                </span>
                <span>{alert.status}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FormProvider>
  );
};

export default EditAlert;
