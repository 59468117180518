import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createHedgeApi,
  deleteHedgeApi,
  getHedgesApi,
  strikeListApi,
  updateHedgeApi,
} from '../../api/hedge-api';
import { AddHedgePayload, HedgeItem, StrikeDetails } from '../../types/hedge';
import { enqueueSnackbar } from 'notistack';

export const useHedges = (
  userId: string,
  year: string,
  scenarioId?: number
) => {
  const userIdYear = `${userId}_${year}`;
  const { data, ...rest } = useQuery({
    queryKey: ['hedges', userIdYear, scenarioId],
    queryFn: () => getHedgesApi(userIdYear, scenarioId),
    enabled: !!userId && !!year,
  });
  return { data: data?.data as HedgeItem[], ...rest };
};

export const useGetStrikeList = (crop_month_trade_date: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['strikes', crop_month_trade_date],
    queryFn: () => strikeListApi(crop_month_trade_date),
    enabled: !!crop_month_trade_date,
  });

  return {
    data: data?.data as StrikeDetails[],
    ...rest,
  };
};

export const useUpdateHedge = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['hedge/update'],
    mutationFn: (payload: any) => updateHedgeApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['hedges'],
      });
    },
    onError: () => {},
  });
};

export const useDeleteHedge = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['hedge/delete'],
    mutationFn: (payload: any) => deleteHedgeApi(payload),
    onSuccess: () => {
      enqueueSnackbar('Hedge deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: ['hedges'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete hedge', { variant: 'error' });
    },
  });
};
export const useCreateHedge = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['hedge/create'],
    mutationFn: (payload: AddHedgePayload) => createHedgeApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['hedges'],
      });
    },
  });
};
