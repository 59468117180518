import { IconProps } from '../../types';

const FarmIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79167 1.75 0 4 0C6.20833 0 8 1.79167 8 4V4.625C7.79167 4.83333 7.66667 5.08333 7.54167 5.33333H0V4ZM5.5 10.25C5.375 10.5833 5.33333 10.9167 5.33333 11.2917V20C5.33333 20.5 5.45833 20.9583 5.66667 21.3333H2C0.875 21.3333 0 20.4583 0 19.3333V6.66667H7L5.5 10.25ZM6.66667 20V11.2917C6.66667 11.125 6.66667 10.9167 6.75 10.7917L8.79167 5.83333C8.91667 5.54167 9.125 5.29167 9.45833 5.125L14.75 2.625C15.0833 2.45833 15.5417 2.45833 15.875 2.625L21.1667 5.125C21.5 5.29167 21.7083 5.54167 21.8333 5.83333L23.875 10.7917C23.9583 10.9167 24 11.125 24 11.2917V20C24 20.75 23.375 21.3333 22.6667 21.3333H18V18.6667C18 17.5833 17.0833 16.6667 16 16.6667H14.6667C13.5417 16.6667 12.6667 17.5833 12.6667 18.6667V21.3333H7.95833C7.25 21.3333 6.625 20.75 6.625 20H6.66667ZM13.3333 12.3333C13.3333 12.9167 13.75 13.3333 14.3333 13.3333H16.3333C16.875 13.3333 17.3333 12.9167 17.3333 12.3333V10.3333C17.3333 9.79167 16.875 9.33333 16.3333 9.33333H14.3333C13.75 9.33333 13.3333 9.79167 13.3333 10.3333V12.3333Z"
        fill={fill}
      />
    </svg>
  );
};

export default FarmIcon;
