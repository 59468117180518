import clsx from 'clsx';
import { Link } from 'react-router-dom';
import APHHistoryIcon from '../../../../assets/icons/APHHistoryIcon';
import BillIcon from '../../../../assets/icons/BillIcon';
import CropInsuranceIcon from '../../../../assets/icons/CropInsuranceIcon';
import DashboardIcon from '../../../../assets/icons/DashboardIcon';
import DataInputIcon from '../../../../assets/icons/DataInputIcon';
import DollarIcon from '../../../../assets/icons/DollarIcon';
import FarmBudgetIcon from '../../../../assets/icons/FarmBudgetIcon';
import FarmIcon from '../../../../assets/icons/FarmIcon';
import FieldInfoAndBoundaryIcon from '../../../../assets/icons/FieldInfoAndBoundaryIcon';
import FieldPerformanceIcon from '../../../../assets/icons/FieldPerformanceIcon';
import ForecastsIcon from '../../../../assets/icons/ForecastsIcon';
import FormEntryIcon from '../../../../assets/icons/FormEntryIcon';
import HistoricalInsightsIcon from '../../../../assets/icons/HistoricalInsightsIcon';
import MapIcon from '../../../../assets/icons/MapIcon';
import MarketsIcon from '../../../../assets/icons/MarketsIcon';
import RiskManagementIcon from '../../../../assets/icons/RiskManagementIcon';
import ScenarioAnalysisIcon from '../../../../assets/icons/ScenarioAnalysisIcon';
import SoilSampleIcon from '../../../../assets/icons/SoilSampleIcon';
import SproutIcon from '../../../../assets/icons/SproutIcon';
import WeatherIcon from '../../../../assets/icons/WeatherIcon';
import { useControlledUser } from '../../../../contexts/auth-context';
import { NavItemType } from '../../../../types';
import NavItem, { getHref } from './NavItem';
import UserBar from './user-bar/UserBar';

const dashboardItems: NavItemType[] = [
  {
    title: 'Overview',
    href: '/app/dashboard',
    icon: DashboardIcon,
  },
  {
    title: 'Field Performance',
    href: '/app/dashboard/field-performance',
    icon: FieldPerformanceIcon,
  },
];

const riskManagementItems: NavItemType[] = [
  {
    title: 'Overview',
    href: '/app/risk-management',
    icon: RiskManagementIcon,
  },
  {
    title: 'Scenario Analysis',
    href: '/app/risk-management/scenario-analysis',
    icon: ScenarioAnalysisIcon,
  },
];

const dataInputItems: NavItemType[] = [
  {
    title: 'Overview',
    href: '/app/data-inputs',
    icon: DataInputIcon,
  },

  {
    title: 'Crop Insurance',
    href: '/app/data-inputs/crop-insurances',
    icon: CropInsuranceIcon,
  },

  {
    title: 'Soil Samples',
    href: '/app/data-inputs/soil-samples',
    icon: SoilSampleIcon,
  },
  {
    title: 'APH Histories',
    href: '/app/data-inputs/aph-histories',
    icon: APHHistoryIcon,
  },
  {
    title: 'Field Info & Boundaries',
    href: '/app/data-inputs/field-info-and-boundary',
    icon: FieldInfoAndBoundaryIcon,
  },
];

const financialsItems: NavItemType[] = [
  {
    title: 'Farm Expenses',
    href: '/app/financials/farm-expenses',
    icon: FarmIcon,
  },
  {
    title: 'Budgets',
    href: '/app/financials/budgets',
    icon: FarmBudgetIcon,
  },
  {
    title: 'Income Statements',
    href: '/app/financials/income-statements',
    icon: BillIcon,
  },
  {
    title: 'Balance Sheet',
    href: '/app/financials/balance-sheet',
    icon: FormEntryIcon,
  },
];

const navItems: NavItemType[] = [
  {
    href: '/app/dashboard',
    title: 'Dashboard',
    icon: DashboardIcon,
    children: dashboardItems,
  },
  {
    href: '/app/map',
    title: 'Map',
    icon: MapIcon,
  },
  {
    href: '/app/risk-management',
    title: 'Risk Management',
    icon: RiskManagementIcon,
    children: riskManagementItems,
  },
  {
    href: '/app/weather',
    title: 'Weather',
    icon: WeatherIcon,
  },
  {
    href: '/app/prescriptions',
    title: 'Prescriptions',
    icon: SproutIcon,
  },
  {
    href: '/app/historical-insights',
    title: 'Historical Insights',
    icon: HistoricalInsightsIcon,
  },
  {
    href: '/app/markets',
    title: 'Markets',
    icon: MarketsIcon,
  },
  {
    href: '/app/financials',
    title: 'Financials',
    icon: DollarIcon,
    children: financialsItems,
  },
  {
    href: '/app/data-inputs',
    title: 'Data Inputs',
    icon: DataInputIcon,
    children: dataInputItems,
  },
];

function DashboardHeader() {
  const controlledUser = useControlledUser();
  return (
    <header className={clsx('flex flex-col space-y-8', '')}>
      <div
        className={clsx(
          'flex flex-col items-center space-y-8',
          'xl:flex-row xl:justify-between xl:space-y-0'
        )}
      >
        <Link to={getHref('/app/dashboard', controlledUser)}>
          <div id="network-logo" />
        </Link>
        <nav className="flex space-x-2">
          {navItems.map((item) => (
            <NavItem key={item.href} data={item} />
          ))}
        </nav>
      </div>

      <UserBar />
    </header>
  );
}

export default DashboardHeader;
