import { useState } from 'react';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import AddMicronutrientForm from './AddMicronutrientForm';
import MicronutrientInfo from './MicronutrientInfo';
import MicronutrientList from './MicronutrientList';

export type MicronutrientType = {
  id: string;
  name: string;
  shortName: string;
};

export const Micronutrients = [
  {
    id: 'Zn',
    name: 'Zinc',
    shortName: 'Zn',
  },
  {
    id: 'Fe',
    name: 'Iron',
    shortName: 'Fe',
  },
  {
    id: 'Cu',
    name: 'Copper',
    shortName: 'Cu',
  },
  { id: 'Mn', name: 'Manganese', shortName: 'Mn' },
  { id: 'B', name: 'Boron', shortName: 'B' },
  {
    id: 'Mo',
    name: 'Molybdenum',
    shortName: 'Mo',
  },
  {
    id: 'Cl',
    name: 'Chlorine',
    shortName: 'Cl',
  },
  {
    id: 'Ca',
    name: 'Calcium',
    shortName: 'Ca',
  },
  {
    id: 'S',
    name: 'Sulfur',
    shortName: 'S',
  },
  {
    id: 'Mg',
    name: 'Magnesium',
    shortName: 'Mg',
  },
];

export default function AddMicronutrient() {
  const [list, setList] = useState<any[]>([
    {
      id: 1,
      name: 'Zone 1',
      product: 'Product 1',
      nLbs: 100,
      nNeed: 0.5,
      nProduct: 1,
      unit: 'Tonne',
      costPerUnit: 300,
      totalUnits: 0.15,
      totalCost: 1234.88,
      applicationPeriod: 'applicationPeriod',
    },
    {
      id: 2,
      name: 'Zone 2',
      product: 'Product 2',
      nLbs: 200,
      nNeed: 0.6,
      nProduct: 1,
      unit: 'Kg',
      costPerUnit: 400,
      totalUnits: 0.25,
      totalCost: 2345.88,
      applicationPeriod: 'applicationPeriod',
    },
    {
      id: 3,
      name: 'Zone 3',
      product: 'Product 3',
      nLbs: 300,
      nNeed: 0.7,
      nProduct: 1,
      unit: 'Litre',
      costPerUnit: 500,
      totalUnits: 0.35,
      totalCost: 3456.88,
      applicationPeriod: 'applicationPeriod',
    },
  ]);

  const [selectedZone, setSelectedZone] = useState(() => list[0]?.id);

  return (
    <div className="space-y-8">
      {Micronutrients?.map((f) => (
        <DisclosureContentLayout
          key={f.id}
          left={
            <FlipLayout>
              {({ side, setSide }) => (
                <div>
                  {side === 'front' && (
                    <MicronutrientInfo
                      onFlip={() => setSide('back')}
                      mNutrient={f}
                      zone={
                        list.find((item) => item?.id === selectedZone) ?? {}
                      }
                    />
                  )}
                  {side === 'back' && <AddMicronutrientForm mNutrient={f} />}
                </div>
              )}
            </FlipLayout>
          }
          right={
            <MicronutrientList
              mNutrient={f}
              data={list}
              zone={list.find((item) => item?.id === selectedZone) ?? {}}
              onSelect={setSelectedZone}
            />
          }
        />
      ))}
    </div>
  );
}
