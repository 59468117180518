import Currency from '../../commons/Currency';
import NumberDisplay from '../../commons/NumberDisplay';

interface HedgedFuturePriceItemProps {
  total: number;
  percent: number;
}

function HedgedFuturePriceItem({ total, percent }: HedgedFuturePriceItemProps) {
  return (
    <div>
      <Currency value={total} />
      <p className="text-primary text-sm-regular">
        for <NumberDisplay value={percent || 0} numberStyle="percent" /> of
        expected production
      </p>
    </div>
  );
}

export default HedgedFuturePriceItem;
