import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  Plugin,
  PointElement,
  Tick,
  TimeScale,
  Title,
  Tooltip,
  LegendItem,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  CategoryScale
);

const backgroundColor = {
  startColor: 'rgba(128, 188, 0, 0.6)',
  stopColor: 'rgba(128, 188, 0, 0)',
};

interface LineChartProps {
  labels?: any[];
  datasets: any[];
  width?: number;
  height?: number;
  borderColor?: string;
  stepSize?: number;
  scales?: ChartOptions['scales'];
  optionPlugins?: ChartOptions['plugins'];
  yTicksCallback?: (
    tickValue: string | number,
    index: number,
    ticks: Tick[]
  ) => string | number | string[] | number[] | null | undefined;
  plugins?: Plugin[];
}

const LineChart = ({
  datasets,
  width = 240,
  height = 150,
  borderColor = '#80BC00',
  stepSize = 5000,
  scales,
  yTicksCallback = (value) => value,
  labels,
  optionPlugins,
  plugins = [],
}: LineChartProps) => {
  const defaultDatasets = {
    labels: labels ? labels : datasets[0]?.labels,
    datasets: datasets.map((dataset: any) => ({
      label: dataset.label ? dataset.label : '',
      lineTension: 0.4,
      borderDash: dataset?.borderDash ?? [],
      borderWidth: 4,
      pointRadius: 0,
      borderColor: dataset?.borderColor ?? borderColor,
      borderCapStyle: 'round' as const,
      fill: dataset?.fill ?? true,
      backgroundColor: dataset?.backgroundColor ?? backgroundColor,
      // backgroundColor: (context: any) => {
      //   const ctx = context.chart.ctx;
      //   const gradient = ctx.createLinearGradient(0, 0, 0, height * 1.05);
      //   gradient.addColorStop(
      //     0,
      //     dataset?.backgroundColor?.startColor ?? backgroundColor.startColor,
      //   );
      //   gradient.addColorStop(
      //     1,
      //     dataset?.backgroundColor?.stopColor ?? backgroundColor.stopColor,
      //   );
      //   return gradient;
      // },
      data: dataset.data,
    })),
  };

  const defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: width && height ? width / height : 1,
    interaction: {
      intersect: false,
      mode: 'nearest' as const,
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'black',
        displayColors: false,
      },
      legend: {
        position: 'right',
        labels: {
          color: 'white',
          font: {
            family: 'RobotoCondensed',
            size: 16,
          },
        },
      },
      ...optionPlugins,
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            family: 'RobotoCondensed',
          },
          stepSize: stepSize,
          callback: yTicksCallback,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            day: 'YY MMM',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            family: 'RobotoCondensed',
          },
        },
      },
      ...scales,
    },
  };

  return (
    <Line
      // @ts-ignore
      options={defaultOptions}
      data={defaultDatasets}
      width={width}
      height={height}
      plugins={plugins}
    />
  );
};

export default LineChart;
