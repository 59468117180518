import apiClient from './api-client';

export const getInsurancesApi = ({
  userId,
  year,
  scenarioId,
}: {
  userId: string;
  year: string;
  scenarioId?: string;
}) => {
  let query = `?user_id=${userId}&year=${year}`;
  query = scenarioId ? query + `&scenario_id=${scenarioId}` : query;
  return apiClient.get(`/insurance${query}`);
};

export const saveInsuranceApi = (payload: any) => {
  return apiClient.post('/insurance', payload);
};

export const deleteInsuranceApi = (payload: any) => {
  return apiClient.post('/insurance/delete', payload);
};
