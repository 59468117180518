import { CreateStoragePayload, StorageItem } from '../types/storage';
import apiClient from './api-client';

export const getStoragesApi = (userId: string, scenarioId?: string) => {
  let query = `?user_id=${userId}`;
  query = scenarioId ? query + `&scenario_id=${scenarioId}` : query;
  return apiClient.get(`/storages${query}`);
};

export const createStorageApi = (payload: CreateStoragePayload) => {
  return apiClient.post('/storages', payload);
};

export const deleteStorageApi = (payload: StorageItem) => {
  return apiClient.post('/storages/delete', payload);
};

export const updateStorageApi = (payload: CreateStoragePayload) => {
  return apiClient.post('/storages/update', payload);
};
