import { IconProps } from '../../types';

export default function RiskIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM10.875 7.125V13.125C10.875 13.7812 11.3438 14.25 12 14.25C12.6094 14.25 13.125 13.7812 13.125 13.125V7.125C13.125 6.51562 12.6094 6 12 6C11.3438 6 10.875 6.51562 10.875 7.125ZM12 18.75C12.7969 18.75 13.4531 18.0938 13.4531 17.2969C13.4531 16.5 12.7969 15.8438 12 15.8438C11.1562 15.8438 10.5 16.5 10.5 17.2969C10.5 18.0938 11.1562 18.75 12 18.75Z"
        fill={fill}
      />
    </svg>
  );
}
