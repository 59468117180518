import { IconProps } from '../../types';

const GridIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.5H6V6.5H0V0.5ZM0 8H6V14H0V8ZM6 15.5V21.5H0V15.5H6ZM7.5 0.5H13.5V6.5H7.5V0.5ZM13.5 8V14H7.5V8H13.5ZM7.5 15.5H13.5V21.5H7.5V15.5ZM21 0.5V6.5H15V0.5H21ZM15 8H21V14H15V8ZM21 15.5V21.5H15V15.5H21Z"
        fill={fill}
      />
    </svg>
  );
};

export default GridIcon;
