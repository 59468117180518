import { createContext, useContext } from 'react';
import { HistoricalInsightsContextProps } from '../../types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createNoteApi,
  createStudyApi,
  deleteStudyApi,
  getStudyNoteApi,
  getTableData,
  getUserCropYearsApi,
  getUserStudies,
  updateStudyApi,
} from '../../api/historical-insights-api';

export const HistoricalInsightsContext = createContext<
  HistoricalInsightsContextProps | undefined
>(undefined);

export const useHistoricalInsights = (): HistoricalInsightsContextProps => {
  const context = useContext(HistoricalInsightsContext);
  if (context === undefined) {
    throw new Error('useMarket must be used within a MarketProvider');
  }
  return context;
};

export const useUserCropYears = (userId: string, crop: string) => {
  const res = useQuery({
    queryKey: ['historical-insights/years', userId, crop],
    queryFn: () => getUserCropYearsApi(userId, crop),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useTableData = (
  userId: string,
  crop: string,
  type: string,
  year: string
) => {
  const res = useQuery({
    queryKey: ['historical-insights/table-data', userId, crop, year, type],
    queryFn: () => getTableData(userId, crop, type, year),
    enabled: !!userId,
  });

  return { data: res.data };
};

export const useStudyNote = (pk: string, xIndex: string) => {
  const res = useQuery({
    queryKey: ['historical-insights/study-note-data', pk, xIndex],
    queryFn: () => getStudyNoteApi(pk, xIndex),
    enabled: !!pk,
  });

  return { data: res.data };
};

export const useCreateStudy = () => {
  const mutation = useMutation({
    mutationKey: ['historical-insights/createStudy'],
    mutationFn: (payload: any) => createStudyApi(payload),
  });

  return mutation;
};

export const useUpdateStudy = () => {
  const mutation = useMutation({
    mutationKey: ['historical-insights/updateStudy'],
    mutationFn: (payload: any) => updateStudyApi(payload),
  });

  return mutation;
};

export const useCreateNote = () => {
  const mutation = useMutation({
    mutationKey: ['historical-insights/createNote'],
    mutationFn: (payload: any) => createNoteApi(payload),
  });

  return mutation;
};

export const useDeleteStudy = () => {
  const mutation = useMutation({
    mutationKey: ['historical-insights/deleteStudy'],
    mutationFn: (payload: any) => deleteStudyApi(payload),
  });

  return mutation;
};

export const useStudyData = (userId: string, year: string) => {
  const res = useQuery({
    queryKey: ['historical-insights/study-data'],
    queryFn: () => getUserStudies(userId, year),
    enabled: !!userId,
  });

  return { data: res.data };
};
