import Prescription from '../../pages/dashboard/prescription';

const prescriptionsRoutes = [
  {
    path: 'prescriptions',
    element: <Prescription />,
  },
];

export default prescriptionsRoutes;
