import { useQuery } from '@tanstack/react-query';
import { getOperationFiles } from '../../api/operation-files';
import { OperationFilesRes } from '../../types';

export const useOperationFiles = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['data-entry/files', userId],
    queryFn: () => getOperationFiles(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
  return { data: data?.data as OperationFilesRes[], ...rest };
};
