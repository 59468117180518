import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface CardHeaderProps {
  title: string | ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  tooltip?: string;
  link?: string;
  icon?: React.ReactNode;
  className?: string;
}

function CardHeader({
  title,
  subtitle,
  tooltip,
  children,
  link,
  icon,
  className,
}: CardHeaderProps) {
  return (
    <header className={clsx('flex items-center justify-between', className)}>
      <div className="flex items-center space-x-1">
        {link ? (
          <Link to={link} className="flex items-center space-x-2">
            {icon}
            <h4 className="display-sm-bold">{title}</h4>
          </Link>
        ) : (
          <div className="flex items-center space-x-2">
            {icon}
            <h4 className="display-sm-bold">{title}</h4>
          </div>
        )}
        {tooltip && <InformationCircleIcon className="size-6 fill-secondary" />}
      </div>
      {children}
      {subtitle && <p className="text-md-regular">{subtitle}</p>}
    </header>
  );
}

export default CardHeader;
