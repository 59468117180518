import { IconProps } from '../../types';

export default function WarningIcon({
  fill = '#FFB71B',
  width = 25,
  height = 22,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2368 18.6621C24.9865 19.9746 24.0494 21.6152 22.5032 21.6152H2.49677C0.9506 21.6152 0.0135291 19.9746 0.763186 18.6621L10.7898 1.59961C11.5395 0.287109 13.4605 0.287109 14.257 1.59961L24.2368 18.6621ZM11.3989 6.99023V12.9902C11.3989 13.6465 11.9612 14.1152 12.5234 14.1152C13.1325 14.1152 13.6479 13.6465 13.6479 12.9902V6.99023C13.6479 6.38086 13.1325 5.86523 12.5234 5.86523C11.8675 5.86523 11.3989 6.38086 11.3989 6.99023ZM12.5234 18.6152C13.3199 18.6152 13.9759 17.959 13.9759 17.1621C13.9759 16.3652 13.3199 15.709 12.5234 15.709C11.6801 15.709 11.0241 16.3652 11.0241 17.1621C11.0241 17.959 11.6801 18.6152 12.5234 18.6152Z"
        fill={fill}
      />
    </svg>
  );
}
