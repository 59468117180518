import { IconProps } from '../../types';

export default function FieldPerformanceIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.929 10.6319C15.4134 11.1007 14.6633 11.1007 14.1008 10.6787L9.03784 6.88156L2.42792 12.132C1.8185 12.6477 0.880922 12.5539 0.365254 11.8976C-0.197292 11.2882 -0.0566552 10.3506 0.599649 9.78805L8.10026 3.83443C8.61593 3.36564 9.36599 3.36564 9.92854 3.78755L14.9915 7.58474L21.6014 2.33431C22.2108 1.77176 23.1484 1.9124 23.664 2.5687C24.2266 3.17813 24.086 4.11571 23.4296 4.63137L15.929 10.6319ZM7.53772 12.4601C7.53772 11.6632 8.19402 10.96 9.03784 10.96C9.83478 10.96 10.538 11.6632 10.538 12.4601V21.4609C10.538 22.3047 9.83478 22.961 9.03784 22.961C8.19402 22.961 7.53772 22.3047 7.53772 21.4609V12.4601ZM1.53723 16.9605C1.53723 16.1636 2.19353 15.4604 3.03735 15.4604C3.83429 15.4604 4.53747 16.1636 4.53747 16.9605V21.4609C4.53747 22.3047 3.83429 22.961 3.03735 22.961C2.19353 22.961 1.53723 22.3047 1.53723 21.4609V16.9605ZM16.5385 15.4604V21.4609C16.5385 22.3047 15.8353 22.961 15.0383 22.961C14.1945 22.961 13.5382 22.3047 13.5382 21.4609V15.4604C13.5382 14.6634 14.1945 13.9603 15.0383 13.9603C15.8353 13.9603 16.5385 14.6634 16.5385 15.4604ZM19.5387 12.4601C19.5387 11.6632 20.195 10.96 21.0388 10.96C21.8358 10.96 22.539 11.6632 22.539 12.4601V21.4609C22.539 22.3047 21.8358 22.961 21.0388 22.961C20.195 22.961 19.5387 22.3047 19.5387 21.4609V12.4601Z"
        fill={fill}
      />
    </svg>
  );
}
