import { BellIcon } from '@heroicons/react/24/solid';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { contractTypes } from '../../../constants';
import { useUser } from '../../../contexts/auth-context';
import { useMarketContext } from '../../../contexts/market-context';
import { useCreateWatchList } from '../../../hooks/market/use-watch-list';
import { WatchListItemPayload } from '../../../types';

const AddToWatchlistButton = () => {
  const user = useUser();
  const { pricesYearRef, pricesMonthRef, pricesCropRef, currentTab } =
    useMarketContext();
  const { mutate: createWatchListItem } = useCreateWatchList();
  const queryClient = useQueryClient();

  const handleAddWatchList = async () => {
    const date = new Date(Date.parse(pricesMonthRef.current + ' 1, 2020')); // Using any non-leap year
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');
    const crop =
      pricesCropRef.current.charAt(0).toUpperCase() +
      pricesCropRef.current.slice(1);
    createWatchListItem(
      {
        date: `${pricesYearRef.current}-${monthNumber}`,
        crop,
        type: contractTypes[currentTab],
        userId: user?.id ?? '',
      },
      {
        onSuccess: () => {
          alert('Item added to watch list');
          queryClient.invalidateQueries([
            'market/alerts',
            user?.id ?? '',
          ] as InvalidateQueryFilters);
        },
        onError: (
          error: unknown,
          _variables: WatchListItemPayload,
          _context: unknown
        ) => {
          console.error('Error creating alert:', error);
          alert('Failed to create alert. Please try again.');
        },
      }
    );
  };

  return (
    <div className="card cursor-pointer p-2 px-4 flex items-center space-x-2 text-lightGray rounded-md max-w-max">
      <BellIcon className="w-6 h-6 text-darkGray" />
      <p className="whitespace-nowrap text-darkGray">Add To Watchlist</p>
    </div>
  );
};

export default AddToWatchlistButton;
