import clsx from 'clsx';

export default function Skeleton({ className }: { className?: string }) {
  return (
    <div role="status" className="animate-pulse">
      <div
        className={clsx(
          'h-[16px] bg-base-1000 rounded w-full min-w-[150]',
          className
        )}
      ></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
