import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated } from '../../contexts/auth-context';

function UnauthRoute({
  children,
}: {
  redirectPath?: string;
  children?: ReactNode;
}): JSX.Element {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) return <Navigate to="/" replace />;

  return <>{children}</> || <Outlet />;
}

export default UnauthRoute;
