import { GridCellLayer } from 'deck.gl';
import { useEffect, useState } from 'react';
import { filterWxData, filterWxDataByValue } from '../../../utils';
import { useMapContext } from '../../../contexts/map-context';
import * as turf from '@turf/turf';

export const useLayers = ({
  wData,
  selectedArea,
  probabilityRange,
  field,
  fieldRange,
  zone,
  zoneRange,
  type,
  crop,
  featureCollection,
}: {
  wData: any;
  selectedArea: string;
  probabilityRange?: [number, number];
  field: any;
  fieldRange: any;
  zone: any;
  zoneRange: any;
  type: string;
  crop?: string;
  featureCollection: any;
}) => {
  const [layers, setLayers] = useState<any[]>([]);
  const [hoverInfo, setHoverInfo] = useState<any>();
  const { creatingStudy, selectedStudyIndex, trigger } = useMapContext();

  useEffect(() => {
    if (wData && selectedArea) {
      const dataChunks = wData.map((data: any) => {
        const filter = { key: selectedArea, role: 1 };
        let wxData = filterWxData(data, filter);
        wxData = filterWxDataByValue(wxData, probabilityRange);
        wxData = filterWxData(wxData, '', field);

        wxData = filterWxData(wxData, '', '', zone);
        wxData = filterWxData(wxData, '', '', '', fieldRange);
        wxData = filterWxData(wxData, '', '', '', '', zoneRange);
        wxData = filterWxData(wxData, '', '', '', '', '', crop);

        const length = wxData['lon'] ? wxData['lon'].length : 0;
        if (length === 0) return {};

        return {
          value: wxData['value'],
          lons: wxData['lon'],
          lats: wxData['lat'],
          elevs: wxData['elev2'].map((e: any) => 1),
          reds: wxData['r'],
          blues: wxData['b'],
          greens: wxData['g'],
          length: length,
          crop: wxData['crop'],
          field: wxData['field'],
          type,
        };
      });

      let filteredChunks = dataChunks;
      if (creatingStudy || selectedStudyIndex !== -1) {
        if (featureCollection.features.length > 0) {
          filteredChunks = dataChunks.map((dataChunk: any) => {
            const filteredData: any = {
              value: [],
              lons: [],
              lats: [],
              elevs: [],
              reds: [],
              blues: [],
              greens: [],
              length: 0,
              crop: [],
              field: [],
              type,
            };

            dataChunk.lons.forEach((lon: number, index: number) => {
              const lat = dataChunk.lats[index];
              const point = turf.point([lon, lat]);
              let flag;
              for (let i = 0; i < featureCollection.features.length; i++) {
                const feature = featureCollection.features[i];
                const polygon = turf.polygon(feature.geometry.coordinates);
                if (turf.booleanPointInPolygon(point, polygon)) {
                  flag = true;
                  break;
                }
              }
              if (flag) {
                filteredData.lons.push(lon);
                filteredData.lats.push(lat);
                filteredData.elevs.push(1);
                filteredData.reds.push(dataChunk.reds[index]);
                filteredData.blues.push(dataChunk.blues[index]);
                filteredData.greens.push(dataChunk.greens[index]);
                filteredData.value.push(dataChunk.value[index]);
                filteredData.length += 1;
                filteredData.crop.push(dataChunk.crop[index]);
                filteredData.field.push(dataChunk.field[index]);
              }
            });
            return filteredData;
          });
        }
      }

      const layers = filteredChunks.map(
        (chunk: any, chunkIndex: any) =>
          new GridCellLayer({
            id: `map-${type}_tab-${chunkIndex}`,
            data: chunk,
            cellSize: 10,
            extruded: false,
            // @ts-ignore
            getPosition: (
              object,
              {
                index,
                data,
                target,
              }: { index: number; data: any; target: number[] }
            ) => {
              target[0] = data.lons[index];
              target[1] = data.lats[index];
              target[2] = data.elevs[index];
              return target;
            },
            // @ts-ignore
            getFillColor: (object, { index, data, target }) => {
              // @ts-ignore
              target[0] = data.reds[index];
              // @ts-ignore
              target[1] = data.greens[index];
              // @ts-ignore
              target[2] = data.blues[index];
              return target;
            },

            pickable: true,
            onHover: (info) => setHoverInfo(info),
            opacity: 0.8,
          })
      );
      setLayers(layers);
    }
  }, [
    wData,
    selectedArea,
    probabilityRange,
    field,
    zone,
    fieldRange,
    zoneRange,
    type,
    crop,
    selectedStudyIndex,
    creatingStudy,
    trigger,
  ]);

  return {
    layers,
    hoverInfo,
  };
};
