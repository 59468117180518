import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getPriceOptions,
  useFuturesContracts,
} from '../../../hooks/contracts/use-futures-contracts';
import { useMarketBidAsk } from '../../../hooks/market/use-market';
import { useUpdateUserRealTimeSettings } from '../../../hooks/user/use-user-real-time-settings';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { convertToFloat } from '../../../utils';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';
import { AwaitSelect } from '../../commons/Select';
import Skeleton from '../../commons/Skeleton';

interface PriceItemProps {
  symbol: string;
  cropName: string;
  onChange: (value: string) => void;
  defaultSymbol?: string;
  size?: 'small' | 'large';
}

function PriceItem({
  symbol,
  cropName,
  defaultSymbol,
  onChange,
  size = 'small',
}: PriceItemProps) {
  const user = useUser();
  const year = useYearFilter();

  const { data: contracts, isFetching } = useFuturesContracts();
  const { mutate: updateSettings } = useUpdateUserRealTimeSettings();

  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');

  const { data: bidAskData, isFetching: isFetchingBidAsk } =
    useMarketBidAsk(symbol);

  const [lastValidBidAsk, setLastValidBidAsk] = useState<any>(bidAskData);

  useEffect(() => {
    console.log(
      'newest data:',
      new Date(msgBidAsk?.data?.last_trade_time),
      lastValidBidAsk,
      new Date(msgBidAsk?.data?.last_trade_time) >
        new Date(lastValidBidAsk?.last_trade_time)
    );
    if (
      msgBidAsk &&
      msgBidAsk.data &&
      msgBidAsk.data.symbol === symbol &&
      (Object.keys(lastValidBidAsk).length === 0 ||
        new Date(msgBidAsk?.data?.last_trade_time) >
          new Date(lastValidBidAsk?.last_trade_time))
    ) {
      setLastValidBidAsk(msgBidAsk.data);
    }
  }, [msgBidAsk, symbol]);

  const data: any = useMemo(() => {
    console.log(
      'last valid bid ask: ',
      lastValidBidAsk,
      'bid ask: ',
      bidAskData
    );
    return Object.keys(lastValidBidAsk).length > 0
      ? lastValidBidAsk
      : bidAskData;
  }, [lastValidBidAsk, bidAskData]);

  useEffect(() => {
    if (defaultSymbol) {
      onChange(defaultSymbol);
    }
  }, [defaultSymbol, onChange]);

  const priceOptions = useMemo(
    () => getPriceOptions(contracts, cropName),
    [contracts, cropName]
  );

  const handleOnChange = useCallback(
    async (value: string) => {
      onChange(value);

      // Save user settings
      if (user?.id && year && value) {
        updateSettings({
          userId: user?.id,
          year: parseInt(year),
          crop: cropName,
          newSetting: {
            settingKey: 'contract',
            settingValue: value,
          },
        });
      }
    },
    [onChange, user?.id, year, cropName, updateSettings]
  );

  const lastTradeTime = new Date(data?.last_trade_time).toLocaleTimeString(
    'en-US',
    {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'America/Chicago',
    }
  );

  return (
    <div className={'space-y-2'}>
      <AwaitSelect
        size="sm"
        name="symbol"
        className={clsx(size === 'large' && 'display-xs-regular px-6 py-3')}
        defaultValue={defaultSymbol}
        options={priceOptions}
        onChange={(e) => handleOnChange(e.target.value)}
        isLoading={isFetching}
      />
      {isFetchingBidAsk ? (
        <Skeleton />
      ) : (
        <>
          <span
            className={clsx(
              'flex space-x-1.5',
              size === 'large' && 'justify-between'
            )}
          >
            <span className={clsx(size === 'large' && 'flex space-x-4')}>
              <p
                className={clsx(
                  size !== 'large' && 'hidden',
                  'display-md-bold'
                )}
              >
                Price:
              </p>
              <p
                className={clsx(
                  size === 'large' && 'display-md-bold',
                  'text-md-bold'
                )}
              >
                ${convertToFloat(data?.trade_price).toFixed(3)}
              </p>
            </span>

            <Fluctuation
              value={convertToFloat(data?.change)}
              currency="USD"
              context="Change"
              className={clsx(size === 'large' && 'text-xl-regular')}
              contextClassname={clsx(size === 'large' && 'text-xl-regular')}
            />
          </span>
          <span>
            {' '}
            {lastTradeTime === 'Invalid Date' ? 'N/A' : lastTradeTime}
          </span>
        </>
      )}
    </div>
  );
}
export default PriceItem;
