import { useUser } from '../../../contexts/auth-context';
import {
  useDeleteSoilSample,
  useSoilSamples,
} from '../../../hooks/soil-sample/use-soil-sample';
import { SoilSampleItem } from '../../../types';
import Table, { TableConfigs } from '../../commons/Table';
import TableLayout from '../../dashboard/commons/TableLayout';
import EditSoilSampleForm from './EditSoilSampleForm';

const SoilSampleType: { [key: string]: string } = {
  field: 'Field',
  zone: 'Zone',
  point: 'Grid',
};

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'legal_description',
      name: 'Legal Description',
    },
    {
      key: 'other_zone',
      name: 'Zone',
    },
    {
      key: 'section',
      name: 'Section',
    },
    {
      key: 'township',
      name: 'Township',
    },
    {
      key: 'range',
      name: 'Range',
    },
    {
      key: 'soil_sample_type',
      name: 'Type',
      type: 'element',
      element: (item: SoilSampleItem) => {
        return <span>{SoilSampleType[item.soil_sample_type]}</span>;
      },
    },
    {
      key: 'BS_Ca_pct',
      name: 'BS Ca %',
    },
    {
      key: 'BS_K_pct',
      name: 'BS K %',
    },
    {
      key: 'BS_Mg_pct',
      name: 'BS Mg %',
    },
    {
      key: 'BS_Na_pct',
      name: 'BS Na %',
    },
    {
      key: 'Ca_ppm',
      name: 'Ca ppm',
    },
    {
      key: 'CEC_meq',
      name: 'CEC meq',
    },
    {
      key: 'Cu_ppm',
      name: 'Cu ppm',
    },
    {
      key: 'K_ppm',
      name: 'K ppm',
    },
    {
      key: 'Mg_ppm',
      name: 'Mg ppm',
    },
    {
      key: 'N1_lb',
      name: 'N1 lb',
    },
    {
      key: 'Na_ppm',
      name: 'Na ppm',
    },
  ],
};

export default function SoilSampleList() {
  const user = useUser();

  const { data, isFetching } = useSoilSamples(user?.id ?? '');
  const { mutate: deleteSoilSample } = useDeleteSoilSample();

  const rowActions = {
    edit: {
      onSelect: (item: any) => {},
      form: ({ item, onClose }: { item: any; onClose: () => void }) => {
        return <EditSoilSampleForm data={item} onClose={onClose} />;
      },
    },
    delete: (item: any) => {
      deleteSoilSample({
        user_id_legal_description_other_zone:
          item.user_id_legal_description_other_zone,
        date: item.Date,
      });
    },
    note: {
      onSave: (item: any, value: any) => {},
      title: 'Add Expense Note',
    },
  };

  return (
    <TableLayout title="Soil Samples">
      <Table
        configs={tableConfigs}
        data={data}
        pagination={{
          size: 10,
        }}
        rowActions={rowActions}
        loading={isFetching}
      />
    </TableLayout>
  );
}
