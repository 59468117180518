import { IconProps } from '../../types';

export default function CircleIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.046875 10.9385C0.282631 8.48668 1.22566 6.22342 2.7345 4.38452L4.90346 6.55348C3.91328 7.77941 3.25316 9.28825 3.06456 10.9385H0.046875ZM6.5066 4.95033L4.33764 2.78137C6.17654 1.27253 8.4398 0.329506 10.8917 0.09375V3.11143C9.24137 3.30004 7.73253 3.96015 6.5066 4.95033ZM13.2021 21.0761C14.8524 20.8875 16.3612 20.2273 17.5872 19.2372L19.7561 21.4061C17.9172 22.915 15.6539 23.858 13.2021 24.0938V21.0761ZM21.0292 13.2018H24.0469C23.8111 15.7008 22.8681 17.9641 21.3593 19.803L19.1903 17.634C20.1805 16.4081 20.8406 14.8993 21.0292 13.2018ZM6.5066 19.2372C7.73253 20.2273 9.24137 20.8875 10.8917 21.0761V24.0938C8.4398 23.858 6.17654 22.915 4.33764 21.4061L6.5066 19.2372ZM0.046875 13.2018H3.06456C3.25316 14.8993 3.91328 16.4081 4.90346 17.634L2.7345 19.803C1.22566 17.9641 0.282631 15.7008 0.046875 13.2018ZM17.5872 4.95033C16.3612 3.96015 14.8524 3.30004 13.2021 3.11143V0.09375C15.6539 0.329506 17.9172 1.27253 19.7561 2.78137L17.5872 4.95033ZM19.1903 6.55348L21.3593 4.38452C22.8681 6.22342 23.8111 8.48668 24.0469 10.9385H21.0292C20.8406 9.28825 20.1805 7.77941 19.1903 6.55348Z"
        fill={fill}
      />
    </svg>
  );
}
