import { Field, Label } from '@headlessui/react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Button from '../../../components/commons/Button';
import NativeCheckbox from '../../../components/commons/inputs/NativeCheckbox';
import TextInput from '../../../components/commons/inputs/TextInput';
import StyledCard from '../../../components/main/StyledCard';
import { SignUpSections } from '../../../constants';
import { CompleteRegisterValueTypes } from '.';
import { useCognitoUser } from '../../../contexts/auth-context';
// import { getFips, getLatLong } from "../../../utils";

export default function DetailsForm({
  onNext,
  form,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes>;
}) {
  const [loading, setLoading] = useState(false);

  const cognitoUser = useCognitoUser();

  useEffect(() => {
    if (!cognitoUser?.role) return;
    if (cognitoUser.role === 'advisor') {
      form.setValue('is_advisor_client', true);
    } else if (cognitoUser.role === 'admin') {
      form.setValue('is_admin', true);
    }
    form.setValue('network_partner', cognitoUser?.network_partner || '');
  }, [cognitoUser?.role]);

  const options = [
    { label: 'Email', value: 'email' },
    { label: 'SMS', value: 'sms' },
    { label: 'Phone', value: 'phone' },
  ];

  // const address = form.watch("address");
  // const city = form.watch("city");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // const latLng = await getLatLong({ address, city });
      // if (latLng) {
      //   const fips = await getFips(latLng);
      //   if (fips) {
      //     form.setValue("countyfips", fips ?? "00000");
      //   }
      // }
      onNext(SignUpSections.Prompts);
    } catch (err) {
      enqueueSnackbar('Please check your address info or try again later', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCard
      title="Sign Up: Enter Your Information"
      className="max-w-screen-lg mx-auto w-full"
    >
      <div className="text-center space-y-4">
        <h3 className="text-lg-regular">
          Please fill out the information below to sign up for an AgriVaR
          account
        </h3>
        <p className="text-lg-light"> * Denotes a Required Field</p>
      </div>

      <div className="space-y-8">
        <div className="flex space-x-3">
          <TextInput
            name={'username'}
            label="Username"
            className="!text-base-1100 !bg-base-000"
            placeholder="Enter your username"
            required
          />
        </div>
        <div className="flex space-x-3">
          <TextInput
            name={'first_name'}
            label="First Name"
            className="!text-base-1100 !bg-base-000"
            placeholder="Enter your first name"
            required
          />
          <TextInput
            name={'last_name'}
            label="Last Name"
            className="!text-base-1100 !bg-base-000"
            placeholder="Enter your last name"
            required
          />
        </div>

        <div className="flex space-x-3">
          <TextInput
            name={'phone'}
            label="Phone"
            className="!text-base-1100 !bg-base-000"
            required
          />
          <TextInput
            name={'business_phone'}
            label="Business Phone"
            className="!text-base-1100 !bg-base-000"
            required
          />
        </div>

        <TextInput
          name={'address'}
          label="Street Address"
          className="!text-base-1100 !bg-base-000"
          placeholder="Enter your street address"
          required
        />

        <div className="flex space-x-3">
          <TextInput
            name={'city'}
            label="City"
            className="!text-base-1100 !bg-base-000"
            placeholder="Enter city"
            required
          />
          <TextInput
            name={'state'}
            label="State"
            className="!text-base-1100 !bg-base-000"
            placeholder="Enter state"
            required
          />
        </div>

        {cognitoUser?.role === 'advisor' && (
          <div className="flex flex-col w-full justify-center items-center ">
            <Field className="flex space-x-8 justify-center">
              <NativeCheckbox
                name="is_advisor_client"
                label="I am an AgriVaR Advisor"
                disabled={true}
              />
            </Field>
            <TextInput
              name={'advisor_description'}
              label="Advisor Role Description"
              className="!text-base-1100 !bg-base-000"
              placeholder="Client"
            />
          </div>
        )}

        <Field className="flex items-center space-x-10">
          <Label>Select Preferred Communication Method</Label>
          {options.map((option) => (
            <NativeCheckbox
              key={option.value}
              name="contact_reference"
              value={option.value}
              label={option.label}
              required
            />
          ))}
        </Field>

        <Field className="flex space-x-8 justify-center">
          <NativeCheckbox
            name="opt_in"
            value="true"
            label="I agree to receive marketing information from AgriVaR"
          />
        </Field>

        <div className="space-x-2">
          <Button
            type="button"
            size="lg"
            color="primary"
            disabled={!form.formState.isValid}
            onClick={handleSubmit}
            loading={loading}
            className="!rounded-md !font-normal"
          >
            Submit
          </Button>
        </div>
      </div>
    </StyledCard>
  );
}
