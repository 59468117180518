import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TriangleDownIcon } from '../../assets/icons';
import Skeleton from './Skeleton';

interface Options {
  value: string;
  label: string;
  icon?: React.ReactElement;
}
interface SelectProps {
  options: Options[];
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  name: string;
  defaultValue?: string;
  className?: string;
  required?: boolean;
  key?: string;
  horizontal?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hiddenLabel?: boolean;
  fieldClassName?: string;
}

function Select(props: SelectProps) {
  const {
    options,
    value,
    className,
    onChange,
    defaultValue: defaultValueProp,
    label,
    size = 'md',
    name,
    required,
    horizontal,
    placeholder = 'Select an option',
    disabled,
    hiddenLabel = false,
    fieldClassName,
  } = props;
  const padding =
    size === 'sm' ? 'py-1.5 pl-4' : size === 'md' ? 'py-2 pl-4' : 'py-3';
  const fontStyle =
    size === 'sm'
      ? 'text-xs-bold'
      : size === 'md'
        ? 'text-md-regular'
        : 'text-lg';

  const form = useFormContext();
  const field = form?.register(name, {
    ...props,
    required: {
      value: props.required || false,
      message: `${label} is required`,
    },
  });
  const error = form?.formState.errors[name];
  const defaultValue =
    form?.formState.defaultValues?.[name] || defaultValueProp;

  const [selectedOption, setSelectedOption] = useState<Options>(() => {
    const defaultOp = options.find((option) => {
      return option.value === defaultValue;
    });
    const initValue = defaultOp ?? options[0];
    return initValue;
  });

  useEffect(() => {
    if (value) {
      const selected = options.find((option) => option.value === value);
      if (selected) setSelectedOption(selected);
    }
  }, [value]);

  const handleChange = (value: string) => {
    // validate
    // if (required && !value) {
    //   form?.setError?.(name, {
    //     type: 'required',
    //     message: `${label} is required`,
    //   });
    // } else {
    //   form?.clearErrors(name);
    // }

    const selected = options.find((option) => option.value === value);
    if (selected) setSelectedOption(selected);
  };

  const formValue = form?.getValues(name);
  useEffect(() => {
    if (formValue) {
      handleChange(formValue);
    }
  }, [formValue]);

  useEffect(() => {
    if (onChange) {
      const event = {
        target: { value: selectedOption?.value },
      } as React.ChangeEvent<HTMLSelectElement>;
      onChange(event);
    }

    if (field) {
      field.onChange({ target: { value: selectedOption?.value, name } });
    }
  }, [selectedOption]);

  return (
    <Field className={clsx('space-y-2 w-full', fieldClassName)}>
      <div
        className={clsx(
          horizontal ? 'flex items-center space-x-2' : 'space-y-1'
        )}
      >
        {label && (
          <Label className={clsx({ 'opacity-0': hiddenLabel })}>
            {label} {required && <span className="text-secondary">*</span>}
          </Label>
        )}
        <Listbox value={selectedOption?.value} onChange={handleChange}>
          <div className="relative">
            <ListboxButton
              disabled={disabled || options.length === 0}
              className={clsx(
                'relative w-full bg-base-1000 border border-base-1000 pl-4 pr-10 text-left rounded',
                'min-w-[150px]',
                padding,
                fontStyle,
                className,
                options?.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
              style={{
                minWidth: '100px',
                boxShadow:
                  '0px 1px 2px 0px #1018280D,2px 2px 4px 0px #00000040 inset',
              }}
            >
              <span className="flex items-center justify-between w-full overflow-x-hidden whitespace-nowrap">
                {options?.length <= 0 && (
                  <p className="opacity-50">No options available</p>
                )}
                {selectedOption?.icon && (
                  <span className="inline-flex items-center mr-2 min-w-9">
                    {selectedOption.icon}
                  </span>
                )}
                {selectedOption?.label ? (
                  <p>{selectedOption.label}</p>
                ) : (
                  <p className="opacity-0">{placeholder}</p>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-4 flex items-center">
                <TriangleDownIcon className="size-5" />
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                anchor={{ to: 'bottom start', gap: 4 }}
                className={clsx(
                  'absolute max-h-60 w-[var(--button-width)]',
                  'overflow-auto bg-block-fill border border-base-1000 shadow-lg z-10',
                  'custom-scrollbar rounded p-2 shadow-lg'
                )}
              >
                {options.map((option, index) => (
                  <ListboxOption
                    key={`${option.value}-${index}`}
                    value={option.value}
                    className={({ focus, selected }) =>
                      clsx(
                        focus ? 'bg-base-900' : '',
                        selected && 'bg-base-1000',
                        'cursor-default select-none relative py-2 pl-3 pr-4 flex items-center border-b border-base-1000 text-base-000',
                        'last:border-0'
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={clsx(
                            'truncate flex items-center justify-between'
                          )}
                        >
                          {option.icon && (
                            <span className="inline-flex items-center mr-2 min-w-9">
                              {option.icon}
                            </span>
                          )}
                          {option.label}
                        </span>
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}

export default Select;

interface AwaitSelectProps extends SelectProps {
  isLoading: boolean;
}
export const AwaitSelect = ({ isLoading, ...rest }: AwaitSelectProps) => {
  return (
    <Field className="space-y-2">
      {isLoading && rest?.label && <Label>{rest.label}</Label>}
      {isLoading ? <Skeleton className="!h-[31px]" /> : <Select {...rest} />}
    </Field>
  );
};
