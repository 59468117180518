import React, { ReactNode, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

interface AppFilterContextType {
  year: string;
  incomeView: 'revenue' | 'net-income';
  setYear: (year: string) => void;
  setIncomeView: (incomeView: 'revenue' | 'net-income') => void;
}

interface AppFilterProviderProps {
  children: ReactNode;
}

export const AppFilterContext = createContext<AppFilterContextType | undefined>(
  {
    year: '2024',
    incomeView: 'net-income',
    setYear: () => {},
    setIncomeView: () => {},
  }
);

export const AppFilterProvider: React.FC<AppFilterProviderProps> = ({
  children,
}) => {
  const [year, setYear] = useState('2024');
  const [incomeView, setIncomeView] = useState<'revenue' | 'net-income'>(
    'net-income'
  );
  return (
    <AppFilterContext.Provider
      value={{
        year,
        setYear,
        incomeView,
        setIncomeView,
      }}
    >
      {children}
    </AppFilterContext.Provider>
  );
};

// Selectors
export function useAppFilterContext() {
  const context = useContextSelector(AppFilterContext, (state) => state);

  if (context === undefined) {
    throw new Error(
      'useAppFilterContext must be used within an AppFilterProvider'
    );
  }

  return context;
}

export function useYearFilter() {
  return useContextSelector(AppFilterContext, (state) => state?.year);
}

export function useIncomeViewFilter() {
  return useContextSelector(AppFilterContext, (state) => state?.incomeView);
}

export function useSetYearFilter() {
  return useContextSelector(AppFilterContext, (state) => state?.setYear);
}

export function useSetIncomeViewFilter() {
  return useContextSelector(AppFilterContext, (state) => state?.setIncomeView);
}
