import { IconProps } from '../../types';

const MapZoomOutIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4374 20.8134C24.1405 21.5634 24.1405 22.7353 23.4374 23.4853C22.6874 24.1884 21.5155 24.1884 20.7655 23.4853L15.1874 17.8603C13.2655 19.1259 10.9218 19.7821 8.39052 19.4071C4.07802 18.8446 0.609273 15.329 0.0467735 11.0634C-0.750102 4.6415 4.59365 -0.70225 11.0155 0.094625C15.2811 0.657125 18.7968 4.12588 19.4061 8.43838C19.7343 10.9696 19.078 13.3134 17.8124 15.1884L23.4374 20.8134ZM13.4999 10.8759C14.1093 10.8759 14.6249 10.4071 14.6249 9.75088C14.6249 9.1415 14.1093 8.62588 13.453 8.62588H5.95302C5.34365 8.62588 4.8749 9.1415 4.8749 9.75088C4.8749 10.4071 5.34365 10.8759 5.95302 10.8759H13.4999Z"
        fill={fill}
      />
    </svg>
  );
};

export default MapZoomOutIcon;
