import { IconProps } from '../../types';

export default function ApprovedIcon({ fill = '#80BC00' }: IconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 12.6152C0.5 6.00586 5.84375 0.615234 12.5 0.615234C19.1094 0.615234 24.5 6.00586 24.5 12.6152C24.5 19.2715 19.1094 24.6152 12.5 24.6152C5.84375 24.6152 0.5 19.2715 0.5 12.6152ZM17.8906 10.5527C18.4062 10.0371 18.4062 9.24023 17.8906 8.72461C17.375 8.20898 16.5781 8.20898 16.0625 8.72461L11 13.7871L8.89062 11.7246C8.375 11.209 7.57812 11.209 7.0625 11.7246C6.54688 12.2402 6.54688 13.0371 7.0625 13.5527L10.0625 16.5527C10.5781 17.0684 11.375 17.0684 11.8906 16.5527L17.8906 10.5527Z"
        fill={fill}
      />
    </svg>
  );
}
