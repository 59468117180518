/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';

const permissions = [
  {
    key: 'full',
    title: 'Full Access (all pages)',
  },
  {
    key: 'dashboard',
    title: 'Dashboard',
  },
  {
    key: 'maps',
    title: 'Maps Page',
  },
  {
    key: 'risk_management',
    title: 'Risk Management Page',
  },
  {
    key: 'weather',
    title: 'Weather Page',
  },
  {
    key: 'prescriptions',
    title: 'Prescriptions Page',
  },
  {
    key: 'historical_insights',
    title: 'Historical Insights Page',
  },
  {
    key: 'markets',
    title: 'Markets Page',
  },
  {
    key: 'data_input',
    title: 'Data Inputs Portal',
    children: [
      {
        key: 'full',
        title: 'Full Access (all pages)',
      },
      {
        key: 'overview',
        title: 'Overview',
      },
      {
        key: 'farm_expenses',
        title: 'Farm Expenses',
      },
      {
        key: 'crop_insurance',
        title: 'Crop Insurances',
      },
      {
        key: 'budgets',
        title: 'Budgets',
      },
      {
        key: 'soil_samples',
        title: 'Soil Samples',
      },
      {
        key: 'aph_histories',
        title: 'APH Histories',
      },
      {
        key: 'partner_connections',
        title: 'Partner Connections',
      },
      {
        key: 'field_boundaries',
        title: 'Field Boundaries and Info',
      },
    ],
  },
];

export default function DataSharingPermissions({
  form,
}: {
  form: UseFormReturn<any, any, undefined>;
}) {
  const fullAccess = form.watch('full_access');
  const fullDataAggregation = form.watch('full_data_aggregation');
  const dataInputFullAccess = form.watch('data_input_full_access');
  const dataInputFullDataAggregation = form.watch(
    'data_input_full_data_aggregation'
  );

  useEffect(() => {
    if (fullAccess === true) {
      form.setValue('dashboard_access', true);
      form.setValue('maps_access', true);
      form.setValue('risk_management_access', true);
      form.setValue('weather_access', true);
      form.setValue('prescriptions_access', true);
      form.setValue('historical_insights_access', true);
      form.setValue('markets_access', true);
    }
  }, [fullAccess]);

  // if any of access is unchecked, uncheck full access
  useEffect(() => {
    if (
      !form.watch('dashboard_access') ||
      !form.watch('maps_access') ||
      !form.watch('risk_management_access') ||
      !form.watch('weather_access') ||
      !form.watch('prescriptions_access') ||
      !form.watch('historical_insights_access') ||
      !form.watch('markets_access')
    ) {
      form.setValue('full_access', false);
    }
  }, [
    form.watch('dashboard_access'),
    form.watch('maps_access'),
    form.watch('risk_management_access'),
    form.watch('weather_access'),
    form.watch('prescriptions_access'),
    form.watch('historical_insights_access'),
    form.watch('markets_access'),
  ]);

  useEffect(() => {
    if (fullDataAggregation === true) {
      form.setValue('dashboard_data_aggregation', true);
      form.setValue('maps_data_aggregation', true);
      form.setValue('risk_management_data_aggregation', true);
      form.setValue('weather_data_aggregation', true);
      form.setValue('prescriptions_data_aggregation', true);
      form.setValue('historical_insights_data_aggregation', true);
      form.setValue('markets_data_aggregation', true);
    }
  }, [fullDataAggregation]);

  // if any of data aggregation is unchecked, uncheck full data aggregation
  useEffect(() => {
    if (
      !form.watch('dashboard_data_aggregation') ||
      !form.watch('maps_data_aggregation') ||
      !form.watch('risk_management_data_aggregation') ||
      !form.watch('weather_data_aggregation') ||
      !form.watch('prescriptions_data_aggregation') ||
      !form.watch('historical_insights_data_aggregation') ||
      !form.watch('markets_data_aggregation')
    ) {
      form.setValue('full_data_aggregation', false);
    }
  }, [
    form.watch('dashboard_data_aggregation'),
    form.watch('maps_data_aggregation'),
    form.watch('risk_management_data_aggregation'),
    form.watch('weather_data_aggregation'),
    form.watch('prescriptions_data_aggregation'),
    form.watch('historical_insights_data_aggregation'),
    form.watch('markets_data_aggregation'),
  ]);

  useEffect(() => {
    if (dataInputFullAccess === true) {
      form.setValue('data_input_overview_access', true);
      form.setValue('data_input_farm_expenses_access', true);
      form.setValue('data_input_crop_insurance_access', true);
      form.setValue('data_input_budgets_access', true);
      form.setValue('data_input_soil_samples_access', true);
      form.setValue('data_input_aph_histories_access', true);
      form.setValue('data_input_partner_connections_access', true);
      form.setValue('data_input_field_boundaries_access', true);
    }
  }, [dataInputFullAccess]);

  // if any of data input access is unchecked, uncheck full data input access
  useEffect(() => {
    if (
      !form.watch('data_input_overview_access') ||
      !form.watch('data_input_farm_expenses_access') ||
      !form.watch('data_input_crop_insurance_access') ||
      !form.watch('data_input_budgets_access') ||
      !form.watch('data_input_soil_samples_access') ||
      !form.watch('data_input_aph_histories_access') ||
      !form.watch('data_input_partner_connections_access') ||
      !form.watch('data_input_field_boundaries_access')
    ) {
      form.setValue('data_input_full_access', false);
    }
  }, [
    form.watch('data_input_overview_access'),
    form.watch('data_input_farm_expenses_access'),
    form.watch('data_input_crop_insurance_access'),
    form.watch('data_input_budgets_access'),
    form.watch('data_input_soil_samples_access'),
    form.watch('data_input_aph_histories_access'),
    form.watch('data_input_partner_connections_access'),
    form.watch('data_input_field_boundaries_access'),
  ]);

  useEffect(() => {
    if (dataInputFullDataAggregation === true) {
      form.setValue('data_input_overview_data_aggregation', true);
      form.setValue('data_input_farm_expenses_data_aggregation', true);
      form.setValue('data_input_crop_insurance_data_aggregation', true);
      form.setValue('data_input_budgets_data_aggregation', true);
      form.setValue('data_input_soil_samples_data_aggregation', true);
      form.setValue('data_input_aph_histories_data_aggregation', true);
      form.setValue('data_input_partner_connections_data_aggregation', true);
      form.setValue('data_input_field_boundaries_data_aggregation', true);
    }
  }, [dataInputFullDataAggregation]);

  // if any of data input data aggregation is unchecked, uncheck full data input data aggregation
  useEffect(() => {
    if (
      !form.watch('data_input_overview_data_aggregation') ||
      !form.watch('data_input_farm_expenses_data_aggregation') ||
      !form.watch('data_input_crop_insurance_data_aggregation') ||
      !form.watch('data_input_budgets_data_aggregation') ||
      !form.watch('data_input_soil_samples_data_aggregation') ||
      !form.watch('data_input_aph_histories_data_aggregation') ||
      !form.watch('data_input_partner_connections_data_aggregation') ||
      !form.watch('data_input_field_boundaries_data_aggregation')
    ) {
      form.setValue('data_input_full_data_aggregation', false);
    }
  }, [
    form.watch('data_input_overview_data_aggregation'),
    form.watch('data_input_farm_expenses_data_aggregation'),
    form.watch('data_input_crop_insurance_data_aggregation'),
    form.watch('data_input_budgets_data_aggregation'),
    form.watch('data_input_soil_samples_data_aggregation'),
    form.watch('data_input_aph_histories_data_aggregation'),
    form.watch('data_input_partner_connections_data_aggregation'),
    form.watch('data_input_field_boundaries_data_aggregation'),
  ]);

  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard
        title="Set Your Data Sharing Permissions"
        className="relative"
      >
        <div>
          <p className="text-center text-xl-regular mb-6">
            These permissions can always be changed later on at any point in the
            settings
          </p>

          <div className="card shadow-lg !rounded-[10px]">
            <table className="w-full mb-6">
              <thead>
                <tr className="bg-base-1100">
                  <th className="rounded-tl-[10px] py-2"></th>
                  <th className="py-2">Allow Advisor Access</th>
                  <th className="rounded-tr-[10px] py-2">
                    Allow Advisor Data Aggregation
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissions.map((permission, index) => (
                  <tr key={index}>
                    <td
                      className={clsx('pl-8 py-2', {
                        'pt-4': index === 0,
                      })}
                    >
                      {permission.title}
                    </td>
                    {!permission.children && (
                      <>
                        <td
                          className={clsx('py-2', {
                            'pt-4': index === 0,
                          })}
                        >
                          <span className="flex justify-center">
                            <Checkbox
                              rounded
                              name={`${permission.key}_access`}
                            />
                          </span>
                        </td>
                        <td
                          className={clsx('py-2', {
                            'pt-4': index === 0,
                          })}
                        >
                          <span className="flex justify-center">
                            <Checkbox
                              rounded
                              name={`${permission.key}_data_aggregation`}
                            />
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                {permissions
                  .filter((p) => p.children)
                  .map((permission) =>
                    permission.children?.map((child, index) => (
                      <tr key={index}>
                        <td className={clsx('pl-16 py-2')}>{child.title}</td>
                        <td>
                          <span className="flex justify-center">
                            <Checkbox
                              rounded
                              name={`${permission.key}_${child.key}_access`}
                            />
                          </span>
                        </td>
                        <td className="flex justify-center">
                          <span className="flex justify-center">
                            <Checkbox
                              rounded
                              name={`${permission.key}_${child.key}_data_aggregation`}
                            />
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </div>
        </div>
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal !w-fit mx-auto mt-5"
          type="submit"
          loading={form.formState.isSubmitting}
        >
          Save
        </Button>
      </StyledCard>
    </div>
  );
}
