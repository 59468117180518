import { useState } from 'react';
import './index.css';
import clsx from 'clsx';

interface TooltipProps {
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  content: React.ReactNode;
}

export default function Tooltip({
  children,
  content,
  position = 'top',
}: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div
        onMouseEnter={toggleVisibility}
        onMouseLeave={toggleVisibility}
        className="tooltip"
      >
        {children}
        {!!content && isVisible && (
          <div
            className={clsx(
              'tooltip-content whitespace-nowrap text-xs-regular text-base-1100',
              position
            )}
          >
            {content}
          </div>
        )}
      </div>
    </>
  );
}
