import apiClient from './api-client';

export const getUserCropYearsApi = async (userId: string, crop: string) => {
  const response = await apiClient.get(
    `/history-insights/years?bucket=agrivar.historical.insights2&prefix=${userId}_${crop}`
  );
  return response.data;
};

export const getTableData = async (
  userId: string,
  crop: string,
  type: string,
  year: string
) => {
  const response = await apiClient.get(
    `/history-insights/table-data?year=${year}&userId=${userId}_2024&crop=${crop}&type=${type}`
  );
  return response.data;
};

export const getStudyNoteApi = async (pk: string, xIndex: string) => {
  const response = await apiClient.get(
    `/history-insights/custom-study?pk=${pk}&xIndex=${xIndex}&type=note`
  );
  return response.data;
};

export const createStudyApi = async (payload: any) => {
  const response = await apiClient.post(
    `/history-insights/custom-study`,
    payload
  );
  return response.data;
};

export const updateStudyApi = async (payload: any) => {
  const response = await apiClient.post(`/history-insights/custom-study`, {
    ...payload,
    func: 'update',
  });
  return response.data;
};

export const createNoteApi = async (payload: any) => {
  const response = await apiClient.post(`/history-insights/custom-study`, {
    ...payload,
    func: 'create_note',
    pk: payload.user_id_type_crop_year,
    note: payload.note,
  });
  return response.data;
};

export const getUserStudies = async (userId: string, year: string) => {
  const response = await apiClient.get(
    `/history-insights/custom-study?userId=${userId}&year=${year}`
  );
  console.log('response', response);
  return response.data;
};

export const deleteStudyApi = async (payload: any) => {
  console.log('deleting study payload', payload);
  const response = await apiClient.post(`/history-insights/custom-study`, {
    ...payload,
    func: 'delete',
  });
  return response.data;
};
