import React, { useMemo, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import CardHeader from '../../../components/commons/CardHeader';
import { mapParamsToTitles } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { useTableData } from '../../../hooks/map/use-map';
import {
  ArrowsPointingOutIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';

function RevenuePerAcre() {
  const user = useUser();
  const year = useYearFilter();
  const {
    selectedDate: date,
    setSelectedCrop,
    selectedCrop,
    setSelectedArea,
    selectedArea: crop,
  } = useMapContext();

  const { type: typeParam = '' } = useParams();
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop,
    type!,
    'crop',
    year!,
    date,
    'crop'
  );

  type DataType = {
    crop: string;
    x_bin: string;
    area: string;
  };

  const cropColors: { [key: string]: string } = {
    Soybeans: 'rgba(174, 154, 125, 1)',
    Corn: 'rgba(255, 183, 27, 1)',
    Other: 'rgba(244, 39, 39, 1)',
    Wheat: 'rgba(98,162,235,1)',
  };

  const labels: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          tableData?.data?.map((dataItem: DataType) => dataItem.x_bin as string)
        )
      ).sort((a: any, b: any): number => {
        let numA = parseInt(a.split('-')[0]);
        let numB = parseInt(b.split('-')[0]);
        return numA - numB;
      }) as string[],
    [tableData?.data]
  );

  const crops = useMemo(() => {
    if (!tableData?.data) return {};

    const cropData: { [key: string]: number[] } = {};

    tableData?.data.forEach((dataItem: DataType) => {
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!cropData[dataItem.crop]) {
        cropData[dataItem.crop] = Array(labels.length).fill(0);
      }

      const index = labels.indexOf(dataItem.x_bin);
      if (index !== -1) {
        cropData[dataItem.crop][index] = area;
      }
    });

    return cropData;
  }, [tableData?.data, labels]);

  // Get unique crop names from the data
  const uniqueCrops = useMemo(() => Object.keys(crops), [crops]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Filtered crops based on search
  const filteredCrops = useMemo(() => {
    return uniqueCrops.filter((cropName) =>
      cropName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueCrops, searchTerm]);

  // Paginated crops based on current page
  const paginatedCrops = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredCrops.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredCrops, currentPage]);

  // Set the first crop as the default selected crop
  const [selectedChartCrop, setSelectedChartCrop] = useState<string>(
    paginatedCrops[0] || ''
  );

  useEffect(() => {
    if (paginatedCrops.length > 0 && !selectedChartCrop) {
      setSelectedChartCrop(paginatedCrops[0]);
    }
  }, [paginatedCrops]);

  const datasets = useMemo(() => {
    if (!crops[selectedChartCrop]) return [];

    return [
      {
        label: selectedChartCrop,
        data: crops[selectedChartCrop],
        backgroundColor:
          cropColors[selectedChartCrop] || 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 25,
      },
    ];
  }, [crops, selectedChartCrop, cropColors]);

  const options: any = {
    indexAxis: 'x',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Revenue Per Acre ($)',
          font: { size: 20 },
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = `${context.dataset.label || ''}`;
            let value =
              context.raw !== null ? `${context.raw.toFixed(2)} Acres` : 'N/A';
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="p-0 h-full col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={`Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Crop`}
        />
        {selectedCrop && (
          <div
            className="card max-w-max flex gap-2 px-4 py-2 rounded-md"
            onClick={() => {
              setSelectedCrop('');
              setSelectedArea('all');
            }}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p>Show All Crops</p>
          </div>
        )}
      </div>

      <div className="h-[250px]">
        <Bar data={{ labels, datasets }} options={options} />
      </div>

      <div className=" flex flex-col justify-between">
        <div className="mt-4 flex flex-col gap-4">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search crops..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 w-full p-2 text-black border rounded"
          />
          <div className="flex gap-4">
            {paginatedCrops &&
              paginatedCrops.length > 0 &&
              paginatedCrops.map((cropName) => (
                <div
                  key={cropName}
                  className={`max-w-max cursor-pointer max-h-max px-4 flex py-2 rounded-md ${cropName === selectedChartCrop ? 'bg-gray-200' : 'bg-gray-100'}`}
                  onClick={() => {
                    setSelectedChartCrop(cropName);
                    setSelectedCrop(cropName);
                    setSelectedArea(cropName);
                  }}
                  style={{
                    backgroundColor:
                      cropColors[cropName] || 'rgba(255, 99, 132, 0.2)',
                  }}
                >
                  {cropName}
                </div>
              ))}
          </div>
        </div>
        {/* Pagination Controls */}
        <div className="flex items-center justify-between mt-4">
          <div
            onClick={() => {
              if (currentPage === 1) {
                return;
              }
              setCurrentPage((prev) => Math.max(prev - 1, 1));
            }}
          >
            <ArrowLeftCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
          <p>
            {currentPage}/{Math.ceil(filteredCrops.length / itemsPerPage)}
          </p>
          <div
            onClick={() => {
              if (currentPage * itemsPerPage >= filteredCrops.length) {
                return;
              }
              setCurrentPage((prev) => prev + 1);
            }}
          >
            <ArrowRightCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenuePerAcre;
