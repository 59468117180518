import { IconProps } from '../../types';

export default function DashedRoundedRectangleIcon({
  fill = '#F9FAFB',
}: IconProps) {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 40 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="3"
        width="32"
        height="20"
        rx="4"
        ry="4"
        stroke={fill}
        strokeWidth="4"
        strokeDasharray="10 3"
        fill="none"
      />
    </svg>
  );
}
