import { groupBy } from 'lodash';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useInsurances } from '../../../../hooks/insurance/use-insurance';
import Table, { TableConfigs } from '../../../commons/Table';
import TableLayout from '../../commons/TableLayout';

interface InsuranceListProps {}

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'aph',
      name: (
        <>
          <p>Actual Production History</p>
          <p className="text-xs-bold">(APH)</p>
        </>
      ),
    },
    {
      key: 'premium_per_acre',
      name: (
        <>
          <p>Premium</p>
          <p className="text-xs-bold">($ per acre)</p>
        </>
      ),
    },
    { key: 'field_name', name: 'Field Applied' },
    { key: 'expectedIndemnity', name: 'Expected Indemnity' },
    {
      key: 'probalibility',
      name: 'Probability of Indemnity Payment',
      unit: '%',
    },
  ],
};

const Crops = {
  Corn: {
    name: 'Corn Revenue Protection (RP) 75% Coverage',
    tooltip: 'demo',
  },
  Soybeans: {
    name: 'Soybeans Revenue Protection (RP) 75% Coverage',
    tooltip: 'demo',
  },
  Wheat: {
    name: 'Wheat Revenue Protection (RP) 75% Coverage',
    tooltip: 'demo',
  },
};

export default function InsuranceList(props: InsuranceListProps) {
  const user = useUser();
  const year = useYearFilter();
  const { data, isFetching } = useInsurances({
    userId: user?.id ?? '',
    year: year ?? '',
  });
  const dataGroupByCrop = groupBy(data, 'crop');
  return (
    <TableLayout
      title="Current Insurance Coverage"
      actions={{
        edit: () => {},
        delete: () => {},
      }}
    >
      {Object.entries(Crops).map(([crop, { name, tooltip }]) => (
        <Table
          key={crop}
          configs={tableConfigs}
          data={dataGroupByCrop[crop]}
          title={{
            name: name,
            tooltip: tooltip,
          }}
          loading={isFetching}
        />
      ))}
    </TableLayout>
  );
}
