import { useMemo } from 'react';
import LineChart from '../../../components/commons/charts/LineChart';
import { ForecastChartConfig } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getFarmSatelliteForecastChartData,
  useFarmSatelliteForecast,
} from '../../../hooks/farm/use-farm-forecast';

interface ForecastSatelliteChartProps {
  cropName: string;
}

export default function ForecastSatelliteChart({
  cropName,
}: ForecastSatelliteChartProps) {
  const user = useUser();
  const year = useYearFilter();
  const userIdYear = useMemo(
    () => (user?.id && year ? `${user?.id}_${year}` : ''),
    [user?.id, year]
  );

  const { data } = useFarmSatelliteForecast(userIdYear, cropName);
  const chartData = getFarmSatelliteForecastChartData(data);

  const estimatedData: any[] = [];
  const forecastedData: any[] = [];

  enum DataType {
    Estimated = 1,
    Forecasted = 2,
  }

  Object.entries(chartData).forEach(([date, dataObject]) => {
    const { dataType, numData } = dataObject;
    const {
      estimated,
      'forecasted_0.1': forecasted_0_1,
      'forecasted_0.5': forecasted_0_5,
      'forecasted_0.9': forecasted_0_9,
    } = numData;

    if (dataType === 'estimated') {
      estimatedData.push({ x: date, y: estimated });
    } else if (dataType === 'forecasted') {
      forecastedData.push(
        { x: date, y: forecasted_0_1 },
        { x: date, y: forecasted_0_5 },
        { x: date, y: forecasted_0_9 }
      );
    }
  });

  const datasets = [
    {
      label: 'Estimated',
      data: estimatedData,
      backgroundColor: 'rgba(255,183,27,1)',
      borderColor: 'rgba(255,183,27,1)',
      pointBackgroundColor: 'rgba(255,183,27,1)',
      fill: false,
    },
    {
      label: 'Forecasted 10%',
      data: forecastedData.filter((_, index) => index % 3 === 0),
      backgroundColor: 'rgba(174, 155, 126, .5)',
      borderColor: 'rgba(255,183,27,1)',
      pointBackgroundColor: 'rgba(255,183,27,1)',
      fill: '+1',
      borderDash: [5, 5],
    },

    {
      label: 'Forecasted 50%',
      data: forecastedData.filter((_, index) => index % 3 === 1),
      backgroundColor: 'rgba(174, 155, 126, .5)',
      borderColor: 'rgba(255,183,27,1)',
      pointBackgroundColor: 'rgba(255,183,27,1)',
      fill: '+1',
      borderDash: [5, 5],
    },

    {
      label: 'Forecasted 90%',
      data: forecastedData.filter((_, index) => index % 3 === 2),
      backgroundColor: 'rgba(174, 155, 126, .5)',
      borderColor: 'rgba(255,183,27,1)',
      pointBackgroundColor: 'rgba(255,183,27,1)',

      fill: false,
      borderDash: [5, 5],
    },
  ];

  return (
    <LineChart
      datasets={datasets}
      labels={Object.keys(chartData)}
      yTicksCallback={(value) => {
        if (typeof value !== 'number') {
          console.error(
            `yTicksCallback received an unexpected type: ${typeof value}`
          );
          return;
        }
        return value.toFixed(1);
      }}
      stepSize={500000}
      {...ForecastChartConfig}
    />
  );
}
