import { IconProps } from '../../types';
export default function PhoneIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4308 10.6833C14.4769 10.7585 15.3997 11.2667 15.9706 12.0687C16.5619 12.8992 16.7401 13.9376 16.4687 14.9507C16.4154 15.1182 16.2843 15.2978 16.1411 15.3997C15.9406 15.5424 15.679 15.5992 15.4134 15.5293C14.9804 15.406 14.7032 14.9561 14.8348 14.474C14.9663 13.992 14.9017 13.4769 14.5959 13.0473C14.3105 12.6464 13.8449 12.4168 13.3464 12.3833C12.8478 12.3498 12.5134 11.9406 12.5386 11.4911C12.5925 11.0212 12.9813 10.6581 13.4308 10.6833ZM13.6691 7.32021C15.7123 7.4624 17.5375 8.45022 18.7201 10.1113C19.9026 11.7725 20.259 13.8492 19.7042 15.7977C19.6221 15.9856 19.5197 16.1448 19.3479 16.2671C19.1474 16.4098 18.9144 16.4463 18.6489 16.3764C18.1872 16.2735 17.9387 15.8031 18.0619 15.3701C18.4648 13.8749 18.1731 12.3133 17.3167 11.1104C16.44 9.87887 15.0803 9.12065 13.5356 9.01195C13.0657 8.95807 12.7026 8.56925 12.7565 8.09935C12.7817 7.64983 13.1706 7.28672 13.6691 7.32021ZM13.9074 3.95712C16.9477 4.16628 19.6753 5.63369 21.4695 8.15402C23.2434 10.6457 23.7779 13.7607 22.96 16.6734C22.8984 16.8899 22.7959 17.0491 22.6527 17.151C22.4522 17.2937 22.1906 17.3505 21.9251 17.2807C21.4921 17.1574 21.2149 16.7074 21.3178 16.2457C21.9837 13.7865 21.5424 11.1661 20.0948 9.13269C18.6268 7.07059 16.3157 5.82453 13.7739 5.64886C13.304 5.59498 12.9695 5.18577 12.9948 4.73626C13.0487 4.26636 13.4375 3.90325 13.9074 3.95712ZM9.47895 13.1945L7.85908 14.1319C8.42805 16.1436 9.67177 17.8906 11.3863 19.0867L12.8023 17.8629C13.0683 17.6303 13.5057 17.5779 13.8242 17.7828L16.4819 19.5157C16.8494 19.7288 16.9713 20.2031 16.8358 20.5584L15.7446 23.1477C15.6092 23.5031 15.1961 23.7108 14.8039 23.6448C8.08692 22.5144 3.54022 16.1276 4.67066 9.41067C4.73667 9.01843 5.06821 8.6961 5.44831 8.68444L8.25217 8.50089C8.63226 8.48923 9.04052 8.75961 9.12159 9.1766L9.88913 12.2552C9.97845 12.6232 9.78574 13.0193 9.47895 13.1945Z"
        fill={fill}
      />
    </svg>
  );
}
