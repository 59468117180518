import { useMemo } from 'react';
import CropIcon from '../../../components/commons/CropIcon';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useFieldRevenue } from '../../../hooks/field/use-field-revenue';
import { useFieldYield } from '../../../hooks/field/use-field-yield';
import { convertToFloat } from '../../../utils';

export const useFieldPerformance = (userId: string, year: string) => {
  const { data: fields, ...rest } = useFieldPolygonsByYear(userId, year);
  const { data: yieldData } = useFieldYield(userId, year);
  const { data: revenueData } = useFieldRevenue(userId, year);

  const fieldsList = useMemo(() => {
    return (
      fields
        ?.map((polygon) => {
          return {
            user_id: polygon.user_id2,
            crop: polygon.crop2,
            _crop: polygon.crop,
            crop_year: polygon.crop_year,
            field: polygon.field,
            field_index: polygon.field_index,
            user_id_field_index: `${polygon.user_id}-${polygon.field_index}`,
          };
        })
        .sort((a, b) => parseInt(b.field_index) - parseInt(a.field_index)) ?? []
    );
  }, [fields]);

  const fieldPerformance = useMemo(() => {
    return fieldsList?.map((field) => {
      const fieldYield = yieldData?.find(
        (yd) => yd.user_id_field_index === field.user_id_field_index
      );
      const fieldRevenue = revenueData?.find(
        (revenue) => revenue.user_id_field_index === field.user_id_field_index
      );

      return {
        crop: field.crop,
        field: (
          <span className="flex items-center space-x-2 justify-start">
            <CropIcon cropName={field.crop} colored />
            <p className="flex-1">{field.field}</p>
          </span>
        ),
        revenue: convertToFloat(fieldRevenue?.revenue),
        netIncome: convertToFloat(fieldRevenue?.net_income),
        revenue1DayChange: convertToFloat(fieldRevenue?.revenue_chg),
        netIncome1DayChange: convertToFloat(fieldRevenue?.net_income_chg),
        revenue14DayChange: convertToFloat(fieldRevenue?.revenue_chg_14day),
        netIncome14DayChange: convertToFloat(
          fieldRevenue?.net_income_chg_14day
        ),
        revenuePerAcre: convertToFloat(fieldRevenue?.revenue_per_acre),
        netIncomePerAcre: convertToFloat(fieldRevenue?.net_income_per_acre),
        revenuePerAcre1DayChange: convertToFloat(
          fieldRevenue?.revenue_per_acre_chg
        ),
        netIncomePerAcre1DayChange: convertToFloat(
          fieldRevenue?.net_income_per_acre_chg
        ),
        var: convertToFloat(fieldRevenue?.var),
        var1DayChange: convertToFloat(fieldRevenue?.var_chg_1day),
        hedgedPrice: convertToFloat(fieldRevenue?.hedged_price),
        percentHedged: convertToFloat(fieldRevenue?.percent_hedged),
        yield: convertToFloat(fieldYield?.yield),
        yield14DayChange: convertToFloat(fieldYield?.yield_chg14),
      };
    });
  }, [fieldsList, yieldData, revenueData]);

  return { data: fieldPerformance || [], ...rest };
};
