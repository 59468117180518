import { useQuery } from '@tanstack/react-query';
import {
  getFieldPolygonsApi,
  getFieldPolygonsByYearApi,
} from '../../api/field-api';
import { FieldPolygonRes } from '../../types';

export const useFieldPolygons = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['fields/polygons/all', userId],
    queryFn: () => getFieldPolygonsApi(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.data as FieldPolygonRes[] | [],
    ...rest,
  };
};

export const useFieldPolygonsByYear = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['fields/polygons/year', userId, year],
    queryFn: () => getFieldPolygonsByYearApi(userId, year),
    enabled: !!userId && !!year,
  });

  return {
    data: data?.data as FieldPolygonRes[] | [],
    ...rest,
  };
};
