import RiskManagement from '../../pages/dashboard/risk-management';

const riskManagementRoutes = [
  {
    path: 'risk-management',
    element: <RiskManagement />,
  },
];

export default riskManagementRoutes;
