import apiClient from './api-client';

export const getFarmRevenuesApi = (userId: string, year: string) => {
  return apiClient.get(`/farm/revenues?userId=${userId}&year=${year}`);
};

export const getFarmYieldApi = (userId: string, year: string, date = '') => {
  const query = date
    ? `?userId=${userId}&year=${year}&date=${date}`
    : `?userId=${userId}&year=${year}`;
  return apiClient.get(`/farm/yield${query}`);
};

export const getFarmRevenues14DaysChangeApi = (
  userId: string,
  year: string
) => {
  return apiClient.get(
    `/farm/revenues/14days-change?userId=${userId}&year=${year}`
  );
};

export const getFarmRevenueForecastApi = (userIdYear: string, crop: string) => {
  return apiClient.get(
    `/farm/revenue/forecast?user_id=${userIdYear}&crop=${crop}`
  );
};

export const getFarmYieldForecastApi = (userIdYear: string, crop: string) => {
  return apiClient.get(
    `/farm/yield/forecast?user_id=${userIdYear}&crop=${crop}`
  );
};

export const getFarmSatelliteForecastApi = (
  userIdYear: string,
  crop: string
) => {
  return apiClient.get(
    `/farm/satellite/forecast?user_id=${userIdYear}&crop=${crop}`
  );
};
