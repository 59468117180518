import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { theme } from 'twin.macro';
import ChartIcon from '../../../assets/icons/ChartIcon';
import DollarIcon from '../../../assets/icons/DollarIcon';
import SatelliteIcon from '../../../assets/icons/SatelliteIcon';
import PageHeader from '../../../components/commons/PageHeader';
import Select from '../../../components/commons/Select';
import { SupportedCrops } from '../../../constants';
import ForecastExpectedRevenueChart from './ForecastExpectedRevenueChart';
import ForecastExpectedYieldChart from './ForecastExpectedYieldChart';
import ForecastSatelliteChart from './ForecastSatelliteChart';

const tabs = [
  {
    label: 'Expected Revenue',
    value: 'expected-revenue',
    icon: DollarIcon,
  },
  {
    label: 'Expected Yield',
    value: 'expected-yield',
    icon: ChartIcon,
  },
  {
    label: 'Satellite',
    value: 'satellite',
    icon: SatelliteIcon,
  },
];

const options = [
  {
    label: 'All',
    value: 'false',
  },
  ...SupportedCrops.map((crop) => ({ label: crop, value: crop })),
];

function ForecastsPage() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [crop, setCrop] = useState(options[0].value);

  return (
    <div className="space-y-8">
      <PageHeader title="Forecasts" />

      <div className="flex flex-col items-center space-y-10">
        <h4 className="display-lg-regular">Expected Revenue</h4>

        <TabGroup
          className="flex flex-col items-center space-y-6"
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <TabList className="card px-6 py-3 max-w-[760px] w-fit flex justify-between whitespace-nowrap space-x-2 ">
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                className={clsx(
                  'flex items-center space-x-2 display-sm-regular p-4',
                  'text-base-900',
                  'data-[selected]:text-base-000 data-[hover]:text-base-000'
                )}
              >
                {({ selected, hover }) => (
                  <>
                    <tab.icon
                      fill={
                        selected || hover
                          ? theme`colors.base.000`
                          : theme`colors.base.900`
                      }
                    />
                    <p>{tab.label}</p>
                  </>
                )}
              </Tab>
            ))}
          </TabList>

          {selectedIndex !== 3 && (
            <Select
              size="sm"
              name="crop"
              defaultValue={crop}
              className="text-md-regular"
              options={options}
              onChange={(e) => setCrop(e.target.value)}
            />
          )}

          <TabPanels>
            <TabPanel>
              <div className="card card-elevated p-6">
                <ForecastExpectedRevenueChart cropName={crop} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="card card-elevated p-6">
                <ForecastExpectedYieldChart cropName={crop} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="card card-elevated p-6">
                <ForecastSatelliteChart cropName={crop} />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
}

export default ForecastsPage;
