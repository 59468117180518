import { PlusCircleIcon } from '@heroicons/react/24/solid';

export const HedgeCropOptions = [
  { label: 'Corn', value: 'Corn' },
  { label: 'Soybeans', value: 'Soybeans' },
  { label: 'Wheat', value: 'Wheat' },
];

export const ContractOptions = [
  { label: 'CBOT Corn', value: 'CBOT Corn' },
  { label: 'CBOT Soybeans', value: 'CBOT Soybeans' },
  { label: 'CBOT Wheat', value: 'CBOT Wheat' },
  { label: 'KCHRW Wheat', value: 'KCHRW Wheat' },
  { label: 'MNHRS Wheat', value: 'MNHRS Wheat' },
  { label: 'CBOT Soybean Oil', value: 'CBOT Soybean Oil' },
  { label: 'ICE Feed Wheat', value: 'ICE Feed Wheat' },
  { label: 'ICE Canola', value: 'ICE Canola' },
  { label: 'Other', value: 'Other' },
];

export const MonthOptions = [
  {
    label: 'Jan',
    value: 'Jan',
  },
  {
    label: 'Feb',
    value: 'Feb',
  },
  {
    label: 'Mar',
    value: 'Mar',
  },
  {
    label: 'Apr',
    value: 'Apr',
  },
  {
    label: 'May',
    value: 'May',
  },
  {
    label: 'Jun',
    value: 'Jun',
  },
  {
    label: 'Jul',
    value: 'Jul',
  },
  {
    label: 'Aug',
    value: 'Aug',
  },
  {
    label: 'Sep',
    value: 'Sep',
  },
  {
    label: 'Oct',
    value: 'Oct',
  },
  {
    label: 'Nov',
    value: 'Nov',
  },
  {
    label: 'Dec',
    value: 'Dec',
  },
];

export const HedgeTypes = {
  Futures: 'Futures',
  Put: 'Put',
  Call: 'Call',
  Cash: 'Cash',
  Basis: 'Basis',
};

export const LeaseTypes = [
  {
    label: 'Cash Lease',
    value: 'Cash',
    icon: PlusCircleIcon,
  },
  {
    label: 'Flex Lease',
    value: 'Flex',
    icon: PlusCircleIcon,
  },
  {
    label: 'Share Lease',
    value: 'Share',
    icon: PlusCircleIcon,
  },
  {
    label: 'CRP EQUIP Lease',
    value: 'CRP/EQIP',
    icon: PlusCircleIcon,
  },
];

export const LessorOptions = [
  {
    label: 'Lessor',
    value: 'Lessor',
  },
  {
    label: 'Lessee',
    value: 'Lessee',
  },
];

export const StorageTypes = [
  {
    label: 'On-Farm',
    value: 'On-Farm',
  },
  {
    label: 'Commercial',
    value: 'Commercial',
  },
];
