import { useQuery } from '@tanstack/react-query';
import { getSeedVarietiesApi } from '../../api/variety-api';
import { SeedVarietiesRes } from '../../types';

export const useSeedVarieties = (year: string, crop: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['variety/seed'],
    queryFn: () => getSeedVarietiesApi(year, crop),
    enabled: !!year && !!crop,
  });

  return { data: data?.data as SeedVarietiesRes[] | [], ...rest };
};
