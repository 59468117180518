import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import Button from '../../../components/commons/Button';
import { useUser, useControlledUser } from '../../../contexts/auth-context';
import {
  authUrl as ClimateAuthUrl,
  useCheckConnectedUserClimate,
} from '../../../hooks/climate/use-climate';
import {
  useCheckConnectedUserJD,
  useGetJDAuthorizationEndpoint,
} from '../../../hooks/john-deere/use-john-deere';

export default function PartnetConnection() {
  const user = useUser();
  const controlledUser = useControlledUser();
  const { isConnected: isJDConnected, isLoading: isJDLoading } =
    useCheckConnectedUserJD(user?.id || '');
  const { data: JDAuthUrl } = useGetJDAuthorizationEndpoint();

  const { isConnected: isClimateConnected, isLoading: isClimateLoading } =
    useCheckConnectedUserClimate(user?.id || '');

  const partners = useMemo(
    () => [
      {
        name: 'John Deere Ops Center',
        isConnected: isJDConnected,
        isLoading: isJDLoading,
        authUrl: JDAuthUrl,
      },
      {
        name: 'Climate Fieldview',
        isConnected: isClimateConnected,
        isLoading: isClimateLoading,
        authUrl: ClimateAuthUrl,
      },
    ],
    [
      isJDConnected,
      isJDLoading,
      JDAuthUrl,
      isClimateConnected,
      isClimateLoading,
    ]
  );

  useEffect(() => {
    if (user?.id) {
      localStorage.setItem('is_admin', controlledUser ? '1' : '0');
      localStorage.setItem('JD_user_id', user?.id || '');
      localStorage.setItem('climate_user_id', user?.id || '');
    }
  }, [user, controlledUser]);

  return (
    <div className="space-y-4">
      {partners?.map((partner, index) => (
        <div
          key={index}
          className="flex whitespace-nowrap justify-between items-center rounded-lg bg-base-1000 border border-base-900"
        >
          <p className="p-2 pl-3 text-md-bold w-full">{partner.name}</p>
          <p
            className={clsx(
              'min-w-[110px] border-l border-r border-base-900 text-md-bold self-stretch flex items-center justify-center',
              partner.isConnected ? 'text-primary' : 'text-red'
            )}
          >
            {partner.isLoading ? (
              <SpinningIcon />
            ) : partner.isConnected ? (
              'Live'
            ) : (
              'Disconnected'
            )}
          </p>
          <span className="p-2 w-full flex justify-end">
            <Button
              disabled={partner.isConnected || partner.isLoading}
              color={partner.isConnected ? 'default' : 'primary'}
              className="!w-fit"
              loading={partner.isLoading}
              onClick={() => {
                window.location.href = partner.authUrl;
              }}
            >
              Reconnect
            </Button>
          </span>
        </div>
      ))}
    </div>
  );
}
