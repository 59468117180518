import { IconProps } from '../../types';

const SoilSampleIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 16.6125C5.5875 16.7625 5.2125 16.8 4.8 16.8C2.775 16.8 1.2 15.1875 1.2 13.2V2.4C0.525 2.4 0 1.875 0 1.2C0 0.5625 0.525 0 1.2 0H8.4C9.0375 0 9.6 0.5625 9.6 1.2C9.6 1.875 9.0375 2.4 8.4 2.4V11.625L6.5625 14.625C6.1875 15.225 6 15.9 6 16.6125ZM3.6 6H6V2.4H3.6V6ZM19.2 0C19.8375 0 20.4 0.5625 20.4 1.2C20.4 1.875 19.8375 2.4 19.2 2.4V8.0625L23.5875 15.2625C23.85 15.675 24 16.125 24 16.6125C24 18.0375 22.8 19.2 21.375 19.2H9.7875C8.3625 19.2 7.1625 18.0375 7.1625 16.6125C7.1625 16.125 7.3125 15.675 7.575 15.2625L11.9625 8.0625V2.4C11.325 2.4 10.7625 1.875 10.7625 1.2C10.7625 0.5625 11.325 0 11.9625 0H19.2ZM14.4 2.4V8.4C14.4 8.625 14.325 8.85 14.2125 9.0375L12.375 12H18.7875L16.95 9.0375C16.8375 8.85 16.8 8.625 16.8 8.4V2.4H14.4Z"
        fill={fill}
      />
    </svg>
  );
};

export default SoilSampleIcon;
