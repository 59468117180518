import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AgrivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import { useVerifySMS } from '../../../hooks/auth/use-verify-sms';
import StyledCard from '../../../components/main/StyledCard';

const validateSchema = yup.object({
  code: yup.string().required().label('Code'),
});

export default function VerifySMSPage() {
  const { verify, isLoading } = useVerifySMS();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      code: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validateSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const handleVerify = async ({ code }: { code: string }) => {
    await verify(code);
  };

  const disabled = !isValid || isLoading;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        <div className="max-w-screen-sm w-full">
          <img
            src={AgrivarLogo}
            alt="Agrivar"
            width={'70%'}
            className="mx-auto mb-4"
          />
          <StyledCard title="Verify SMS Code">
            <form
              className="max-w-screen-sm flex flex-col w-full space-y-8"
              onSubmit={handleSubmit(handleVerify)}
            >
              <TextInput
                label="Code*"
                name="code"
                placeholder="Enter sms code"
                className="!bg-base-000 !text-block-fill"
              />
              <div className="space-y-5">
                <Button
                  type="submit"
                  disabled={disabled}
                  color="primary"
                  loading={isLoading}
                  className="!font-normal !rounded-md"
                  size="lg"
                >
                  Verify
                </Button>
                <Button
                  onClick={() => navigate('/login')}
                  className="!font-normal !rounded-md"
                  size="lg"
                >
                  Return to Login
                </Button>
              </div>
            </form>
          </StyledCard>
        </div>
      </div>
    </FormProvider>
  );
}
