import { cloneElement, useState } from 'react';
import { theme } from 'twin.macro';
import Tooltip from '../../commons/tooltip';

export default function ActionButton({
  action,
  active,
}: {
  action: {
    action: () => void;
    icon: React.ReactElement;
    name: string;
    tooltip?: string;
    activeTooltip?: string;
  };
  active?: boolean;
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <Tooltip
      position="right"
      content={active ? action.activeTooltip : action.tooltip}
    >
      <button
        className="mapbox-custom-action-button"
        onClick={action.action}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {cloneElement(action.icon, {
          fill:
            hovered || active ? theme`colors.primary` : theme`colors.base.800`,
        })}
      </button>
    </Tooltip>
  );
}
