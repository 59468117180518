import {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import clsx from 'clsx';
import { BBox, Feature, FeatureCollection } from 'geojson';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  LngLat,
  LngLatBoundsLike,
  Map,
  MapMouseEvent,
  MapRef,
  Popup,
} from 'react-map-gl';
import CircleIcon from '../../../assets/icons/CircleIcon';
import MapTrashIcon from '../../../assets/icons/MapTrashIcon';
import MapZoomInIcon from '../../../assets/icons/MapZoomInIcon';
import MapZoomOutIcon from '../../../assets/icons/MapZoomOutIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import SquareIcon from '../../../assets/icons/SquareIcon';
import { DEFAULT_CENTER } from '../../../constants';
import { EnhancedFieldPolygon } from '../../../types';
import Button from '../../commons/Button';
import '../../mapbox-draw/index.css';
import DrawControl from './DrawControl';

interface FIBMapProps {
  features: FeatureCollection[];
  selectedField?: EnhancedFieldPolygon;
  onCreateFeature: (event: DrawCreateEvent) => void;
  onUpdateFeature: (event: DrawUpdateEvent) => void;
  onSelectFeature: (feature?: Feature) => void;
  drawRef: React.MutableRefObject<MapboxDraw | null>;
  drawMode: string;
  setDrawMode: (mode: string) => void;
  onSubmitByDraw: (values: any) => void;
  isPendingSaveDraw: boolean;
  form: any;
  activeFeature: Feature;
  onDeleteFeature: (event?: DrawDeleteEvent) => void;
  loading?: boolean;
}

export default function FIBMap({
  features,
  selectedField,
  onCreateFeature,
  onSelectFeature,
  drawRef,
  setDrawMode,
  drawMode,
  onSubmitByDraw,
  isPendingSaveDraw,
  form,
  activeFeature,
  onDeleteFeature,
  onUpdateFeature,
  loading,
}: FIBMapProps) {
  const map = useRef<MapRef>(null);

  const [drawing, setDrawing] = useState<boolean>(false);
  const [selectedOnSelectionChange, setSelectedOnSelectionChange] =
    useState<any>();
  const [selectedOnClick, setSelectedOnClick] = useState<any>();

  /*
  click point => onClick - undefined; selection - has
  click polygon => onClick - has; selection - has
  click outside => both undefined
  */

  useEffect(() => {
    const idOnClick = selectedOnClick?.id;
    const idOnSelection = selectedOnSelectionChange?.id;
    if (!idOnClick && !idOnSelection) {
      //outside
      onSelectFeature(undefined);
    } else if (!idOnClick && idOnSelection) {
      // point
      onSelectFeature(selectedOnSelectionChange);
    } else if (idOnClick && idOnSelection && idOnClick === idOnSelection) {
      // polygon
      onSelectFeature(selectedOnSelectionChange);
    }
  }, [selectedOnClick, selectedOnSelectionChange]);

  const controls = [
    {
      name: 'draw_polygon',
      icon: <PolygonIcon />,
      action: () => {
        setDrawing(false);
        setDrawMode('draw_polygon');
        drawRef.current?.changeMode('draw_polygon');
      },
      tooltip: 'Select The Polygon Draw Tool',
      activeTooltip: 'Polygon Selected. Click on the map to start drawing',
    },
    {
      name: 'draw_rectangle',
      icon: <SquareIcon />,
      action: () => {
        setDrawing(false);
        setDrawMode('draw_rectangle');
        drawRef.current?.changeMode('draw_rectangle');
      },
      tooltip: 'Select The Rectangle Draw Tool',
      activeTooltip: 'Rectangle Selected. Click on the map to start drawing',
    },
    {
      name: 'draw_circle',
      icon: <CircleIcon />,
      action: () => {
        setDrawing(false);
        setDrawMode('draw_circle');
        drawRef.current?.changeMode('draw_circle');
      },
      tooltip: 'Select The Circle Draw Tool',
      activeTooltip: 'Circle Selected. Click on the map to start drawing',
    },
    {
      name: 'zoom_in',
      icon: <MapZoomInIcon />,
      action: () => {
        map.current?.zoomIn();
      },
    },
    {
      name: 'zoom_out',
      icon: <MapZoomOutIcon />,
      action: () => {
        map.current?.zoomOut();
      },
    },
    {
      name: 'trash',
      icon: <MapTrashIcon />,
      action: () => {
        drawRef.current?.trash();
      },
    },
  ];

  // Handle keyboards
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        drawRef.current?.trash();
      }
    };

    // Attach event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  // Handle on create feature
  const handleCreate = (event: DrawCreateEvent) => {
    setDrawMode('simple_select');
    setDrawing(false);
    drawRef.current?.add(event.features[0]);
    // @ts-ignore
    onCreateFeature({ features: event.features });
    const centroid = turf.centroid(event.features[0].geometry);
    // @ts-ignore
    setSavePopup({
      lat: centroid.geometry.coordinates[1],
      lng: centroid.geometry.coordinates[0],
    });
  };

  // Handle update feature
  const handleUpdate = (event: DrawUpdateEvent) => {
    onUpdateFeature(event);
  };

  const handleDelete = (event: DrawDeleteEvent) => {
    onDeleteFeature(event);
  };

  // Handle on click
  const clickCount = useRef(0); // to show popup on first click position
  useEffect(() => {
    clickCount.current = 0;
  }, [drawMode]);

  const handleClick = (event: MapMouseEvent) => {
    // Handle show instruction popup
    clickCount.current += 1;
    if (clickCount.current === 1) {
      setStaticPopup(event.lngLat);
    }

    const featureIds = drawRef.current?.getFeatureIdsAt(event.point);
    const featureId = drawRef.current?.get(featureIds?.[0] || '');
    setSelectedOnClick(featureId);
    if (!featureId) {
      setDrawing(true);
    }
  };

  // Handle selection change
  const handleSelectionChange = (event: DrawSelectionChangeEvent) => {
    if (!event.points.length) {
      // dont update when click point
      setSelectedOnSelectionChange(event.features[0]);
    }
  };

  // Popup
  const [savePopup, setSavePopup] = useState<LngLat>();
  const [mouseMovePopup, setMouseMovePopup] = useState<LngLat>();
  const [staticPopup, setStaticPopup] = useState<LngLat>();
  const instructPopupContent = useMemo(() => {
    if (drawMode === 'simple_select') return '';
    if (!drawing && drawMode !== 'simple_select') {
      return 'Click to start drawing shape';
    }
    if (drawing) {
      switch (drawMode) {
        case 'draw_polygon':
          return 'Click first point again to close this polygon boundary shape';
        case 'draw_rectangle':
          return 'Drag to enlarge your rectangle boundary shape';
        case 'draw_circle':
          return 'Drag to enlarge your circle boundary shape';
        default:
          return '';
      }
    }
  }, [drawMode, drawing]);

  // Clear save popup
  useEffect(() => {
    if (!activeFeature?.id) {
      setSavePopup(undefined);
    }
  }, [activeFeature?.id]);

  // Handle mouse move
  const handleMouseMove = (event: MapMouseEvent) => {
    setMouseMovePopup(event.lngLat);
  };

  // Render features
  useEffect(() => {
    if (map.current && drawRef.current && features?.length > 0) {
      const validFeatures = features.filter(
        (feat: any) => !!feat?.features?.[0]?.geometry?.coordinates?.length
      );
      validFeatures.forEach((feat) => drawRef.current?.add(feat));
    }
  }, [features]);

  // Selected field
  useEffect(() => {
    if (map.current && drawRef.current && selectedField?.user_id_field_index) {
      drawRef.current.changeMode('simple_select' as any, {
        featureIds: [selectedField?.user_id_field_index],
      });
    }
  }, [selectedField]);

  // Handle fit bounds
  const allFieldBounds: BBox | undefined = useMemo(() => {
    if (features?.length) {
      const bboxes = features
        .filter(
          (feat: any) => !!feat?.features?.[0]?.geometry?.coordinates?.length
        )
        .map((feat: any) => (feat.bbox ? feat.bbox : turf.bbox(feat)));
      const lng1 = Math.min(...bboxes.map((bbox: any) => bbox[0]));
      const lat1 = Math.min(...bboxes.map((bbox: any) => bbox[1]));
      const lng2 = Math.max(...bboxes.map((bbox: any) => bbox[2]));
      const lat2 = Math.max(...bboxes.map((bbox: any) => bbox[3]));
      return [lng1, lat1, lng2, lat2];
    }
  }, [features]);

  const selectedFieldBounds = useMemo(() => {
    if (selectedField?.hasBound) {
      return turf.bbox(selectedField?.features[0]);
    }
  }, [selectedField]);

  useEffect(() => {
    const boundsToFit = selectedFieldBounds
      ? selectedFieldBounds
      : allFieldBounds;
    if (map.current && drawRef.current && boundsToFit) {
      const [lng1, lat1, lng2, lat2] = boundsToFit;
      const lngLatBounds: LngLatBoundsLike = [
        [lng1, lat1],
        [lng2, lat2],
      ];
      map.current.fitBounds(lngLatBounds, {
        padding: 100,
        duration: 500,
      });
    }
  }, [allFieldBounds, drawRef, selectedFieldBounds]);

  const field = form.watch('field');
  const crop = form.watch('crop');
  const yearValue = form.watch('year');
  const isValid = !!field && !!crop && !!yearValue;

  return (
    <div className="h-[468px] rounded-2xl relative overflow-hidden">
      {loading && (
        <div
          className={clsx(
            'absolute w-full h-full z-10 flex items-center justify-center bg-white/30'
          )}
        >
          <SpinningIcon />
        </div>
      )}
      <Map
        initialViewState={{
          longitude: DEFAULT_CENTER.lon,
          latitude: DEFAULT_CENTER.lat,
          zoom: 12,
        }}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}
        ref={map}
      >
        {mouseMovePopup && instructPopupContent && !drawing && (
          //@ts-ignore
          <Popup
            anchor="left"
            longitude={mouseMovePopup?.lng}
            latitude={mouseMovePopup?.lat}
            closeButton={false}
            closeOnClick={false}
            offset={{
              left: [25, -20],
            }}
          >
            <p className="text-base-1100">{instructPopupContent}</p>
          </Popup>
        )}
        {staticPopup && instructPopupContent && drawing && (
          //@ts-ignore
          <Popup
            longitude={staticPopup?.lng}
            latitude={staticPopup?.lat}
            closeButton={false}
            closeOnClick={false}
            offset={{
              top: [0, 30],
              bottom: [0, -50],
            }}
          >
            <p className="text-base-1100">{instructPopupContent}</p>
          </Popup>
        )}
        {savePopup && (
          //@ts-ignore
          <Popup
            anchor="bottom"
            longitude={savePopup?.lng}
            latitude={savePopup?.lat}
            closeButton={false}
            closeOnClick={false}
            offset={{
              bottom: [0, -50],
            }}
          >
            <div className="flex space-x-2 items-center">
              <p className="text-base-1100 text-nowrap">Boundary Complete!</p>
              <Button
                onClick={() => {
                  onDeleteFeature();
                }}
              >
                Delete
              </Button>
              <Button
                color="primary"
                loading={isPendingSaveDraw}
                disabled={isPendingSaveDraw || !isValid || !activeFeature}
                onClick={form.handleSubmit(onSubmitByDraw)}
              >
                Save
              </Button>
            </div>
          </Popup>
        )}
        <DrawControl
          ref={drawRef}
          controls={controls}
          onCreate={handleCreate}
          onClick={handleClick}
          onSelectionChange={handleSelectionChange}
          onMouseMove={handleMouseMove}
          drawMode={drawMode}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </Map>
    </div>
  );
}
