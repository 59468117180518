import { CloudArrowDownIcon } from '@heroicons/react/24/solid';
import { UseFormReturn } from 'react-hook-form';
import { CompleteRegisterValueTypes } from '.';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';
import { SignUpSections } from '../../../constants';

export default function CMEPricesTermsConditions({
  onNext,
  form,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes, any, undefined>;
}) {
  return (
    <div className="max-w-screen-lg mx-auto">
      <StyledCard title="CME Prices Terms and Conditions" className="relative">
        <div className="space-y-4 max-h-[500px] overflow-y-auto custom-scrollbar p-4 bg-base-1000 rounded-lg">
          <p>SUBSCRIBER ADDENDUM</p>
          <p>
            This addendum is by and between AgriVaR and insert
            (&ldquo;Subscriber&rdquo;) (the &ldquo;Subscriber Addendum&rdquo;).
            The Distributor and Subscriber are each a &ldquo;Party&rdquo; and
            collectively the &ldquo;Parties&rdquo;. 1. Background (A)
            Distributor has entered into an agreement with Chicago Mercantile
            Exchange Inc (or its successors or assignees) (&ldquo;CME&rdquo;)
            whereby CME Group has granted the Distributor (which may include
            certain other Affiliates of Distributor authorized by CME
            (collectively the &ldquo;Distributor Group&rdquo;)) the right to
            receive certain market data and other financial information licensed
            by CME, (the &ldquo;CME Licensed Information&rdquo;) and to
            retransmit the same to Subscriber and certain Affiliates of
            Subscriber (as agreed between Distributor and Subscriber) (the
            &ldquo;Subscriber Group&rdquo;). (B) In turn, Distributor and
            Subscriber have entered into an agreement, by which Distributor
            Group will, among other things, provide Subscriber Group with CME
            Licensed Information and may include, under the same agreement,
            other market data sublicensed from other exchanges (the
            &ldquo;Distribution Agreement&rdquo;). (C) This Subscriber Addendum
            is an addendum to the Distribution Agreement and sets forth the
            additional terms and conditions upon which Subscriber Group may
            receive and access the CME Licensed Information, but does not apply
            to any other market data that may be sublicensed to Subscriber
            Group, on behalf of any other exchange besides CME, under the
            Distribution Agreement.
          </p>

          <p>
            NOW, THEREFORE, in consideration of the mutual covenants and
            agreements set forth herein, and for other good and valuable
            consideration, the receipt and sufficiency of which are hereby
            acknowledged, the Parties agree as follows. 2. Definitions The below
            definitions apply only to the terms set out in this Subscriber
            Addendum and do not amend the Distribution Agreement for any other
            purpose. All other capitalized terms used in this Subscriber
            Addendum, have the same meaning as defined in the Distribution
            Agreement, unless otherwise stated herein. Affiliate: an entity that
            controls, is controlled by or is under common control with a Party.
            Control means the ownership or control, directly or indirectly, of
            at least fifty percent (50%) or more of all of the voting shares (or
            other securities or rights) entitled to vote for the election of
            directors or other governing authority. Automated Trading System:
            any system or software operated by Distributor that generates and/or
            routes orders electronically with no, or only de minimis, human
            action involved in generating, sending and/or verifying orders.
            Benchmark Information: CME Licensed Information designated by CME as
            benchmark information in the Information Policies.
          </p>

          <p>
            2. CME Group: CME and its Affiliates. CME Licensed Information
            Product: certain specified instruments of CME Licensed Information,
            grouped as one data product, as specified in the Information Product
            Fee Schedule. Confidential Information: all information disclosed by
            the Parties in connection with this Subscriber Addendum, which
            should reasonably be considered as confidential because of its
            nature and the manner of its disclosure including but not limited to
            the CME Licensed Information. Day: a calendar day. Device: any
            display unit (fixed or portable) which has the ability to access,
            receives, processes or displays the CME Licensed Information,
            whether in whole or part, through the Service. CME reserves the sole
            right to determine what constitutes a Device. Distributor Group:
            Distributor and its Affiliates. Futures and Options on Futures
            Information: the CME Licensed Information that originates from
            Designated Contract Markets (DCM) as defined in the Commodities
            Exchange Act 1936 (as amended and updated) or any other equivalent
            legislation. Such DCMs include, but are not limited to the CME,
            NYMEX, COMEX, CBOT and DME, DCMs. Information Policies: the policies
            applicable to CME Licensed Information as set out on the CME website
            (currently located at www.cmegroup.com/informationpolicies) as they
            may be amended from time to time. Information Product Fee Schedule:
            the document issued by Distributor to Subscriber, describing the CME
            Licensed Information licensed by Distributor on behalf of CME and
            the corresponding fees. Internal Controls: the electronic systems
            (including software and hardware), network configurations, rules,
            procedures, and policies which, taken together and to the
            satisfaction of CME: (i) identify the ability to access CME Licensed
            Information; (ii) permit access to CME Licensed Information using a
            defined Unit of Count; (iii) prevent any unauthorized accessto CME
            Licensed Information; and (iv) retain auditable records of the
            forgoing. Intellectual Property Rights: patents, trademarks, service
            marks, trade and service names, domain names, copyrights, topography
            rights, database rights, design rights, trade secrets and other
            intellectual property, proprietary or moral rights, whether or not
            they are registered and including all applications and rights to
            apply for registration, and all similar or equivalent rights,
            anywhere in the world. Managed User Non-Display: a Service
            authorized by CME that facilitates the Non-Display Use of CME
            Licensed Information by a Subscriber who is an individual natural
            person. Non-Display Use: non-viewable use of CME Licensed
            Information in any system, process, program, machine or calculation
            other than in order to display or distribute CME Licensed
            Information for display. Such use may include, but is not limited
            to, calculation of P&amp;L, portfolio valuation, order processing,
            use within Automated Trading Systems and automated order routing.
            Person: means any natural person, proprietorship, corporation,
            partnership, limited liability company or other organization.
          </p>

          <p>3</p>

          <p>
            Personal Data: any information relating to an identified or
            identifiable natural person. Privacy Center: the CME Group Inc.
            Privacy Center on the CME website (currently located at
            http://www.cmegroup.com/privacy-policy.html) as may be amended from
            time to time. Service: any medium provided by, or on behalf of,
            Distributor or a Distributor Group entity, through which the CME
            Licensed Information is made available. Unit of Count: the unit of
            measure that is used for the assessment of fees and or reporting.
            Unless otherwise advised by CME, Device is the default Unit of Count
            in respect of CME Licensed Information. If a Device is configured to
            access multiple Services, then each ability to access a Service on
            that Device will be one Unit of Count, for example, a laptop running
            three (3) Services shall be counted as three (3) Devices. Unless the
            context requires otherwise: &#9679; words importing the singular
            shall include the plural and vice versa; &#9679; words importing any
            gender shall include the other genders and vice versa; &#9679;
            referencesto the word &ldquo;include&rdquo; shall mean
            &ldquo;including, without limitation&rdquo; or &ldquo;including, but
            not limited to&rdquo;; &#9679; headings used are for reference
            purposes only and shall not affect the interpretation of this
            Subscriber Addendum.
          </p>

          <p>
            3. Subscriber Addendum Term 3.1 This Subscriber Addendum is
            effective as of the date the CME Licensed Information was first made
            available by Distributor to a Subscriber Group entity and will
            continue until terminated in accordance with the provisions herein
            (the &ldquo;Subscriber Addendum Term&rdquo;). 4. Receipt of CME
            Licensed Information by Subscriber Group 4.1 Subject to the terms
            and conditions of this Subscriber Addendum, during the Subscriber
            Addendum Term, Subscriber Group is permitted to access CME Licensed
            Information via the Service and display such CME Licensed
            Information on a Device for its own internal business activities.
            4.2 All Non-Display Use of CME Licensed Information requires a
            direct license with CME. 5 Internal Controls 5.1 SUBSCRIBER WARRANTS
            (ON BEHALF OF ITSELF AND THE REST OF THE SUBSCRIBER GROUP) TO BOTH
            DISTRIBUTOR AND CME, THAT DURING THE SUBSCRIBER ADDENDUM TERM,
            SUBSCRIBER GROUP SHALL AT ALL TIMES HAVE IN PLACE AND MAINTAIN
            EFFECTIVE INTERNAL CONTROLS IN ACCORDANCE WITH CME&rsquo;S
            INFORMATION POLICIES, USING CME&rsquo;S UNIT OF COUNT AND WILL
            MAINTAIN AUDITABLE EVIDENCE OF THE OPERATION OF THE INTERNAL
            CONTROLS AND SUBSCRIBER WILL AT ALL TIMES FAMILIARIZE ITSELF WITH
            SUCH INFORMATION POLICIES.
          </p>

          <p>4</p>

          <p>
            6. CME Licensed Information 6.1 Subscriber acknowledges and agrees
            on behalf of Subscriber Group that: (a) Subscriber Group shall not
            redistribute CME Licensed Information outside of Subscriber Group.
            Notwithstanding the forgoing, should any entity within the
            Subscriber Group wish to distribute CME Licensed Information outside
            of the Subscriber Group, then Subscriber must seek permission from
            CME directly. If such request is approved by CME (in its sole
            discretion) then each applicable Subscriber Group entity, must be
            licensed under a separate license agreement with CME directly, for
            the right to distribute the CME Licensed Information. (b) Subscriber
            Group shall not misrepresent CME Licensed Information or deface or
            remove any trademarks transmitted with CME Licensed Information. (c)
            Subscriber Group shall not access or use CME Licensed Information
            for any illegal purpose and agrees, that each Subscriber Group
            entity, will only access or use CME Licensed Information, in
            accordance with the laws and regulations of the jurisdiction in
            which they operate. (d) Subscriber Group shall not use the CME
            Licensed Information or any portion thereof, in the creation,
            distribution, settlement or maintenance of any derivative work
            (including but not limited to financial products, indexes, quotes,
            spot prices, curves, surfaces, contracts for difference (CFDs) and
            other leveraged products, futures, options on futures, indicative
            optimized portfolio values (IOPV), net asset value (NAV), or
            analytical reference figures or values calculated from CME Licensed
            Information for purposes of fund administration and portfolio
            management services, risk management services or valuation services
            based on the Information) unless Subscriber Group is licensed to do
            so by CME. (e) CME and CME licensors, hold all right, title and
            interest in and to CME Licensed Information and the Intellectual
            Property Rights in the CME Licensed Information, and in the format
            in which CME Licensed Information is transmitted by CME. Except for
            the limited license expressly granted herein, all rights not
            expressly licensed under this Subscriber Addendum are expressly
            reserved to CME and CME licensors and the ownership and Intellectual
            Property Rights of the CME Group entities and other CME licensors
            are not transferred, assigned or affected in any way by this
            Subscriber Addendum. Subscriber undertakes that Subscriber Group
            shall not carry out text and data mining, as those terms are defined
            in EU Directive 2017/790. Subscriber acknowledges on behalf of
            Subscriber Group that the reservation of rights by CME and CME
            licensors in this Subscriber Addendum is appropriate. (f) It
            consents to CME Group&rsquo;s use of the Personal Data as described
            in the Privacy Center, and represents and warrants to CME Group,
            that each Subscriber Group entity, has either obtained valid
            consents from those individuals to whom the Personal Data relates,
            or has satisfied an applicable legal basis, pursuant to all
            applicable laws, so as to ensure that CME Group is able to process
            the Personal Data, as envisaged under this Subscriber Addendum and
            in accordance with applicable laws. Subscriber will ensure that any
            transfer of Personal Data from any Subscriber Group entity to CME
            Group, to countries other than where the individual provided its
            Personal Data, will satisfy all applicable laws.
          </p>

          <p>5</p>

          <p>
            (g) If CME permits the Subscriber Group to redistribute CME Licensed
            Information, pursuant to section 6.1 (a) above, Subscriber Group
            shall not distribute, or permit distribution, of CME Licensed
            Information to any entity located in any country subject to
            comprehensive sanctions by the Office of Foreign Assets Control
            and/or identified on the U.S. Department of the Treasury&rsquo;s
            Specially Designated Nationals and Blocked Persons List. (h)
            Subscriber Group shall not use Benchmark Information other than for
            display on one or more Devices. (i) If Subscriber is licensed for
            Managed User Non-Display of CME Licensed Information (which for the
            avoidance of doubt will not include Benchmark Information), then
            Subscriber will not use the CME Licensed Information for any other
            purpose (including viewing the CME Licensed Information on a Device
            and/or the creation of derived works), without a separate license
            with CME or Distributor.
          </p>

          <p>
            6.2 Subscriber accepts responsibility for Subscriber Groups
            compliance with the terms of this Subscriber Addendum and will cause
            the same to comply with the terms of the Subscriber Addendum. 7
            Subscriber Reporting Obligations 7.1 Subscriber Group will maintain
            complete and accurate books and records, relating to all Units of
            Count that had the ability to access CME Licensed Information
            (&ldquo;Records&rdquo;), and retain such Records, for the most
            recent five (5) year period. 7.2 FOR THE AVOIDANCE OF DOUBT, THE
            DEFAULT UNIT OF COUNT FOR FUTURES AND OPTIONS ON FUTURES INFORMATION
            IS ALWAYS DEVICE (UNLESS OTHERWISE ADVISED BY CME). 7.3 In addition
            to any other Units of Count that Subscriber is required to report to
            Distributor and (unless Subscriber has otherwise agreed directly
            with CME), Subscriber is required to report to Distributor (or
            directly to CME if requested) on a date agreed between Subscriber
            and Distributor (or between Subscriber and CME, as applicable), the
            maximum number of Devices within the Subscriber Group, that have the
            ability to access CME Licensed Information, each month, via each
            Service covering each CME Licensed Information Product. For example,
            where CME Licensed Information constitutes Futures and Options on
            Futures Information, if Subscriber has ten (10) Devices that are
            enabled to access the CBOT DCM and five (5) of those Devices are
            also enabled to access the NYMEX DCM, then Subscriber must report
            ten (10) Devices for CBOT and five (5) Devices for NYMEX. Where
            Device is the default Unit of Count for the CME Licensed
            Information, the following rules apply: (a) Subscriber Group is
            required to report the total number of Services per unique Devices
            which are authorized by Internal Controls to access the CME Licensed
            Information, irrespective of whether such Device did actually access
            the CME Licensed Information. For example, a laptop running three
            (3) Services shall be reported as three (3) Devices. (b) Subscriber
            Group is strictly prohibited from using any
            &ldquo;usage-based&rdquo; recording system as a means for counting
            and reporting Devices. (c) In cases where the same Device is used
            for both display and Non-Display Use, the Subscriber&rsquo;s
            reporting of such Device to Distributor under the terms of this
            Subscriber
          </p>

          <p>6</p>

          <p>
            Addendum, does not relieve Subscriber of its obligation to license
            with CME directly for the Non-Display Use (unless Subscriber is
            directly licensed with Distributor for Managed User Non Display) and
            any additional reporting obligation thereunder for the Non-Display
            Use activity. (d) In relation to cases where an access ID is enabled
            to concurrently access CME Licensed Information via multiple
            Devices, the Subscriber must report the total number of Devices that
            each access ID has been enabled to access CME Licensed Information,
            on a per Service basis. For examples, please see the Unit of Count
            guides available at CME&rsquo;s Market Data
          </p>

          <p>
            Policy Education Center
            https://www.cmegroup.com/market-data/license-data/market-
            data-policy-education-center.html.
          </p>

          <p>
            (e) Subscriber Group is strictly prohibited from netting Device
            Units of Counts across Services or unique users, without the express
            written permission of CME.
          </p>

          <p>
            7.4 Subscriber shall be liable to CME and Distributor for any
            unreported Units of Count identified as having the ability to access
            CME Licensed Information. 8 Audits 8.1 Notwithstanding the
            provisions of the Distribution Agreement, during the Subscriber
            Addendum Term and for a period of not less than twenty-four (24)
            months thereafter, Subscriber must produce on request, the Records
            and complete and accurate books and records related to Internal
            Controls, for the most recent five (5) year period. Distributor or
            CME or their authorized representatives (the &ldquo;Auditor&rdquo;),
            may inspect equipment, software and Records to verify compliance
            with this Subscriber Addendum. 8.2 Audits may be carried out by the
            Auditor, at any Subscriber Group location, during business hours,
            during the Subscriber Addendum Term and up to twenty-four (24)
            months following termination: (a) once per twelve (12)-month period
            on no less than thirty (30) Days&rsquo; notice; and (b) without
            advance notice and more than once in any twelve (12) month period,
            if Distributor or CME, reasonably suspects a material breach of the
            Subscriber Addendum by any Subscriber Group entity. All information
            observed during an audit will be treated in accordance with the
            confidentiality provisions hereunder and used only to verify
            compliance with the Subscriber Addendum. CME or Distributor or
            either of their agents, will comply with
            Subscriber&rsquo;sreasonable safety and security rules and
            regulations at all times when on site at a Subscriber Group location
            throughout the audit.
          </p>

          <p>
            8.3 Subscriber must pay any outstanding fees revealed by an audit to
            CME, and Distributor, within thirty (30) Days of receipt of an
            invoice from CME or Distributor and will be subject to interest at
            the lower of 1.5% per month or the maximum permitted by law.
          </p>

          <p>
            8.4 If the Auditor identifies a lack of records or failure of
            Internal Controls such that the amount of any under- reported access
            to the CME Licensed Information during the audit period and the
            applicable fees cannot
          </p>

          <p>
            be established with reasonable certainty or agreed by the Parties,
            CME or Distributor may appoint an independent professional auditor
            to determine the fees owed to Distributor or CME at
            Subscriber&rsquo;s sole cost and expense. Distributor or CME and
            Subscriber will accept the determination of the appointed
            independent professional auditor.
          </p>

          <p>7</p>

          <p>
            8.5 If an audit reveals an underpayment, Subscriber shall bear the
            reasonable costs and expenses of the audit. 9 Termination 9.1 Either
            Party may terminate this Subscriber Addendum, or any license
            individually: (a) On thirty (30) Days&rsquo; written notice; or (b)
            With immediate effect on written notice if: (i) The other Party
            enters into compulsory or voluntary liquidation, has a receiver
            appointed, presents a petition for its winding up (or has such a
            petition presented by a creditor) (ii) The other Party commits a
            material, irremediable breach of any term of this Subscriber
            Addendum, which includes, but is not limited to, the following
            breaches by Subscriber: misrepresentation of CME Licensed
            Information; failure to use effective Internal Controls; failure to
            prevent, identify or end unauthorized use, distribution or
            redistribution of CME Licensed Information; or (iii) The other Party
            fails to remedy a material breach within thirty (30) Days of written
            notice to do so.
          </p>

          <p>
            9.2 This Subscriber Addendum or any license individually shall
            automatically terminate, with immediate effect, if
            Distributor&rsquo;s rights to sublicense the CME Licensed
            Information is partially or wholly revoked. 9.3 This Subscriber
            Addendum shall automatically terminate, if the Distribution
            Agreement itself expires or is terminated for any reason. 10 Fees
            10.1 Subscriber will pay Distributor for the right to receive the
            CME Licensed Information, in accordance with the Distribution
            Agreement terms and conditions. 10.2 On occasion, Subscriber may be
            required to pay CME directly for access to and use of the CME
            Licensed Information licensed hereunder. In such circumstances, any
            fees paid to CME, shall be covered by a separate written agreement
            between CME and Subscriber, but shall only cover the receipt of the
            CME Licensed Information identified in that separate agreement and
            not any other market data received by Subscriber Group pursuant to
            the terms of the Distribution Agreement. 11 Covenants,
            Representations and Warranties of Subscriber. 11.1 Subscriber, for
            itself and on behalf of the rest of the Subscriber Group, covenants,
            represents and warrants that: (a) Subscriber Group is not (unless
            otherwise approved in writing by CME) engaged in the business of
            distributing CME Licensed Information and that, to its knowledge
            after reasonable inquiry, it is receiving the CME Licensed
            Information from Distributor who is authorized by CME to distribute
            the CME Licensed Information. (b) Subscriber Group will not use or
            permit any other person to use, CME Licensed Information for any
            illegal purpose and that each Subscriber Group entity, will only
            access or use CME Licensed Information, in accordance with the laws
            and regulations of the jurisdiction in which they operate.
          </p>

          <p>
            8 12 CME Group as a Third-Party Beneficiary 12.1 The Distributor and
            Subscriber acknowledges and agrees that CME Group (only) are
            third-party beneficiaries ofthis Subscriber Addendum, and are
            entitled to allthe rights and benefits thereunder, and may enforce
            the provisions of this Subscriber Addendum directly against
            Distributor and Subscriber Group, as if it were a party thereto.
          </p>

          <p>
            13 Limitations Of Liability and Damages 13.1 SUBSCRIBER (FOR AND ON
            BEHAF OF SUBSCRIBER GROUP) AND DISTRIBUTOR (FOR AND ON BEHALF OF
            DISTRIBUTOR GROUP) AGREES: (A) THAT TO THE MAXIMUM EXTENT PERMITTED
            BY LAW, NO CME GROUP ENTITY, NOR ANY OF THEIR RESPECTIVE OFFICERS,
            DIRECTORS, MEMBERS, EMPLOYEES, AGENTS, CONSULTANTS, OR LICENSORS
            SHALL BE LIABLE FOR: (A) ANY DELAY, INACCURACIES, ERRORS, OMISSIONS,
            OR INTERRUPTION OF ANY KIND IN RELATION TO THE CME LICENSED
            INFORMATION OR FOR ANY RESULTING LOSS OR DAMAGE; OR (B) LOSS OR
            DAMAGE ARISING FROM UNAUTHORIZED ACCESS TO OR MISUSE OF CME LICENSED
            INFORMATION. (B) EXCEPT AS EXPRESSLY SET OUT IN THIS SECTION, NO CME
            GROUP ENTITY NOR ANY CME GROUP LICENSORS, MAKE ANY REPRESENTATIONS
            OR WARRANTIES AND EACH HEREBY DISCLAIMS AND SHALL HAVE NO LIABILITY
            FOR ALL OTHER REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING WITHOUT LIMITATION TO THE MERCHANTABILITY, QUALITY OF THE
            CME LICENSED INFORMATION OR ITS FITNESS FOR A PARTICULAR PURPOSE,
            UNINTERRUPTED SERVICE OR ERROR-FREE SERVICE, OR THE SEQUENCE,
            TIMELINESS, ACCURACY OR COMPLETENESS OF THE CME LICENSED
            INFORMATION. THE CME LICENSED INFORMATION IS PROVIDED ON AN
            &ldquo;AS IS&rdquo; BASIS AT SUBSCRIBER GROUPS SOLE RISK (C) THAT TO
            THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO CIRCUMSTANCES SHALL ANY
            CME GROUP ENTITY OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS,
            MEMBERS, EMPLOYEES, AGENTS, CONSULTANTS OR LICENSORS BE LIABLE
            HEREUNDER TO ANY DISTRIBUTOR GROUP OR SUBSCRIBER GROUP ENTITY OR TO
            OTHERS DIRECTLY OR INDIRECTLY MAKING USE OF CME LICENSED
            INFORMATION, FOR ANY LOST PROFITS, INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY OR CONSEQUENTIAL DAMAGES, ARISING UNDER THIS AGREEMENT,
            EVEN IF ANY DISTRIBUTOR GROUP OR SUBSCRIBER GROUP ENTITY HAS BEEN
            ADVISED OF THE POSSIBILITY THEREOF AND EVEN IF DUE TO CME&rsquo;S
            ERROR, OMISSION, OR NEGLIGENCE. (D) IF THE FOREGOING DISCLAIMER AND
            LIMITATION OF LIABILITY SHOULD BE DEEMED INVALID OR INEFFECTIVE BY A
            COURT OF COMPETENT JURISDICTION, NEITHER CME GROUP, NOR THEIR
            RESPECTIVE MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS SHALL
            BE LIABLE FOR ANY OF THE FOREGOING BEYOND THE ACTUAL AMOUNT OF LOSS
            OR DAMAGE, OR THE SUM OF FIFTY DOLLARS ($50.00), WHICHEVER IS LESS.
          </p>

          <p>14 Confidentiality</p>

          <p>9</p>

          <p>
            14.1 Confidential Information will be held in confidence by the
            receiving party and not be disclosed without the disclosing
            party&rsquo;s consent to any third party, except to any CME Group
            entity, CME Group licensors and any Distributor Group or Subscriber
            Group entity, and each of their respective agents, consultants and
            third-party advisors, provided they are bound by substantially
            similar confidentiality provisions as are set out in this Subscriber
            Addendum. 14.2 Confidential Information does not include Information
            that: (a) at the time of disclosure is already publicly available;
            (b) is already known to the receiving party prior to disclosure by
            the disclosing party; (c) after disclosure becomes publicly
            available through no fault of the receiving party; (d)
            becomesrightfully known to either Party without restriction from
            another source; (e) is developed independently by the receiving
            party without use of the disclosing party&rsquo;s Confidential
            Information; or (f) is required to be disclosed by order of legal or
            regulatory authorities, or is requested by agency action of a
            regulatory authority with jurisdiction over the receiving party,
            provided that the receiving party providesreasonable notice to the
            disclosing party ofsuch required disclosure and reasonably
            cooperates with the disclosing party in preventing or limiting such
            disclosure.
          </p>

          <p>
            15 Modification 15.1 Notwithstanding anything to the contrary
            hereunder, Distributor and CME may from time to time, modify and
            amend this Subscriber Addendum, and Subscriber agrees that
            Subscriber Group shall be bound by such terms upon notice.
            Subscriber may terminate the Subscriber Addendum upon ten (10)
            Days&rsquo; electronic or written notice upon such modification or
            amendment. By continuing to access or use the CME Licensed
            Information after Distributor or CME has provided Subscriber with
            notice of a modification, Subscriber is indicating on behalf of
            Subscriber Group that Subscriber Group agrees to be bound by the
            modified Subscriber Addendum. 16 Miscellaneous. 16.1 If any part,
            term or provision of this Subscriber Addendum is held illegal,
            invalid or unenforceable, the validity or enforceability of the
            remainder of the Subscriber Addendum shall not be affected. 16.2 The
            failure of either Party (including CME Group) to exercise any right
            or remedy under this Subscriber Addendum or at law shall not prevent
            any further exercise of that right or remedy. 16.3 In the event of
            any conflict between the terms and conditions of this Subscriber
            Addendum and any other agreement relating to Subscriber's receipt
            and use of CME Licensed Information, including, without limitation,
            the Distributor Agreement, the terms and conditions of this
            Subscriber Addendum will prevail. Notwithstanding the forgoing, CME
            may amend its Information Policies at any time on at least thirty
            (30)) Days written notice to Distributor and Subscriber Group will
            be required to comply with any such amendments. 16.4 Upon any
            termination of the Subscriber Addendum, Subscriber Group shall
            discontinue any use of the CME Licensed Information, and delete any
            and all CME Licensed Information received under this Subscriber
            Addendum, including without limitation any stored CME Licensed
            Information. Notwithstanding the forgoing, Subscriber Group may
            retain CME Licensed Information for the sole purpose of complying
            with Subscriber Groups regulatory obligations and for only as long
            as is necessary
          </p>

          <p>10</p>

          <p>
            to comply with such requirements and shall not use the CME Licensed
            Information for any other purpose during this period.
          </p>

          <p>
            17 Survival 17.1 The Definitions, Section 12 (CME Group as a
            Third-Party Beneficiary), Section 13 (Limitation of Liability and
            Damages), Section 17 (Survival) Section 18 (Governing Law and
            Jurisdiction), those sections concerning CME&rsquo;s Intellectual
            Property Rights in the CME Licensed Information and, Sections that
            by their nature, should reasonably survive, and any amendments to
            the provisions of the aforementioned, will survive any termination
            or expiration of this Subscriber Addendum. 18 Governing Law and
            Jurisdiction 18.1 This Subscriber Addendum shall be governed by the
            internal laws of the State of Illinois, and the federal laws of the
            United States, without regard to conflicts of laws principles. The
            Parties hereto consent and submit to the exclusive jurisdiction and
            venue of the state courts located in Cook County, Illinois and the
            U.S. District Court for the Northern District of Illinois.
          </p>

          <p>
            IN WITNESS WHEREOF, each of the Parties has caused this Subscriber
            Addendum to be duly executed in its name and on its behalf by the
            officer or representative duly authorized.
          </p>
        </div>
        <Checkbox
          name="cme_terms_conditions"
          rounded
          label="I have read, understand, and agree to the Terms of Service"
          className="mt-5"
        />
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal !w-fit mt-5 mx-auto"
          disabled={!form.watch('cme_terms_conditions')}
          onClick={() => onNext(SignUpSections.SMSTermsConditions)}
        >
          Continue
        </Button>
        <button className="flex items-center space-x-2 text-[#343E44] mx-auto mt-4">
          <CloudArrowDownIcon className="size-6" />
          <span>Download Terms</span>
        </button>
        <p className="text-right absolute right-6 bottom-4">2/3</p>
      </StyledCard>
    </div>
  );
}
