import { IconProps } from '../../types';

const FormEntryIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0151 0.615234L18.0151 6.61523H12.0151V0.615234ZM10.5151 6.61523C10.5151 7.45898 11.1714 8.11523 12.0151 8.11523H18.0151V22.3652C18.0151 23.6309 16.9839 24.6152 15.7651 24.6152H2.26514C0.999512 24.6152 0.0151367 23.6309 0.0151367 22.3652V2.86523C0.0151367 1.64648 0.999512 0.615234 2.26514 0.615234H10.5151V6.61523ZM12.7651 20.1152C13.1401 20.1152 13.5151 19.7871 13.5151 19.3652C13.5151 18.9902 13.1401 18.6152 12.7651 18.6152H5.26514C4.84326 18.6152 4.51514 18.9902 4.51514 19.3652C4.51514 19.7871 4.84326 20.1152 5.26514 20.1152H12.7651ZM12.7651 17.1152C13.1401 17.1152 13.5151 16.7871 13.5151 16.3652C13.5151 15.9902 13.1401 15.6152 12.7651 15.6152H5.26514C4.84326 15.6152 4.51514 15.9902 4.51514 16.3652C4.51514 16.7871 4.84326 17.1152 5.26514 17.1152H12.7651ZM13.5151 13.3652C13.5151 12.9902 13.1401 12.6152 12.7651 12.6152H5.26514C4.84326 12.6152 4.51514 12.9902 4.51514 13.3652C4.51514 13.7871 4.84326 14.1152 5.26514 14.1152H12.7651C13.1401 14.1152 13.5151 13.7871 13.5151 13.3652Z"
        fill={fill}
      />
    </svg>
  );
};

export default FormEntryIcon;
