import { IconProps } from '../../types';

const ScenarioAnalysisIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10.5222C3 11.3509 2.33 12.0197 1.5 12.0197C0.67 12.0197 0 11.3509 0 10.5222C0 6.39911 3.36 3.03472 7.5 3.03472H15L14.96 1.15785C14.96 0.718579 15.26 0.309262 15.67 0.0896279C16.08 -0.0900726 16.55 -0.0102057 16.88 0.279312L20.63 3.65369C20.87 3.86334 21 4.13289 21 4.49229C21 4.85169 20.86 5.16117 20.59 5.37083L16.84 8.7452C16.63 8.93489 16.36 9.03472 16.09 9.03472C15.98 9.03472 15.82 9.00477 15.67 8.93489C15.26 8.75518 15 8.35585 15 7.9066L14.96 6.02973H7.5C5.02 6.02973 3 8.04637 3 10.5222ZM24 13.5172C24 17.6504 20.64 21.0048 16.5 21.0048H9V22.8816C9 23.3309 8.74 23.7302 8.33 23.9099C8.18 23.9698 8.03 24.0098 7.87 24.0098C7.6 24.0098 7.33 23.9099 7.12 23.7202L3.37 20.3459C3.13 20.1362 3 19.8267 3 19.5073C3 19.1878 3.14 18.8883 3.37 18.6786L7.12 15.3043C7.45 15.0048 7.93 14.9349 8.33 15.1146C8.74 15.2943 9 15.6936 9 16.1429V18.0197H16.5C18.98 18.0197 21 16.0031 21 13.5272C21 12.6986 21.67 12.0297 22.5 12.0297C23.33 12.0297 24 12.6986 24 13.5272V13.5172Z"
        fill={fill}
      />
    </svg>
  );
};

export default ScenarioAnalysisIcon;
