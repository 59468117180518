import { IconProps } from '../../types';

export default function PaperPlaneIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9725 6.00733L20.7225 20.5972C20.6874 20.9487 20.4764 21.2651 20.16 21.4409C19.9843 21.5112 19.8085 21.5816 19.5975 21.5816C19.4569 21.5816 19.3163 21.5464 19.1757 21.4761L14.8866 19.6831L13.0936 22.355C12.953 22.6011 12.7069 22.7066 12.4608 22.7066C12.0741 22.7066 11.7577 22.3901 11.7577 22.0034V18.6284C11.7577 18.3472 11.828 18.1011 11.9686 17.9253L19.6327 8.08155L9.29676 17.398L5.67567 15.8862C5.28895 15.7105 5.0077 15.3589 5.0077 14.9019C4.97254 14.4097 5.18348 14.0581 5.5702 13.8472L21.3202 4.88233C21.6718 4.6714 22.1639 4.6714 22.5155 4.91749C22.8671 5.16358 23.0429 5.58546 22.9725 6.00733Z"
        fill={fill}
      />
    </svg>
  );
}
