import { useMemo } from 'react';
import { useUser } from '../contexts/auth-context';
import { useFieldPolygons } from './field/use-field-polygons';

export const useYears = () => {
  const user = useUser();
  const { data, ...rest } = useFieldPolygons(user?.id || '');

  const years = useMemo(() => {
    return Array.from(
      new Set(
        data
          ?.map((field) => parseInt(field.crop_year, 10)) // Convert crop_year to number here
          .filter((year) => !isNaN(year)) // Filter out any NaN values that may result from the conversion
          .sort((a, b) => a - b)
      )
    );
  }, [data]);

  return { years, ...rest };
};
