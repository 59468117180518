import HistoricalInsights from '../../pages/dashboard/historical-insights';

const historicalInsightsRoutes = [
  {
    path: 'historical-insights',
    element: <HistoricalInsights />,
  },
];

export default historicalInsightsRoutes;
