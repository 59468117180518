import { signOut } from 'aws-amplify/auth';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import {
  useSetControlledUser,
  useSetIsAuthenticated,
  useMainUser,
} from '../../../../contexts/auth-context';
import { NavItemType } from '../../../../types';
import Button from '../../../commons/Button';

const userItems: NavItemType[] = [
  {
    href: '/app/dashboard',
    title: 'Dashboard',
  },
  {
    href: '/staff-request',
    title: 'Advisor Requests',
  },
];

const advisorItems: NavItemType[] = [
  {
    href: '/staff',
    title: 'Advisor Clients',
  },
  {
    href: '/staff-request',
    title: 'Advisor Client Requests',
  },
];

const adminItems: NavItemType[] = [
  {
    href: '/users',
    title: 'Clients',
  },
  {
    href: '/incoming-users',
    title: 'Client Requests',
  },
];

export default function MainHeader() {
  const setIsAuthenticated = useSetIsAuthenticated();
  const setControlledUser = useSetControlledUser();
  const user = useMainUser();
  const isAdmin = user?.is_admin;
  const isAdvisorClient = user?.is_advisor_client;

  const handleSignOut = async () => {
    setIsAuthenticated?.(false);
    setControlledUser?.(undefined);
    await signOut();
  };

  return (
    <header
      className={clsx(
        'flex flex-col space-y-8 p-4 w-full absolute top-0 left-0 z-50',
        'shadow-lg'
      )}
    >
      <div className={clsx('flex items-center justify-between')}>
        <Link to="/">
          <div id="network-logo" />
        </Link>
        <div className="flex items-center space-x-4">
          <nav className="flex space-x-4">
            {!isAdmin &&
              !isAdvisorClient &&
              userItems.map((item) => (
                <NavLink
                  key={item.title}
                  to={item.href}
                  className="whitespace-nowrap"
                >
                  <span className="text-md-bold text-base-900 hover:text-base-000">
                    {item.title}
                  </span>
                </NavLink>
              ))}

            {isAdmin &&
              adminItems.map((item) => (
                <NavLink
                  key={item.title}
                  to={item.href}
                  className="whitespace-nowrap"
                >
                  <span className="text-md-bold text-base-900 hover:text-base-000">
                    {item.title}
                  </span>
                </NavLink>
              ))}

            {isAdvisorClient &&
              advisorItems.map((item) => (
                <NavLink
                  key={item.title}
                  to={item.href}
                  className="whitespace-nowrap"
                >
                  <span className="text-md-bold text-base-900 hover:text-base-000">
                    {item.title}
                  </span>
                </NavLink>
              ))}
          </nav>

          <Button color="primary" onClick={handleSignOut}>
            Sign Out
          </Button>
        </div>
      </div>
    </header>
  );
}
