// @ts-nocheck
import clsx from 'clsx';
import DeckGL, { GridCellLayer } from 'deck.gl';
import { useCallback, useEffect, useState } from 'react';
import Map from 'react-map-gl';
import SpinningIcon from '../../../assets/icons/SpinningIcon';
import {
  AttributeOptions,
  MAP_STYLE,
  prescriptionsBucket,
} from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { PrescriptionMapLegend } from '../../../types';
import {
  convertBigInt64ToInt,
  getLatestKeys,
  getParquetData,
} from '../../../utils';
import { getColorLegendData } from './helpers';

interface PrescriptionMapProps {
  crop: string;
  field: string;
  attribute: string;
  selectedZoneNames: string[];
  onLegendDataChange: (data: PrescriptionMapLegend) => void;
  scale: number;
}

export default function PrescriptionMap({
  crop,
  field,
  attribute,
  selectedZoneNames,
  onLegendDataChange,
  scale,
}: PrescriptionMapProps) {
  const user = useUser();
  const year = useYearFilter();
  const [viewState, setViewState] = useState({
    longitude: user?.lon ? parseFloat(user?.lon) : -98.381933,
    latitude: user?.lon ? parseFloat(user?.lat) : 44.704991,
    zoom: 14,
    pitch: 30,
    bearing: 0,
  });
  const [layers, setLayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sKeys, setsKeys] = useState<any[]>([]);
  const [colorData, setColorData] = useState<any[]>([]);

  useEffect(() => {
    onLegendDataChange(getColorLegendData(colorData));
  }, [colorData]);

  const render = useCallback(
    (wData: any) => {
      const meanLons: any = [];
      const meanLats: any = [];
      if (wData) {
        const opt = AttributeOptions.find((opt) => opt.key === attribute)?.name;
        const dataChunks = wData.map((data: any) => {
          const lons = data.getChild('lon').data[0].values;
          const lats = data.getChild('lat').data[0].values;
          const elevs = data.getChild('elev').data[0].values;
          const elevs2 = data.getChild('elev2').data[0].values;
          const value = data.getChild('value').data[0].values;
          const fieldArea = data.getChild('field_area').data[0].values;
          const field = data.getChild('field').data[0].values;
          const fieldIndex = data.getChild('field_index').data[0].values;
          const agrivarZone2 = data.getChild('agrivar_zone2').data[0].values;
          const agrivarZone2Offsets =
            data.getChild('agrivar_zone2').data[0].valueOffsets;
          const agrivarZone1 = data.getChild('agrivar_zone1').data[0].values;
          const agrivarZone1Offsets =
            data.getChild('agrivar_zone1').data[0].valueOffsets;
          const reds = data.getChild('x_r').data[0].values;
          const greens = data.getChild('x_g').data[0].values;
          const blues = data.getChild('x_b').data[0].values;
          const length = data.numRows;

          const meanLon =
            lons.reduce(
              (accumulator: any, currentData: any) => accumulator + currentData,
              0
            ) / lons.length;
          const meanLat =
            lats.reduce(
              (accumulator: any, currentData: any) => accumulator + currentData,
              0
            ) / lats.length;

          meanLons.push(meanLon);
          meanLats.push(meanLat);

          return {
            option: opt,
            value,
            lons,
            lats,
            elevs,
            elevs2,
            fieldArea,
            field,
            fieldIndex,
            agrivarZone1,
            agrivarZone1Offsets,
            agrivarZone2,
            agrivarZone2Offsets,
            reds,
            blues,
            greens,
            length,
          };
        });

        const layers = dataChunks.map(
          (chunk: any, chunkIndex: any) =>
            new GridCellLayer({
              id: `prescriptions-layer-${chunkIndex}`,
              data: chunk,
              cellSize: 10,
              extruded: true,
              getPosition: (object, { index, data, target }) => {
                const startOffset = data.agrivarZone2Offsets[index];
                const endOffset = data.agrivarZone2Offsets[index + 1]
                  ? data.agrivarZone2Offsets[index + 1]
                  : data.agrivarZone2.length();

                let zoneArray = [];
                for (let i = startOffset; i < endOffset; i++) {
                  zoneArray.push(data.agrivarZone2[i]);
                }

                const zoneArray1 = new Uint8Array(zoneArray);
                const zone = new TextDecoder().decode(zoneArray1);
                if (!selectedZoneNames.includes(zone)) return;

                target[0] = data.lons[index];
                target[1] = data.lats[index];
                // target[2] = data.elevs[index];
                return target;
              },
              getFillColor: (object, { index, data, target }) => {
                target[0] = data.reds[index];
                target[1] = data.greens[index];
                target[2] = data.blues[index];
                return target;
              },
              getElevation: (object, { index, data, target }) => {
                return data.elevs2[index];
              },

              pickable: true,
              elevationScale: scale,
              opacity: 0.8,
            })
        );

        const colorData: any = [];
        dataChunks.forEach((chunk: any) => {
          chunk.value.forEach((value: any, index: any) => {
            const value1 = convertBigInt64ToInt(value);
            colorData.push({
              value: value1,
              color: `rgb(${chunk.reds[index]}, ${chunk.greens[index]}, ${chunk.blues[index]})`,
            });
          });
        });

        setColorData(colorData);
        setLayers(layers);
      }

      return {
        lons: meanLons,
        lats: meanLats,
      };
    },
    [attribute, scale, selectedZoneNames]
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setColorData([]);
        const crop1 = crop.charAt(0).toUpperCase() + crop.slice(1);
        const prefix = `${user?.id}_${year}_${crop1}_${field}_${attribute}_Prescription`;
        const s3Keys = await getLatestKeys(prescriptionsBucket, prefix);
        setsKeys(s3Keys);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [attribute, crop, field, user?.id, year]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = [];
        const meanLons = [],
          meanLats = [];
        for (const key of sKeys) {
          const wData = await getParquetData(prescriptionsBucket, key);
          data.push(wData);

          const { lons, lats } = render(data);

          meanLons.push(...lons);
          meanLats.push(...lats);
        }

        const meanLon =
          meanLons.reduce(
            (accumulator, currentData) => accumulator + currentData,
            0
          ) / meanLons.length;
        const meanLat =
          meanLats.reduce(
            (accumulator, currentData) => accumulator + currentData,
            0
          ) / meanLats.length;

        if (meanLon && meanLat) {
          setViewState((prev) => ({
            ...prev,
            longitude: meanLon,
            latitude: meanLat,
          }));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, [render, sKeys]);

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="relative overflow-hidden w-full min-h-[440px]">
        <div
          className={clsx(
            'flex justify-center items-center absolute h-full w-full bg-white/30 z-10',
            { hidden: !loading }
          )}
        >
          <SpinningIcon />
        </div>
        <DeckGL
          layers={layers}
          viewState={viewState}
          controller={true}
          onViewStateChange={handleViewStateChange}
        >
          <Map
            reuseMaps
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={MAP_STYLE}
          />
        </DeckGL>
      </div>
    </div>
  );
}
