import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  InboxIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { get } from 'lodash';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { theme } from 'twin.macro';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import SpinningIcon from '../../assets/icons/SpinningIcon';
import { convertToFloat, parseMoney } from '../../utils';
import Button from './Button';
import Checkbox from './Checkbox';
import Currency from './Currency';
import Fluctuation from './Fluctuation';
import IconButton from './inputs/IconButton';
import TextInput from './inputs/TextInput';
import NumberDisplay from './NumberDisplay';
import Pagination, { usePagination } from './Pagination';

export interface Highlight {
  rowKey: string;
  columnKey: string;
}

export interface TableConfigs {
  cols: {
    key: string;
    name?: string | ReactNode;
    type?: 'currency' | 'fluctuation' | 'number' | 'boolean' | 'element';
    unit?: string;
    numberStyle?: 'unit' | 'currency' | 'percent' | 'decimal';
    currency?: string;
    total?: boolean;
    element?: (item: any) => JSX.Element;
    merged?: boolean;
    className?: string;
  }[];
}

export interface RowActions {
  edit?: {
    onSelect: (item: any) => void;
    form?: (params: { item: any; onClose: () => void }) => JSX.Element;
  };
  delete?: (item: any) => void;
  note?: {
    title: string;
    onSelect?: (item: any) => void;
    onSave: (item: any, value: any) => void;
    onSaveAll?: (item: any, value: any) => void;
  };
}

export interface TableProps {
  configs: TableConfigs;
  data: Record<string, any>[];
  loading?: boolean;
  title?: {
    name: string;
    tooltip?: string;
  };
  noDataTitle?: string;
  hasTotalRow?: boolean;
  tableActions?: ReactNode;
  totalRowVariant?: 'primary' | 'secondary';
  extraActions?: (item: any) => JSX.Element;
  rowActions?: RowActions;
  pagination?: {
    size: number;
  };
  handleFilter?: (data: Record<string, any>[]) => Record<string, any>[];
  highlights?: string[];
  highlightCells?: Highlight[];
  indexKey?: string;
  checkedRows?: string[];
  onRowCheck?: (item: any, checked: boolean) => void;
  onRowClick?: (item: any) => void;
  tableFixed?: boolean;
  header?: boolean;
  subRow?: (item: any) => JSX.Element | null;
  initNote?: string;
}

const getTotal = (data: Record<string, any>[], key: string) => {
  return data?.reduce((acc, curr) => acc + Number(curr[key] ?? 0), 0);
};

function Table({
  configs,
  data = [],
  hasTotalRow,
  title,
  noDataTitle = 'No data',
  tableActions,
  totalRowVariant = 'primary',
  rowActions,
  extraActions,
  pagination,
  handleFilter,
  loading,
  highlights,
  highlightCells,
  indexKey = 'id',
  checkedRows = [],
  onRowCheck,
  onRowClick,
  tableFixed = false,
  header = true,
  subRow,
  initNote,
}: TableProps) {
  // Pagination
  const filteredData = handleFilter ? handleFilter(data) : data;
  const { page, pageSize, skip, endIndex, setPage, onNextPage, onPrevPage } =
    usePagination({
      size: pagination?.size || 10,
      total: filteredData.length,
    });
  const totalPage = Math.ceil(filteredData?.length / pageSize);
  const tableData = pagination
    ? filteredData?.slice(skip, endIndex)
    : filteredData;

  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [noteIndex, setNoteIndex] = useState<number | undefined>();
  const [note, setNote] = useState<string>();

  useEffect(() => {
    setNote(initNote);
  }, [initNote]);

  // Find pages of the highlights
  const highlightPages = useMemo(() => {
    const highlightIndices = highlights?.map((highlight) =>
      filteredData.findIndex((item) => item[indexKey] === highlight)
    );
    return highlightIndices?.map((index) => Math.floor(index / pageSize) + 1);
  }, [filteredData, highlights, indexKey, pageSize]);

  useEffect(() => {
    if (highlightPages?.length) {
      const highlightPage = highlightPages[0];
      if (highlightPage) {
        setPage(highlightPage);
      }
    }
  }, [highlightPages]);

  const tdContent = (item: any, key: any, config: (typeof configs.cols)[0]) => {
    const value = get(item, key)
      ? get(item, key) === 'nan'
        ? '-'
        : get(item, key)
      : '-';

    switch (config.type) {
      case 'currency':
        if (value === '-') return value;
        return (
          <Currency
            value={
              convertToFloat(parseMoney(value.toString())?.toString() ?? '0') ??
              0
            }
          />
        );
      case 'fluctuation':
        return (
          <Fluctuation
            value={convertToFloat(value) ?? 0}
            unit={config.unit}
            currency={config.currency}
          />
        );
      case 'number':
        if (value === '-') return value;
        const displayValue =
          config.numberStyle === 'percent' ? value / 100 : value;
        return (
          <NumberDisplay
            value={convertToFloat(displayValue) ?? 0}
            numberStyle={config.numberStyle}
          />
        );
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'element':
        return config.element?.(item);
      default:
        return `${value} ${config.unit ?? ''}`;
    }
  };

  const shouldHighlightCell = (rowKey: string, columnKey: string): boolean => {
    if (highlightCells) {
      return highlightCells.some(
        (highlight) =>
          highlight.rowKey === rowKey && highlight.columnKey === columnKey
      );
    } else if (highlights) {
      return highlights.includes(rowKey);
    }
    return false;
  };

  const handleClickNote = (item: any, index: number) => {
    rowActions?.note?.onSelect?.(item);
    if (noteIndex === index) {
      setNoteIndex(undefined);
    } else {
      setNoteIndex(index);
    }
    setNote('');
  };

  const handleSaveNote = (item: any) => {
    rowActions?.note?.onSave(item, note);
    setNote('');
    setNoteIndex(undefined);
  };

  const handleSaveNoteAll = (item: any) => {
    rowActions?.note?.onSaveAll?.(item, note);
    setNote('');
    setNoteIndex(undefined);
  };

  const handleClickEdit = (item: any, index: number) => {
    if (rowActions?.edit?.form) {
      if (editIndex === index) {
        setEditIndex(undefined);
      } else {
        setEditIndex(index);
      }
    }
    rowActions?.edit?.onSelect(item);
  };

  useEffect(() => {
    setEditIndex(undefined);
    setNoteIndex(undefined);
  }, [page]);

  return (
    <div>
      <div className="overflow-x-auto overflow-y-hidden relative">
        <table
          className={clsx('border border-base-1000 w-full text-center', {
            'table-fixed': tableFixed,
          })}
        >
          <thead>
            <tr>
              <th colSpan={configs.cols.length + 1} className="p-0">
                {title && (
                  <div className="text-xl-bold flex justify-between px-4 py-2 bg-base-800">
                    <span className="flex items-center space-x-2">
                      <h4>{title.name}</h4>
                      {title.tooltip && (
                        <InformationCircleIcon className="size-6 fill-secondary" />
                      )}
                    </span>

                    {tableActions}
                  </div>
                )}
              </th>
            </tr>
          </thead>
          {header && (
            <thead>
              <tr className="bg-base-900">
                {configs.cols.map((col) => (
                  <th
                    className={clsx(
                      'border-base-1000 border-b-base-900 p-2',
                      !col.merged && 'border-l',
                      col.className
                    )}
                    key={col.key}
                  >
                    {col.name}
                  </th>
                ))}
                {onRowCheck && (
                  <th className="border border-base-1000 border-b-base-900 p-2">
                    Select
                  </th>
                )}
                {rowActions?.note && (
                  <th className="border border-base-1000 border-b-base-900 p-2">
                    Note
                  </th>
                )}
                {(rowActions || extraActions) && (
                  <th className="border border-base-1000 border-b-base-900 p-2">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
          )}

          <tbody>
            {tableData?.map((item, index) => (
              <Fragment key={index}>
                <tr
                  key={index}
                  className={clsx('odd:bg-base-1100', 'even:bg-base-1000')}
                  onClick={() => onRowClick?.(item)}
                >
                  {configs.cols.map((col) => (
                    <td
                      key={col.key}
                      className={clsx(
                        'border-b border-base-900 p-2',
                        !col.merged && 'border-l',
                        col.className
                      )}
                      style={{
                        ...(shouldHighlightCell(item[indexKey], col.key)
                          ? {
                              boxShadow:
                                '3px 3px 6px 0px #80BC0080 inset,-3px -3px 7px 0px #80BC0080 inset',
                            }
                          : {}),
                      }}
                    >
                      {tdContent(item, col.key, col)}
                    </td>
                  ))}

                  {onRowCheck && (
                    <td className="border border-base-900 p-2 w-0">
                      <span className="flex justify-center">
                        <Checkbox
                          name="select"
                          onChange={(checked) => onRowCheck?.(item, checked)}
                          {...(checkedRows?.includes(item?.[indexKey])
                            ? { checked: true }
                            : {})}
                        />
                      </span>
                    </td>
                  )}
                  {rowActions?.note && (
                    <td className="border border-base-900 p-2 w-0">
                      <span className="flex items-center justify-center">
                        <button onClick={() => handleClickNote(item, index)}>
                          <DocumentIcon
                            {...(noteIndex === index
                              ? { fill: theme`colors.secondary` }
                              : {})}
                          />
                        </button>
                      </span>
                    </td>
                  )}
                  {(rowActions || extraActions) && (
                    <td className="border border-base-900 p-2 w-0">
                      <span className="flex space-x-1">
                        {rowActions?.edit && (
                          <IconButton
                            onClick={() => handleClickEdit(item, index)}
                          >
                            <PencilSquareIcon className="size-3" />
                          </IconButton>
                        )}

                        {rowActions?.delete && (
                          <IconButton
                            onClick={() => {
                              if (
                                confirm(
                                  'Are you sure you want to delete this item?'
                                )
                              ) {
                                rowActions.delete?.(item);
                                if (index === noteIndex) {
                                  setNoteIndex(undefined);
                                }
                                if (index === editIndex) {
                                  setEditIndex(undefined);
                                }
                              }
                            }}
                          >
                            <TrashIcon className="size-3" />
                          </IconButton>
                        )}
                        {extraActions?.(item)}
                      </span>
                    </td>
                  )}
                </tr>
                {noteIndex === index && (
                  <tr key={`note-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900"
                    >
                      <div className="p-4 pt-2 space-y-2 text-left">
                        <p className="text-sm-bold">
                          {rowActions?.note?.title}
                        </p>
                        <div className="bg-base-400 p-2 rounded-lg space-y-4">
                          <TextInput
                            name="note"
                            value={note}
                            className="!bg-base-000 !border-0 text-base-1100"
                            onChange={(e) => setNote(e.target.value)}
                          />
                          <div className="flex space-x-4 justify-end">
                            <Button
                              size="sm"
                              color="primary"
                              className="!w-fit"
                              onClick={() => handleSaveNote(item)}
                            >
                              Save
                            </Button>
                            {rowActions?.note?.onSaveAll && (
                              <Button
                                size="sm"
                                color="primary"
                                className="!w-fit"
                                onClick={() => handleSaveNoteAll(item)}
                              >
                                Save and Apply to all zone
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {editIndex === index && (
                  <tr key={`edit-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900"
                    >
                      <div className="p-4 pt-2 text-left">
                        {rowActions?.edit?.form?.({
                          item,
                          onClose: () => setEditIndex(undefined),
                        })}
                      </div>
                    </td>
                  </tr>
                )}
                {subRow?.(item) && (
                  <Disclosure as={'tr'} key={`sub-${index}`}>
                    <td
                      colSpan={configs.cols.length + 2}
                      className="border border-base-900"
                    >
                      <DisclosureButton className="w-full flex justify-center items-center p-1 text-sm">
                        <ChevronDownIcon className="size-4" />
                      </DisclosureButton>
                      <DisclosurePanel>
                        <div className={clsx('p-4 pt-2')}>{subRow(item)}</div>
                      </DisclosurePanel>
                    </td>
                  </Disclosure>
                )}
              </Fragment>
            ))}

            {/* Total row */}
            {data?.length > 0 &&
              hasTotalRow &&
              totalRowVariant === 'primary' && (
                <tr className="bg-block-fill">
                  <td className="px-4 py-2 text-md-bold">
                    <p>Totals</p>
                  </td>
                  {configs.cols.map(
                    (col, index) =>
                      // reduce a col give place for the total
                      index !== 0 && (
                        <td key={col.key} className="p-2">
                          {col.total && (
                            <>
                              {col.type === 'currency' && (
                                <Currency
                                  className={clsx(
                                    getTotal(data, col.key) < 0
                                      ? 'text-red'
                                      : 'text-primary'
                                  )}
                                  value={getTotal(data, col.key) ?? 0}
                                />
                              )}
                              {col.type === 'fluctuation' && (
                                <Fluctuation
                                  value={getTotal(data, col.key) ?? 0}
                                  unit={col.unit}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'number' && (
                                <NumberDisplay
                                  className={clsx(
                                    getTotal(data, col.key) < 0
                                      ? 'text-red'
                                      : 'text-primary'
                                  )}
                                  value={getTotal(data, col.key) ?? 0}
                                />
                              )}
                            </>
                          )}
                        </td>
                      )
                  )}
                </tr>
              )}

            {data?.length > 0 &&
              hasTotalRow &&
              totalRowVariant === 'secondary' && (
                <tr className="bg-base-700 text-base-1100 text-md-bold">
                  <td className="px-4 py-2 text-md-bold">
                    <p>TOTALS</p>
                  </td>
                  {configs.cols.map(
                    (col, index) =>
                      // reduce a col give place for the total
                      index !== 0 && (
                        <td
                          key={col.key}
                          className="p-2 border border-base-900"
                        >
                          {col.total && (
                            <>
                              {col.type === 'currency' && (
                                <Currency
                                  value={getTotal(data, col.key) ?? 0}
                                />
                              )}
                              {col.type === 'fluctuation' && (
                                <Fluctuation
                                  value={getTotal(data, col.key) ?? 0}
                                  unit={col.unit}
                                  currency={col.currency}
                                />
                              )}
                              {col.type === 'number' && (
                                <NumberDisplay
                                  value={getTotal(data, col.key) ?? 0}
                                />
                              )}
                            </>
                          )}
                        </td>
                      )
                  )}
                </tr>
              )}
          </tbody>
        </table>
        {loading && (
          <div className="absolute w-full h-full top-0 right-0 bg-base-1000 opacity-50 flex items-center justify-center">
            <SpinningIcon />
          </div>
        )}
        {data?.length === 0 && (
          <div className="w-full flex flex-col items-center justify-center h-[300px] opacity-20 space-y-2">
            {!loading && <p className="display-xs-regular">{noDataTitle}</p>}
            <InboxIcon className="size-12" />
          </div>
        )}
      </div>
      {/* Footer */}
      <div className={clsx('relative', onRowCheck && 'min-h-[40px]')}>
        <div className="px-3 absolute bottom-2">
          {checkedRows?.length > 0 && (
            <p className="whitespace-nowrap">{checkedRows.length} selected</p>
          )}
        </div>
        {pagination && totalPage > 0 && (
          <Pagination
            page={page}
            totalPage={totalPage}
            setPage={setPage}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
          />
        )}
      </div>
    </div>
  );
}

export default Table;
