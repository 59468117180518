import { IconProps } from '../../types';

const FieldInfoAndBoundaryIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58929 21.8571C6.05357 23.1429 4.82143 24 3.42857 24C1.5 24 0 22.5 0 20.5714C0 19.1786 0.857143 17.9464 2.14286 17.4107V6.64286C0.857143 6.10714 0 4.875 0 3.42857C0 1.55357 1.5 0 3.42857 0C4.82143 0 6.05357 0.910714 6.58929 2.14286H17.3571C17.8929 0.910714 19.125 0 20.5714 0C22.4464 0 24 1.55357 24 3.42857C24 4.875 23.0893 6.10714 21.8571 6.64286V17.4107C23.0893 17.9464 24 19.1786 24 20.5714C24 22.5 22.4464 24 20.5714 24C19.125 24 17.8929 23.1429 17.3571 21.8571H6.58929ZM3.42857 4.28571C3.85714 4.28571 4.28571 3.91071 4.28571 3.42857C4.28571 3 3.85714 2.57143 3.42857 2.57143C2.94643 2.57143 2.57143 3 2.57143 3.42857C2.57143 3.91071 2.94643 4.28571 3.42857 4.28571ZM4.71429 17.4107C5.57143 17.7857 6.21429 18.4286 6.58929 19.2857H17.3571C17.7321 18.4286 18.375 17.7857 19.2857 17.4107V6.64286C18.375 6.26786 17.7321 5.57143 17.3571 4.71429H6.58929C6.21429 5.57143 5.57143 6.26786 4.71429 6.64286V17.4107ZM3.42857 19.7143C2.94643 19.7143 2.57143 20.1429 2.57143 20.5714C2.57143 21.0536 2.94643 21.4286 3.42857 21.4286C3.85714 21.4286 4.28571 21.0536 4.28571 20.5714C4.28571 20.1429 3.85714 19.7143 3.42857 19.7143ZM20.5714 19.7143C20.0893 19.7143 19.7143 20.1429 19.7143 20.5714C19.7143 21.0536 20.0893 21.4286 20.5714 21.4286C21 21.4286 21.4286 21.0536 21.4286 20.5714C21.4286 20.1429 21 19.7143 20.5714 19.7143ZM20.5714 2.57143C20.0893 2.57143 19.7143 3 19.7143 3.42857C19.7143 3.91071 20.0893 4.28571 20.5714 4.28571C21 4.28571 21.4286 3.91071 21.4286 3.42857C21.4286 3 21 2.57143 20.5714 2.57143Z"
        fill={fill}
      />
    </svg>
  );
};

export default FieldInfoAndBoundaryIcon;
