import { useQuery } from '@tanstack/react-query';
import { getPricesApi, getSpreadsApi } from '../../api/market-api';

export const useSpreads = (root: string) => {
  const res = useQuery({
    queryKey: ['market/spreads', root],
    queryFn: () => getSpreadsApi(root),
    enabled: !!root,
  });
  return { data: res.data };
};

export const useSpreadPrices = (symbol: string) => {
  const res = useQuery({
    queryKey: ['market/spread-prices', symbol],
    queryFn: () => getPricesApi(symbol),
    enabled: !!symbol,
  });

  return { data: res.data };
};
