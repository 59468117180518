import Button from '../../../components/commons/Button';
import CardHeader from '../../../components/commons/CardHeader';
import AddFarmExpenseForm from '../../../components/data-inputs/farm-expenses/AddFarmExpenseForm';
import FarmExpenseList from '../../../components/data-inputs/farm-expenses/FarmExpenseList';

export default function FarmExpenses() {
  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-3 space-y-8">
        <div className="card !rounded-lg p-4">
          <AddFarmExpenseForm />
        </div>

        <div className="card !rounded-lg p-4 space-y-6">
          <CardHeader title="View Saved Farm Expense Items" />
          <p>
            Return to previously saved farm expense items that have not yet been
            submitted.
          </p>
          <Button color="primary">View Saved Farm Expense Items</Button>
        </div>
      </div>

      <div className="card !rounded-lg col-span-9 overflow-hidden h-fit">
        <FarmExpenseList />
      </div>
    </div>
  );
}
