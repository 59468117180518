import { IconProps } from '../../types';

const MapTrashIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32812 0.84375C6.5625 0.328125 7.07812 0 7.64062 0H13.3125C13.875 0 14.3906 0.328125 14.625 0.84375L15 1.5H19.5C20.2969 1.5 21 2.20312 21 3C21 3.84375 20.2969 4.5 19.5 4.5H1.5C0.65625 4.5 0 3.84375 0 3C0 2.20312 0.65625 1.5 1.5 1.5H6L6.32812 0.84375ZM18.4688 21.8906C18.4219 23.1094 17.4375 24 16.2188 24H4.73438C3.51562 24 2.53125 23.1094 2.48438 21.8906L1.45312 6H19.5L18.4688 21.8906Z"
        fill={fill}
      />
    </svg>
  );
};

export default MapTrashIcon;
