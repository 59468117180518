import { IconProps } from '../../types';

export default function DocumentIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6429 10.2857C11.1429 10.2857 10.7857 10.6786 10.7857 11.1429V16H1.35714C0.857143 16 0.5 15.6429 0.5 15.1429V0.857143C0.5 0.392857 0.857143 0 1.35714 0H15.6429C16.1071 0 16.5 0.392857 16.5 0.857143V10.2857H11.6429ZM16.25 12.25L12.75 15.75C12.5714 15.9286 12.3571 16 12.1429 16H11.9286V11.4286H16.5V11.6786C16.5 11.8929 16.3929 12.1071 16.25 12.25Z"
        fill={fill}
      />
    </svg>
  );
}
