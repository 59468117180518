import { useQuery } from '@tanstack/react-query';
import { getFieldMachinesApi } from '../../api/field-api';

export const useFieldMachines = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field/prescription/export/transfer-jd-machine', userId],
    queryFn: () => getFieldMachinesApi(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  return { data: data?.data, ...rest };
};
