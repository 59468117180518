import Market from '../../pages/dashboard/market';

const marketsRoutes = [
  {
    path: 'markets',
    element: <Market />,
  },
];

export default marketsRoutes;
