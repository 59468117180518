import CardHeader from '../../../commons/CardHeader';
import { Legend } from '../../../commons/inputs/SliderInput';

export default function SubtotalInfo() {
  const chartLegend = [
    {
      label: 'Seeding Totals',
      color: 'bg-base-300',
    },
    {
      label: 'Fertilizer Totals',
      color: 'bg-base-800',
    },
    {
      label: 'Chemical Totals',
      color: 'bg-base-500',
    },
    {
      label: 'Micronutrient Totals',
      color: 'bg-base-1000',
    },
  ];

  return (
    <div className="space-y-8">
      <CardHeader title="Prescription Subtotals" tooltip="tooltip" />
      <div>
        <h4 className="text-md-bold">Prescription Breakdown</h4>
        <p className="text-sm-regular text-base-500">
          Your prescription details.
        </p>
        <hr className="mt-5" />
      </div>

      <div className="flex flex-col items-center space-y-5">
        <svg
          width="235"
          height="236"
          viewBox="0 0 235 236"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="117.5" cy="118" r="117.5" fill="#F9FAFB" />
          <path
            d="M235 118C235 140.75 228.396 163.011 215.988 182.08C203.581 201.15 185.905 216.207 165.106 225.424C144.306 234.641 121.279 237.622 98.8178 234.005C76.357 230.388 55.4293 220.328 38.5754 205.047L117.5 118H235Z"
            fill="#5C7284"
          />
          <path
            d="M39.3106 205.708C22.1413 190.402 9.84193 170.393 3.93742 148.163C-1.9671 125.932 -1.21707 102.458 6.09452 80.6494L117.5 118L39.3106 205.708Z"
            fill="#323E48"
          />
          <path
            d="M117.5 0.5C132.93 0.5 148.21 3.53923 162.465 9.44416C176.721 15.3491 189.674 24.0041 200.585 34.915C211.496 45.8258 220.151 58.779 226.056 73.0347C231.961 87.2905 235 102.57 235 118L117.5 118V0.5Z"
            fill="#A3B4C2"
          />
          <path
            d="M6.31915 79.9859C14.2483 56.7952 29.2308 36.6644 49.1692 22.4116C69.1076 8.15873 93.0037 0.497383 117.513 0.500001L117.5 118L6.31915 79.9859Z"
            fill="#D5DCE2"
          />
        </svg>

        <div className="grid grid-cols-4 gap-4">
          {chartLegend.map((legend, index) => (
            <span className="col-span-2">
              <Legend key={index} color={legend.color} label={legend.label} />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
