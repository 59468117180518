import clsx from 'clsx';
import { useState } from 'react';
import { theme } from 'twin.macro';
import ArrowDownCircleIcon from '../../../../assets/icons/ArrowDownCircleIcon';
import ArrowUpCircleIcon from '../../../../assets/icons/ArrowUpCircleIcon';
import CaretDownIcon from '../../../../assets/icons/CaretDownIcon';
import DocumentPlusCircleIcon from '../../../../assets/icons/DocumentPlusCircleIcon';
import DollarIcon from '../../../../assets/icons/DollarIcon';
import TreeSproutCirleIcon from '../../../../assets/icons/TreeSproutCirleIcon';
import CardHeader from '../../../commons/CardHeader';

const HedgeTypeOptions = [
  {
    value: 'Futures',
    label: 'Futures',
    icon: DocumentPlusCircleIcon,
  },
  { value: 'Put', label: 'Put', icon: ArrowUpCircleIcon },
  { value: 'Call', label: 'Call', icon: ArrowDownCircleIcon },
  { value: 'Cash', label: 'Cash', icon: DollarIcon },
  { value: 'Basis', label: 'Basis', icon: TreeSproutCirleIcon },
];

export default function HedgingTypeForm({
  title,
  onChange,
}: {
  title: string;
  onChange: (type: string) => void;
}) {
  const [open, setOpen] = useState(true);
  return (
    <div className="space-y-8">
      <CardHeader
        title={open ? 'Select Hedge Type' : 'Add Hedge Options'}
        tooltip={'tooltip'}
      />
      <div>
        <h4 className="text-md-bold">Current Hedging Breakdown</h4>
        <p className="text-sm-regular text-base-500">
          What kind of hedging would you like to add?
        </p>
        <hr className="mt-5" />
      </div>

      {/* Type list */}
      <div>
        <div className="relative">
          <div
            className={clsx(
              'border bg-block-fill px-4 py-5 w-full text-xl-bold cursor-pointer',
              open ? 'border-transparent' : 'rounded-xl border-base-1000'
            )}
            onClick={() => setOpen((prev) => !prev)}
          >
            {title}
          </div>
          {!open && (
            <span className="pointer-events-none absolute -translate-y-2/4 top-[50%] right-4 fill-base-000">
              <CaretDownIcon />
            </span>
          )}
        </div>

        {open && (
          <ul className="px-4">
            {HedgeTypeOptions.map((option) => (
              <li
                className={clsx(
                  'group',
                  ' cursor-pointer border-b border-base-500 py-4 px-3 text-base-500 text-md-regular flex items-center space-x-2',
                  'hover:text-base-000',
                  'last:border-b-0',
                  'first:pt-2'
                )}
                onClick={() => onChange(option.value)}
              >
                {option?.icon ? (
                  <option.icon fill={theme`colors.base.500`} />
                ) : (
                  <></>
                )}
                <span>{option.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
