import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldIcon, GridIcon, ZoneIcon } from '../../../assets/icons';
import { SoilSampleFormKeys } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  useBLMData,
  useCreateSoilSample,
  useSoilSampleZoneFieldOptions,
} from '../../../hooks/soil-sample/use-soil-sample';
import { useDebounce } from '../../../hooks/use-debounce';
import { useProcessCSV } from '../../../hooks/use-process-csv';
import { SoilSampleFormValues } from '../../../types';
import { getDate, removeQuotes } from '../../../utils';
import Button from '../../commons/Button';
import CardHeader from '../../commons/CardHeader';
import ComboDatePicker from '../../commons/inputs/ComboDatePicker';
import RadioInput from '../../commons/inputs/RadioInput';
import TextInput from '../../commons/inputs/TextInput';
import { AwaitSelect } from '../../commons/Select';

const SoilSampleTypes = [
  {
    value: 'zone',
    label: 'Zone',
    icon: ZoneIcon,
  },
  {
    value: 'point',
    label: 'Grid',
    icon: GridIcon,
  },
  {
    value: 'field',
    label: 'Field',
    icon: FieldIcon,
  },
];

const ignoreValues = ['pre', 'zone', 'field'];

export default function AddSoilSampleForm() {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });
  const { mutateAsync: create, isPending } = useCreateSoilSample();

  const type = form.watch('pre.soil_sample_type');
  useEffect(() => {
    form.setValue('soil_sample_type', type);
  }, [type]);

  const {
    zoneOptions,
    fieldOptions,
    isFetching: isFetchingOptions,
  } = useSoilSampleZoneFieldOptions(user?.id ?? '', year ?? '');

  const [step, setStep] = useState<
    'first' | 'zone' | 'field' | 'point' | 'details'
  >('first');

  const handleFirstStep = () => {
    switch (type) {
      case 'zone':
        setStep('zone');
        break;
      case 'field':
        setStep('field');
        break;
      case 'point':
        setStep('point');
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (values: any) => {
    if (!user) return;

    // filter ignore values
    const cleanValues: SoilSampleFormValues = Object.keys(values)
      .filter((key) => !ignoreValues.includes(key))
      .reduce((acc: SoilSampleFormValues, key) => {
        acc[key] = values[key];
        return acc;
      }, {} as SoilSampleFormValues);

    try {
      await create({
        userId: user.id,
        samples: [cleanValues],
      });
      enqueueSnackbar('Soil Sample submitted successfully', {
        variant: 'success',
      });
      form.reset();
      setStep('first');
    } catch (error) {
      enqueueSnackbar('Failed to create soil sample', { variant: 'error' });
    }
  };

  // Manually set other form values based on soil sample type
  const zone = form.watch('zone');
  const otherZone = form.watch('pre.other_zone');

  useEffect(() => {
    form.setValue('other_zone', otherZone);
  }, [otherZone]);

  useEffect(() => {
    if (zone) {
      const zoneDetails = JSON.parse(zone);
      form.setValue('geometry', zoneDetails.geometry);
      form.setValue('legal_description', zoneDetails.legal_description);
      form.setValue('range', zoneDetails.range);
      form.setValue('section', zoneDetails.section);
      form.setValue('township', zoneDetails.township);
      form.setValue('other_zone', zoneDetails.other_zone);
      form.setValue('geometry', JSON.stringify(zoneDetails.geometry));
    }
  }, [zone]);

  const gridLat = useDebounce(form.watch('lat'));
  const gridLon = useDebounce(form.watch('lon'));
  const { data: blmData, isFetching: isFetchingBLMData } = useBLMData(
    gridLat,
    gridLon
  );
  useEffect(() => {
    if (type === 'point' && blmData) {
      form.setValue('legal_description', blmData?.legal_description || '');
      form.setValue('range', blmData?.range || '');
      form.setValue('section', blmData?.section || '');
      form.setValue('township', blmData?.township || '');
    }
  }, [blmData, type]);

  const field = form.watch('field');
  useEffect(() => {
    if (field) {
      const fieldDetails = JSON.parse(field);
      form.setValue('geometry', JSON.stringify(fieldDetails.geometry));
      form.setValue('legal_description', fieldDetails.legal_description);
      form.setValue('legals_json', JSON.stringify(fieldDetails.legals_json));
      form.setValue('range', fieldDetails.range);
      form.setValue('section', fieldDetails.section);
      form.setValue('township', fieldDetails.township);
    }
  }, [field]);

  // Handle upload file
  // Fill form with csv file data
  const { processCSV, data: csvData, loading: loadingFile } = useProcessCSV();
  useEffect(() => {
    if (csvData?.length > 0) {
      SoilSampleFormKeys.forEach((key) => {
        if (key === 'Date') {
          form.setValue(key, getDate(csvData[0][key]));
        } else if (key === 'geometry') {
          form.setValue(key, removeQuotes(csvData[0][key]));
        } else {
          form.setValue(key, csvData[0][key]);
        }
        form.trigger(key);
      });
    }
  }, [csvData, form.setValue]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const fileExtension = file?.type.split('/')[1];
    if (fileExtension !== 'csv') {
      enqueueSnackbar('Invalid file type. Please upload a CSV file', {
        variant: 'error',
      });
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target?.result as string;
        processCSV(text);
      };
      reader.readAsText(file);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-6">
      <CardHeader title="Add New Soil Samples" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} key={`${isPending}`}>
          {/* First Step */}
          <div className="space-y-5" hidden={step !== 'first'}>
            <p>Please choose an area to submit samples for.</p>
            {SoilSampleTypes.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="pre.soil_sample_type"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800"
                required={step === 'first'}
              />
            ))}
            <Button color="primary" onClick={handleFirstStep} disabled={!type}>
              Next
            </Button>
          </div>

          {/* zone step */}
          <div className="space-y-5" hidden={step !== 'zone'}>
            <p>Please choose the area zone and number.</p>
            <AwaitSelect
              label="Zone"
              name="zone"
              options={zoneOptions || []}
              isLoading={false}
              required={step === 'zone'}
            />
            <TextInput
              name="pre.other_zone"
              label="Zone Number"
              disabled={!zone}
              required={step === 'zone'}
            />
            <Button
              color="primary"
              disabled={!zone || !otherZone || isFetchingOptions}
              onClick={() => setStep('details')}
              loading={isFetchingOptions}
            >
              Next
            </Button>
          </div>

          {/* grid step */}
          <div className="space-y-5" hidden={step !== 'point'}>
            <p>Please input the Latitude and Longitude.</p>
            <TextInput
              name="lat"
              label="Latitude"
              required={step === 'point'}
            />
            <TextInput
              name="lon"
              label="Longitude"
              required={step === 'point'}
            />
            <Button
              color="primary"
              disabled={!gridLat || !gridLon || isFetchingBLMData}
              onClick={() => setStep('details')}
              loading={isFetchingBLMData}
            >
              Next
            </Button>
          </div>

          {/* field step */}
          <div className="space-y-5" hidden={step !== 'field'}>
            <p>Please choose field.</p>
            <AwaitSelect
              label="Field"
              name="field"
              options={fieldOptions || []}
              isLoading={false}
              required={step === 'field'}
            />
            <Button
              color="primary"
              disabled={isFetchingOptions || !field}
              onClick={() => setStep('details')}
              loading={isFetchingOptions}
            >
              Next
            </Button>
          </div>

          {/* Details step */}
          <div
            className={clsx(
              {
                hidden: step !== 'details',
              },
              'space-y-4'
            )}
          >
            <p className="text-secondary">* Indicates Required Field</p>
            <div className="flex gap-4">
              <Button
                startIcon={<ArrowUpTrayIcon className="size-4" />}
                className="relative"
                loading={loadingFile}
                disabled={loadingFile}
              >
                Upload File
                <input
                  type="file"
                  name="file"
                  className="absolute top-0 left-0 opacity-0 w-full h-full"
                  onChange={handleUploadFile}
                  multiple={false}
                  ref={fileInputRef}
                  disabled={loadingFile}
                />
              </Button>
              <Button
                startIcon={<ArrowDownTrayIcon className="size-4" />}
                className="relative"
              >
                <a
                  href={`${process.env.PUBLIC_URL}/soil-sample-template.csv`}
                  className="absolute top-0 left-0 opacity-0 w-full h-full"
                >
                  {' '}
                </a>
                Sample File
              </Button>
            </div>
            <ComboDatePicker name="Date" label="Date of Sample" required />
            <TextInput
              name="soil_sample_type"
              label="Soil Sample Type"
              disabled
            />
            <TextInput
              name="legal_description"
              label="Legal Description"
              required
            />
            <TextInput name="geometry" label="Geometry" required />
            <TextInput name="other_zone" label="Zone Number" required />
            <TextInput name="section" label="Section" required />
            <TextInput name="township" label="Township" required />
            <TextInput name="range" label="Range" required />

            <div className="grid grid-cols-3 gap-4">
              <TextInput name="Zn_ppm" label="Zn ppm" required />
              <TextInput name="S1_lb" label="S1 lb" required />
              <TextInput name="S2_lb" label="S2 lb" required />
              <TextInput name="Salt1" label="Salt 1" required />
              <TextInput name="Salt2" label="Salt 2" required />
              <TextInput name="pH" label="pH Level" required />
              <TextInput name="P_ppm" label="P ppm" required />
              <TextInput name="N1_lb" label="N1 lb" required />
              <TextInput name="N2_lb" label="N2 lb" required />
              <TextInput name="Na_ppm" label="Na lb" required />
              <TextInput name="OM" label="OM" required />
              <TextInput name="Cu_ppm" label="Cu ppm" required />
              <TextInput name="K_ppm" label="K ppm" required />
              <TextInput name="Mg_ppm" label="Mg ppm" required />
              <TextInput name="Ca_ppm" label="Ca ppm" required />
              <TextInput name="CEC_meq" label="CEC meq" required />
              <TextInput name="BS_Na_pct" label="BS Na %" required />
              <TextInput name="BS_Mg_pct" label="BS Mg %" required />
              <TextInput name="BS_K_pct" label="BS K %" required />
              <TextInput name="BS_Ca_pct" label="BS Ca %" required />
            </div>

            <div className="flex gap-4">
              <Button>Save Sample</Button>
              <Button
                type="submit"
                color="primary"
                disabled={!form.formState.isValid || isPending}
                loading={isPending}
              >
                Submit Sample
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
