import { IconProps } from '../../types';

const APHHistoryIcon = ({ fill = '#455663' }: IconProps) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 2.25C7.5 1.03125 8.48438 0 9.75 0H11.25C12.4688 0 13.5 1.03125 13.5 2.25V18.75C13.5 20.0156 12.4688 21 11.25 21H9.75C8.48438 21 7.5 20.0156 7.5 18.75V2.25ZM0 11.25C0 10.0312 0.984375 9 2.25 9H3.75C4.96875 9 6 10.0312 6 11.25V18.75C6 20.0156 4.96875 21 3.75 21H2.25C0.984375 21 0 20.0156 0 18.75V11.25ZM18.75 3C19.9688 3 21 4.03125 21 5.25V18.75C21 20.0156 19.9688 21 18.75 21H17.25C15.9844 21 15 20.0156 15 18.75V5.25C15 4.03125 15.9844 3 17.25 3H18.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default APHHistoryIcon;
