import { IconProps } from '../../types';

const DollarUnlockIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.5H6.9V7.25C6.9 5.18 8.58 3.5 10.65 3.5C12.72 3.5 14 4.43 14 6.5V6.25C14 7.08 14.67 7.75 15.5 7.75C16.33 7.75 16.95 7.125 16.99 6.335C16.905 2.685 14.32 0.5 10.65 0.5C6.98 0.5 3.9 3.52 3.9 7.25L3.795 9.5H3C1.345 9.5 0 10.845 0 12.5V21.5C0 23.155 1.345 24.5 3 24.5H18C19.655 24.5 21 23.155 21 21.5V12.5C21 10.845 19.655 9.5 18 9.5ZM13.4 18.79C13.23 19.77 12.485 20.415 11.44 20.64V20.935C11.44 21.45 11.02 21.875 10.5 21.875C9.98 21.875 9.56 21.455 9.56 20.935V20.62C9.155 20.53 8.75 20.4 8.385 20.27L8.185 20.2C7.695 20.025 7.44 19.49 7.615 19.005C7.79 18.515 8.325 18.265 8.81 18.43L9.02 18.505C9.39 18.635 9.805 18.785 10.13 18.835C10.775 18.935 11.485 18.845 11.55 18.48C11.59 18.24 11.615 18.11 10.24 17.715L9.97 17.635C9.16 17.395 7.255 16.84 7.6 14.83C7.77 13.85 8.51 13.195 9.56 12.97V12.69C9.56 12.175 9.98 11.75 10.5 11.75C11.02 11.75 11.44 12.17 11.44 12.69V13C11.695 13.055 11.97 13.13 12.31 13.245C12.8 13.415 13.06 13.95 12.89 14.44C12.72 14.93 12.185 15.185 11.695 15.02C11.42 14.925 11.135 14.83 10.87 14.795C10.23 14.695 9.515 14.785 9.45 15.15C9.415 15.32 9.39 15.51 10.46 15.84L10.76 15.915C11.805 16.21 13.75 16.77 13.4 18.8V18.79Z"
        fill={fill}
      />
    </svg>
  );
};

export default DollarUnlockIcon;
