import { InformationCircleIcon } from '@heroicons/react/24/solid';

interface PageHeaderProps {
  title: string;
  tooltip?: string;
}

function PageHeader(props: PageHeaderProps) {
  const { title, tooltip } = props;

  return (
    <div className="flex space-x-6 items-center justify-center">
      <span>
        <svg
          width="59"
          height="61"
          viewBox="0 0 59 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.01831 40.3593C2.44075 41.6292 2.94141 42.8521 3.53595 44.0123C22.8897 45.047 30.4153 51.7102 36.658 59.6119C37.8158 59.2827 38.9423 58.8908 40.0531 58.4204C32.4023 48.3551 24.0162 41.0648 2.01831 40.3593Z"
            fill="#AE9B7E"
          />
          <path
            d="M9.15261 51.82C16.4435 52.8704 22.6705 55.8022 27.9432 60.5683C28.3969 60.5997 28.835 60.6154 29.2887 60.6154C30.4465 60.6154 31.573 60.5527 32.6838 60.4116C25.6276 52.7607 16.7564 48.5119 5.82007 47.8848C6.8214 49.2958 7.93224 50.6128 9.13697 51.8356L9.15261 51.82Z"
            fill="#AE9B7E"
          />
          <path
            d="M57.905 36.91C57.9519 36.7062 57.9832 36.4867 58.0302 36.2829C58.1866 35.2952 58.2805 34.3075 58.3274 33.3197C47.3911 31.1405 34.6867 32.4261 24.8768 36.8316C18.0866 34.0723 9.99773 32.5829 0.844971 32.7553C0.844971 33.649 0.923199 34.5583 1.03272 35.4676C1.06401 35.7655 1.12659 36.0477 1.17353 36.3456C21.6382 36.1104 35.8601 44.2787 42.7756 57.1348C43.7925 56.586 44.7626 55.9746 45.717 55.3004C44.6687 53.325 43.464 51.4436 42.1184 49.6877C45.0911 49.2017 48.4393 49.1546 51.897 49.5466C52.7576 48.4961 53.5555 47.3987 54.2595 46.2385C48.9713 45.3919 43.7925 45.5173 39.443 46.5677C38.5043 45.58 37.4873 44.6393 36.4234 43.73C42.7599 42.0681 49.1121 41.8957 55.8398 43.291C56.3561 42.1935 56.7942 41.0647 57.154 39.8889C48.7053 38.0545 40.8042 38.4622 32.8562 41.0334C31.7297 40.2808 30.5562 39.5753 29.3202 38.9168C38.0193 35.7655 48.7053 35.0443 57.8894 36.8944L57.905 36.91Z"
            fill="#AE9B7E"
          />
          <path
            d="M0.610107 30.1058C0.688336 30.1058 0.78221 30.1058 0.860439 30.1058C8.918 29.9491 16.3028 31.0779 22.655 33.1944H26.8324C35.5783 29.9491 46.2957 28.8046 56.1056 30.2626C56.8722 30.3724 57.6388 30.5135 58.3898 30.6546V26.5312H41.9931L57.9205 20.4795L55.9335 15.9642L40.319 22.3452L52.3036 10.665L48.6895 7.21583L36.4858 19.335L43.2291 3.68826L38.5354 1.80689L31.9955 17.093V0.615356H26.9106V16.7795L20.6836 1.82257L15.9742 3.6569L22.3264 18.7392L10.7329 7.12176L7.0718 10.5239L18.7592 22.2668L3.39505 15.7761L1.3611 20.2757L16.5688 26.5312H0.641398V30.1215L0.610107 30.1058Z"
            fill="#FFB71B"
          />
          <path
            d="M15.1613 27.1897V31.266C18.619 31.9245 21.7951 32.8338 24.846 33.9627C30.369 31.6737 36.7994 30.2313 43.3706 29.7766V23.9914C43.355 22.7528 42.4006 22.0787 41.2115 22.0787C40.0224 22.0787 39.0837 22.7685 39.0837 23.9914V26.0452L37.7694 25.5592V20.6206C37.7694 19.2566 36.7055 18.457 35.4069 18.457C34.1083 18.457 33.0444 19.2409 33.0444 20.6206V23.866L30.2595 22.8626L23.8604 22.0943L13.1743 26.9232L13.4716 27.8012L15.1613 27.2054V27.1897Z"
            fill="#323E48"
          />
        </svg>
      </span>
      <span className="flex space-x-2">
        <h3 className="display-2xl-regular">{title}</h3>
        {tooltip && <InformationCircleIcon className="size-4 fill-secondary" />}
      </span>
    </div>
  );
}

export default PageHeader;
