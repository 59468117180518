import { useState, useEffect } from 'react';
import { useDebounce } from '../../../hooks/use-debounce';

export const useDateIndex = (initialDateIndex: number) => {
  const [dateIndex, setDateIndex] = useState<number>(0);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedDateIndex(initialDateIndex);
    setDateIndex(initialDateIndex);
  }, [initialDateIndex]);

  const debouncedDateIndex = useDebounce(dateIndex, 500);
  useEffect(() => {
    setSelectedDateIndex(debouncedDateIndex);
  }, [debouncedDateIndex]);

  return {
    dateIndex,
    setDateIndex,
    selectedDateIndex,
    setSelectedDateIndex,
  };
};
