import { useMemo } from 'react';
import { FertilizerType } from '.';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useDeletePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { convertToFloat } from '../../../../utils';
import Checkbox from '../../../commons/Checkbox';
import NumberDisplay from '../../../commons/NumberDisplay';
import Select from '../../../commons/Select';
import Table, { TableConfigs } from '../../../commons/Table';
import TableLayout from '../../commons/TableLayout';
import { isEmpty } from 'lodash';

interface FertilizerListProps {
  selectedZone: FieldPrescriptionRes;
  data: FieldPrescriptionRes[];
  onSelect: (zone: any) => void;
  fertilizer: FertilizerType;
  loading: boolean;
  setIsEditing: (value: boolean) => void;
  setSelectedProductByZone: (zone: string, value: any) => void;
  selectedProductByZone: Record<string, PrescriptionProductRes>;
}

export default function FertilizerList({
  fertilizer,
  selectedZone,
  data,
  onSelect,
  loading,
  setIsEditing,
  setSelectedProductByZone,
  selectedProductByZone,
}: FertilizerListProps) {
  const user = useUser();
  const year = useYearFilter();
  const { mutate: deleteSeeding, isPending: isDeleting } =
    useDeletePrescriptionProduct();
  const tableConfigs: TableConfigs = {
    cols: [
      {
        name: 'Select Zone',
        key: 'id',
        type: 'element',
        element: (item: FieldPrescriptionRes) => (
          <Checkbox
            name="id"
            label={`Zone ${item.agrivar_zone1}`}
            checked={selectedZone.agrivar_zone === item.agrivar_zone}
            onChange={(checked: boolean) =>
              checked && onSelect(item.agrivar_zone)
            }
            rounded
          />
        ),
      },
      { name: 'Acres', key: 'area', unit: 'acres' },
      {
        name: `Residual ${fertilizer.shortName} lbs.`,
        key: `Residual ${fertilizer.shortName} lbs.`,
        type: 'element',
        element: (item: FieldPrescriptionRes) => {
          const residualFert = `${fertilizer.shortName}1_lb`;
          const value = convertToFloat(item[residualFert]);
          return <NumberDisplay value={value} />;
        },
      },
      {
        name: `${fertilizer.shortName} lbs. Needed`,
        key: `${fertilizer.shortName} lbs. Needed`,
        type: 'element',
        element: (item: FieldPrescriptionRes) => {
          const selectedProduct = !isEmpty(
            selectedProductByZone[item.agrivar_zone]?.[
              fertilizer.name.toLowerCase()
            ]
          )
            ? selectedProductByZone[item.agrivar_zone]
            : ({} as any);
          return (
            <NumberDisplay
              value={
                selectedProduct?.[`${fertilizer.name.toLowerCase()}`] ?? ''
              }
            />
          );
        },
      },
      {
        name: `Historical Average ${fertilizer.shortName} lbs. Applied`,
        key: `Historical Average ${fertilizer.shortName} lbs. Applied`,
        type: 'element',
        element: (item: FieldPrescriptionRes) => {
          const averageFert = `average_${fertilizer.shortName.toLowerCase()}_rate`;

          const value = convertToFloat(item[averageFert]);
          return <NumberDisplay value={value} />;
        },
      },
      {
        name: `Yield (Bu) Per Pound of ${fertilizer.shortName}`,
        key: `Yield (Bu) Per Pound of ${fertilizer.shortName}`,
        type: 'element',
        element: (item: FieldPrescriptionRes) => {
          let result;
          const recommended_fert = `recommended_${fertilizer.shortName.toLowerCase()}_rate`;
          const value = parseFloat(item[recommended_fert]);
          let zone_yield = parseFloat(item.zone_yield);
          if (!isNaN(value) && value !== 0) {
            result = (zone_yield / value).toString();
          } else {
            result = ''; // or any default value
          }
          return <NumberDisplay value={convertToFloat(result)} />;
        },
      },

      {
        name: 'Cost Per Acre',
        key: 'cost_per_arce',
        type: 'currency',
      },
      {
        name: 'Fertilizer',
        key: 'product',
        type: 'element',
        element: (item: FieldPrescriptionWithProducts) => {
          const options = item.products
            ?.filter((fert) => {
              return !isEmpty(fert?.[fertilizer.name.toLowerCase()]);
            })
            ?.map((fert) => ({
              value: fert.product,
              label: `${fert.application_time} ${fert.product}`,
            }));
          if (!options.length) return <></>;
          return (
            <Select
              className="w-52"
              name="product"
              options={options}
              onChange={(e) =>
                setSelectedProductByZone(
                  item.agrivar_zone,
                  item.products?.find(
                    (fert) => fert.product === e.target.value
                  ) as PrescriptionProductRes
                )
              }
            />
          );
        },
      },
    ],
  };

  const rowActions = {
    edit: {
      onSelect: (item: FieldPrescriptionWithProducts) => {
        onSelect(item.agrivar_zone);
        setIsEditing(true);
      },
    },
    delete: (item: FieldPrescriptionWithProducts) => {
      deleteSeeding({
        uniqueId: selectedProductByZone[item.agrivar_zone].uniqueid,
        userId: user?.id ?? '',
        year: year ?? '',
        zone: item.agrivar_zone,
      });
    },
    note: {
      onSelect: (item: FieldPrescriptionRes) => {
        onSelect(item.agrivar_zone);
      },
      onSave: (item: FieldPrescriptionRes, value: string) => {},
      onSaveAll: (item: FieldPrescriptionRes, value: string) => {},
      title: 'Add Note',
    },
  };

  return (
    <TableLayout title={`${fertilizer.name}`}>
      <Table
        configs={tableConfigs || {}}
        data={data}
        loading={loading || isDeleting}
        rowActions={rowActions}
      />
    </TableLayout>
  );
}
