import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { theme } from 'twin.macro';
import CropIcon from '../../../components/commons/CropIcon';
import Disclosure from '../../../components/commons/Disclosure';
import CurrentRiskMGTStrategiesCard from '../../../components/dashboard/risk-management/CurrentRiskMGTStrategiesCard';
import CurrentRiskReductionCard from '../../../components/dashboard/risk-management/CurrentRiskReductionCard';
import RemainingVARCard from '../../../components/dashboard/risk-management/RemainingVARCard';
import RiskMGTImpactsByFieldsCard from '../../../components/dashboard/risk-management/RiskMGTImpactsByFieldsCard';
import RiskReductionByToolCard from '../../../components/dashboard/risk-management/RiskReductionByToolCard';
import SourcesOfRiskByTypeCard from '../../../components/dashboard/risk-management/SourcesOfRiskByTypeCard';
import TotalVARCard from '../../../components/dashboard/risk-management/TotalVARCard';
import Hedging from '../../../components/dashboard/risk-management/hedging';
import Insurance from '../../../components/dashboard/risk-management/insurance';
import Leases from '../../../components/dashboard/risk-management/leases';
import Storage from '../../../components/dashboard/risk-management/storage';
import { SupportedCrops } from '../../../constants';

function RiskManagement() {
  const [selectedCrop, setSelectedCrop] = useState<string>('all');
  const handleSelectCrop = (cropName: string) => {
    setSelectedCrop(cropName);
  };

  const isActive = useMemo(() => {
    return (value: string) => {
      return value === selectedCrop;
    };
  }, [selectedCrop]);

  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-12 xl:col-span-4">
        <TotalVARCard />
      </div>
      <div className="col-span-12 xl:col-span-4">
        <CurrentRiskReductionCard />
      </div>
      <div className="col-span-12 xl:col-span-4">
        <RemainingVARCard />
      </div>

      {/* Second row */}
      <div
        className={clsx(
          'col-span-12 flex flex-col space-y-8',
          'xl:flex-row xl:space-x-8 xl:space-y-0',
          'min-h-[430px]'
        )}
      >
        <div className="w-full xl:w-[43%] space-y-8 lg:flex lg:space-y-0 lg:space-x-8">
          <div className="w-full xl:w-1/2">
            <SourcesOfRiskByTypeCard />
          </div>
          <div className="w-full xl:w-1/2">
            <RiskReductionByToolCard />
          </div>
        </div>
        <div className="w-full xl:w-fit space-y-8 lg:flex lg:space-y-0 lg:space-x-8">
          <div className="w-full xl:w-2/3">
            <CurrentRiskMGTStrategiesCard />
          </div>
          <div className="w-full xl:w-1/2">
            <RiskMGTImpactsByFieldsCard />
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="col-span-12 flex justify-center">
        <div className="px-2 border border-base-1000 rounded-lg w-fit space-x-3 flex items-center justify-between">
          <button
            className={clsx(
              'display-md-bold p-2',
              isActive('all') ? 'text-base-000' : 'text-base-600'
            )}
            onClick={() => handleSelectCrop('all')}
          >
            All
          </button>
          {SupportedCrops.map((cropName) => (
            <button
              key={cropName}
              onClick={() => handleSelectCrop(cropName)}
              className="p-2"
            >
              <CropIcon
                cropName={cropName}
                fill={
                  isActive(cropName)
                    ? theme`colors.base.000`
                    : theme`colors.base.600`
                }
              />
            </button>
          ))}
        </div>
      </div>

      {/*  */}
      <div className="col-span-12 space-y-8">
        <Disclosure title="Hedging" tooltip="demo">
          <Hedging />
        </Disclosure>
        <Disclosure title="Leases" tooltip="demo">
          <Leases />
        </Disclosure>
        <Disclosure title="Insurance" tooltip="demo">
          <Insurance />
        </Disclosure>
        <Disclosure title="Storage" tooltip="demo">
          <Storage />
        </Disclosure>
      </div>
    </div>
  );
}

export default RiskManagement;
