import { IconProps } from '../../types';

const FieldIcon = ({ fill = '#5C7284' }: IconProps) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.8498L4.08942 22.8859L8.38625 0.540579L6.81721 0.5L0 22.8498ZM6.58274 22.8498L10.6722 22.8859L11.2358 0.53607L9.67123 0.5L6.58274 22.8498ZM19.9106 22.8859L24 22.8498L17.1828 0.5L15.6138 0.540579L19.9106 22.8859ZM17.4172 22.8498L13.3278 22.8859L12.7642 0.53607L14.3333 0.5L17.4172 22.8498Z"
        fill={fill}
      />
    </svg>
  );
};

export default FieldIcon;
