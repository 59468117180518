import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getClimateTokenApi,
  getUserClimateApi,
  getPullBoundariesFromClimateApi,
} from '../../api/climate-api';
import { ClimateTokenPayload } from '../../types';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
const ClimateScopes =
  'operations:read parties:read agronomicCycles:read asApplied:read asApplied:write asHarvested:read asHarvested:write asPlanted:read asPlanted:write boundaries:write exports:read farmOrganizations:read fields:read fields:write imagery:write locations:read operations:read parties:read plantingActivitySummary:read queries:read queries:write resourceOwners:read rx:read rx:write rxZones:write scouting:read soil:write user';
const clientId = process.env.REACT_APP_CLIMATE_CLIENT_ID;
const redirectUri = encodeURIComponent(
  process.env.REACT_APP_CLIMATE_CALLBACK_URL!
);
const scope = encodeURIComponent(ClimateScopes);

export const authUrl = `https://climate.com/static/app-login/index.html?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=agrivar&scope=${scope}`;

export function useCheckConnectedUserClimate(userId: string) {
  const { data, ...rest } = useQuery({
    queryKey: ['climate/checkConnectedUserClimate', userId],
    queryFn: () => getUserClimateApi(userId),
    enabled: !!userId,
    retry: 3,
  });

  const item = data?.data ?? {};
  const isConnected = !!(
    item &&
    Object.keys(item).length > 0 &&
    item.status !== 'expired'
  );
  const isExpired = item?.status === 'expired';

  return {
    isConnected,
    isExpired,
    ...rest,
  };
}

export function usePullBoundariesFromClimate(userId: string, enabled: boolean) {
  return useQuery({
    queryKey: ['pullBoundariesFromJD', userId],
    queryFn: () => getPullBoundariesFromClimateApi(userId),
    enabled: enabled && !!userId,
    retry: 3,
  });
}

export function useGetClimateToken() {
  const isAdmin = localStorage.getItem('is_admin');
  const user = localStorage.getItem('climate_user_id');
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: ['climate/get-token'],
    mutationFn: (payload: ClimateTokenPayload) => getClimateTokenApi(payload),
    onSuccess: (data) => {
      enqueueSnackbar('Climate Connected!', { variant: 'success' });

      localStorage.setItem('climate_token', data?.data?.accessToken);
      localStorage.removeItem('climate_user_id');

      const organizationAccessUrl = data?.data?.organizationAccessUrl;
      if (organizationAccessUrl) {
        window.open(organizationAccessUrl, '_self');
      } else {
        const targetPath =
          isAdmin === '1'
            ? `/user/${user}/app/data-inputs`
            : '/app/data-inputs';
        navigate(targetPath, { replace: true });
      }
    },
    onError: () => {
      enqueueSnackbar('Climate Connection Failed! Please try again later.', {
        variant: 'error',
      });
      const targetPath = user
        ? `/user/${user}/app/data-inputs`
        : '/app/data-inputs';
      navigate(targetPath, { replace: true });
    },
  });
  return mutation;
}
