import ExpectedAreaAndYieldCard from '../../components/dashboard/ExpectedAreaAndYieldCard';
import ExpectedRevenueCard from '../../components/dashboard/expected-revenue-card/ExpectedRevenueCard';
import ValueAtRiskCard from '../../components/dashboard/value-at-risk/ValueAtRiskCard';
import MapBlock from '../../components/dashboard/map-block/MapBlock';
import PriceBlockCard from '../../components/dashboard/price-table-card/PriceTableCard';

function Dashboard() {
  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-12 xl:col-span-6">
        <ExpectedRevenueCard />
      </div>
      <div className="col-span-12 xl:col-span-6">
        <ValueAtRiskCard />
      </div>
      <div className="col-span-12 xl:col-span-4">
        <ExpectedAreaAndYieldCard />
      </div>
      <div className="col-span-12 xl:col-span-8">
        <PriceBlockCard />
      </div>
      <div className="col-span-12 xl:col-span-12">
        <MapBlock />
      </div>
    </div>
  );
}

export default Dashboard;
