import React, { useState, useMemo, useEffect } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BellAlertIcon,
  BellIcon,
  ChartBarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

import CreateAlert from './CreateAlert';
import AddToWatchList from './AddToWatchList';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';

import { useUser } from '../../../contexts/auth-context';
import {
  capitalizeFirst,
  formatAlertDate,
  formatStrikePriceToDollars,
} from '../../../utils';
import Button from '../../commons/Button';
import { commodityOptions } from './PriceChart';
import EditAlert from './EditAlert';
import { useAlerts, useDeleteAlert } from '../../../hooks/market/use-alerts';
import { useAllFutures } from '../../../hooks/market/use-futures';
import AddToWatchlistButton from './AddCurrentViewToWatchList';

const MarketAlertsSidebar = () => {
  const [isCreatingNewAlert, setIsCreatingNewAlert] = useState(false);
  const [creatingNewAlertType, setCreatingNewAlertType] = useState('');
  const [isUpdatingEditAlert, setIsUpdatingEditAlert] = useState(false);
  const [updatingAlertIndex, setUpdatingAlertIndex] = useState(-1);
  const [isCreatingWatchItem, setIsCreatingWatchItem] = useState(false);
  const user = useUser();
  const { mutate: deleteAlert } = useDeleteAlert(user?.id || '');
  const queryClient = useQueryClient();
  const alerts = useAlerts(user!.id);
  const futures = useAllFutures('KE');

  // Function to determine if an alert is triggered
  const isTriggered = (alert: any) => {
    if (alert.condition.toLowerCase().includes('below')) {
      return parseFloat(alert.price) > parseFloat(alert.alertPrice);
    } else if (alert.condition.toLowerCase().includes('above')) {
      return parseFloat(alert.price) < parseFloat(alert.alertPrice);
    } else {
      return parseFloat(alert.price) === parseFloat(alert.alertPrice);
    }
  };

  const formattedAlerts = useMemo(() => {
    const groupedAlertsByType: any = {
      futures: { triggered: [], active: [], canceled: [] },
      basis: { triggered: [], active: [], canceled: [] },
      options: { triggered: [], active: [], canceled: [] },
      cash: { triggered: [], active: [], canceled: [] },
      spread: { triggered: [], active: [], canceled: [] },
    };

    alerts?.data?.alerts.forEach((alert: any) => {
      let currentPrice;
      let change;
      if (alert.type === 'future') {
        const futurescontract = futures.data?.find((item: any) => {
          return item.symbol === alert.symbol;
        });
        currentPrice = parseFloat(futurescontract?.last!).toFixed(2) ?? 0;
        change = parseFloat(futurescontract?.change!).toFixed(2) ?? 0;
      }

      const formattedAlert = {
        id: alert['user_id-type-crop-basis_location'],
        commodity: alert.crop.charAt(0).toUpperCase() + alert.crop.slice(1),
        condition: `${capitalizeFirst(alert.dateRange)} at or ${capitalizeFirst(alert.priceRange)} $${alert.price}`,
        price:
          alert.type === 'basis'
            ? parseFloat(alert.lastBasis).toFixed(2)
            : parseFloat(alert.lastPrice).toFixed(2),
        change:
          alert.type === 'basis'
            ? parseFloat(alert.basisChange).toFixed(2)
            : parseFloat(alert.change).toFixed(2),
        date: formatAlertDate(alert['date']),
        unformattedDate: alert['date'],
        type: alert.type.charAt(0).toUpperCase() + alert.type.slice(1),
        optionType: alert.option_type ?? '',
        strikePrice: formatStrikePriceToDollars(alert.strike_price) ?? '',
        alertPrice: alert.price,
      };

      if (isTriggered(formattedAlert)) {
        groupedAlertsByType[alert.type].triggered.push(formattedAlert);
      } else if (alert.status === 'active') {
        groupedAlertsByType[alert.type].active.push(formattedAlert);
      } else {
        groupedAlertsByType[alert.type].canceled.push(formattedAlert);
      }
    });

    return groupedAlertsByType;
  }, [alerts]);

  return (
    <div className=" w-full p-6 pl-0 pt-0 space-y-4">
      <div onClick={() => setIsCreatingWatchItem(!isCreatingWatchItem)}>
        <AddToWatchlistButton />
      </div>

      {isCreatingWatchItem && (
        <AddToWatchList setIsCreatingWatchItem={setIsCreatingWatchItem} />
      )}

      <div className="flex items-center space-x-2">
        <BellAlertIcon className="h-6 w-6 mr-2" />
        <h2 className="text-2xl text-base-bold">Market Alerts</h2>
      </div>

      {isCreatingNewAlert ? (
        <CreateAlert
          isCreatingNewAlert={isCreatingNewAlert}
          setIsCreatingNewAlert={setIsCreatingNewAlert}
          creatingNewAlertType={creatingNewAlertType}
        />
      ) : (
        formattedAlerts &&
        Object.keys(formattedAlerts).map((alertType) => (
          <div className="card p-4">
            <div className="flex items-center mb-2 justify-between">
              <div className="flex">
                <ChartBarIcon className="h-6 w-6 mr-2" />
                <p className="font-bold text-xl">
                  {alertType.slice(0, 1).toUpperCase()}
                  {alertType.slice(1, alertType.length)}
                </p>
              </div>
              <Button
                onClick={() => {
                  setIsCreatingNewAlert(!isCreatingNewAlert);
                  setCreatingNewAlertType(alertType);
                }}
                className="max-w-max card flex items-center justify-between space-x-2"
              >
                <div className="flex border-0 gap-4 items-center">
                  <PlusCircleIcon className="h-6 w-6 text-darkGray" />
                  <span className="text-darkGray">
                    {isCreatingNewAlert ? 'Cancel' : 'Add'}
                  </span>
                </div>
              </Button>
            </div>
            {['triggered', 'active', 'canceled'].map((category) => (
              <Disclosure defaultOpen={true} key={alertType + category}>
                {({ open }) => (
                  <>
                    <DisclosureButton className="my-2 flex p-0 border-[0px] border-darkGray justify-between items-center w-full">
                      <h3 className="text-lg text-darkGray">
                        {capitalizeFirst(category)}
                      </h3>
                      {open ? (
                        <ChevronUpIcon className="h-5 w-5" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5" />
                      )}
                    </DisclosureButton>
                    <DisclosurePanel className="space-y-4">
                      {formattedAlerts[alertType][category].map(
                        (alert: any, index: number) => (
                          <>
                            <div
                              key={alert.id}
                              className="flex  card items-center justify-between p-2 px-4"
                            >
                              <div className="flex items-center space-x-4">
                                <Button
                                  onClick={() => {
                                    if (updatingAlertIndex === -1) {
                                      setIsUpdatingEditAlert(true);
                                      setUpdatingAlertIndex(alert.id);
                                    } else {
                                      setIsUpdatingEditAlert(false);
                                      setUpdatingAlertIndex(-1);
                                    }
                                  }}
                                  className="max-w-2 h-8 bg-gray"
                                >
                                  <PencilSquareIcon className="h-5 w-5" />
                                </Button>
                                {commodityOptions.filter(
                                  (option) => option.value === alert.commodity
                                )[0].icon ?? <></>}
                                <div>
                                  <p className="text-base-bold">
                                    {alert.commodity} {alert.date}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center space-x-2">
                                {alert.type === 'Options' && (
                                  <div
                                    className={`${alert.change < 0 ? 'text-red' : 'text-green'} mr-2`}
                                  >
                                    <p className="text-base-bold">
                                      {alert.strikePrice}
                                    </p>

                                    <p className="text-sm flex">
                                      {alert.optionType}
                                    </p>
                                  </div>
                                )}
                                <div className="flex flex-col mr-2">
                                  <div
                                    className={`${alert.change < 0 ? 'text-red' : 'text-green'} w-full items-center justify-between gap-2 mr-2 flex`}
                                  >
                                    <p className="text-base-bold">
                                      ${alert.price}
                                    </p>

                                    <p className="text-sm flex items-center">
                                      {alert.change < 0 ? (
                                        <ArrowDownIcon className=" w-4 h-4" />
                                      ) : (
                                        <ArrowUpIcon className="w-4 h-4" />
                                      )}{' '}
                                      ${alert.change}
                                    </p>
                                  </div>
                                  <p className="text-sm">{alert.condition}</p>
                                </div>

                                <Button
                                  onClick={async () => {
                                    const resp = confirm(
                                      `Are you sure you want to delete alert: ${alert.commodity} ${alert.date}`
                                    );
                                    if (!resp) {
                                      return;
                                    }
                                    await deleteAlert(
                                      {
                                        id: alert.id,
                                        date: alert.unformattedDate,
                                      },
                                      {
                                        onSuccess: () => {
                                          queryClient.invalidateQueries([
                                            'market/alerts',
                                            user?.id ?? '',
                                          ] as InvalidateQueryFilters);
                                          window.alert(
                                            'Alert deleted successfully'
                                          );
                                        },
                                        onError: (error: Error) => {
                                          console.error(
                                            'Error creating alert:',
                                            error
                                          );
                                          window.alert(
                                            'Failed to delete alert. Please try again.'
                                          );
                                        },
                                      }
                                    );
                                  }}
                                  className="max-w-2 h-8 bg-gray"
                                >
                                  <XMarkIcon className="h-6 w-6 cursor-pointer" />
                                </Button>
                              </div>
                            </div>

                            <div className="w-full">
                              {isUpdatingEditAlert &&
                                updatingAlertIndex === alert.id && (
                                  <EditAlert
                                    alert={alerts?.data?.alerts[index]}
                                    isUpdatingEditAlert={isUpdatingEditAlert}
                                    setIsUpdatingEditAlert={
                                      setIsUpdatingEditAlert
                                    }
                                  />
                                )}
                            </div>
                          </>
                        )
                      )}
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default MarketAlertsSidebar;
