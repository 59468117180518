import { isArray } from 'lodash';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../types';

export const getColorLegendData = (
  data?: {
    value: number;
    color: string;
  }[]
) => {
  if (!data || !isArray(data) || data.length === 0) return;

  const sortedData = data.sort((a, b) => b.value - a.value);

  const max = sortedData[sortedData.length - 1]?.value;
  const min = sortedData[0]?.value;

  const countNum = sortedData.length < 10 ? sortedData.length : 10;
  const colors = [],
    values = [];

  const step = Math.floor(sortedData.length / countNum);

  for (let index = 0; index < sortedData.length; index += step) {
    values.push(sortedData[index]?.value);
    colors.push(sortedData[index]?.color);
  }
  return {
    values,
    colors,
    min,
    max,
  };
};

export const getZone = (user_id_agrivar_zone: string) => {
  return (
    user_id_agrivar_zone.split('-')[2] ?? user_id_agrivar_zone.split('-')[1]
  );
};

export const getZonesWithProducts = (
  prescriptions: FieldPrescriptionRes[],
  products: PrescriptionProductRes[],
  inputType: string
): FieldPrescriptionWithProducts[] => {
  const result = prescriptions.map((prescription) => {
    const productsByZone = products?.filter((product) => {
      return (
        getZone(product.user_id_agrivar_zone) === prescription.agrivar_zone &&
        product.input_type === inputType
      );
    });
    return {
      ...prescription,
      products: productsByZone || ([] as PrescriptionProductRes[]),
    };
  });
  return result;
};
