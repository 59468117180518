import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import StorageList from './StorageList';
import StorageOptions from './StorageOptions';

export default function Storage() {
  return (
    <DisclosureContentLayout
      left={<StorageOptions />}
      right={<StorageList />}
    />
  );
}
