import clsx from 'clsx';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useMainUser } from '../../../contexts/auth-context';
import MainHeader from './header/MainHeader';

function MainLayout() {
  const user = useMainUser();

  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', 'new-vision');
    return () => {
      document.querySelector('html')?.setAttribute('data-theme', 'agrivar');
    };
  }, []);

  return (
    <div className={clsx('px-4 py-4 mx-auto', 'md:px-8 md:py-8')}>
      <MainHeader />
      <div id="main" className="mt-16">
        <p className="display-md-bold text-center mb-8">
          {`${user?.first_name} ${user?.last_name}`}
        </p>
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
