import { HedgeItem } from '../types/hedge';
import apiClient from './api-client';

export const getHedgesApi = (userId: string, scenarioId?: number) => {
  let query = `?user_id=${userId}`;
  query = scenarioId ? query + `&scenario_id=${scenarioId}` : query;
  return apiClient.get(`/hedge${query}`);
};

export const createHedgeApi = (payload: any) => {
  return apiClient.post('/hedge', { ...payload, exit_date: 'Open' });
};

export const deleteHedgeApi = (payload: any) => {
  return apiClient.post('/hedge/delete', payload);
};

export const updateHedgeApi = (payload: HedgeItem) => {
  return apiClient.post('/hedge/update', payload);
};

export const strikeListApi = (crop_month_trade_date: string) => {
  return apiClient.get(
    `/options-contracts?crop_month_trade_date=${crop_month_trade_date}`
  );
};
