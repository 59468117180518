import {
  S3Client,
  PutObjectCommand,
  PutObjectCommandInput,
} from '@aws-sdk/client-s3';
import type { Readable as StreamReadable } from 'stream';
import { Buffer } from 'buffer';
import apiClient from './api/api-client';
import { AWS_FILES_BUCKET } from './constants';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION!,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  },
});

export function uploadZip(
  file: Buffer,
  fileName: string,
  userId: string,
  storageKey: string,
  callback: (data: unknown) => void
): void {
  const key = `${userId}/${storageKey}/${fileName}`;
  uploadApi(file, key, 'application/zip', async (error, response) => {
    if (error) {
      callback(error);
      return;
    }
    callback(response);
  });
}

async function uploadApi(
  file: Buffer,
  key: string,
  type: string,
  callback: (error: Error | null, data?: any) => void
) {
  const resp = await apiClient.post(`/upload`, {
    files: [file],
    key,
    storageType: type,
  });
  callback(null, resp);
  return resp;
}

export async function uploadFile(
  file: Buffer | Uint8Array | Blob | StreamReadable | string,
  fileName: string,
  type: string,
  callback: (error: Error | null, data?: any) => void
): Promise<void> {
  try {
    const bucket = AWS_FILES_BUCKET;

    const params: PutObjectCommandInput = {
      Bucket: bucket,
      Key: fileName,
      Body: file,
      ContentType: type,
      ACL: 'private',
    };

    const command = new PutObjectCommand(params);
    const response = await s3Client.send(command);

    callback(null, response);
  } catch (error: any) {
    console.error('Upload error:', error);
    callback(error);
  }
}
