import { useMutation, useQuery } from '@tanstack/react-query';
import { getJDAuthorizationEndpointApi } from '../../api/climate-api';
import {
  getJDTokenApi,
  getPullBoundariesFromJDApi,
  getUserJDApi,
} from '../../api/john-deere-api';
import { JDTokenPayload } from '../../types';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
const clientId = process.env.REACT_APP_MY_JOHN_DEERE_CLIENT_ID;
const redirectUri = encodeURIComponent(
  process.env.REACT_APP_MY_JOHN_DEERE_CALLBACK_URL!
);
export const MyJDScopes = 'ag1 ag2 ag3 eq1 eq2 org1 org2 files offline_access';
const scope = encodeURIComponent(MyJDScopes);

export function useCheckConnectedUserJD(userId: string) {
  // Check status
  const { data, ...rest } = useQuery({
    queryKey: ['my-john-deere/checkConnectedUserJD', userId],
    queryFn: () => getUserJDApi(userId),
    enabled: !!userId,
    retry: 3,
  });

  const { item } = data?.data ?? {};
  const isConnected = !!(
    item &&
    Object.keys(item).length > 0 &&
    item.status !== 'expired'
  );
  const isExpired = item?.status === 'expired';

  return {
    isConnected,
    isExpired,
    ...rest,
  };
}

export function usePullBoundariesFromJD(userId: string, enabled: boolean) {
  return useQuery({
    queryKey: ['my-john-deere/pull-boundaires', userId],
    queryFn: () => getPullBoundariesFromJDApi(userId),
    enabled: enabled && !!userId,
    retry: 3,
  });
}

export function useGetJDAuthorizationEndpoint() {
  const { data, isSuccess, ...rest } = useQuery({
    queryKey: ['getJDAuthorizationEndpoint'],
    queryFn: () => getJDAuthorizationEndpointApi(),
    retry: false,
  });

  const authorizationEndpoint = isSuccess
    ? data?.data?.authorization_endpoint
    : '';

  const connectUrl = `${authorizationEndpoint}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=agrivar&scope=${scope}`;

  return {
    data: connectUrl,
    isSuccess,
    ...rest,
  };
}

export function useGetJDToken() {
  const isAdmin = localStorage.getItem('is_admin');
  const user = localStorage.getItem('JD_user_id');
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: ['my-john-deere/get-token'],
    mutationFn: (payload: JDTokenPayload) => getJDTokenApi(payload),
    onSuccess: (data) => {
      enqueueSnackbar('MyJohnDreere Connected!', { variant: 'success' });

      localStorage.removeItem('JD_user_id');
      localStorage.setItem('myjohndeere_token', data?.data?.accessToken);

      const organizationAccessUrl = data?.data?.organizationAccessUrl;
      if (organizationAccessUrl) {
        window.open(organizationAccessUrl, '_self');
      } else {
        const targetPath =
          isAdmin === '1'
            ? `/user/${user}/app/data-inputs`
            : '/app/data-inputs';
        navigate(targetPath, { replace: true });
      }
    },
    onError: () => {
      enqueueSnackbar('Something went wrong, please try again later!', {
        variant: 'error',
      });
      const targetPath =
        isAdmin === '1' ? `/user/${user}/app/data-inputs` : '/app/data-inputs';
      navigate(targetPath, { replace: true });
    },
  });
  return mutation;
}
