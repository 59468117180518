import { useState } from 'react';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import SubtotalInfo from './SubtotalInfo';
import SubtotalList from './SubtotalList';

export default function Subtotal() {
  const [list, setList] = useState<any[]>([
    {
      prescription: 'Seeding Subtotals',
      prescriptionQuantity: 100,
      prescriptionCost: 1234.88,
    },
    {
      prescription: 'Fertilizer Subtotals',
      prescriptionQuantity: 200,
      prescriptionCost: 2345.88,
    },
    {
      prescription: 'Chemical Subtotals',
      prescriptionQuantity: 300,
      prescriptionCost: 3456.88,
    },
    {
      prescription: 'Micronutrient Subtotals',
      prescriptionQuantity: 400,
      prescriptionCost: 4567.88,
    },
  ]);

  return (
    <div className="space-y-8">
      <DisclosureContentLayout
        left={<SubtotalInfo />}
        right={<SubtotalList data={list} />}
      />
    </div>
  );
}
