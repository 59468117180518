import { useQuery } from '@tanstack/react-query';
import { getBasisApi, getBasisPricesAPI } from '../../api/market-api';
import { BasisRes } from '../../types';

export const useBasis = (userId: string, year: string, symbols: string[]) => {
  const res = useQuery({
    queryKey: ['basis', userId, year, symbols],
    queryFn: () => getBasisApi(userId, year, symbols),
    enabled: !!(userId && year && symbols),
  });

  return { data: res.data };
};

export const useBasisPrices = (basisID: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['basis-prices', basisID],
    queryFn: () => getBasisPricesAPI(basisID),
    enabled: !!basisID,
  });

  return { data: data?.data as BasisRes[] | [], ...rest };
};
