import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState, useMemo } from 'react';

interface YearCalendarProps {
  availableYears?: number[];
  defaultYear?: number;
  onChange: (year: number) => void;
}

const MIN_YEAR = 1900;
const MAX_YEAR = 2099;
const VIEW_LENGTH = 12;

export default function YearCalendar({
  availableYears,
  defaultYear,
  onChange,
}: YearCalendarProps) {
  const currentYear = new Date().getFullYear();
  const initialStartYear = useMemo(() => {
    const baseYear = defaultYear || currentYear;
    return Math.min(
      Math.max(baseYear - (baseYear % 12), MIN_YEAR),
      MAX_YEAR - (MAX_YEAR % 12)
    );
  }, [defaultYear, currentYear]);

  const [startYear, setStartYear] = useState(initialStartYear);
  const [selectedYear, setSelectedYear] = useState<number | null>(
    defaultYear || availableYears?.[0] || currentYear
  );

  const years = useMemo(
    () => Array.from({ length: VIEW_LENGTH }, (_, i) => startYear + i),
    [startYear]
  );

  const goToPrevView = () =>
    setStartYear((prevStartYear) =>
      Math.max(prevStartYear - VIEW_LENGTH, MIN_YEAR)
    );
  const goToNextView = () =>
    setStartYear((prevStartYear) =>
      Math.min(prevStartYear + VIEW_LENGTH, MAX_YEAR - VIEW_LENGTH + 1)
    );

  const handleOnClick = (year: number) => {
    setSelectedYear(year);
    onChange(year);
  };

  const isSelected = (year: number) => year === selectedYear;
  const isAvailable = (year: number) =>
    availableYears ? availableYears.includes(year) : true;

  return (
    <div className="card w-[192px] py-5 px-6">
      <div className="header flex items-center justify-between px-2">
        <button onClick={goToPrevView} aria-label="Previous Decade">
          <ChevronLeftIcon className="size-4" />
        </button>
        <span className="text-lg-bold text-secondary">{selectedYear}</span>
        <button onClick={goToNextView} aria-label="Next Decade">
          <ChevronRightIcon className="size-4" />
        </button>
      </div>
      <div className="grid grid-cols-3 mt-4">
        {years.map((year) => (
          <button
            key={year}
            className={clsx(
              'size-12 text-sm-regular flex items-center justify-center rounded-full',
              isSelected(year) ? 'bg-primary text-base-000' : 'text-base-500',
              isAvailable(year)
                ? 'cursor-pointer hover:bg-base-900 hover:text-base-000'
                : 'cursor-not-allowed opacity-30'
            )}
            onClick={() => isAvailable(year) && handleOnClick(year)}
            aria-label={`Select Year ${year}`}
          >
            {year}
          </button>
        ))}
      </div>
    </div>
  );
}
