import { Amplify } from 'aws-amplify';
import * as Auth from 'aws-amplify/auth';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_AWS_DOMAIN || '',
          scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [process.env.REACT_APP_AWS_SIGNIN_URI || ''],
          redirectSignOut: [process.env.REACT_APP_AWS_SIGNIN_URI || ''],
          responseType: 'code',
        },
        username: true,
      },
    },
  },
});

export const getCurrentUser = async () => {
  try {
    const user = await Auth.getCurrentUser();
    return user;
  } catch (error) {
    return null;
  }
};

export const fetchUserAttributes = async () => {
  try {
    const userAttrs = await Auth.fetchUserAttributes();
    return userAttrs;
  } catch (error) {
    return null;
  }
};
export const getTokens = async () => {
  const session = await Auth.fetchAuthSession();
  return {
    accesToken: session.tokens?.accessToken.toString(),
    idToken: session.tokens?.idToken?.toString(),
  };
};

export const signOut = async () => {
  return await Auth.signOut();
};

export const signIn = async (email: string, password: string) => {
  try {
    const { isSignedIn, nextStep } = await Auth.signIn({
      username: email,
      password,
    });
    return { isSignedIn, nextStep };
  } catch (error) {
    throw error;
  }
};
