import React, { ReactNode, useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { getCurrentUser } from '../auth';
import { useUserProfile } from '../hooks/user/use-user-profile';
import { CognitoUserType, UserProfileRes } from '../types';
import { fetchUserAttributes } from 'aws-amplify/auth';

interface AuthContextType {
  isAuthenticated: boolean | null;
  cognitoUser: CognitoUserType | undefined;
  user: UserProfileRes | undefined;
  setIsAuthenticated: (value: boolean) => void;
  isUserLoading: boolean;
  controlledUser?: UserProfileRes | undefined;
  setControlledUser: React.Dispatch<
    React.SetStateAction<UserProfileRes | undefined>
  >;
  setUser: React.Dispatch<React.SetStateAction<UserProfileRes | undefined>>;
}

export const AuthContext = createContext<AuthContextType | undefined>({
  isAuthenticated: null,
  setIsAuthenticated: () => {},
  user: undefined,
  cognitoUser: undefined,
  isUserLoading: true,
  controlledUser: undefined,
  setControlledUser: () => {},
  setUser: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserProfileRes>(); // The actual logged in user
  const [controlledUser, setControlledUser] = useState<UserProfileRes>(); // The user that is being act on behalf of, this only available for admin
  const [cognitoUser, setCognitoUser] = useState<CognitoUserType>(); // The cognito user attributes

  const {
    data: userProfile,
    isPending,
    isSuccess,
  } = useUserProfile(isAuthenticated === true);

  // Make sure to check if the user is authenticated
  useEffect(() => {
    (async () => {
      const user = await getCurrentUser();
      if (user) {
        const userAttrs = await fetchUserAttributes();
        const role = userAttrs?.['custom:role'];
        const network_partner = userAttrs?.['custom:network_partner'];
        setCognitoUser({
          role,
          network_partner,
        });
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    })();
  }, []);

  // Then get the user profile
  useEffect(() => {
    if (!isPending && isSuccess) {
      setUser(userProfile);
      setIsUserLoading(isPending);
    }
  }, [userProfile, isPending, isSuccess]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        cognitoUser,
        user,
        isUserLoading,
        controlledUser,
        setControlledUser,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Selectors

export function useAuthContext() {
  const context = useContextSelector(AuthContext, (state) => state);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
}

export function useIsAuthenticated() {
  return useContextSelector(AuthContext, (state) => state?.isAuthenticated);
}

export function useMainUser() {
  return useContextSelector(AuthContext, (state) => state?.user);
}

export function useCognitoUser() {
  return useContextSelector(AuthContext, (state) => state?.cognitoUser);
}

export function useSetIsAuthenticated() {
  return useContextSelector(AuthContext, (state) => state?.setIsAuthenticated);
}

export function useIsUserLoading() {
  return useContextSelector(AuthContext, (state) => state?.isUserLoading);
}

export function useControlledUser() {
  return useContextSelector(AuthContext, (state) => state?.controlledUser);
}

export function useSetControlledUser() {
  return useContextSelector(AuthContext, (state) => state?.setControlledUser);
}

export function useSetMainUser() {
  return useContextSelector(AuthContext, (state) => state?.setUser);
}

export function useUser() {
  const user = useMainUser();
  const controlledUser = useControlledUser();
  return controlledUser || user;
}
