import {
  FieldPolygonCreatePayload,
  FieldPolygonDuplicatePayload,
  FieldPolygonUpdatePayload,
} from '../types';
import apiClient from './api-client';

export const getAllFieldPolygonApi = (user_id: string, crop_year: string) => {
  return apiClient.get(
    `field_polygon/all?user_id=${user_id}&crop_year=${crop_year}`
  );
};

// Test?
export const getPartnerFieldsApi = (userId: string, partner_type?: string) => {
  return apiClient.get(
    `test/names?userId=${userId}&partner_type=${partner_type}`
  );
};

export const getPartnerFieldGeometry = (
  userId: string,
  field_index?: string | number,
  partner_type?: string
) => {
  return apiClient.get(
    `test?userId=${userId}&field_index=${field_index}&partner_type=${partner_type}`
  );
};

// Mutations
export const updateFieldPolygonApi = (payload: FieldPolygonUpdatePayload) => {
  return apiClient.post(`field_polygon/update`, payload);
};

export const duplicateFieldPolygonApi = (
  payload: FieldPolygonDuplicatePayload
) => {
  return apiClient.post(`field_polygon/duplicate`, payload);
};

export const createFieldPolygonApi = (payload: FieldPolygonCreatePayload) => {
  return apiClient.post(`field_polygon`, payload);
};

export const deleteFieldPolygonApi = ({
  user_id_field_index,
  crop_year,
  boundary = false,
}: {
  user_id_field_index: string;
  crop_year: string;
  boundary?: boolean;
}) => {
  return apiClient.delete(
    `field_polygon?user_id_field_index=${user_id_field_index}&crop_year=${crop_year}&boundary=${boundary}`
  );
};
