import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import AgrivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import Select from '../../../components/commons/Select';
import StyledCard from '../../../components/main/StyledCard';
import { useRequestUserRegistration } from '../../../hooks/user/use-users';
import { NETWORK_PARTNER_LOGO } from '../../../constants';

const validateSchema = yup.object({
  email: yup.string().email().required().label('Email'),
  requesting_status: yup.string().required().label('Role'),
  network_partner: yup.string().required().label('Network Partner'),
});

export default function AdvisorSignUpPage() {
  const {
    mutate: sendRequest,
    isPending,
    isSuccess,
  } = useRequestUserRegistration();
  const form = useForm({
    defaultValues: {
      email: '',
      requesting_status: 'advisor',
      network_partner: 'New Vision',
    },
    resolver: yupResolver(validateSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const networkPartner = form.watch(
    'network_partner'
  ) as keyof typeof NETWORK_PARTNER_LOGO;

  const handleSignupRequest = (values: {
    email: string;
    requesting_status: string;
    network_partner: string;
  }) => {
    sendRequest(values);
  };

  const disabled = !isValid || isPending;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        {!isSuccess ? (
          <div className="max-w-screen-sm w-full">
            <img
              src={NETWORK_PARTNER_LOGO.Agrivar}
              alt={networkPartner}
              width={'70%'}
              className="mx-auto mb-4"
            />
            <StyledCard title="Sign Up">
              <form
                onSubmit={handleSubmit(handleSignupRequest)}
                className="flex flex-col w-full space-y-8 pb-8 px-8"
              >
                <TextInput
                  label="Email*"
                  name="email"
                  placeholder="Enter email"
                  className="!bg-base-000 !text-block-fill"
                  required
                />

                <Select
                  name="network_partner"
                  options={[
                    {
                      label: 'New Vision',
                      value: 'New Vision',
                    },
                    {
                      label: 'Heartland',
                      value: 'Heartland',
                    },
                    {
                      label: 'CFE',
                      value: 'CFE',
                    },
                  ]}
                  label="Network Partner"
                  required
                />

                <div className="flex flex-col space-y-5">
                  <Button
                    type="submit"
                    size="lg"
                    className="!font-normal !rounded-md"
                    disabled={disabled}
                    color="primary"
                    loading={isPending}
                  >
                    Submit
                  </Button>
                  <Link to="/login">
                    <Button
                      type="button"
                      size="lg"
                      className="!font-normal !rounded-md"
                    >
                      Return to Login
                    </Button>
                  </Link>
                </div>
              </form>
            </StyledCard>
          </div>
        ) : (
          <div className="max-w-screen-md w-full">
            <StyledCard
              title="Your Request Was Successfully Submitted"
              className="text-center text-lg-regular"
            >
              <p>Your account request is being processed.</p>
              <br />
              <p>
                Once your account is approved, you will receive an email from
                'no-reply@agrivar.ag' with a temporary password to sign into
                your new AgriVaR account. You will reset your password after
                completing this initial sign in.
              </p>
              <br />
              <p>
                If you do not see the email, be sure to check your spam or junk
                folders as well.
              </p>
              <Link to="/login">
                <Button
                  color="primary"
                  className="!w-fit mx-auto mt-8 !font-normal !rounded-md"
                  size="lg"
                >
                  Go Back to Sign In
                </Button>
              </Link>
            </StyledCard>
          </div>
        )}
      </div>
    </FormProvider>
  );
}
