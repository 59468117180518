import { Field, Label } from '@headlessui/react';
import clsx from 'clsx';
import { get } from 'lodash';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { theme } from 'twin.macro';

interface RadioInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'name'> {
  endAdornment?: any;
  unit?: string;
  name: string;
  label?: ReactNode | string | ((checked: boolean) => JSX.Element);
  labelClassName?: string;
  icon?: (props: any) => JSX.Element;
}

function RadioInput(props: RadioInputProps) {
  const {
    label,
    className,
    labelClassName,
    endAdornment,
    unit,
    name,
    disabled,
    type,
    icon: Icon,
    required,
    ...rest
  } = props;
  const [hovered, setHovered] = useState(false);
  const formContext = useFormContext();
  const error = get(formContext?.formState.errors, name);

  if (!formContext) {
    // In case you dont get the values by handleSubmit of react-hook-form
    // you forgot to wrap the form with <FormProvider> in the parent component
  }

  const checked = formContext?.watch(name) === rest.value;

  return (
    <Field
      className="w-auto flex items-center space-x-2"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <input
        id={`${name}-${rest.value}`}
        className={clsx('custom-radio', clsx(className))}
        disabled={disabled}
        type="radio"
        {...formContext?.register(name, {
          required: required ? `${label} is required` : false,
        })}
        {...rest}
        name={name}
      />
      {label && (
        <Label
          htmlFor={`${name}-${rest.value}`}
          className={clsx(
            labelClassName,
            disabled ? 'cursor-auto' : 'cursor-pointer',
            'flex justify-center space-x-2',
            {
              'text-primary': !disabled && (hovered || checked),
            }
          )}
        >
          {Icon && (
            <Icon
              fill={
                !disabled && (hovered || checked)
                  ? theme`colors.primary`
                  : theme`colors.base.800`
              }
            />
          )}
          <span>{typeof label === 'function' ? label(checked) : label}</span>
        </Label>
      )}
      {error && <p className="text-red">{`${error?.message}`}</p>}
    </Field>
  );
}

export default RadioInput;
