import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'twin.macro';
import DocumentPlusCircleIcon from '../../../../assets/icons/DocumentPlusCircleIcon';
import {
  HedgeCropOptions,
  HedgeTypes,
  MonthOptions,
} from '../../../../constants/risk-management';
import { useUser } from '../../../../contexts/auth-context';
import { useUpdateHedge } from '../../../../hooks/hedge/use-hedge';
import { HedgeItem } from '../../../../types/hedge';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select from '../../../commons/Select';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import TextInput from '../../../commons/inputs/TextInput';
import { getAddHedgePayload, PositionOptions, useHedgeForm } from '../helpers';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';

interface EditHedgeFormProps {
  data: HedgeItem;
  onCancel: () => void;
}
export default function EditHedgeForm({ data, onCancel }: EditHedgeFormProps) {
  const type = data.hedge_type;
  const user = useUser();

  const form = useForm({
    defaultValues: {
      ...data,
      month: data.contract_date?.slice(0, 3),
      year: data.contract_date?.slice(-2),
    },
  });

  const { contractOptions, strikeOptions, fieldOptions, isFetchingPrice } =
    useHedgeForm({
      form,
      type,
    });

  const { mutateAsync: update, isPending } = useUpdateHedge();
  const onSubmit = async ({ month, year, ...values }: any) => {
    const payload = getAddHedgePayload({
      formValues: values,
      userId: user?.id ?? '',
    });

    try {
      await update(payload);
      enqueueSnackbar(`Update Hedge ${type} successfully`, {
        variant: 'success',
      });
      onCancel();
      form.reset();
    } catch (error) {
      enqueueSnackbar(`Failed to Update Hedge ${type}`, {
        variant: 'error',
      });
    }
  };

  return (
    <div className="space-y-8">
      <CardHeader
        icon={<DocumentPlusCircleIcon fill={theme`colors.base.000`} />}
        title={`Edit Hedge ${type}`}
      />

      {/* Form */}
      <FormProvider {...form}>
        <form
          className="grid grid-cols-3 gap-4"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <TextInput label="Hedge Name" name="hedge_name" required />

          {![HedgeTypes.Basis, HedgeTypes.Cash].includes(type) && (
            <Select
              label="Position"
              name="position"
              options={PositionOptions}
              required
            />
          )}

          <ComboSelectInput
            label="Exchange Commodity"
            name="contract"
            options={contractOptions ?? []}
            required
          />

          {form.watch('contract') === 'Other' && (
            <TextInput name="contract_other" label="Other" required />
          )}

          <div className="flex items-end gap-2">
            <Select
              name="month"
              options={MonthOptions}
              label="Contract Month/Year"
              required
            />

            <Select
              name="year"
              label="Year"
              hiddenLabel
              options={getYearOptions(10, 5, false)}
              required
            />
          </div>

          <TextInput
            label="Quantity Hedged"
            name="quantity"
            type="number"
            required
          />

          <ComboDatePicker label="Trade Date" name="dateTrade" required />

          <TextInput
            label={
              [HedgeTypes.Call, HedgeTypes.Put].includes(type)
                ? 'Option Premium (negative value for paid premiums, cents/unit)'
                : 'Price'
            }
            name="price"
            required
            loading={isFetchingPrice}
            type="number"
          />
          {[HedgeTypes.Call, HedgeTypes.Put].includes(type) && (
            <ComboSelectInput
              label="Strike Price (cents/unit)"
              name="strike"
              required
              options={strikeOptions}
            />
          )}

          <div className="flex items-end gap-2">
            <Select
              label="Crop Year Hedged"
              name="crop"
              options={HedgeCropOptions}
              required
            />
            <Select
              name="crop_year"
              label="Crop Year"
              hiddenLabel
              options={getYearOptions(4, 1)}
              required
            />
          </div>

          <Select
            label="Apply to field"
            name="fieldApplied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
          />

          <TextInput
            type="number"
            label="Share"
            name="producerShare"
            endAdornment="%"
            required
          />

          <div className="flex justify-end space-x-3 col-span-3">
            <Button className="!w-fit" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="!w-fit"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
