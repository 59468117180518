import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SpinningIcon from '../../assets/icons/SpinningIcon';
import {
  useIsAuthenticated,
  useIsUserLoading,
  useMainUser,
} from '../../contexts/auth-context';
import { Role, UserProfileRes } from '../../types';

export const getUserRole = (user: UserProfileRes): Role => {
  if (user?.is_admin) {
    return 'admin';
  }
  if (user?.is_advisor_client) {
    return 'advisor';
  }
  return 'user';
};

// Use for routes that require authentication but not need user profile
function OnboardingRoute({
  redirectPath = '/login',
  children,
  roles,
}: {
  redirectPath?: string;
  children?: ReactNode;
  roles?: Role[];
}): JSX.Element {
  const isUserLoading = useIsUserLoading();
  const isAuthenticated = useIsAuthenticated();
  const user = useMainUser();

  if (isAuthenticated === false) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isUserLoading) {
    return (
      <div className="fixed flex justify-center items-center h-full w-full bg-base-900">
        <SpinningIcon />
      </div>
    );
  }

  if (isUserLoading === false) {
    if (user?.id && user?.is_completed !== false) {
      return <Navigate to="/" replace />;
    }
  }

  return <>{children}</> || <Outlet />;
}

export default OnboardingRoute;
