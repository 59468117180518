import Button from '../../../components/commons/Button';
import Table, { TableConfigs } from '../../../components/commons/Table';
import {
  useApproveUserRegistration,
  useIncomingUsers,
} from '../../../hooks/user/use-users';
import { UserProfileRes } from '../../../types';

export default function IncomingUserList() {
  const { data: users } = useIncomingUsers();

  const { mutate: approveUser, isPending: isApprovePending } =
    useApproveUserRegistration();

  const handleApprove = async (item: UserProfileRes) => {
    approveUser({ userId: item.id, email: item.email });
  };

  const tableConfigs: TableConfigs = {
    cols: [
      {
        key: 'id',
        name: 'ID',
      },
      {
        key: 'email',
        name: 'Email',
      },
    ],
  };

  const extraActions = (item: UserProfileRes) => {
    return (
      <div className="flex space-x-2 mx-auto w-fit">
        <Button
          color="primary"
          size="sm"
          onClick={() => handleApprove(item)}
          loading={isApprovePending}
          disabled={isApprovePending}
        >
          Approve
        </Button>
        <Button size="sm" className="bg-red">
          Decline
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Table configs={tableConfigs} data={users} extraActions={extraActions} />
    </div>
  );
}
