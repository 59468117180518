import { useQuery } from '@tanstack/react-query';
import { getInsurancesApi } from '../../api/insurance-api';
import { InsuranceItem } from '../../types/insurance';

export const useInsurances = ({
  userId,
  year,
  scenarioId,
}: {
  userId: string;
  year: string;
  scenarioId?: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['insurance', userId, year, scenarioId],
    queryFn: () =>
      getInsurancesApi({
        userId,
        year,
        scenarioId,
      }),
  });

  return { data: data?.data as InsuranceItem[], ...rest };
};
