import { Field, Label } from '@headlessui/react';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useUpdateExpense } from '../../../hooks/expense/use-expense';
import { ExpenseItem } from '../../../types';
import { parseMoney } from '../../../utils';
import Button from '../../commons/Button';
import Checkbox from '../../commons/Checkbox';
import ComboDatePicker from '../../commons/inputs/ComboDatePicker';
import TextInput from '../../commons/inputs/TextInput';

interface EditFarmExpenseFormProps {
  data: ExpenseItem;
  onClose: () => void;
}

type ExpenseFormValues = {
  purchase_date: string;
  vendor: string;
  vendor_address: string;
  isPaid: boolean;
  description: string;
  expense_amount: number;
  entry_date: string;
};

export default function EditFarmExpenseForm({
  data,
  onClose,
}: EditFarmExpenseFormProps) {
  const user = useUser();
  const year = useYearFilter();

  const { mutateAsync: updateExpense, isPending } = useUpdateExpense();
  const form = useForm({
    defaultValues: {
      purchase_date: data?.purchase_date,
      vendor: data?.vendor,
      vendor_address: data?.vendor_address,
      isPaid: data?.isPaid === 'true',
      description: data?.description,
      expense_amount: parseMoney(data?.expense_amount) ?? 0,
      entry_date: data?.entry_date,
    },
  });

  const handleSubmit = async (values: ExpenseFormValues) => {
    if (!user || !year) return;
    const updates = Object.entries(values).map(([key, value]) => ({
      key,
      value: value?.toString() ?? '',
    }));

    try {
      await updateExpense({
        userId: user.id,
        year: year,
        expenseId: data.expense_id,
        updates,
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Failed to update expense', { variant: 'error' });
    }
  };

  return (
    <div>
      <p className="mb-2">Edit Expense</p>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="rounded-lg bg-base-900 p-4"
        >
          <div className="grid grid-cols-4 gap-4">
            <ComboDatePicker
              name="purchase_date"
              label="Date of Expense"
              required
            />
            <TextInput name="vendor" label="Vendor" required />
            <TextInput name="vendor_address" label="Vendor Address" />
            <Field className="space-y-3">
              <Label>Payment Status</Label>
              <Checkbox
                name="isPaid"
                value="true"
                label="Expense Paid"
                defaultChecked={data?.isPaid === 'true'}
                rounded
              />
            </Field>
            <TextInput name="description" label="Description" />
            <TextInput
              name="expense_amount"
              type="number"
              label="Expense Amount"
              required
            />
            <ComboDatePicker name="entry_date" label="Expense Entry Date" />
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <Button
              type="submit"
              color="primary"
              size="sm"
              className="!w-fit"
              loading={isPending}
              disabled={
                isPending || !form.formState.isDirty || !form.formState.isValid
              }
            >
              Save
            </Button>
            <Button color="primary" size="sm" className="!w-fit">
              Move to List
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
