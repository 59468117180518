import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import HedgingList from './HedgingList';
import HedgingOptions from './HedgingOptions';

export default function Hedging() {
  return (
    <DisclosureContentLayout
      left={<HedgingOptions />}
      right={<HedgingList />}
    />
  );
}
