import {
  UpdateMyProfilePayload,
  UpdateUserPayload,
  UserSettingRealTimeUpdatePayload,
  UserSettingsRealTimeSetPayload,
} from '../types';
import apiClient, { apiClientWithoutToken } from './api-client';

export const getUsersApi = ({ is_completed }: { is_completed?: boolean }) => {
  if (!!is_completed) {
    return apiClient.get(`/users?is_completed=${is_completed}`);
  }
  return apiClient.get(`/users`);
};

export const getUserByIdApi = (userId: string) => {
  return apiClient.get(`/users?userId=${userId}`);
};

export const getIncomingUsersApi = () => {
  return apiClient.get(`/users/register-request`);
};

export const getStaffApi = () => {
  return apiClient.get(`/users/advisors`);
};

export const getUserApi = (userId: string) => {
  return apiClient.get(`/users$?userId=${userId}`);
};

export const getUserProfileApi = async () => {
  return apiClient.get(`/users/me`);
};

export const getUserRealTimeSettingsApi = (userId: string, year: string) => {
  console.log('getting user settings api call userId', userId, year);
  return apiClient.get(
    `/user/settings/real-time?user_id=${userId}&year=${year}`
  );
};

export const setUserRealTimeSettingsApi = (
  payload: UserSettingsRealTimeSetPayload[]
) => {
  return apiClient.post(`/user/settings/real-time`, payload);
};

export const updateUserRealTimeSettingsApi = (
  payload: UserSettingRealTimeUpdatePayload
) => {
  return apiClient.post('/user/settings/real-time/update', payload);
};

export const sendStaffRequestApi = (email: string) => {
  return apiClient.post(`/users/advisors/request`, {
    email: email,
  });
};

export const sendTempPasswordApi = (userId: string, email: string) => {
  return apiClient.post('/users/send-temp-password', {
    id: userId,
    email: email,
  });
};

export const updateUserApi = (payload: UpdateUserPayload) => {
  return apiClient.post(`/users/update`, { ...payload });
};

export const approveUserRegistrationApi = (userId: string, email: string) => {
  return apiClient.post(`/users/register-approve`, {
    id: userId,
    email: email,
  });
};

export const approveStaffRequestApi = (memberId: string) => {
  return apiClient.post(`/users/advisors/approve-request`, {
    member_id: memberId,
  });
};

export const requestUserRegistrationApi = (payload: {
  email: string;
  requesting_status: string;
  network_partner: string;
}) => {
  return apiClientWithoutToken.post(`/users/register-request`, payload);
};

export const updateMyProfileApi = (payload: UpdateMyProfilePayload) => {
  return apiClient.post(`/users/me`, { ...payload });
};

export const getPartnerMembersApi = ({
  searchText,
  partner,
}: {
  searchText: string;
  partner: string;
}) => {
  return apiClient.get(
    `users/members?search_text=${searchText}&partner=${partner}`
  );
};

export const connectMembersApi = (payload: {
  uniqueid: string;
  name: string;
  user_id: string;
}) => {
  return apiClient.post('/users/members', payload);
};

export const disconnectMembersApi = ({
  uniqueid,
  name,
}: {
  uniqueid: string;
  name: string;
}) => {
  return apiClient.delete(`/users/members?uniqueid=${uniqueid}&name=${name}`);
};

export const resetUserPasswordApi = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return apiClient.post(`/users/reset-password`, { email, password });
};
