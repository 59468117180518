export default function CornIconColored() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_163_6958)">
        <path
          d="M12.8703 4.85864C12.7941 4.32766 12.7441 3.79781 12.6679 3.31799H11.7581C11.6819 3.82283 11.6068 4.32879 11.5557 4.85864H12.8692H12.8703Z"
          fill="#FFB71B"
        />
        <path
          d="M12.2124 7.13162H13.1221C13.0971 6.72798 13.0459 6.29819 12.9959 5.8684C12.9959 5.76721 12.9709 5.66601 12.9709 5.56482H11.455C11.455 5.66601 11.4299 5.76721 11.4299 5.8684C11.3799 6.29819 11.3537 6.70183 11.3037 7.13162H12.2135H12.2124Z"
          fill="#FFB71B"
        />
        <path
          d="M10.697 5.81835C10.697 5.79334 10.697 5.71716 10.722 5.61597C10.1409 5.64098 9.5848 5.666 9.07987 5.71716C8.95364 6.22199 8.8274 6.75298 8.70117 7.30897C9.33233 7.25895 9.96463 7.20778 10.5458 7.18277C10.5958 6.72796 10.647 6.27316 10.697 5.81835Z"
          fill="#FFB71B"
        />
        <path
          d="M12.5661 2.56078C12.4649 1.87857 12.3387 1.22252 12.2124 0.615356C12.0862 1.22138 11.985 1.85356 11.8838 2.56078H12.5661Z"
          fill="#FFB71B"
        />
        <path
          d="M10.4951 7.91492C9.78778 7.93993 9.15662 7.9911 8.54934 8.04113C8.44813 8.57211 8.37193 9.07694 8.29688 9.60679C9.00423 9.53061 9.71159 9.48058 10.3689 9.45557C10.4189 8.95073 10.4451 8.44476 10.4951 7.91492Z"
          fill="#FFB71B"
        />
        <path
          d="M10.3181 11.6534C10.3181 11.1986 10.3432 10.6938 10.3682 10.214C9.58463 10.239 8.87728 10.3152 8.24612 10.3914C8.19608 10.5938 8.16992 10.8212 8.16992 11.0486C8.64983 11.2259 9.10472 11.4522 9.55961 11.7057C9.81208 11.6807 10.0645 11.6557 10.317 11.6557L10.3181 11.6534Z"
          fill="#FFB71B"
        />
        <path
          d="M14.1071 11.6534C14.3595 11.6534 14.612 11.6784 14.8906 11.7034C15.3455 11.451 15.8004 11.2486 16.2803 11.0462C16.2553 10.8188 16.2303 10.5914 16.2041 10.389C15.5729 10.3129 14.8656 10.2628 14.082 10.2117C14.082 10.6915 14.1071 11.1713 14.1071 11.6511V11.6534Z"
          fill="#FFB71B"
        />
        <path
          d="M11.0002 3.31799C10.5965 3.31799 10.1916 3.34301 9.81294 3.36802C9.61051 3.84784 9.43424 4.37882 9.25684 4.95984C9.78792 4.90981 10.2928 4.88366 10.7728 4.88366C10.849 4.45387 10.924 3.92402 11.0002 3.31799Z"
          fill="#FFB71B"
        />
        <path
          d="M14.0325 9.48059C14.6898 9.5056 15.3972 9.55677 16.1045 9.63181C16.0283 9.10083 15.9533 8.57098 15.852 8.06615C15.2709 8.01612 14.6136 7.96496 13.9062 7.93994C13.9824 8.44477 14.0075 8.95074 14.0325 9.48059Z"
          fill="#FFB71B"
        />
        <path
          d="M11.0508 11.6284C11.4807 11.6034 11.8593 11.6034 12.213 11.6034C12.5667 11.6034 12.9454 11.6034 13.3753 11.6284C13.3753 11.1736 13.3503 10.6938 13.3252 10.214C12.9716 10.214 12.5929 10.189 12.2142 10.189C11.8355 10.189 11.4568 10.189 11.1031 10.214C11.0781 10.6938 11.0781 11.1736 11.0531 11.6284H11.0508Z"
          fill="#FFB71B"
        />
        <path
          d="M11.0764 12.6643C11.4301 12.9167 11.8088 13.1953 12.2136 13.5489C12.6173 13.1953 13.0222 12.9178 13.3509 12.6643V12.3607C12.9722 12.3607 12.5935 12.3357 12.1886 12.3357C11.7838 12.3357 11.4051 12.3357 11.0264 12.3607C11.0514 12.4619 11.0514 12.5631 11.0764 12.6643Z"
          fill="#FFB71B"
        />
        <path
          d="M11.4045 0.918945C10.9496 1.24754 10.5458 1.85357 10.166 2.61082C10.4947 2.5858 10.8233 2.5858 11.1258 2.56079C11.202 2.05596 11.3032 1.49996 11.4033 0.918945H11.4045Z"
          fill="#FFB71B"
        />
        <path
          d="M13.6278 4.88366C14.1077 4.90867 14.6127 4.93368 15.1438 4.95984C14.9664 4.37882 14.7901 3.84898 14.5877 3.36802C14.209 3.34301 13.8303 3.31799 13.4004 3.31799C13.5016 3.92402 13.5778 4.455 13.6278 4.88366Z"
          fill="#FFB71B"
        />
        <path
          d="M14.2599 2.61082C13.8812 1.85357 13.4764 1.2464 13.0215 0.918945C13.1227 1.49996 13.2239 2.05596 13.299 2.56079C13.6026 2.5858 13.9301 2.5858 14.2588 2.61082H14.2599Z"
          fill="#FFB71B"
        />
        <path
          d="M13.7287 5.81835C13.7788 6.27316 13.83 6.72796 13.855 7.18277C14.4361 7.20778 15.0673 7.25895 15.6996 7.30897C15.5983 6.75298 15.4721 6.22313 15.3209 5.71716C14.8159 5.66713 14.2598 5.64098 13.6787 5.61597C13.7287 5.71716 13.7287 5.79334 13.7287 5.81835Z"
          fill="#FFB71B"
        />
        <path
          d="M13.2998 9.45557C13.2748 8.95073 13.2236 8.44476 13.1986 7.91492H11.2789C11.2289 8.4459 11.2028 8.97575 11.1777 9.45557C11.5564 9.45557 11.9351 9.43055 12.2638 9.43055C12.5924 9.43055 12.8949 9.43055 13.2998 9.45557Z"
          fill="#FFB71B"
        />
        <path
          d="M11.6575 14.0525C11.0263 13.5216 10.4452 13.1179 9.9653 12.8143C7.66583 11.2987 4.5339 10.6677 3.22041 10.4653C3.043 10.4403 2.91677 10.6677 3.06916 10.7939C3.90274 11.5773 5.67113 13.4715 6.95961 16.4016C7.64194 18.0184 7.79319 19.3067 7.89441 20.3675C8.22307 19.0543 8.9054 17.791 9.38531 16.9326C10.0927 15.7205 10.8762 14.7859 11.6586 14.0525H11.6575Z"
          fill="#80BC00"
        />
        <path
          d="M21.1816 10.4664C19.8681 10.6688 16.735 11.2748 14.4367 12.8155C13.2994 13.5727 11.4048 14.861 10.0413 17.3112C9.23271 18.7507 7.71679 21.4784 8.93021 23.1964C10.1175 24.9144 13.6042 25.0906 15.3476 23.6262C17.116 22.1617 15.8275 20.0651 17.4446 16.4016C18.7081 13.4715 20.5015 11.5773 21.3351 10.7939C21.4863 10.6677 21.3851 10.4403 21.1838 10.4653L21.1816 10.4664Z"
          fill="#80BC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_163_6958">
          <rect
            width="18.406"
            height="24"
            fill="white"
            transform="translate(3 0.615356)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
