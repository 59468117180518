import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  useHistoricalInsights,
  useTableData,
} from '../../hooks/historical-insights/use-historical-insights';
import { useUser } from '../../contexts/auth-context';
import { table } from 'console';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const PercentageHorizontalBar = () => {
  const user = useUser();
  const { year, relationship, crop, page } = useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const data = {
    labels: tableData.data
      ?.slice(page * 5, (page + 1) * 5)
      .map((data: any) => data.x_bin),
    datasets: [
      {
        label: 'Yield Performance',
        data: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => data.yield_performance),
        backgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map(
            (data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 0.6)`
          ),
        borderColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
        borderWidth: 1,
        borderRadius: 20, // Rounded bars
        barThickness: 25, // Thickness of the bars
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
            color: '#ffffff',
          },
        },
        grid: {
          display: false, // Hide the grid lines
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
            color: '#ffffff',
          },
        },
        grid: {
          display: false, // Hide the grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: '#ffffff',
        font: {
          weight: 'bold',
          size: 16,
        },
        align: 'end',
        anchor: 'end',
      },
    },
    elements: {
      bar: {
        hoverBackgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0 auto' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default PercentageHorizontalBar;
