import { IconProps } from '../../types';

const MarketsIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17.75C3 18.1719 3.32812 18.5 3.75 18.5H22.5C23.2969 18.5 24 19.2031 24 20C24 20.8438 23.2969 21.5 22.5 21.5H3.75C1.64062 21.5 0 19.8594 0 17.75V2C0 1.20312 0.65625 0.5 1.5 0.5C2.29688 0.5 3 1.20312 3 2V17.75ZM20.5781 12.0781L19.0312 10.5781L15.2812 14.3281C14.7188 14.9375 13.7344 14.9375 13.1719 14.3281L9.75 10.9062L7.03125 13.5781C6.46875 14.1875 5.48438 14.1875 4.92188 13.5781C4.3125 13.0156 4.3125 12.0312 4.92188 11.4688L8.67188 7.71875C9.23438 7.10938 10.2188 7.10938 10.7812 7.71875L14.25 11.1406L16.9219 8.46875L15.375 6.92188C14.6719 6.21875 15.1875 5 16.1719 5H21.375C21.9844 5 22.5 5.51562 22.5 6.125V11.3281C22.5 12.3125 21.2812 12.8281 20.5781 12.0781Z"
        fill={fill}
      />
    </svg>
  );
};

export default MarketsIcon;
