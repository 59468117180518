import {
  ArrowLeftEndOnRectangleIcon,
  ArrowsPointingOutIcon,
  BackwardIcon,
  BookOpenIcon,
  BookmarkIcon,
  CubeTransparentIcon,
  EyeIcon,
  EyeSlashIcon,
  FunnelIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useEffect, useMemo } from 'react';
import Select from '../../../components/commons/Select';
import ZoomInIcon from '../../../assets/icons/ZoomInIcon';
import ZoomOutIcon from '../../../assets/icons/ZoomOutIcon';
import MoveIcon from '../../../assets/icons/MoveIcon';
import PolygonIcon from '../../../assets/icons/PolygonIcon';
import CircleIcon from '../../../assets/icons/CircleIcon';
import {
  useCreateNote,
  useDeleteStudy,
  useHistoricalInsights,
  useStudyData,
  useStudyNote,
} from '../../../hooks/historical-insights/use-historical-insights';
import * as turf from '@turf/turf';
import { set } from 'lodash';
import SquareIcon from '../../../assets/icons/SquareIcon';
import MapZoomInIcon from '../../../assets/icons/MapZoomInIcon';
import MapZoomOutIcon from '../../../assets/icons/MapZoomOutIcon';
import TextInput from '../../../components/commons/inputs/TextInput';
import { capitalizeFirst } from '../../../utils';
import { useForm } from 'react-hook-form';
import { useUser } from '../../../contexts/auth-context';
import Table, { TableConfigs } from '../../../components/commons/Table';
import { createStudyTableConfig } from '../../../constants/tableConfigs';
import { EditIcon } from '../../../assets/icons';
import RectangleIcon from '../../../assets/icons/RectangleIcon';
import { format } from 'path';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { mapRelationshipOptions } from '../../../constants/selectCommonConfigs';
import { useSetYearFilter } from '../../../contexts/app-filter-context';
import Disclosure from '../../../components/commons/Disclosure';

function MapToolbar() {
  const {
    viewState,
    setViewState,
    deck,
    map,
    drawRef: draw,
    creatingStudy,
    setCreatingStudy,
    relationship,
    studyDetails,
    setStudyDetails,
    createStudy,
    setCreateStudy,
    year,
    setStudies,
    setSelectedStudyIndex,
    selectedStudyIndex,
    selectedDrawMode,
    setSelectedDrawMode,
    myFeatureCollection,
    setMyFeatureCollection,
    selectedFeatureIndexes,
    setSelectedFeatureIndexes,
    setEditMode,
    setSaveEditingStudy,
    rgb,
    setRgb,
    setRelationship,
    editMode,
    setModalMode,
    setYear,
    setCrop,
  } = useHistoricalInsights();

  const setYearFilter = useSetYearFilter();

  const [studyRelationshipFilter, setStudyRelationshipFilter] =
    React.useState('');

  const studyTableConfig = (relationship: string): TableConfigs => {
    return {
      cols: [
        {
          key: 'name',
          name: 'Name',
        },
        {
          key: 'year',
          name: 'Year',
        },
        {
          key: 'crop',
          name: 'Crop',
        },
        {
          key: 'relationship',
          name: 'Type',
        },
        {
          key: 'area',
          name: 'Acres',
        },
        {
          key: 'lon',
          name: 'lon',
        },
        {
          key: 'lat',
          name: 'lat',
        },
        {
          key: 'x_value',
          name: 'Value',
        },
        {
          key: 'x_bin',
          name: `Value Range`,
        },
        {
          key: 'yield',
          name: 'Yield',
        },
        {
          key: 'yieldPerformance',
          name: 'Yield Performance',
        },
        {
          key: '',
          name: 'View Study',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                if (
                  selectedStudyIndex ===
                  item.user_id_type_crop_year + item.x_index
                ) {
                  setSelectedStudyIndex(-1);
                  return;
                }
                setSelectedStudyIndex(
                  item.user_id_type_crop_year + item.x_index
                );
              }}
              style={{
                backgroundColor:
                  selectedStudyIndex ===
                  item.user_id_type_crop_year + item.x_index
                    ? 'gray'
                    : '#80bc00',
              }}
              className={`w-full h-8 justify-center items-center flex rounded-md`}
            >
              {selectedStudyIndex ===
              item.user_id_type_crop_year + item.x_index ? (
                <p>Hide</p>
              ) : (
                <p>View</p>
              )}
            </div>
          ),
        },
      ],
    };
  };

  const updateMapSelections = (study: any) => {
    setYearFilter?.(study.year);
    setYear(study.year);
    setRelationship(study.relationship);
    setCrop(study.crop);
  };

  const queryClient = useQueryClient();

  const { mutate: createNote } = useCreateNote();

  const { mutate: deleteStudy } = useDeleteStudy();

  const [studyNotePk, setStudyNotePk] = React.useState<string | null>(null);
  const [studyNodeXIndex, setStudyNodeXIndex] = React.useState<string | null>(
    null
  );

  const studyNoteData = useStudyNote(studyNotePk ?? '', studyNodeXIndex ?? '');

  console.log('studyNoteData', studyNoteData);

  const studyNote = useMemo(() => {
    return studyNoteData.data?.note ?? '';
  }, [studyNoteData]);

  console.log('studyNote', studyNote);

  const rowActions = {
    edit: {
      onSelect: (item: any) => {
        updateMapSelections(item);
        if (editMode == 'edit') {
          setEditMode('');
          setMyFeatureCollection({
            type: 'FeatureCollection',
            features: [],
          });
          return;
        }
        console.log('select');
        setEditMode('edit');

        // remove all polygons and redraw study polyogns on map
        const polygons = item.polygons;
        const features = polygons.map((polygon: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: polygon,
            },
            properties: {},
          };
        });
        setMyFeatureCollection({
          type: 'FeatureCollection',
          features: features,
        });
        setSaveEditingStudy(item);
      },
    },
    delete: (item: any) => {
      console.log('delete study', item);
      deleteStudy(item, {
        onSuccess: () => {
          alert('Study Deleted');
          // invalidate the query
          queryClient.invalidateQueries([
            'historical-insights/study-data',
          ] as InvalidateQueryFilters);
        },
        onError: (error: unknown, _variables: any, _context: unknown) => {
          console.log('Error deleting study:', error);
          alert(`Failed to delete study. Please try again. ${error}`);
        },
      });
    },
    note: {
      onSelect: (item: any) => {
        //
        setStudyNotePk(item.user_id_type_crop_year);
        setStudyNodeXIndex(item.x_index);
      },
      onSave: (item: any, value: string) => {
        createNote(
          {
            user_id_type_crop_year: item.user_id_type_crop_year,
            xIndex: item.x_index,
            note: value,
          },
          {
            onSuccess: () => {
              alert('Note Saved');
              // invalidate the query
              queryClient.invalidateQueries([
                'historical-insights/createNode',
              ] as InvalidateQueryFilters);
            },
            onError: (error: unknown, _variables: any, _context: unknown) => {
              console.log('Error saving note:', error);
              alert(`Failed to saven note. Please try again. ${error}`);
            },
          }
        );
      },
      title: 'Add Note',
    },
  };

  const user = useUser();

  const handleDrawPolygon = () => {
    if (draw.current) {
      draw.current.changeMode('draw_polygon');
    }
  };

  const studies = useStudyData(user?.id ?? '', year);

  console.log('studies', studies);

  useEffect(() => {
    if (!studies.data) {
      return;
    }
    setStudies(studies.data);
  }, [studies]);

  const handleDeleteShapes = () => {
    if (draw.current) {
      draw.current.trash();
    }
  };

  const formattedTableData = useMemo(() => {
    return studies.data
      ?.map((data: any) => {
        return {
          ...data,
          x_value: parseFloat(data.x_value).toFixed(2),
          area: parseFloat(data.area).toFixed(2),
          lon: parseFloat(data.lon).toFixed(2),
          lat: parseFloat(data.lat).toFixed(2),
          yield: parseFloat(data.yield).toFixed(2),
          yield_performance: parseFloat(data.yield_performance).toFixed(2),
        };
      })
      .filter((data: any) => {
        console.log('studyRelationshipFilter', studyRelationshipFilter);
        if (studyRelationshipFilter === 'all') {
          return true;
        } else if (studyRelationshipFilter !== '') {
          return data.relationship === studyRelationshipFilter;
        }
        return true;
      });
  }, [studies.data, studyRelationshipFilter]);

  return (
    <div className="w-full">
      <div className="justify-center h-[120px] mb-4 w-full flex flex-col gap-4 items-center justify-center">
        <div className="flex gap-4 items-center justify-between w-full card px-6 py-2">
          <div
            onClick={() => {
              setModalMode('');
              setSelectedDrawMode(0);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <ArrowsPointingOutIcon
              fill={selectedDrawMode === 0 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 0 ? 'text-yellow' : 'white'}>
              Move
            </p>
          </div>
          <div
            onClick={() => {
              setViewState({ ...viewState, zoom: viewState.zoom + 1 });
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <MapZoomInIcon fill="white" />
            <p>Zoom In</p>
          </div>
          <div
            onClick={() =>
              setViewState({ ...viewState, zoom: viewState.zoom - 1 })
            }
            className="flex cursor-pointer gap-2 items-center"
          >
            <MapZoomOutIcon fill="white" />
            <p>Zoom Out</p>
          </div>
          <div
            className="flex cursor-pointer gap-2 items-center"
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(1);
            }}
          >
            <PolygonIcon
              fill={selectedDrawMode === 1 ? '#eab208' : 'white'}
              className="w-2 h-2 text-black"
              fontSize={16}
            />
            <p
              className={`${selectedDrawMode === 1 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Polygon
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(3);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <RectangleIcon
              fill={selectedDrawMode === 3 ? '#eab208' : 'white'}
              className="text-white"
            />
            <p
              className={`${selectedDrawMode === 3 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Rectangle
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(2);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <SquareIcon
              fill={selectedDrawMode === 2 ? '#eab208' : 'white'}
              className={`h-4 w-4 ${selectedDrawMode === 2 ? 'text-yellow' : 'text-darkGray'} `}
            />
            <p
              className={`${selectedDrawMode === 2 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Square
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(4);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <CircleIcon
              fill={selectedDrawMode === 4 ? '#eab208' : 'white'}
              className="h-4 w-4 text-darkGray"
            />
            <p
              className={`${selectedDrawMode === 4 ? 'text-yellow' : 'text-white'}`}
            >
              Draw Circle
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('drawingModeSelected');
              setSelectedDrawMode(5);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <PaintBrushIcon
              fill={selectedDrawMode === 5 ? '#eab208' : 'white'}
              className="h-6 w-6 text-white"
            />
            <p
              className={`${selectedDrawMode === 5 ? 'text-yellow' : 'text-white'}`}
            >
              Drag & Draw Polygon-String
            </p>
          </div>
        </div>
        <div className="flex gap-4 w-full items-center justify-between card px-6 py-2">
          <div
            onClick={() => {
              setModalMode('editPolygons');
              setSelectedDrawMode(6);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <PencilSquareIcon
              fill={selectedDrawMode === 6 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 6 ? 'text-yellow' : 'white'}>
              Edit Polygons
            </p>
          </div>
          <div
            onClick={() => {
              setModalMode('transformPolygons');
              setSelectedDrawMode(7);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <CubeTransparentIcon
              fill={selectedDrawMode === 7 ? '#eab208' : 'white'}
              className="h-7 w-7 text-white"
            />
            <p className={selectedDrawMode === 7 ? 'text-yellow' : 'white'}>
              Transform Polygon
            </p>
          </div>

          <div
            onClick={() => {
              setSelectedFeatureIndexes([]);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <EyeSlashIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Unselect All</p>
          </div>
          <div
            onClick={() => {
              // make an array from 0 - myFeatureCollection.features.length
              let indexes = Array.from(
                Array(myFeatureCollection.features.length).keys()
              );
              setSelectedFeatureIndexes(indexes);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <EyeIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Select All</p>
          </div>
          <div
            onClick={() => {
              let newFeatures = myFeatureCollection.features.filter(
                (feature, index) => {
                  return !selectedFeatureIndexes.includes(index);
                }
              );
              setMyFeatureCollection({
                type: 'FeatureCollection',
                features: newFeatures,
              });
              setSelectedFeatureIndexes([]);
            }}
            className="flex cursor-pointer gap-2 items-center"
          >
            <TrashIcon fill={'white'} className="h-7 w-7 text-white" />
            <p className={'text-white'}>Delete Selection</p>
          </div>
        </div>
      </div>
      <Disclosure className="mt-4" title="Custom Studies">
        {creatingStudy ? (
          <div className="card cursor-pointer justify-center items-center  flex  flex-col px-6 w-full">
            <p className="text-xl ">
              Save {capitalizeFirst(relationship)} Study
            </p>
            <TextInput
              name="studyName"
              placeholder="Study Name"
              className="mt-4"
              onChange={(e) =>
                setStudyDetails({ ...studyDetails, name: e.target.value })
              }
            />
            <div
              style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
              className="w-full rounded-xl mt-4"
            >
              <Table
                configs={createStudyTableConfig(relationship)}
                data={[studyDetails]}
              />
            </div>

            <div className="flex gap-4 mt-4">
              <div
                onClick={() => setCreateStudy(true)}
                className="cursor-pointer bg-primary flex gap-2 rounded-xl px-4 py-2"
              >
                <BookmarkIcon color={'white'} className="w-6 h-6" />
                <p>Save</p>
              </div>
              <div
                onClick={() => setCreatingStudy(false)}
                className="flex gap-2 card px-4 py-2"
              >
                <XCircleIcon color={'darkGray'} className="w-6 h-6" />
                <p>Cancel</p>
              </div>
            </div>
          </div>
        ) : (
          studies &&
          studies.data &&
          studies.data.length > 0 && (
            <div className="space-y-0 cursor-pointer justify-start items-center  flex p-0 mt-0">
              <div className="flex flex-col">
                <div className="flex items-center px-2 gap-4 py-2 bg-darkerGray">
                  <Select
                    options={[
                      { label: 'All', value: 'all' },
                      ...mapRelationshipOptions,
                    ]}
                    name="tab"
                    className="max-w-[160px]"
                    onChange={(e) => {
                      if (e.target.value === 'all') {
                        setStudyRelationshipFilter(e.target.value);
                        return;
                      }
                      setRelationship(e.target.value);
                      setRgb([0, 0, 0]);
                      setStudyRelationshipFilter(e.target.value);
                    }}
                  />

                  {creatingStudy ? (
                    <div
                      onClick={() => {
                        setCreatingStudy(false);
                      }}
                      className="flex cursor-pointer gap-2 items-center"
                    >
                      <XCircleIcon
                        className="w-6 h-6 text-darkGray"
                        fontSize={16}
                      />
                      <p>Remove Study Filter</p>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (myFeatureCollection.features.length === 0) {
                          alert('Please draw a polygon to create a study');
                          return;
                        }
                        setCreatingStudy(true);
                      }}
                      className="flex p-2 ml-auto min-w-[240px] rounded-md bg-primary cursor-pointer gap-2 items-center"
                    >
                      <PlusCircleIcon
                        className="w-7 h-7 text-white"
                        fontSize={16}
                      />
                      <p className="text-white">Create Study From Selection</p>
                    </div>
                  )}
                </div>

                {formattedTableData.length > 0 && (
                  <Table
                    configs={studyTableConfig(relationship)}
                    data={formattedTableData ?? []}
                    rowActions={rowActions}
                    initNote={studyNote}
                    pagination={{ size: 5 }}
                  />
                )}
              </div>
            </div>
          )
        )}
      </Disclosure>
    </div>
  );
}

export default MapToolbar;
