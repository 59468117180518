import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useUpdateFieldPrescription } from '../../../../hooks/field/use-field-prescription';
import { FieldPrescriptionRes } from '../../../../types';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import YieldGoalInfo from './YieldGoalInfo';
import YieldGoalList from './YieldGoalList';

interface SetYieldGoalProps {
  prescriptions: FieldPrescriptionRes[];
  crop: string;
  field: string;
  loading: boolean;
}

export type Zone = {
  zone: string;
  name: string;
  acres: number;
  yieldGoal: number;
  historicalAvg: number;
  agrivarRec: number;
  expectedRevenuePerAcre: number;
};

export default function SetYieldGoal({
  field,
  crop,
  loading,
  prescriptions: prescriptionsProp,
}: SetYieldGoalProps) {
  const user = useUser();
  const year = useYearFilter();
  const { mutate: update, isPending } = useUpdateFieldPrescription();

  const [selectedZone, setSelectedZone] = useState<string>(
    prescriptionsProp[0]?.agrivar_zone || ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [prescriptions, setPrescriptions] = useState<FieldPrescriptionRes[]>(
    []
  );

  useEffect(() => {
    setPrescriptions(prescriptionsProp);
  }, [prescriptionsProp]);

  const handleSelectZone = (zone: string) => {
    setSelectedZone(zone);
  };

  const handleChangeGoal = (value: number) => {
    setPrescriptions((prev) =>
      prev.map((item) =>
        item.agrivar_zone === selectedZone
          ? { ...item, user_adjusted_yield: value.toString() }
          : item
      )
    );
  };

  const handleUpdateGoal = (value: number) => {
    const updatedZone = prescriptions.find(
      (item) => item.agrivar_zone === selectedZone
    );
    if (!updatedZone || !year || !user) return;

    update({
      user_id: user.id,
      year: updatedZone.crop_year,
      user_id_crop: updatedZone.user_id_crop,
      agrivar_zone: updatedZone.agrivar_zone,
      field: updatedZone.field,
      key: 'user_adjusted_yield',
      user_adjusted_yield: value.toString(),
    });
  };
  const selectZoneObj = useMemo(() => {
    return (
      prescriptions?.find((pres) => pres.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionRes)
    );
  }, [prescriptions, selectedZone]);

  return (
    <DisclosureContentLayout
      left={
        <YieldGoalInfo
          selectedZone={selectZoneObj}
          onChange={handleChangeGoal}
          onUpdate={handleUpdateGoal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isPending={isPending}
        />
      }
      right={
        <YieldGoalList
          data={prescriptions}
          selectedZone={selectZoneObj}
          onSelect={handleSelectZone}
          loading={loading}
          setIsEditing={setIsEditing}
        />
      }
    />
  );
}
