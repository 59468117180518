import { useQuery } from '@tanstack/react-query';
import {
  getFarmRevenueForecastApi,
  getFarmSatelliteForecastApi,
  getFarmYieldForecastApi,
} from '../../api/farm-api';
import {
  ForecastRevenueRes,
  ForecastSatelliteRes,
  ForecastYieldRes,
} from '../../types';
import { convertToFloat } from '../../utils';
import { mapCropInto } from './use-farm-revenues-14-change';

export function useFarmRevenueForecast(userId: string, crop: string) {
  const { data, ...rest } = useQuery({
    queryKey: ['farm/revenue/forecast', userId, crop],
    queryFn: () => getFarmRevenueForecastApi(userId, crop),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
  return { data: data?.data as ForecastRevenueRes[] | [], ...rest };
}

export function useFarmYieldForecast(userIdYear: string, crop: string) {
  const { data, ...rest } = useQuery({
    queryKey: ['farm/yield/forecast', userIdYear, crop],
    queryFn: () => getFarmYieldForecastApi(userIdYear, crop),
    enabled: !!userIdYear,
    refetchOnWindowFocus: false,
  });
  return { data: data?.data as [] | [], ...rest };
}

export function useFarmSatelliteForecast(userId: string, crop: string) {
  const { data, ...rest } = useQuery({
    queryKey: ['farm/satellite/forecast', userId, crop],
    queryFn: () => getFarmSatelliteForecastApi(userId, crop),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
  return { data: data?.data as [] | [], ...rest };
}

// helper
function convertForecast(
  data: ForecastRevenueRes[] | ForecastSatelliteRes[] | ForecastYieldRes[] = [], // if data is undefined, use an empty array
  field: 'revenue' | 'ndvi_s2' | 'yield',
  subfields: string[]
): Record<string, { numData: Record<string, number>; dataType: string }> {
  return (data as any[]).reduce(
    (
      acc: Record<
        string,
        { numData: Record<string, number>; dataType: string }
      >,
      r
    ) => {
      const keyDate = r.Date;
      if (!acc[keyDate]) {
        acc[keyDate] = {
          numData: {
            estimated: convertToFloat(r[field]),
            ...subfields.reduce(
              (fAcc: Record<string, number>, fKey) => {
                fAcc[`forecasted_${fKey}`] = convertToFloat(
                  r[`${field}_${fKey}`]
                );
                return fAcc;
              },
              <Record<string, number>>{}
            ),
          },
          dataType: r.data_type,
        };
      } else {
        acc[keyDate].numData.estimated += convertToFloat(r[field]);
        subfields.forEach((fKey) => {
          acc[keyDate].numData[`forecasted_${fKey}`] += convertToFloat(
            r[`${field}_${fKey}`]
          );
        });
      }
      return acc;
    },
    {}
  );
}

export function getFarmRevenueForecastChartData(data: ForecastRevenueRes[]) {
  return convertForecast(data, 'revenue', ['0.1', '0.5', '0.9']);
}

export function getFarmYieldForecastChartData(data: ForecastYieldRes[]) {
  return convertForecast(data, 'yield', ['0.1', '0.5', '0.9']);
}

export function getFarmSatelliteForecastChartData(
  data: ForecastSatelliteRes[]
) {
  return convertForecast(data, 'ndvi_s2', ['0.1', '0.5', '0.9']);
}

export const getFarmRiskForecastChartData = (
  data: unknown[]
): {
  hvar: number[];
  hnvar: number[];
  tradeDates: string[];
} => {
  if (!data)
    return {
      hvar: [],
      hnvar: [],
      tradeDates: [],
    };
  const revenues = mapCropInto(data);
  const results = revenues?.reduce(
    (acc: any, r: any) => {
      const hvar = r[`${r.crop}_hvar`];
      const hnvar = r[`${r.crop}_nhvar`];
      const tradeDates = r[`${r.crop}_trade_date`];

      tradeDates.forEach((date: string, index: number) => {
        acc.hvar[index] = acc.hvar[index]
          ? acc.hvar[index] + convertToFloat(hvar[index])
          : convertToFloat(hvar[index]);

        acc.hnvar[index] = acc.hnvar[index]
          ? acc.hnvar[index] + convertToFloat(hnvar[index])
          : convertToFloat(hnvar[index]);
        acc.tradeDates[index] = date;
      });
      return acc;
    },
    { hvar: [], hnvar: [], tradeDates: [] }
  );

  return results;
};
