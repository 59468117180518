import clsx from 'clsx';
import { useEffect } from 'react';
import DashboardLayout from '../dashboard/DashboardLayout';
import AdminHeader from './header/AdminHeader';

function AdminLayout() {
  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', 'new-vision');
    return () => {
      document.querySelector('html')?.setAttribute('data-theme', 'agrivar');
    };
  }, []);

  return (
    <div className={clsx('max-w-[1600px] py-4 mx-auto', 'md:py-8')}>
      <AdminHeader />
      <div id="main" className="mt-8">
        <DashboardLayout />
      </div>
    </div>
  );
}

export default AdminLayout;
