import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { FormProvider, useForm } from 'react-hook-form';
import { MicronutrientType } from '.';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select from '../../../commons/Select';
import TextInput from '../../../commons/inputs/TextInput';

export default function AddMicronutrientForm({
  mNutrient,
}: {
  mNutrient: MicronutrientType;
}) {
  const form = useForm({
    defaultValues: {
      name: '',
      brand: '',
      seedingRate: 0,
      variety: '',
      maturity: 0,
      retailer: '',
      costPerUnit: 0,
      unit: '',
      totalUnits: 0,
      totalCost: 0,
    },
  });

  const handleSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <div className="space-y-8">
      <CardHeader
        title={`${mNutrient.name} (${mNutrient.shortName}) (lbs. Per Acre)`}
        tooltip={'tooltip'}
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <Select
            label="Select Product"
            name="product"
            options={[
              { label: 'Select', value: '' },
              { label: 'Product 1', value: 'product-1' },
              { label: 'Product 2', value: 'product-2' },
              { label: 'Product 3', value: 'product-3' },
            ]}
          />
          <TextInput
            name="lbs"
            label={`${mNutrient.name} Amount In Pounds`}
            type="number"
            placeholder="Please enter percent"
          />
          <TextInput
            name="nNeed"
            label={`Percent Of ${mNutrient.name} Need`}
            type="number"
            placeholder="Please enter percent"
          />
          <TextInput
            name="nProduct"
            label={`Percent Of ${mNutrient.name} In Product`}
            type="number"
            placeholder="Please enter percent"
          />
          <Select
            label="Select Unit Type"
            name="unit"
            options={[
              { label: 'Select', value: '' },
              { label: 'Bag', value: 'bag' },
              { label: 'Box', value: 'box' },
              { label: 'Pallet', value: 'pallet' },
            ]}
          />
          <TextInput
            name="costPerUnit"
            label="Cost Per Unit"
            type="number"
            placeholder="Please enter cost per unit"
          />
          <TextInput
            name="totalUnits"
            label="Total Units"
            type="number"
            placeholder="Please enter total units"
          />
          <TextInput
            name="totalCost"
            label="Total Cost"
            type="number"
            placeholder="Please enter total cost"
          />

          <Select
            name="applicationPeriod"
            label="Select Application Period"
            options={[
              { label: 'Select', value: '' },
              { label: 'Period 1', value: 'period-1' },
              { label: 'Period 2', value: 'period-2' },
              { label: 'Period 3', value: 'period-3' },
            ]}
          />

          <Button
            type="submit"
            size="lg"
            color="primary"
            startIcon={<PlusCircleIcon className="size-6" />}
            shadow
          >
            Add To Fertilizers
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
