import { TableConfigs } from '../components/commons/Table';
import { capitalizeFirst } from '../utils';

const spreadTableConfig: any = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: `Spread HLCO Chart`,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'MMM d, yyyy',
      },
      title: {
        display: true,
        text: 'Date',
      },
      ticks: {
        source: 'auto',
      },
    },
    y: {
      beginAtZero: false,
      title: {
        display: true,
        text: 'Price',
      },
      ticks: {
        callback: function (value: any) {
          return value.toFixed(2);
        },
      },
    },
  },
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

export const createStudyTableConfig = (relationship: string): TableConfigs => {
  return {
    cols: [
      {
        key: 'name',
        name: 'Name',
      },
      {
        key: 'year',
        name: 'Year',
      },
      {
        key: 'crop',
        name: 'Crop',
      },
      {
        key: 'area',
        name: 'Area',
      },
      {
        key: 'avgLon',
        name: 'lon',
      },
      {
        key: 'avgLat',
        name: 'lat',
      },
      {
        key: 'x',
        name: capitalizeFirst(relationship),
      },
      {
        key: 'xbin',
        name: `${capitalizeFirst(relationship)} Range`,
      },
      {
        key: 'yieldVar',
        name: 'Yield',
      },
      {
        key: 'yield_performance',
        name: 'Yield Performance',
      },
    ],
  };
};

export const createMapStudyTableConfig = (
  relationship: string
): TableConfigs => {
  return {
    cols: [
      {
        key: 'name',
        name: 'Name',
      },
      {
        key: 'year',
        name: 'Year',
      },
      {
        key: 'area',
        name: 'Area',
      },
      {
        key: 'avgLon',
        name: 'lon',
      },
      {
        key: 'avgLat',
        name: 'lat',
      },
      {
        key: 'x',
        name: capitalizeFirst(relationship),
      },
      {
        key: 'xbin',
        name: `${capitalizeFirst(relationship)} Range`,
      },
    ],
  };
};
