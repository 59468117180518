import { IconProps } from '../../types';

export default function TreeSproutCirleIcon({ fill = '#A3B4C2' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1471_334)">
        <path
          d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM12.88 17.66C12.88 18.14 12.44 18.54 11.98 18.54C11.52 18.54 11.13 18.15 11.13 17.64V14.14C7.75 14.14 5.01 11.4 5.01 8.02H6.76C10.14 8.02 12.88 10.79 12.88 14.17V17.67V17.66ZM13.51 12.38C13.22 11.27 12.66 10.26 11.9 9.44C12.95 7.56 14.95 6.29 17.25 6.29H19C19 9.46 16.59 12.06 13.51 12.38Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1471_334">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
