export function calculateTotalUnit({
  amount,
  percentNeed,
  percentInProduct,
}: {
  percentNeed: number;
  percentInProduct: number;
  amount: number;
}) {
  return (amount * percentNeed) / percentInProduct;
}

export function calculateCostPerAcre({
  amount,
  percentNeed,
  percentInProduct,
  pricePerUnit,
  unit,
}: {
  amount: number;
  percentNeed: number;
  percentInProduct: number;
  pricePerUnit: number;
  unit: string;
}) {
  let totalUnitPerAcre = calculateTotalUnit({
    amount,
    percentNeed,
    percentInProduct,
  }); // in lbs
  if (unit === 'GAL') {
    totalUnitPerAcre = totalUnitPerAcre / 8.34;
  } else if (unit === 'TONS') {
    totalUnitPerAcre = totalUnitPerAcre / 2000;
  }

  return totalUnitPerAcre * pricePerUnit;
}

export function calculateTotalCost({
  amount,
  percentNeed,
  percentInProduct,
  pricePerUnit,
  numberOfAcres,
  unit,
}: {
  amount: number;
  percentNeed: number;
  percentInProduct: number;
  pricePerUnit: number;
  numberOfAcres: number;
  unit: string;
}) {
  const costPerAcre = calculateCostPerAcre({
    amount,
    percentNeed,
    percentInProduct,
    pricePerUnit,
    unit,
  });

  return costPerAcre * numberOfAcres;
}
