import { IconProps } from '../../types';

const EditIcon = ({ fill = '#F9FAFB' }: IconProps) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4844 1.83203C12 2.34766 12 3.16797 11.4844 3.68359L10.7812 4.38672L8.48438 2.08984L9.1875 1.38672C9.70312 0.871094 10.5234 0.871094 11.0391 1.38672L11.4844 1.83203ZM4.03125 6.54297L7.94531 2.62891L10.2422 4.92578L6.32812 8.83984C6.1875 8.98047 6 9.09766 5.8125 9.16797L3.72656 9.84766C3.51562 9.91797 3.30469 9.87109 3.16406 9.70703C3 9.56641 2.95312 9.33203 3.02344 9.14453L3.70312 7.05859C3.77344 6.87109 3.89062 6.68359 4.03125 6.54297ZM4.5 2.37109C4.89844 2.37109 5.25 2.72266 5.25 3.12109C5.25 3.54297 4.89844 3.87109 4.5 3.87109H2.25C1.82812 3.87109 1.5 4.22266 1.5 4.62109V10.6211C1.5 11.043 1.82812 11.3711 2.25 11.3711H8.25C8.64844 11.3711 9 11.043 9 10.6211V8.37109C9 7.97266 9.32812 7.62109 9.75 7.62109C10.1484 7.62109 10.5 7.97266 10.5 8.37109V10.6211C10.5 11.8633 9.49219 12.8711 8.25 12.8711H2.25C0.984375 12.8711 0 11.8633 0 10.6211V4.62109C0 3.37891 0.984375 2.37109 2.25 2.37109H4.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;
