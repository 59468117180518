import { useQuery } from '@tanstack/react-query';
import { getFieldsYieldApi } from '../../api/field-api';
import { FieldYieldRes } from '../../types';

export const useFieldYield = (userId: string, year: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['field/yield/all', userId, year],
    queryFn: () => getFieldsYieldApi(userId, year),
    refetchOnWindowFocus: false,
    enabled: !!userId && !!year,
  });

  return {
    data: data?.data as FieldYieldRes[] | [],
    ...rest,
  };
};
