import { ButtonHTMLAttributes } from 'react';
import { Button as HButton } from '@headlessui/react';
import clsx from 'clsx';
import SpinningIcon from '../../assets/icons/SpinningIcon';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'default' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  startIcon?: React.ReactNode;
  shadow?: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  className,
  type = 'button',
  color = 'default',
  size = 'md',
  startIcon,
  shadow = false,
  loading,
  disabled,
  ...rest
}: ButtonProps) => {
  const fontStyle =
    size === 'sm'
      ? 'text-sm-bold'
      : size === 'lg'
        ? 'display-xs-bold'
        : 'text-sm-semibold';
  const borderRadius =
    size === 'sm' ? 'rounded-md' : size === 'lg' ? 'rounded-xl' : 'rounded-md';
  const padding =
    size === 'sm' ? 'px-4 py-1' : size === 'lg' ? 'px-6 py-2.5' : 'px-4 py-2';
  const shawdow = shadow ? 'shadow-[0px_8px_16px_0px_rgba(0,0,0,0.5)]' : '';
  const textColor = color === 'primary' ? 'text-base-200' : 'text-base-000';

  return (
    <HButton
      type={type}
      className={clsx(
        'w-full flex items-center justify-center space-x-2 whitespace-nowrap active:opacity-70 transition-opacity duration-200',
        color === 'default' && 'bg-base-900',
        color === 'primary' && 'bg-primary',
        color === 'secondary' && 'bg-secondary',
        color === 'danger' && 'bg-red',
        shawdow,
        fontStyle,
        borderRadius,
        padding,
        textColor,
        disabled && 'opacity-50 cursor-not-allowed',
        disabled && 'cursor-not-allowed',
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {loading ? <SpinningIcon /> : startIcon ? startIcon : <></>}
      <p>{children}</p>
    </HButton>
  );
};

export default Button;
