import { useQuery } from '@tanstack/react-query';
import { getOptionsApi, getStrikePricesApi } from '../../api/market-api';

export const useOptions = (symbol: string, option?: string) => {
  const res = useQuery({
    queryKey: ['market/options', symbol, option ?? ''],
    queryFn: () =>
      option ? getOptionsApi(symbol, option) : getOptionsApi(symbol),
    enabled: !!symbol,
  });
  return { data: res.data };
};

export const useStrikePrices = (symbol: string) => {
  const res = useQuery({
    queryKey: ['market/strikePrices', symbol],
    queryFn: () => getStrikePricesApi(symbol),
    enabled: !!symbol,
  });

  return { data: res.data };
};
