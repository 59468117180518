import Currency from '../../../components/commons/Currency';
import Fluctuation from '../../../components/commons/Fluctuation';
import PageHeader from '../../../components/commons/PageHeader';
import VAR14ChangeChart from '../../../components/dashboard/value-at-risk/VAR14ChangeChart';
import VARChart from '../../../components/dashboard/value-at-risk/VARChart';
import { useVARChartData } from '../../../hooks/farm/use-farm-revenues';

function ValueAtRiskPage() {
  const {
    nRVAR,
    hVARToShow,
    vAR,
    varToShow,
    riskReductionHedges,
    riskReductionInsurance,
    priceRisk,
  } = useVARChartData();

  return (
    <div className="space-y-8">
      <PageHeader title="Value-at-risk" tooltip="demo" />

      {/* Totals */}
      <div className="flex flex-col items-center space-y-3">
        <Currency
          value={varToShow.total}
          className="text-secondary display-xl-bold"
        />
        <Fluctuation value={varToShow.change} context="Change" currency="USD" />
      </div>

      {/* Blocks */}
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 lg:col-span-6 card card-elevated p-6">
          <div className="col-span-12 md:col-span-6 w-full flex flex-col items-center space-y-4">
            <p className="display-xl-regular">
              Potential Daily Loss to Revenue
            </p>
            <VARChart nRVAR={nRVAR} hVAR={hVARToShow} vAR={vAR.total} />
            <div className="text-center space-y-1">
              <p className="text-primary display-xs-regular">
                Risk Reduction <Currency value={riskReductionHedges} /> hedges
              </p>
              <p className="text-primary display-xs-regular">
                Risk Reduction <Currency value={riskReductionInsurance} />{' '}
                Insurance
              </p>
              <p className="text-red display-lg-bold">
                <Currency value={priceRisk} /> Price Risk
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 card card-elevated p-6">
          <VAR14ChangeChart height={415} />
        </div>
      </div>
    </div>
  );
}

export default ValueAtRiskPage;
