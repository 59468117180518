import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { AppFilterProvider } from './contexts/app-filter-context';
import { AuthProvider } from './contexts/auth-context';
import router from './routes';
import { SnackbarProvider } from 'notistack';
import { WebsocketProvider } from './contexts/websocket-context';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider />
      <AuthProvider>
        <WebsocketProvider>
          <AppFilterProvider>
            <RouterProvider router={router} />
          </AppFilterProvider>
        </WebsocketProvider>
      </AuthProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
