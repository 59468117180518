import { JDTokenPayload } from '../types';
import apiClient from './api-client';

export const getUserJDApi = (userId: string) => {
  return apiClient.get(`/my-john-deere?userId=${userId}`);
};

export const getPullBoundariesFromJDApi = (userId: string) => {
  return apiClient.get(`/my-john-deere/pull-boundaires?userId=${userId}`);
};

export const getJDTokenApi = (payload: JDTokenPayload) => {
  return apiClient.post(`/my-john-deere/get-token`, payload);
};
