import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState, useMemo } from 'react';

interface DateCalendarProps {
  availableYears?: number[];
  defaultYear?: number;
  onChange: (date: Date) => void;
}

const MIN_YEAR = 1900;
const MAX_YEAR = 2099;
const VIEW_LENGTH = 12;
const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function DateCalendar({
  availableYears,
  defaultYear,
  onChange,
}: DateCalendarProps) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();

  const initialStartYear = useMemo(() => {
    const baseYear = defaultYear || currentYear;
    return Math.min(
      Math.max(baseYear - (baseYear % 12), MIN_YEAR),
      MAX_YEAR - (MAX_YEAR % 12)
    );
  }, [defaultYear, currentYear]);

  const [startYear, setStartYear] = useState(initialStartYear);
  const [selectedYear, setSelectedYear] = useState<number | null>(
    defaultYear || availableYears?.[0] || currentYear
  );
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [step, setStep] = useState<'year' | 'month' | 'date'>('year');

  const years = useMemo(
    () => Array.from({ length: VIEW_LENGTH }, (_, i) => startYear + i),
    [startYear]
  );

  const months = useMemo(() => Array.from({ length: 12 }, (_, i) => i), []);

  const dates = useMemo(() => {
    if (selectedYear !== null && selectedMonth !== null) {
      const daysInMonth = new Date(
        selectedYear,
        selectedMonth + 1,
        0
      ).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    }
    return [];
  }, [selectedYear, selectedMonth]);

  const goToPrevView = () =>
    setStartYear((prevStartYear) =>
      Math.max(prevStartYear - VIEW_LENGTH, MIN_YEAR)
    );
  const goToNextView = () =>
    setStartYear((prevStartYear) =>
      Math.min(prevStartYear + VIEW_LENGTH, MAX_YEAR - VIEW_LENGTH + 1)
    );

  const handleYearClick = (year: number) => {
    setSelectedYear(year);
    setStep('month');
  };

  const handleMonthClick = (month: number) => {
    setSelectedMonth(month);
    setStep('date');
  };

  const handleDateClick = (date: number) => {
    setSelectedDate(date);
    if (selectedYear !== null && selectedMonth !== null) {
      onChange(new Date(selectedYear, selectedMonth, date));
    }
  };

  const isYearSelected = (year: number) => year === selectedYear;
  const isMonthSelected = (month: number) => month === selectedMonth;
  const isDateSelected = (date: number) => date === selectedDate;

  const isYearAvailable = (year: number) =>
    availableYears ? availableYears.includes(year) : true;

  return (
    <div className="card w-[192px] py-5 px-6">
      {step === 'year' && (
        <>
          <div className="header flex items-center justify-between px-2">
            <button onClick={goToPrevView} aria-label="Previous Decade">
              <ChevronLeftIcon className="size-4" />
            </button>
            <span className="text-lg-bold text-secondary">Select Year</span>
            <button onClick={goToNextView} aria-label="Next Decade">
              <ChevronRightIcon className="size-4" />
            </button>
          </div>
          <div className="grid grid-cols-3 mt-4">
            {years.map((year) => (
              <button
                key={year}
                className={clsx(
                  'size-12 text-sm-regular flex items-center justify-center rounded-full',
                  isYearSelected(year)
                    ? 'bg-primary text-base-000'
                    : 'text-base-500',
                  isYearAvailable(year)
                    ? 'cursor-pointer hover:bg-base-900 hover:text-base-000'
                    : 'cursor-not-allowed opacity-30'
                )}
                onClick={() => isYearAvailable(year) && handleYearClick(year)}
                aria-label={`Select Year ${year}`}
              >
                {year}
              </button>
            ))}
          </div>
        </>
      )}
      {step === 'month' && (
        <>
          <div className="header flex items-center justify-between px-2">
            <button onClick={() => setStep('year')} aria-label="Previous Year">
              <ChevronLeftIcon className="size-4" />
            </button>
            <span className="text-lg-bold text-secondary">Select Month</span>
            <span className="size-4"></span> {/* Empty span for alignment */}
          </div>
          <div className="grid grid-cols-3 mt-4">
            {months.map((month) => (
              <button
                key={month}
                className={clsx(
                  'size-12 text-sm-regular flex items-center justify-center rounded-full',
                  isMonthSelected(month)
                    ? 'bg-primary text-base-000'
                    : 'text-base-500',
                  'cursor-pointer hover:bg-base-900 hover:text-base-000'
                )}
                onClick={() => handleMonthClick(month)}
                aria-label={`Select Month ${MONTH_NAMES[month]}`}
              >
                {MONTH_NAMES[month]}
              </button>
            ))}
          </div>
        </>
      )}
      {step === 'date' && (
        <>
          <div className="header flex items-center justify-between px-2">
            <button
              onClick={() => setStep('month')}
              aria-label="Previous Month"
            >
              <ChevronLeftIcon className="size-4" />
            </button>
            <span className="text-lg-bold text-secondary">Select Date</span>
            <span className="size-4"></span> {/* Empty span for alignment */}
          </div>
          <div className="grid grid-cols-3 mt-4">
            {dates.map((date) => (
              <button
                key={date}
                className={clsx(
                  'size-12 text-sm-regular flex items-center justify-center rounded-full',
                  isDateSelected(date)
                    ? 'bg-primary text-base-000'
                    : 'text-base-500',
                  'cursor-pointer hover:bg-base-900 hover:text-base-000'
                )}
                onClick={() => handleDateClick(date)}
                aria-label={`Select Date ${date}`}
              >
                {date}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
