import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowsPointingOutIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';
import Table, { TableConfigs } from '../../../components/commons/Table';
import {
  useHistoricalInsights,
  useTableData,
} from '../../../hooks/historical-insights/use-historical-insights';
import { useUser } from '../../../contexts/auth-context';
import { capitalizeFirst } from '../../../utils';

function TableData() {
  const user = useUser();
  const { year, relationship, crop, page, setPage, setNewCenter, rgb, setRgb } =
    useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const formattedTableData = useMemo(() => {
    return tableData.data?.slice(page * 5, (page + 1) * 5).map((data: any) => {
      return {
        ...data,
        color: { r: data.x_r, g: data.x_g, b: data.x_b },
        yield: parseFloat(data.yield).toFixed(2),
        yield_performance: parseFloat(data.yield_performance).toFixed(2),
      };
    });
  }, [tableData.data, page]);

  const historicalInsightsYieldConfigs = (
    relationship: string
  ): TableConfigs => {
    return {
      cols: [
        {
          key: 'x_bin',
          name: capitalizeFirst(relationship),
        },
        {
          key: 'color',
          name: 'Map Key',
          type: 'element',
          element: (item) => (
            <div
              onClick={() => {
                setNewCenter([parseFloat(item.lon), parseFloat(item.lat)]);
                setRgb([
                  parseInt(item.x_r),
                  parseInt(item.x_g),
                  parseInt(item.x_b),
                ]);
              }}
              style={{
                backgroundColor: `rgb(${item.x_r}, ${item.x_g}, ${item.x_b})`,
              }}
              className={`w-full h-4`}
            />
          ),
        },
        {
          key: 'yield',
          name: 'Yield',
        },
        {
          key: 'yield_performance',
          name: 'Yield Performance',
        },
      ],
    };
  };

  return (
    <div className="card w-full mb-2 pt-2 pb-5 border-2 ">
      <div className="mb-2 mb-2 px-6 flex gap-2 items-center">
        <p className="text-xl ">All {capitalizeFirst(crop)} Fields</p>
        <InformationCircleIcon className="w-8 h-8 text-yellow" />
        <div className="justify-center ml-auto flex items-center space-x-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
          >
            <ArrowLeftCircleIcon className="w-8 h-8 text-yellow" />
          </div>
          <div>
            <p>
              {(page + 1).toString()}/
              {Math.ceil((tableData.data?.length || 0) / 10).toString()}
            </p>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (page < Math.ceil((tableData.data?.length || 0) / 10) - 1) {
                setPage(page + 1);
              }
            }}
          >
            <ArrowRightCircleIcon className="w-8 h-8 text-yellow" />
          </div>
        </div>
      </div>

      {!(rgb[0] === 0 && rgb[1] === 0 && rgb[2] === 0) && (
        <div
          className="card ml-auto mr-auto cursor-pointer max-w-max flex gap-2 px-4 py-2 rounded-md"
          onClick={() => setRgb([0, 0, 0])}
        >
          <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
          <p>Show All</p>
        </div>
      )}

      <Table
        configs={historicalInsightsYieldConfigs(relationship)}
        loading={false}
        data={formattedTableData ?? []}
      />
    </div>
  );
}

export default TableData;
