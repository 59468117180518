import { IconProps } from '../../types';

const DataInputIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.8C12 16.05 13.0875 18.0375 14.775 19.2H2.4C1.05 19.2 0 18.15 0 16.8V8.4C0 7.0875 1.05 6 2.4 6H16.8C17.6625 6 18.45 6.4875 18.8625 7.2375C18.7875 7.2375 18.675 7.2 18.6 7.2C14.925 7.2 12 10.1625 12 13.8ZM16.5 3C16.9875 3 17.4 3.4125 17.4 3.9C17.4 4.425 16.9875 4.8 16.5 4.8H2.7C2.175 4.8 1.8 4.425 1.8 3.9C1.8 3.4125 2.175 3 2.7 3H16.5ZM14.7 0C15.1875 0 15.6 0.4125 15.6 0.9C15.6 1.425 15.1875 1.8 14.7 1.8H4.5C3.975 1.8 3.6 1.425 3.6 0.9C3.6 0.4125 3.975 0 4.5 0H14.7ZM13.2 13.8C13.2 10.8375 15.6 8.4 18.6 8.4C21.5625 8.4 24 10.8375 24 13.8C24 16.8 21.5625 19.2 18.6 19.2C15.6 19.2 13.2 16.8 13.2 13.8ZM19.2 11.4C19.2 11.1 18.9 10.8 18.6 10.8C18.2625 10.8 18 11.1 18 11.4V13.2H16.2C15.8625 13.2 15.6 13.5 15.6 13.8C15.6 14.1375 15.8625 14.4 16.2 14.4H18V16.2C18 16.5375 18.2625 16.8 18.6 16.8C18.9 16.8 19.2 16.5375 19.2 16.2V14.4H21C21.3 14.4 21.6 14.1375 21.6 13.8C21.6 13.5 21.3 13.2 21 13.2H19.2V11.4Z"
        fill={fill}
      />
    </svg>
  );
};

export default DataInputIcon;
