import { ClockIcon } from '@heroicons/react/24/solid';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

const MarketSessionButton = () => {
  const [timeMessage, setTimeMessage] = useState('');

  useEffect(() => {
    const calculateMarketTime = () => {
      const now = moment().tz('America/Chicago');
      const marketClose = moment.tz(
        now.format('YYYY-MM-DD') + ' 13:15',
        'America/Chicago'
      );
      const marketOpen = moment.tz(
        now.format('YYYY-MM-DD') + ' 06:00',
        'America/Chicago'
      );

      if (now.isBefore(marketClose)) {
        const duration = moment.duration(marketClose.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();
        setTimeMessage(
          `${hours} hours, ${minutes} minutes until regular session closes`
        );
      } else {
        const nextMarketOpen = now.isBefore(marketOpen)
          ? marketOpen
          : marketOpen.add(1, 'day');
        const duration = moment.duration(nextMarketOpen.diff(now));
        const hours = duration.hours();
        const minutes = duration.minutes();
        setTimeMessage(
          `${hours} hours, ${minutes} minutes until regular session opens`
        );
      }
    };

    calculateMarketTime();
    const interval = setInterval(calculateMarketTime, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="card p-2 px-4 flex items-center space-x-2 max-w-max rounded-md">
      <ClockIcon className="w-6 h-6 text-base-000" />
      <p className="text-base-000">{timeMessage}</p>
    </div>
  );
};

export default MarketSessionButton;
