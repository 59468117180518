import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className="flex justify-center items-center flex-col my-auto h-screen space-y-2">
      <h1 className="text-2xl text-base-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Link to="/">
        <button>Go back</button>
      </Link>
    </div>
  );
}
