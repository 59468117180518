import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Button from '../../../components/commons/Button';
import Checkbox from '../../../components/commons/Checkbox';
import StyledCard from '../../../components/main/StyledCard';
import { SignUpSections } from '../../../constants';
import { CompleteRegisterValueTypes } from '.';

const Sections = {
  Prompt1: 'prompt1',
  Prompt2: 'prompt2',
  Prompt3: 'prompt3',
  Prompt4: 'prompt4',
};

export default function Prompts({
  onNext,
  form,
  isPending,
}: {
  onNext: (nextSection: string) => void;
  form: UseFormReturn<CompleteRegisterValueTypes, any, undefined>;
  isPending: boolean;
}) {
  const [isProUser, setIsProUser] = useState(false);
  const [currentSection, setCurrentSection] = useState(Sections.Prompt1);

  const { watch } = form;
  const formValues = watch();

  const handleNext = (nextSection: string) => {
    setCurrentSection(nextSection);
  };

  useEffect(() => {
    if (
      formValues.cme1?.filter((a: string) => !!a).length === 2 &&
      formValues.cme2?.filter((a: string) => !!a).length === 3 &&
      formValues.cme3?.filter((a: string) => !!a).length === 2
    ) {
      setIsProUser(false);
    } else {
      setIsProUser(true);
    }
  }, [currentSection, formValues]);

  const renderPrompt1 = () => {
    return (
      <div className="space-y-4">
        <p className="display-xs-regular">1. Please select all that apply:</p>
        <div className="ml-4 space-y-4">
          {[
            {
              label: 'Have an active futures trading account',
              value: '1',
            },
            {
              label:
                'Be an individual, natural person or small business entity',
              value: '2',
            },
          ].map((option, index) => (
            <Checkbox
              key={option.value}
              name={`cme1.${index}`}
              label={option.label}
              value={option.value}
              rounded
            />
          ))}
        </div>
      </div>
    );
  };

  const renderPrompt2 = () => {
    return (
      <div className="space-y-4">
        <p className="display-xs-regular">2. Please select all that apply:</p>
        <div className="ml-4 space-y-4">
          {[
            {
              label:
                'Member (or hold or lease any type membership) of any exchange',
              value: '1',
            },
            {
              label:
                'Registered or qualified as a professional trader or investment adviser with any stock, commodities or futures exchange or contract market, or with any financial regulator authority',
              value: '2',
            },
            {
              label:
                'Acting on behalf of an institution that engages in brokerage, banking, investment, or financial activities',
              value: '3',
            },
          ].map((option, index) => (
            <Checkbox
              key={option.value}
              name={`cme2.${index}`}
              value={option.value}
              label={option.label}
              rounded
            />
          ))}
        </div>
      </div>
    );
  };

  const renderPrompt3 = () => {
    return (
      <div className="space-y-4">
        <p className="display-xs-regular">
          3. Please select all that will apply on how you will use the CME price
          data information:
        </p>
        <div className="ml-4 space-y-4">
          {[
            {
              label: 'Solely for my personal or private use',
              value: '1',
            },
            {
              label:
                'Limited to managing my own assets, and not be used in connection with the manage of any assets of any third party(ies) in any capacity',
              value: '2',
            },
          ].map((option, index) => (
            <Checkbox
              key={option.value}
              name={`cme3.${index}`}
              value={option.value}
              label={option.label}
              rounded
            />
          ))}
        </div>
      </div>
    );
  };

  const renderPrompt4 = () => {
    return (
      <div className="space-y-4">
        <p className="display-xs-regular">
          {isProUser
            ? "You will be defined as a Professional Subscriber by CME. This is used for AgriVaR's reporting to CME. Thank you."
            : "You will be defined as a Non-Professional Subscriber by CME. This is used for AgriVaR's reporting to CME. Thank you."}
        </p>
        <Button
          color="primary"
          size="lg"
          className="!rounded-md !font-normal"
          loading={isPending}
          onClick={() => onNext(SignUpSections.UserTermsConditions)}
        >
          Continue
        </Button>
      </div>
    );
  };

  return (
    <StyledCard
      title={'Sign Up: CME Information'}
      className="max-w-screen-lg mx-auto w-full"
    >
      <div className="space-y-8 text-lg-regular">
        {renderPrompt1()}
        {renderPrompt2()}
        {renderPrompt3()}
        {renderPrompt4()}
      </div>
    </StyledCard>
  );
}
