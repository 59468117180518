import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import CardHeader from '../../../components/commons/CardHeader';
import { mapParamsToTitles } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useMapContext } from '../../../contexts/map-context';
import { useTableData } from '../../../hooks/map/use-map';
import tinycolor from 'tinycolor2';
import {
  ArrowsPointingOutIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';
import { set } from 'lodash';

function RevenueByField({ crop }: { crop: string }) {
  const user = useUser();
  const year = useYearFilter();
  const {
    selectedDate: date,
    setSelectedField: setSelectedMapField,
    selectedField: selectedMapField,
    setSelectedFieldIndex,
  } = useMapContext();

  const { type: typeParam = '' } = useParams();
  const type = typeParam === 'hiResolutionSatellite' ? 'revenue' : typeParam;
  const tableData = useTableData(
    user?.id || '',
    crop,
    type!,
    'field',
    year!,
    date,
    'field'
  );

  const [selectedField, setSelectedField] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState<string>('');

  type DataType = {
    field: string;
    crop: string;
    x_bin: string;
    area: string;
    field_index: string;
  };

  const cropColors: { [key: string]: string } = {
    Soybeans: 'rgba(174, 154, 125, 1)',
    Corn: 'rgba(255, 183, 27, 1)',
    Other: 'rgba(244, 39, 39, 1)',
    Wheat: 'rgba(98,162,235,1)',
  };

  const labels: string[] = useMemo(
    () =>
      Array.from(
        new Set(tableData?.data?.map((dataItem: DataType) => dataItem.x_bin))
      ).sort((a: any, b: any): number => {
        let numA = parseInt(a.split('-')[0]);
        let numB = parseInt(b.split('-')[0]);
        return numA - numB;
      }) as string[],
    [tableData?.data]
  );

  const [fieldIndexMap, setFieldIndexMap] = useState<{ [key: string]: string }>(
    {}
  );

  const fields = useMemo(() => {
    if (!tableData?.data) return {};

    const fieldData: { [key: string]: { data: number[]; crop: string } } = {};
    const tmpFieldIndexMap: { [key: string]: string } = {};
    tableData.data.forEach((dataItem: DataType) => {
      tmpFieldIndexMap[dataItem.field] = dataItem.field_index;
      let area = parseFloat(dataItem.area);
      if (isNaN(area)) area = 0;

      if (!fieldData[dataItem.field]) {
        fieldData[dataItem.field] = {
          data: Array(labels.length).fill(0),
          crop: dataItem.crop,
        };
      }

      const index = labels.indexOf(dataItem.x_bin);
      if (index !== -1) {
        fieldData[dataItem.field].data[index] = area;
      }
    });

    setFieldIndexMap(tmpFieldIndexMap);

    return fieldData;
  }, [tableData?.data, labels]);

  // Get unique field names from the data
  const uniqueFields = useMemo(
    () =>
      Object.keys(fields).sort((a, b) => {
        const cropA = fields[a].crop || 'Unknown Crop';
        const cropB = fields[b].crop || 'Unknown Crop';
        const comp = cropA.localeCompare(cropB);
        // if the crops are the same, sort by field name
        if (comp === 0) return a.localeCompare(b);
        return comp;
      }),
    [fields]
  );

  // Set the first field as the default selected field
  useEffect(() => {
    if (uniqueFields.length > 0 && !selectedField) {
      setSelectedField(uniqueFields[0]);
      setSelectedFieldIndex(fieldIndexMap[uniqueFields[0]]);
    }
  }, [uniqueFields, selectedField]);

  const filteredFields = useMemo(() => {
    return uniqueFields.filter((fieldName) =>
      fieldName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueFields, searchTerm]);

  const paginatedFields = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredFields.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredFields, currentPage]);

  const datasets = useMemo(() => {
    if (!fields[selectedField]) return [];

    const fieldCrop = fields[selectedField]?.crop;

    return [
      {
        label: selectedField,
        data: fields[selectedField].data,
        backgroundColor:
          cropColors[fieldCrop || ''] || 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 20,
      },
    ];
  }, [fields, selectedField, cropColors]);

  const options: any = {
    indexAxis: 'x',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        title: {
          display: true,
          text: 'Revenue Per Acre ($)',
          font: { size: 20 },
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        title: {
          display: true,
          text: 'Acres',
          font: { size: 20 },
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            let value =
              context.raw !== null ? `$${context.raw.toFixed(2)}` : 'N/A';
            let crop = fields[selectedField]?.crop || 'Unknown Crop';
            return `${label} (${crop}): ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="p-0 col-span-3">
      <div className="w-full flex gap-8 justify-between cursor-pointer">
        <CardHeader
          title={`Expected ${mapParamsToTitles[(typeParam as keyof typeof mapParamsToTitles) || 'revenue']} per Acre by Field`}
        />
        {selectedMapField && (
          <div
            className="card max-w-max flex gap-2 px-4 py-2 rounded-md"
            onClick={() => setSelectedMapField('')}
          >
            <ArrowsPointingOutIcon className="w-6 h-6 cursor-pointer" />
            <p>Show All Fields</p>
          </div>
        )}
      </div>

      <div className="h-[250px]">
        <Bar data={{ labels, datasets }} options={options} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="mt-4 flex gap-4 w-full flex-wrap">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search fields..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 w-full p-2 text-black border rounded"
          />
          {paginatedFields.map((fieldName) => (
            <div
              key={fieldName}
              className={`cursor-pointer px-4 py-2 flex items-center rounded-md ${fieldName === selectedField ? 'bg-gray-200' : 'bg-gray-100'}`}
              onClick={() => {
                setSelectedField(fieldName);
                setSelectedMapField(fieldName);
                setSelectedFieldIndex(fieldIndexMap[fieldName]);
              }}
              style={{
                backgroundColor:
                  cropColors[fields[fieldName]?.crop] ||
                  'rgba(255, 99, 132, 0.2)',
              }}
            >
              {fieldName}
            </div>
          ))}
        </div>
        {/* Pagination Controls */}
        <div className="flex items-center justify-between mt-4">
          <div
            onClick={() => {
              if (currentPage === 1) {
                return;
              }
              setCurrentPage((prev) => Math.max(prev - 1, 1));
            }}
          >
            <ArrowLeftCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
          <p>
            {currentPage}/{Math.ceil(filteredFields.length / itemsPerPage)}
          </p>
          <div
            onClick={() => {
              if (currentPage * itemsPerPage >= filteredFields.length) {
                return;
              }
              setCurrentPage((prev) => prev + 1);
            }}
          >
            <ArrowRightCircleIcon className="w-8 text-yellow h-8 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenueByField;
