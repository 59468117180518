import { useGSAP } from '@gsap/react';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { useEffect } from 'react';
import { theme } from 'twin.macro';
import { SpinningIcon } from '../../../../assets/icons';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(Draggable);

const MAX_ROTATION = 340;
const MIN_ROTATION = 20;

const getRotationFromValue = ({
  value,
  max,
  min,
}: {
  value: number;
  max: number;
  min: number;
}) => {
  const percentage = (value - min) / (max - min);
  const result = percentage * (MAX_ROTATION - MIN_ROTATION) + MIN_ROTATION;
  if (Number.isNaN(result)) {
    return MIN_ROTATION;
  }
  if (result >= MAX_ROTATION) return MAX_ROTATION;
  if (result <= MIN_ROTATION) return MIN_ROTATION;
  return result;
};

const getValueFromRotation = ({
  rotation,
  max,
  min,
}: {
  rotation: number;
  max: number;
  min: number;
}) => {
  return Math.round(
    ((rotation - MIN_ROTATION) / (MAX_ROTATION - MIN_ROTATION)) * (max - min) +
      min
  );
};

export default function Knob({
  id,
  min = 0,
  max = 10,
  value = 0,
  marks,
  onChange = (value: number) => {},
  onUpdate = (value: number) => {},
  loading,
}: {
  id: string;
  min: number;
  max: number;
  value: number;
  marks?: {
    name: string;
    value: number;
    color: string;
  }[];
  onChange?: (value: number) => void;
  onUpdate?: (value: number) => void;
  loading?: boolean;
}) {
  useGSAP(() => {
    console.log('render');
    const initialRotation = getRotationFromValue({ value, max, min });
    gsap.set(`#${id}`, { rotation: initialRotation });

    Draggable.create(`#${id}`, {
      type: 'rotation',
      throwProps: true,
      bounds: {
        minRotation: MIN_ROTATION,
        maxRotation: MAX_ROTATION,
      },
      onDrag: function () {
        const value = getValueFromRotation({
          rotation: this.rotation,
          max,
          min,
        });
        onChange(value);
      },
      onDragEnd: function () {
        const value = getValueFromRotation({
          rotation: this.rotation,
          max,
          min,
        });
        onUpdate(value);
      },
    });
  }, []);

  useEffect(() => {
    gsap.set(`#${id}`, { rotation: getRotationFromValue({ value, max, min }) });
  }, [id, max, min, value]);

  return (
    <>
      <div className="relative size-[136px]">
        {loading && (
          <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
            <SpinningIcon />
          </span>
        )}
        <div className="knob">
          <div
            id={id}
            className={clsx(
              'knob-handle absolute top-[50%] left-[50%] w-[100px] h-[100px] rounded-full z-50'
            )}
            style={{
              transform: `translate(-50%, -50%)`,
            }}
          >
            <span
              className={clsx(
                'cursor-pointer absolute bottom-0 left-[50%] -translate-x-[50%] size-4 rounded-full'
              )}
              style={{
                background:
                  'linear-gradient(150.02deg, #FFB71B 32.51%, #FF9900 52.03%)',
                border: `1px solid ${theme`colors.secondary`}`,
              }}
            ></span>
          </div>

          {/* marks */}

          {marks?.map((mark, index) => (
            <span
              key={mark.name}
              className={clsx(
                'absolute top-[50%] left-[50%] w-[133px] h-[133px] rounded-full z-10'
              )}
              style={{
                transform: `translate(-50%, -50%) rotate(${getRotationFromValue({ value: mark.value, max, min })}deg)`,
              }}
            >
              <span
                className={clsx(
                  'absolute marker w-1 h-2 block bottom-0 left-[50%]',
                  mark.color
                )}
                style={{ transform: 'rotate(-2deg)' }}
              ></span>
            </span>
          ))}
        </div>
      </div>
      <style>
        {`
          .knob {
            --n: 60;   /* control the number of dashes */
            --d: 5deg; /* control the distance between dashes */
            --t: 8px;  /* control the thickness of border*/
            --c: ${theme`colors.base.700`};  /* control the coloration (can be a gradient) */
            aspect-ratio: 1;
            position: absolute;
            background: linear-gradient(313.91deg, #222C34 53.63%, #3E4951 98.05%);
            border: 2px solid ${theme`colors.base.700`};
            width: 136px;
            height: 136px;
            border-radius: 100%;
            box-shadow: 0px 8px 16px 0px #00000080;
          }
          .knob::after {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 100%;
            padding: var(--t);
            background: var(--c);
            mask:
                linear-gradient(#0000 0 0) content-box,
                repeating-conic-gradient(
                  from calc(var(--d)/2),
                  #000 0 calc(360deg/var(--n) - var(--d)),
                  #0000 0 calc(360deg/var(--n))
                );
            mask-composite: intersect;
          }
          .knob::before {
            z-index: 1;
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 100%;
            padding: calc(var(--t) + 1px);
            background: #222C34;
            mask:
                linear-gradient(#0000 0 0) content-box,
                repeating-conic-gradient(
                  from 160deg,
                  #000 0 40deg,
                  #0000 0 360deg
                );
            mask-composite: intersect;
          }
        `}
      </style>
    </>
  );
}

export function KnobSmall({
  min,
  max,
  value,
  marks,
}: {
  min: number;
  max: number;
  value: number;
  marks?: {
    name: string;
    value: number;
    color: string;
  }[];
}) {
  const getRotateDeg = (value: number) => {
    const percentage = (value - min) / (max - min);
    return percentage * 360;
  };

  return (
    <>
      <div className="relative size-[66px]">
        <div className="knob-small">
          <span
            className={clsx(
              'absolute top-[50%] left-[50%] w-[48px] h-[48px] rounded-full'
            )}
            style={{
              transform: `translate(-50%, -50%) rotate(${getRotateDeg(value)}deg)`,
            }}
          >
            <span
              className={clsx(
                'absolute bottom-0 left-[50%] -translate-x-[50%] size-2.5 rounded-full'
              )}
              style={{
                background:
                  'linear-gradient(150.02deg, #FFB71B 32.51%, #FF9900 52.03%)',
                border: `1px solid ${theme`colors.secondary`}`,
              }}
            ></span>
          </span>

          {/* marks */}

          {marks?.map((mark, index) => (
            <span
              key={mark.name}
              className={clsx(
                'absolute top-[50%] left-[50%] w-[63px] h-[63px] rounded-full z-10'
              )}
              style={{
                transform: `translate(-50%, -50%) rotate(${getRotateDeg(mark.value)}deg)`,
              }}
            >
              <span
                className={clsx(
                  'absolute marker w-1 h-1 block bottom-0 left-[50%]',
                  mark.color
                )}
                style={{ transform: 'rotate(-4deg)' }}
              ></span>
            </span>
          ))}
        </div>
      </div>
      <style>
        {`
          .knob-small {
            --n: 30;   /* control the number of dashes */
            --d: 10deg; /* control the distance between dashes */
            --t: 4px;  /* control the thickness of border*/
            --c: ${theme`colors.base.700`};  /* control the coloration (can be a gradient) */
            aspect-ratio: 1;
            position: absolute;
            background: linear-gradient(313.91deg, #222C34 53.63%, #3E4951 98.05%);
            border: 2px solid ${theme`colors.base.700`};
            width: 66px;
            height: 66px;
            border-radius: 100%;
            box-shadow: 0px 8px 16px 0px #00000080;
          }
          .knob-small::after {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 100%;
            padding: var(--t);
            background: var(--c);
            mask:
                linear-gradient(#0000 0 0) content-box,
                repeating-conic-gradient(
                  from calc(var(--d)/2),
                  #000  0 calc(360deg/var(--n) - var(--d)),
                  #0000 0 calc(360deg/var(--n))
                );
            mask-composite: intersect;
          }
        `}
      </style>
    </>
  );
}
