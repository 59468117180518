import { SupportedCrops } from '../../../constants';
import CardHeader from '../../commons/CardHeader';
import CropIcon from '../../commons/CropIcon';
import Currency from '../../commons/Currency';
import Fluctuation from '../../commons/Fluctuation';

export default function TotalVaRCard() {
  return (
    <div className="card p-6 space-y-8">
      <CardHeader title="Total Value-at-Risk" tooltip="test">
        <div className="flex space-x-4 items-center">
          <Currency value={30000} className="text-xl-bold text-secondary" />
          <Fluctuation value={100} currency="USD" />
        </div>
      </CardHeader>

      <div className="col-span-12 md:col-span-6 w-full flex flex-col justify-between space-y-8">
        {/* Table */}
        {SupportedCrops.map((crop) => (
          <div key={crop} className="flex items-center w-full space-x-4">
            <div className="w-8 self-center">
              <CropIcon cropName={crop} />
            </div>
            <div className="flex-1 flex justify-between">
              <div className="space-y-1">
                <Currency value={123000} className="text-md-bold" />
                <Fluctuation value={123} currency="USD" context="Prev. Close" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
