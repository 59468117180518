import { IconProps } from '../../types';

export default function SatelliteIcon3({ fill = '#5C7284' }: IconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.504 13.0227C23.7849 13.3036 23.9722 13.725 23.9722 14.0996C23.9722 14.4742 23.7849 14.8955 23.4571 15.1297L18.9155 19.6713C18.4005 20.2799 17.4641 20.2799 16.8554 19.6713L13.1098 15.9256L12.6416 16.3938C13.8121 18.9221 13.7184 21.825 12.4075 24.2597C12.1734 24.6811 11.6115 24.7279 11.3306 24.4002L6.27394 19.3903L5.43117 20.1863C5.47799 20.3268 5.52481 20.4204 5.52481 20.5609C5.52481 21.4036 4.86932 22.0591 4.02655 22.0591C3.18378 22.0591 2.52829 21.4036 2.52829 20.5609C2.52829 19.7649 3.18378 19.0626 4.02655 19.0626C4.16701 19.0626 4.26065 19.1094 4.40111 19.1562L5.24389 18.3135L0.187251 13.3036C-0.140494 12.9759 -0.0936736 12.4141 0.327713 12.1799C2.76239 10.869 5.66527 10.7753 8.19359 11.9458L8.6618 11.4776L4.91614 7.73198C4.30747 7.12331 4.30747 6.1869 4.91614 5.57823L9.45775 1.08344C9.69185 0.802517 10.1132 0.615234 10.4878 0.615234C10.8624 0.615234 11.2838 0.802517 11.5647 1.08344L15.3103 4.78228L17.5577 2.53488C18.1664 1.92621 19.1496 1.92621 19.7583 2.53488L22.0057 4.78228C22.6144 5.39095 22.6144 6.37418 22.0057 6.98285L19.7583 9.23024L23.504 13.0227ZM10.2537 9.88573L13.7184 6.421L10.4878 3.19037L7.02307 6.65511L10.2537 9.88573ZM17.9323 17.5643L21.397 14.0996L18.1664 10.869L14.7017 14.3337L17.9323 17.5643Z"
        fill={fill}
      />
    </svg>
  );
}
