import { useMemo } from 'react';
import { MicronutrientType } from '.';
import Checkbox from '../../../commons/Checkbox';
import Table, { TableConfigs } from '../../../commons/Table';
import TableLayout from '../../commons/TableLayout';

interface MicronutrientListProps {
  zone: any;
  data: any[];
  onSelect: (zone: any) => void;
  mNutrient: MicronutrientType;
}

export default function MicronutrientList({
  mNutrient,
  zone,
  data,
  onSelect,
}: MicronutrientListProps) {
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Select Zone',
          key: 'id',
          type: 'element',
          element: (item) =>
            item && (
              <Checkbox
                name="id"
                label={item?.name}
                checked={zone?.id === item?.id}
                onChange={(checked: boolean) => checked && onSelect(item?.id)}
                rounded
              />
            ),
        },
        { name: 'Product', key: 'product' },
        { name: `${mNutrient.shortName} lbs.`, key: 'nLbs', type: 'number' },
        {
          name: `% of ${mNutrient.shortName} Need`,
          key: 'nNeed',
          type: 'number',
          numberStyle: 'percent',
        },
        {
          name: `% of ${mNutrient.shortName} in Product`,
          key: 'nProduct',
          type: 'number',
          numberStyle: 'percent',
        },
        { name: 'Unit', key: 'unit' },
        {
          name: 'Cost Per Unit',
          key: 'costPerUnit',
          type: 'currency',
        },
        {
          name: 'Total Units',
          key: 'totalUnits',
          type: 'number',
        },
        {
          name: 'Total Cost',
          key: 'totalCost',
          type: 'currency',
        },
        { name: 'Application Period', key: 'applicationPeriod' },
      ],
    };
  }, [mNutrient.shortName, onSelect, zone?.id]);

  return (
    <TableLayout
      title={`${mNutrient.name} (${mNutrient.shortName}) Application`}
    >
      <Table configs={tableConfigs || {}} data={data} />
    </TableLayout>
  );
}
