import CropIcon from '../../../components/commons/CropIcon';
import PageHeader from '../../../components/commons/PageHeader';
import BasisItem from '../../../components/dashboard/price-table-card/BasisItem';
import HedgedBasisItem from '../../../components/dashboard/price-table-card/HedgedBasisItem';
import HedgedFuturePriceItem from '../../../components/dashboard/price-table-card/HedgedFuturePriceItem';
import PriceItem from '../../../components/dashboard/price-table-card/PriceItem';
import { SupportedCrops } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import {
  getHedgedFuturePriceByCrop,
  useFarmRevenues,
} from '../../../hooks/farm/use-farm-revenues';
import { usePriceTable } from '../hooks/use-price-table';

function CropPriceBlock({
  cropName,
  hedgedFuturePrice,
}: {
  cropName: string;
  hedgedFuturePrice: { total: number; percent: number };
}) {
  const {
    selectedSymbol,
    setSelectedSymbol,
    selectedBasisId,
    setSelectedBasisId,
    userSettings,
  } = usePriceTable(cropName);

  const settings = userSettings.find((item) => item.crop === cropName);

  return (
    <div className="card px-8 py-12 col-span-12 lg:col-span-4 flex flex-col items-center">
      <CropIcon size="large" cropName={cropName} />
      <div className="self-stretch space-y-8 mt-8">
        <div className="space-y-2">
          <PriceItem
            symbol={selectedSymbol}
            cropName={cropName}
            onChange={setSelectedSymbol}
            defaultSymbol={settings?.contract}
            size="large"
          />
        </div>

        <div className="space-y-2">
          <BasisItem
            cropName={cropName}
            symbol={selectedSymbol}
            basisId={selectedBasisId}
            onChange={setSelectedBasisId}
            defaultBasisId={settings?.basis}
            size="large"
          />
        </div>

        <div className="space-y-2">
          <p className="display-md-regular">Hedged Future Price</p>
          <span className="flex items-center space-x-4">
            <HedgedFuturePriceItem {...hedgedFuturePrice} />
          </span>
        </div>

        <div className="space-y-2">
          <p className="display-md-regular">Hedged Basis</p>
          <span className="flex items-center space-x-4">
            <HedgedBasisItem />
          </span>
        </div>
      </div>
    </div>
  );
}

function PricePage() {
  const year = useYearFilter();
  const user = useUser();
  const { data: farmRevenue } = useFarmRevenues(user?.id || '', year || '');
  const hedgedFuturePriceByCrop = getHedgedFuturePriceByCrop(farmRevenue);

  return (
    <div className="space-y-8">
      <PageHeader title="Price" />

      {/* Blocks */}
      <div className="grid grid-cols-12 gap-8">
        {SupportedCrops.map((crop: string) => (
          <CropPriceBlock
            key={crop}
            cropName={crop}
            hedgedFuturePrice={hedgedFuturePriceByCrop?.[crop]}
          />
        ))}
      </div>
    </div>
  );
}
export default PricePage;
