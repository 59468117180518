import { IconProps } from '../../types';

export default function DollarIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.6154C0 6.00598 5.34375 0.615356 12 0.615356C18.6094 0.615356 24 6.00598 24 12.6154C24 19.2716 18.6094 24.6154 12 24.6154C5.34375 24.6154 0 19.2716 0 12.6154ZM12.9375 6.89661C12.9375 6.33411 12.5156 5.86536 11.9531 5.86536C11.3906 5.86536 10.9219 6.33411 10.9219 6.89661V7.64661C10.6406 7.74036 10.3594 7.78723 10.125 7.92786C9.28125 8.30286 8.48438 8.95911 8.29688 9.99036C8.0625 11.0685 8.53125 12.0529 9.46875 12.6154C10.125 13.0372 11.0156 13.3185 11.7188 13.506C11.8125 13.5529 11.9062 13.5529 12.0469 13.5997C12.6094 13.7404 13.1719 13.881 13.6406 14.2091C14.2031 14.5841 13.9219 15.2872 13.3594 15.4747C12.9844 15.6622 12.3281 15.756 11.5312 15.6154C10.9688 15.5216 10.4531 15.3341 9.89062 15.1466C9.79688 15.1466 9.65625 15.0997 9.5625 15.0529C9.04688 14.8654 8.4375 15.1466 8.29688 15.7091C8.10938 16.2247 8.39062 16.7872 8.90625 16.9747C9.5625 17.2091 10.2656 17.3966 10.9219 17.5372V18.381C10.9219 18.9435 11.3906 19.3654 11.9531 19.3654C12.5156 19.3654 12.9375 18.9435 12.9375 18.381V17.6779C13.3594 17.631 13.7812 17.5372 14.1562 17.3497C15 17.0216 15.75 16.3185 15.9375 15.2872C16.125 14.2091 15.7031 13.1779 14.8125 12.5685C14.1094 12.0529 13.1719 11.8185 12.4219 11.5841C12.3281 11.5841 12.2344 11.5372 12.0938 11.4904C11.5781 11.3497 11.0156 11.2091 10.5469 10.9279C9.98438 10.5529 10.4062 9.99036 10.9219 9.75598C11.4844 9.52161 12.1875 9.47473 12.8438 9.56848C13.1719 9.66223 13.5 9.75598 13.8281 9.84973C13.875 9.84973 13.9688 9.89661 14.0156 9.89661C14.5781 10.0372 15.1406 9.75598 15.2812 9.19348C15.4219 8.67786 15.0938 8.11536 14.5781 7.92786C14.4844 7.92786 14.3906 7.88098 14.2969 7.88098C13.875 7.74036 13.4062 7.59973 12.9375 7.55286V6.89661Z"
        fill={fill}
      />
    </svg>
  );
}
