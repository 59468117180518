import apiClient from './api-client';

export const getBasisAPI = (
  userId: string,
  year: string,
  symbols: string[]
) => {
  return apiClient.get(
    `/basis?userId=${userId}&year=${year}&symbols=${symbols}`
  );
};
