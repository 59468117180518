import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ContractOptions,
  LessorOptions,
  MonthOptions,
} from '../../../../constants/risk-management';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../../hooks/field/use-field-polygons';
import { useUpdateLease } from '../../../../hooks/lease/use-lease';
import { LeaseItem, UpdateLeasePayload } from '../../../../types/lease';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select, { AwaitSelect } from '../../../commons/Select';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import TextInput from '../../../commons/inputs/TextInput';

export default function EditLeaseForm({
  onCancel,
  data,
}: {
  data: LeaseItem;
  onCancel: () => void;
}) {
  const user = useUser();
  const year = useYearFilter();

  const form = useForm({
    defaultValues: {
      ...data,
      leaseMonth: data.tied_futures_month_year?.slice(0, 3),
      leaseYear: data.tied_futures_month_year?.slice(-2),
    },
  });

  // Field options
  const { data: allFields, isFetching: isFetchingFields } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}-${field.field_index}`,
    }));
  }, [allFields]);

  // set field_applied and field_index
  const fieldApplied = form.watch('field_applied');
  useEffect(() => {
    if (fieldApplied) {
      const [field, field_index] = fieldApplied.split('-');
      if (field) {
        form.setValue('field_applied', field);
      }
      if (field_index) {
        form.setValue('field_index', field_index);
      }
    }
  }, [fieldApplied]);

  // set tied_futures_month_year
  const leaseMonth = form.watch('leaseMonth');
  const leaseYear = form.watch('leaseYear');
  useEffect(() => {
    if (leaseMonth && leaseYear) {
      form.setValue('tied_futures_month_year', `${leaseMonth} '${leaseYear}`);
    }
  }, [leaseMonth, leaseYear]);

  const { mutateAsync: update, isPending } = useUpdateLease();
  const handleSubmit = async ({
    leaseYear,
    leaseMonth,
    user_id,
    year,
    unique_lease_id,
    ...values
  }: Record<string, any>) => {
    if (!user || !year) return;
    const payload: UpdateLeasePayload = {
      user_id: user?.id,
      year: year,
      unique_lease_id: data.unique_lease_id,
      data: Object.entries(values).map(([key, value]) => ({
        key,
        value,
      })),
    };
    try {
      await update(payload);
      enqueueSnackbar(`Successfully update Lease`, {
        variant: 'success',
      });
      form.reset();
      onCancel();
    } catch (error) {
      enqueueSnackbar(`Failed to update Lease`, { variant: 'error' });
    }
  };

  return (
    <div>
      <CardHeader title={`Edit Lease`} className="mb-4" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="grid grid-cols-4 gap-4"
        >
          <Select
            name="lessee_or_lessor"
            label="Lessee / Lessor"
            options={LessorOptions}
            required
          />
          <TextInput name="lease_name" label="Lease Name" required />
          <TextInput name="acres_leased" label="Acres" type="number" required />
          <TextInput
            name="share_of_production_pct"
            label="Production Share"
            type="number"
            required
          />
          <TextInput
            name="share_of_revenue_pct"
            label="Revenue Share"
            type="number"
            endAdornment={'%'}
            required
          />
          <TextInput
            name="base_rent_per_acre"
            label="Rent / Acre"
            type="number"
            endAdornment={'%'}
            required
          />
          <ComboDatePicker name="date_pymt" label="Payment Date" required />
          {data.lease_type === 'Flex' && (
            <>
              <div className="flex items-end gap-2">
                <Select
                  name="leaseMonth"
                  options={MonthOptions}
                  label="Month/Year"
                  required
                />
                <Select
                  name="leaseYear"
                  label="Year"
                  hiddenLabel
                  options={getYearOptions(10, 5, false)}
                  required
                />
              </div>
              <Select
                name="exchange_commodity"
                label="Exchange/Commodity"
                options={ContractOptions}
                required
              />
              {form.watch('exchange_commodity') === 'Other' && (
                <TextInput
                  name="exchange_commodity_other"
                  label="Other"
                  required
                />
              )}
            </>
          )}
          <AwaitSelect
            name="field_applied"
            label="Field Applied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
            required
            isLoading={isFetchingFields}
          />

          <div className="flex justify-end space-x-3 col-span-4">
            <Button className="!w-fit" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="!w-fit"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Apply
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
