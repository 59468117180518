import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import AgrivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import Button from '../../../components/commons/Button';
import TextInput from '../../../components/commons/inputs/TextInput';
import { PasswordRegex } from '../../../constants';
import { useConfirmNewPassword } from '../../../hooks/auth/use-new-password';
import StyledCard from '../../../components/main/StyledCard';

const validateSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Invalid password')
    .matches(PasswordRegex, 'Invalid password')
    .required()
    .label('Password'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Password not match')
    .label('Confirm Password'),
});

export default function NewPasswordPage() {
  const { confirmNewPassword, isLoading } = useConfirmNewPassword();
  const form = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validateSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
  } = form;

  const handleUpdatePassword = async ({
    password,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    await confirmNewPassword(password);
  };

  const disabled = !isValid;

  return (
    <FormProvider {...form}>
      <div className="flex flex-col justify-center items-center p-4">
        <div className="max-w-screen-sm w-full">
          <img
            src={AgrivarLogo}
            alt="Agrivar"
            width={'70%'}
            className="mx-auto mb-4"
          />
          <StyledCard title="Create New Password">
            <form
              className="max-w-screen-sm flex flex-col w-full space-y-8"
              onSubmit={handleSubmit(handleUpdatePassword)}
            >
              <div className="space-y-5">
                <TextInput
                  label="Password*"
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                  className="!bg-base-000 !text-block-fill"
                />
                <TextInput
                  label="Confirm Password*"
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  type="password"
                  className="!bg-base-000 !text-block-fill"
                />
              </div>
              {errors.password && (
                <div className="text-base-100">
                  Password must at least:
                  <ul className="list-disc pl-4">
                    <li>8 characters long</li>
                    <li>One uppercase</li>
                    <li>One lowercase</li>
                    <li>One number</li>
                    <li>One special character (!@#$%^&*)</li>
                  </ul>
                </div>
              )}
              <div className="flex flex-col space-y-5">
                <Button
                  type="submit"
                  disabled={disabled}
                  color="primary"
                  loading={isLoading}
                  className="!font-normal !rounded-md"
                  size="lg"
                >
                  Update
                </Button>
                <Link to="/">
                  <Button className="!font-normal !rounded-md" size="lg">
                    Login
                  </Button>
                </Link>
              </div>
            </form>
          </StyledCard>
        </div>
      </div>
    </FormProvider>
  );
}
