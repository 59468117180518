import { enqueueSnackbar } from 'notistack';
import papaparse from 'papaparse';
import { useState } from 'react';

interface CsvRow {
  [key: string]: string;
}

export const useProcessCSV = () => {
  const [data, setData] = useState<CsvRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const processCSV = async (file: any) => {
    try {
      setLoading(true);
      papaparse.parse(file, {
        complete: (result: any) => {
          const headers = result.data[0];
          const rows = result.data.slice(1);

          const formattedData = rows.map((row: string[]) => {
            return headers.reduce(
              (acc: CsvRow, header: string, index: number) => {
                acc[header] = row[index];
                return acc;
              },
              {}
            );
          });

          setData(formattedData);
          setLoading(false);
          enqueueSnackbar('CSV file processed successfully', {
            variant: 'success',
          });
        },
      });
    } catch (error) {
      enqueueSnackbar('Error processing CSV file', { variant: 'error' });
      setLoading(false);
    }
  };

  return { processCSV, data, loading };
};
