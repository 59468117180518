import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SpinningIcon from '../../assets/icons/SpinningIcon';
import {
  useIsAuthenticated,
  useIsUserLoading,
  useMainUser,
} from '../../contexts/auth-context';

function SuperRoute({
  children,
  redirectPath = '/login',
}: {
  redirectPath?: string;
  children?: ReactNode;
}): JSX.Element {
  const isUserLoading = useIsUserLoading();
  const isAuthenticated = useIsAuthenticated();
  const user = useMainUser();
  const isAdmin = user?.is_admin;
  const isAdvisor = user?.is_advisor_client;
  const isAllowed = isAdmin || isAdvisor;

  if (isAuthenticated === false) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isUserLoading)
    return (
      <div className="fixed flex justify-center items-center h-full w-full bg-base-900">
        <SpinningIcon />
      </div>
    );

  if (isAuthenticated && !isAllowed) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</> || <Outlet />;
}

export default SuperRoute;
