import { IconProps } from '../../types';

const XMarkSquareIcon = ({ fill = '#F42727' }: IconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 0.615356C20.1406 0.615356 21.5 1.97473 21.5 3.61536V18.6154C21.5 20.3029 20.1406 21.6154 18.5 21.6154H3.5C1.8125 21.6154 0.5 20.3029 0.5 18.6154V3.61536C0.5 1.97473 1.8125 0.615356 3.5 0.615356H18.5ZM7.20312 8.91223L9.40625 11.1154L7.20312 13.3185C6.73438 13.7872 6.73438 14.4904 7.20312 14.9122C7.625 15.381 8.32812 15.381 8.75 14.9122L10.9531 12.7091L13.2031 14.9122C13.625 15.381 14.3281 15.381 14.75 14.9122C15.2188 14.4904 15.2188 13.7872 14.75 13.3185L12.5469 11.1154L14.75 8.91223C15.2188 8.49036 15.2188 7.78723 14.75 7.31848C14.3281 6.89661 13.625 6.89661 13.2031 7.31848L10.9531 9.56848L8.75 7.31848C8.32812 6.89661 7.625 6.89661 7.20312 7.31848C6.73438 7.78723 6.73438 8.49036 7.20312 8.91223Z"
        fill={fill}
      />
    </svg>
  );
};

export default XMarkSquareIcon;
