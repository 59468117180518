import { IconProps } from '../../types';

export default function DocumentPlusCircleIcon({
  fill = '#A3B4C2',
}: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1471_283)">
        <path
          d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM17.25 17.69C17.25 18.41 16.66 19 15.94 19H8.06C7.34 19 6.75 18.41 6.75 17.69V6.31C6.75 5.59 7.34 5 8.06 5H12.87V8.5C12.87 8.98 13.26 9.38 13.77 9.38H17.25V17.69ZM14.57 8.5C13.67 8.5 13.75 7.71 13.75 7.71V5L17.25 8.5H14.57Z"
          fill={fill}
        />
        <path
          d="M12.6304 11.9796C12.6304 11.6396 12.3604 11.3496 12.0004 11.3496C11.6404 11.3496 11.3404 11.6396 11.3404 11.9796V13.5596H9.78039C9.44039 13.5596 9.15039 13.8296 9.15039 14.1896C9.15039 14.5496 9.44039 14.8196 9.78039 14.8196H11.3404V16.3996C11.3404 16.7396 11.6304 17.0296 12.0004 17.0296C12.3704 17.0296 12.6304 16.7396 12.6604 16.3496V14.8196H14.1904C14.5504 14.8196 14.8504 14.5496 14.8504 14.1896C14.8504 13.8296 14.5604 13.5296 14.1904 13.5296H12.6304V11.9696V11.9796Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1471_283">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
