import { useQuery } from '@tanstack/react-query';

export const useWebSocketData = (event: string) => {
  const res = useQuery({
    queryKey: ['websocket', event],
    queryFn: () => {},
    staleTime: Infinity,
    gcTime: Infinity,
  });
  return res;
};

export const useWebSocketDataV2 = (eventType: string) => {
  const res = useQuery({
    queryKey: ['websocketv2', eventType],
    queryFn: () => [],
    staleTime: Infinity,
    gcTime: Infinity,
  });
  return res;
};
