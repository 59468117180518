import { IconProps } from '../../types';

const DashboardIcon = ({ fill = '#fff' }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.307739" width="10.2857" height="10.2857" rx="1" fill={fill} />
      <rect
        x="13.7142"
        y="0.307739"
        width="10.2857"
        height="10.2857"
        rx="1"
        fill={fill}
      />
      <rect y="14.022" width="10.2857" height="10.2857" rx="1" fill={fill} />
      <rect
        x="13.7142"
        y="14.022"
        width="10.2857"
        height="10.2857"
        rx="1"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
