export default function CornIconBigColored() {
  return (
    <svg
      width="150"
      height="151"
      viewBox="0 0 150 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_163_3961)">
        <path
          d="M78.4425 35.1006C78.0493 32.3351 77.791 29.5755 77.3977 27.0764H72.702C72.3088 29.7058 71.9214 32.341 71.6572 35.1006H78.4366H78.4425Z"
          fill="#FFB71B"
        />
        <path
          d="M75.0499 46.9386H79.7457C79.6165 44.8363 79.3524 42.5978 79.0941 40.3594C79.0941 39.8323 78.965 39.3052 78.965 38.7782H71.1408C71.1408 39.3052 71.0116 39.8323 71.0116 40.3594C70.7534 42.5978 70.6184 44.7001 70.3601 46.9386H75.0558H75.0499Z"
          fill="#FFB71B"
        />
        <path
          d="M67.2258 40.0988C67.2258 39.9685 67.2258 39.5717 67.3549 39.0447C64.3555 39.175 61.4853 39.3052 58.8791 39.5717C58.2276 42.2011 57.5761 44.9666 56.9246 47.8624C60.1822 47.6019 63.4457 47.3354 66.4451 47.2051C66.7034 44.8363 66.9675 42.4675 67.2258 40.0988Z"
          fill="#FFB71B"
        />
        <path
          d="M76.8753 23.1324C76.3529 19.5793 75.7014 16.1623 75.0498 13C74.3983 16.1564 73.8759 19.449 73.3535 23.1324H76.8753Z"
          fill="#FFB71B"
        />
        <path
          d="M66.1868 51.0188C62.5359 51.1491 59.2783 51.4156 56.1439 51.6761C55.6215 54.4417 55.2282 57.071 54.8408 59.8306C58.4917 59.4339 62.1426 59.1733 65.5353 59.043C65.7936 56.4137 65.9286 53.7784 66.1868 51.0188Z"
          fill="#FFB71B"
        />
        <path
          d="M65.2711 70.4901C65.2711 68.1213 65.4002 65.492 65.5294 62.9929C61.4852 63.1232 57.8343 63.52 54.5766 63.9167C54.3184 64.9708 54.1833 66.1552 54.1833 67.3396C56.6603 68.2634 59.0082 69.4419 61.356 70.7625C62.6591 70.6322 63.9622 70.5019 65.2652 70.5019L65.2711 70.4901Z"
          fill="#FFB71B"
        />
        <path
          d="M84.8286 70.4901C86.1317 70.4901 87.4347 70.6204 88.8728 70.7506C91.2206 69.436 93.5685 68.3819 96.0455 67.3278C95.9163 66.1434 95.7872 64.959 95.6522 63.9049C92.3946 63.5081 88.7436 63.2476 84.6995 62.9811C84.6995 65.4801 84.8286 67.9792 84.8286 70.4782V70.4901Z"
          fill="#FFB71B"
        />
        <path
          d="M68.7928 27.0764C66.7091 27.0764 64.6195 27.2067 62.6649 27.337C61.6201 29.836 60.7103 32.6016 59.7947 35.6277C62.5358 35.3671 65.1419 35.2309 67.6189 35.2309C68.0122 32.9924 68.3996 30.2328 68.7928 27.0764Z"
          fill="#FFB71B"
        />
        <path
          d="M84.4413 59.1733C87.834 59.3035 91.4849 59.57 95.1358 59.9609C94.7425 57.1953 94.3551 54.4357 93.8327 51.8064C90.8334 51.5458 87.4407 51.2793 83.7898 51.149C84.1831 53.7784 84.3122 56.4136 84.4413 59.1733Z"
          fill="#FFB71B"
        />
        <path
          d="M69.051 70.3598C71.2697 70.2295 73.2243 70.2295 75.0498 70.2295C76.8752 70.2295 78.8298 70.2295 81.0486 70.3598C81.0486 67.991 80.9194 65.4919 80.7903 62.9929C78.9648 62.9929 77.0102 62.8626 75.0557 62.8626C73.1011 62.8626 71.1465 62.8626 69.321 62.9929C69.1919 65.4919 69.1919 67.991 69.0628 70.3598H69.051Z"
          fill="#FFB71B"
        />
        <path
          d="M69.1862 75.7546C71.0117 77.0693 72.9663 78.5202 75.0559 80.3619C77.1396 78.5202 79.2292 77.0752 80.9255 75.7546V74.1735C78.9709 74.1735 77.0163 74.0432 74.9267 74.0432C72.8372 74.0432 70.8826 74.0432 68.928 74.1735C69.0571 74.7005 69.0571 75.2276 69.1862 75.7546Z"
          fill="#FFB71B"
        />
        <path
          d="M70.8766 14.5812C68.5288 16.2926 66.4451 19.449 64.4846 23.393C66.1809 23.2627 67.8773 23.2627 69.4386 23.1324C69.8319 20.5031 70.3543 17.6073 70.8708 14.5812H70.8766Z"
          fill="#FFB71B"
        />
        <path
          d="M82.3517 35.2309C84.8286 35.3612 87.4348 35.4915 90.1759 35.6277C89.2602 32.6016 88.3504 29.842 87.3056 27.337C85.351 27.2067 83.3965 27.0764 81.1777 27.0764C81.7001 30.2328 82.0934 32.9983 82.3517 35.2309Z"
          fill="#FFB71B"
        />
        <path
          d="M85.6152 23.393C83.6606 19.449 81.571 16.2867 79.2231 14.5812C79.7455 17.6073 80.2679 20.5031 80.6553 23.1324C82.2225 23.2627 83.913 23.2627 85.6093 23.393H85.6152Z"
          fill="#FFB71B"
        />
        <path
          d="M82.874 40.0988C83.1322 42.4675 83.3964 44.8363 83.5255 47.2051C86.5249 47.3354 89.7825 47.6019 93.0461 47.8624C92.5237 44.9666 91.8721 42.207 91.0915 39.5717C88.4854 39.3112 85.6151 39.175 82.6157 39.0447C82.874 39.5717 82.874 39.9685 82.874 40.0988Z"
          fill="#FFB71B"
        />
        <path
          d="M80.6612 59.043C80.5321 56.4137 80.268 53.7784 80.1388 51.0188H70.2309C69.9726 53.7843 69.8376 56.544 69.7085 59.043C71.6631 59.043 73.6177 58.9127 75.314 58.9127C77.0103 58.9127 78.5716 58.9127 80.6612 59.043Z"
          fill="#FFB71B"
        />
        <path
          d="M72.1855 82.9853C68.9278 80.2198 65.9284 78.1175 63.4514 76.5364C51.5831 68.6424 35.4181 65.3558 28.6387 64.3017C27.723 64.1714 27.0715 65.3558 27.858 66.0131C32.1604 70.0933 41.2877 79.9592 47.938 95.22C51.4598 103.641 52.2404 110.351 52.7628 115.876C54.4592 109.036 57.981 102.457 60.4579 97.9856C64.1088 91.6728 68.153 86.805 72.1913 82.9853H72.1855Z"
          fill="#5E8A00"
        />
        <path
          d="M121.344 64.3076C114.564 65.3617 98.3935 68.5181 86.531 76.5423C80.6614 80.4863 70.8826 87.1958 63.8449 99.9576C59.6716 107.455 51.8473 121.661 58.1102 130.609C64.2381 139.558 82.2344 140.475 91.2326 132.848C100.36 125.221 93.7096 114.3 102.056 95.22C108.577 79.9592 117.834 70.0933 122.136 66.0131C122.917 65.3558 122.394 64.1714 121.356 64.3017L121.344 64.3076Z"
          fill="#80BC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_163_3961">
          <rect
            width="95"
            height="125"
            fill="white"
            transform="translate(27.5 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
