import { IconProps } from '../../types';

export default function ArrowUpCircleIcon({ fill = '#A3B4C2' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1471_299)">
        <path
          d="M24 12C24 18.63 18.63 24 12 24C5.37 24 0 18.63 0 12C0 5.37 5.37 0 12 0C18.63 0 24 5.37 24 12ZM17.78 10.72L12.53 5.47C12.24 5.18 11.76 5.18 11.47 5.47L6.22 10.72C6 10.94 5.94 11.25 6.06 11.54C6.18 11.83 6.45 12 6.75 12H9.75V16.5C9.75 17.33 10.42 18 11.25 18H12.75C13.58 18 14.25 17.33 14.25 16.5V12H17.25C17.55 12 17.83 11.82 17.94 11.54C18.05 11.26 17.99 10.94 17.78 10.72Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1471_299">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
