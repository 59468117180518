import { IconProps } from '../../types';

export default function ArrowLeftRightIcon({ fill = '#F9FAFB' }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49775 8.23795C0.655239 8.23795 -5.14239e-05 7.58266 -5.14239e-05 6.74014C-5.14239e-05 5.94443 0.655239 5.24234 1.49775 5.24234H18.8161L16.1482 2.57437C15.5397 2.01269 15.5397 1.02976 16.1482 0.468082C16.7098 -0.140402 17.6928 -0.140402 18.2545 0.468082L23.4968 5.7104C24.1053 6.27208 24.1053 7.25501 23.4968 7.81669L18.2545 13.059C17.9736 13.3399 17.5992 13.4803 17.2247 13.4803C16.8035 13.4803 16.429 13.3399 16.1482 13.059C15.5397 12.4973 15.5397 11.5144 16.1482 10.9527L18.8161 8.23795H1.49775ZM22.467 15.727C23.2627 15.727 23.9648 16.4291 23.9648 17.2248C23.9648 18.0673 23.2627 18.7226 22.467 18.7226H5.10185L7.76982 21.4374C8.3783 21.999 8.3783 22.982 7.76982 23.5437C7.48898 23.8245 7.11453 23.9649 6.74008 23.9649C6.31882 23.9649 5.94437 23.8245 5.66353 23.5437L0.421207 18.3013C-0.187277 17.7397 -0.187277 16.7567 0.421207 16.195L5.66353 10.9527C6.2252 10.3442 7.20814 10.3442 7.76982 10.9527C8.3783 11.5144 8.3783 12.4973 7.76982 13.059L5.10185 15.727H22.467Z"
        fill={fill}
      />
    </svg>
  );
}
