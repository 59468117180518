import clsx from 'clsx';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import PoweredByArivarLogo from '../../../assets/icons/powered-by-agrivar-logo.svg';
import DashboardHeader from './header/DashboardHeader';
import { useEffect } from 'react';
import {
  useSetControlledUser,
  useMainUser,
} from '../../../contexts/auth-context';
import { useUserById } from '../../../hooks/user/use-users';
import { useWebSocket } from '../../../hooks/websocket/use-websocket';

function DashboardLayout() {
  const user = useMainUser();
  const params = useParams();
  const navigate = useNavigate();
  const setControlledUser = useSetControlledUser();
  const controlledUserID =
    params.userId ||
    (localStorage.getItem('is_admin') === '1'
      ? localStorage.getItem('JD_user_id') ||
        localStorage.getItem('climate_user_id')
      : '');
  const { data: controlledUser } = useUserById(controlledUserID ?? '');

  if ((user?.is_admin || user?.is_advisor_client) && !controlledUserID) {
    navigate('/', { replace: true });
  }

  if ((user?.is_admin || user?.is_advisor_client) && controlledUserID) {
    setControlledUser?.(controlledUser);
  }

  // @TODO find a better way to call useWebSocket
  useWebSocket();
  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', 'new-vision');
    return () => {
      document.querySelector('html')?.setAttribute('data-theme', 'agrivar');
    };
  }, []);

  return (
    <div
      className={clsx('max-w-[1600px] px-4 py-4 mx-auto', 'md:px-8 md:py-8')}
    >
      <DashboardHeader />
      <div id="main" className="mt-8">
        <Outlet />
      </div>
      <footer className="flex justify-end mt-8">
        <span className="border border-primary py-1.5 px-4 rounded-xl shadow-[0px_4px_16px_0px_#000000B2]">
          <img src={PoweredByArivarLogo} alt="powered by agrivar logo" />
        </span>
      </footer>
    </div>
  );
}

export default DashboardLayout;
