import axios, { AxiosInstance } from 'axios';
import { getTokens } from '../auth';

interface CustomAxiosInstance extends AxiosInstance {
  headers?: {
    'Content-Type': string;
  };
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(async (config) => {
  const { idToken: token } = await getTokens();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

// check for expired token
apiClient.interceptors.response.use(async (response) => {
  const isValidSession = await getTokens();
  if (!isValidSession) {
    alert('Session expired, please login again');
    window.location.href = '/';

    return Promise.reject();
  }
  return response;
});

export const apiClientWithoutToken: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export default apiClient;
