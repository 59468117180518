import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Form, FormProvider, useForm } from 'react-hook-form';
import { commodityOptions } from './PriceChart';

import { Chart } from 'react-chartjs-2';
import ReactSlider from 'react-slider';
import {
  CropToCode,
  MonthToCode,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import { optionsChartOptions } from '../../../constants/chartConfigs';
import { optionTypeOptions } from '../../../constants/selectCommonConfigs';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { usePrices } from '../../../hooks/market/use-futures';
import { useOptions, useStrikePrices } from '../../../hooks/market/use-options';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import {
  capitalizeFirst,
  constructOptionsSymbol,
  getTime,
  parseOptionsContract,
} from '../../../utils';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/Table';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';

function OptionsTable() {
  const optionsTableConfigs: TableConfigs = {
    cols: [
      {
        key: 'contract',
        name: 'Contract',
      },
      {
        key: 'optionDetails',
        name: 'Option',
        type: 'element',
        element: (item: any) => {
          return (
            <div className="flex">
              <span className="flex gap-2">
                {capitalizeFirst(item.optionType)} &nbsp;
                {item.strike}
              </span>
            </div>
          );
        },
      },
      {
        key: 'low',
        name: 'Low',
      },
      {
        key: 'high',
        name: 'High',
      },
      {
        key: 'last',
        name: 'Last',
      },
      {
        key: 'change',
        name: 'Change',
        type: 'element',
        element: (item: any) => {
          if (item.change > 0) {
            return (
              <div className="flex">
                <span className="text-green flex gap-2">
                  <ArrowUpIcon className="h-4 w-4 text-green" />
                  {item.change}
                </span>
              </div>
            );
          } else {
            return (
              <span className="text-red flex items-center gap-2">
                <ArrowDownIcon className="h-4 w-4 text-red" />
                {item.change}
              </span>
            );
          }
        },
      },
      {
        key: 'bid',
        name: 'Bid',
      },
      {
        key: 'ask',
        name: 'Ask',
      },
      {
        key: 'valuation',
        name: 'AgriVaR Valuation',
      },
      {
        key: 'volume',
        name: 'Volume',
      },
      {
        key: 'openInterest',
        name: 'Open Interest',
      },
      {
        key: 'IV',
        name: 'IV',
      },
      {
        key: 'delta',
        name: 'Delta',
      },
      {
        key: 'lastTradeTime',
        name: 'Last Trade',
      },
    ],
  };

  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 0]);

  const methods = useForm();
  const crop = methods.watch('crop', 'Corn');
  const month = methods.watch('month', 'december');
  const year = methods.watch('year', '2024');
  const strikePrice = methods.watch('strikePrice', '0000');
  const optionType = methods.watch('optionType', 'put');

  const underlying = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year.slice(3);
  }, [crop, month, year]);

  const options = useOptions(underlying, '');

  const strikePrices = useStrikePrices(underlying);

  const optionsSymbol = useMemo(() => {
    return constructOptionsSymbol(
      CropToCode[crop],
      month,
      year,
      optionType,
      strikePrice
    );
  }, [crop, month, year, strikePrice, optionType]);

  const { setRoots } = useWebsocketContext();

  const optionsHLCO = usePrices(optionsSymbol);
  const msgHLCO: any = useWebSocketDataV2('HLCO');
  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');

  const [loadedTableData, setLoadedTableData] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [highlightedCells, setHighlightedCells] = useState<
    { rowKey: string; columnKey: string }[]
  >([]);

  useEffect(() => {
    const newHighlights: { rowKey: string; columnKey: string }[] = [];
    let mapData: any[] = [];

    if (tableData && tableData.length > 0) {
      mapData = tableData; // Use the existing tableData
    } else {
      mapData = options.data?.data; // Initialize with futures data
      setLoadedTableData(true); // Mark as loaded
    }
    const updatedData =
      mapData
        ?.map((item: any) => {
          if (item && item.symbol) {
            let newHLCO = {};
            let newBidAsk = {};
            let isNewMessage = false;
            let columnUpdated = false;

            const parsedContract = parseOptionsContract(item.symbol);
            const contract =
              parsedContract.commodity +
              ', ' +
              parsedContract.month +
              " '" +
              parsedContract.year;

            const optionType = parsedContract.optionType;
            const strikePrice = parsedContract.strikePrice;
            const optionDetails = `${capitalizeFirst(optionType)} ${strikePrice}`;

            if (msgHLCO && msgHLCO.data.symbol === item.symbol) {
              newHLCO = msgHLCO.data;
              isNewMessage = true; // Only trigger highlight on HLCO message
              if (
                item.high !== msgHLCO.data.high &&
                msgHLCO.data.high !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'high',
                });
              }
              if (
                item.low !== msgHLCO.data.low &&
                msgHLCO.data.low !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'low' });
              }
              if (
                item.last !== msgHLCO.data.last &&
                msgHLCO.data.last !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'last',
                });
              }
              if (
                item.change !== msgHLCO.data.change &&
                msgHLCO.data.change !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'change',
                });
              }
            }
            if (msgBidAsk && msgBidAsk.data.symbol === item.symbol) {
              console.log('found bid ask message contract', contract);
              newBidAsk = msgBidAsk.data;
              isNewMessage = true; // Trigger highlight on BidAsk only if no HLCO for this symbol
              if (
                item.bid !== msgBidAsk.data.bid &&
                msgBidAsk.data.bid !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'bid' });
              }
              if (
                item.change !== msgBidAsk.data.change &&
                msgBidAsk.data.change !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'change',
                });
              }
              if (
                item.volume !== msgBidAsk.data.volume &&
                msgBidAsk.data.volume !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'volume',
                });
              }
              if (
                item.openInterest !== msgBidAsk.data.open_interest &&
                msgBidAsk.data.open_interest !== undefined
              ) {
                columnUpdated = true;
                item.openInterest = msgBidAsk.data.open_interest;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'openInterest',
                });
              }
              if (
                item.last !== msgBidAsk.data.trade_pice &&
                msgBidAsk.data.trade_price !== undefined
              ) {
                columnUpdated = true;
                item.last = msgBidAsk.data.trade_price;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'last',
                });
              }
              if (
                item.IV !== msgBidAsk.data.implied_volatility &&
                msgBidAsk.data.implied_volatility !== undefined
              ) {
                item.IV = msgBidAsk.data.implied_volatility;
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'IV' });
              }
              if (
                item.IV_change !== msgBidAsk.data.implied_volatility_chg &&
                msgBidAsk.data.implied_volatility_chg !== undefined
              ) {
                item.IV_change = msgBidAsk.data.implied_volatility_chg;
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'IV_change',
                });
              }
              if (
                item.ask !== msgBidAsk.data.ask &&
                msgBidAsk.data.ask !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'ask' });
              }
            }

            const date = moment(
              `${month} 1, 20${year}`,
              'MMM D, YYYY'
            ).toISOString();

            const record = {
              ...item,
              ...newHLCO,
              ...newBidAsk,
              contract,
              optionType,
              strike: strikePrice,
              optionDetails,
              lastTradeTime: getTime(item.last_trade_time),
            };
            if (columnUpdated) {
              console.log('new record: ', record, 'old item: ', item);
            }
            record.change = parseFloat(record.change).toFixed(4);
            return record;
          }
          return null;
        })
        .filter((value: any) => value !== null && value !== undefined)
        .sort((a: any, b: any) => {
          if (a.contract < b.contract) {
            return -1;
          }
          if (a.contract > b.contract) {
            return 1;
          }
          // sort by strike
          if (a.strike < b.strike) {
            return -1;
          }
          if (a.strike > b.strike) {
            return 1;
          }
          return 0;
        }) ?? [];

    setTableData(updatedData);
    if (newHighlights.length > 0) {
      setHighlightedCells((prev) =>
        Array.from(new Set([...prev, ...newHighlights]))
      );
    }
  }, [options.data?.data, msgHLCO.data, msgBidAsk.data]);

  useEffect(() => {
    console.log('this is highlighted cells', highlightedCells);
  }, [highlightedCells.length]);

  useEffect(() => {
    // Remove highlights after the specified duration
    const timeout = setInterval(() => {
      setHighlightedCells([]);
    }, 500);

    // Cleanup timeout if component unmounts or dependencies change
    return () => clearTimeout(timeout);
  }, []);

  const priceChartData = useMemo(() => {
    if (!optionsHLCO.data || !optionsHLCO.data.price) return [];
    return optionsHLCO.data.price.map((item: any) => {
      if (
        msgHLCO &&
        msgHLCO.data.symbol === item.symbol &&
        msgHLCO.data.trade_date === item.trade_date
      ) {
        return {
          x: new Date(item.trade_date).getTime(),
          o: msgHLCO.data?.open
            ? parseFloat(msgHLCO.data.open)
            : parseFloat(item.open),
          h: msgHLCO.data?.high
            ? parseFloat(msgHLCO.data.high)
            : parseFloat(item.high),
          l: msgHLCO.data?.low
            ? parseFloat(msgHLCO.data.low)
            : parseFloat(item.low),
          c: msgHLCO.data?.close
            ? parseFloat(msgHLCO.data.close)
            : parseFloat(item.close),
        };
      } else {
        return {
          x: new Date(item.trade_date).getTime(),
          o: parseFloat(item.open),
          h: parseFloat(item.high),
          l: parseFloat(item.low),
          c: parseFloat(item.close),
        };
      }
    });
  }, [optionsHLCO.data, msgHLCO]);

  const filteredPriceChartData = useMemo(() => {
    return priceChartData.filter(
      (item: any) => item.x >= sliderValues[0] && item.x <= sliderValues[1]
    );
  }, [priceChartData, sliderValues]);

  const minDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.min(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  const maxDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.max(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  useEffect(() => {
    if (minDate && maxDate) {
      setSliderValues([minDate, maxDate]);
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    setRoots([CropToCode[crop]]);
  }, [crop]);

  const data: any = {
    datasets: [
      {
        label: 'Options HLCO',
        data: filteredPriceChartData,
        type: 'candlestick',
        borderColor: '#00c0ef',
        backgroundColor: 'rgba(0, 192, 239, 0.2)',
      },
    ],
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <p className="text-lg text-darkGray mb-2">Contract</p>
          <div className="flex items-center pb-6 justify-between text-lightGray rounded-md">
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2 rounded-md">
                <Select options={commodityOptions} name="crop" />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  name="month"
                  options={
                    cropToMonthOptions[crop as keyof typeof cropToMonthOptions]
                  }
                  defaultValue={
                    cropToMonthOptions[crop as keyof typeof cropToMonthOptions][
                      cropToMonthOptions[
                        crop as keyof typeof cropToMonthOptions
                      ].length - 1
                    ].value
                  }
                  className="w-full"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  name="year"
                  options={yearOptions}
                  defaultValue={'2024'}
                  className="w-full"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                {strikePrices &&
                  strikePrices?.data &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ] &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ].length > 0 && (
                    <Select
                      name="strikePrice"
                      options={
                        strikePrices.data?.strikePrices[
                          optionType.slice(0, 1).toUpperCase() +
                            optionType.slice(1, optionType.length)
                        ]!
                      }
                    />
                  )}
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  name="optionType"
                  options={optionTypeOptions}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
      <div className="card relative overflow-hidden">
        <div className="flex flex-col mb-4">
          <Chart
            type="candlestick"
            data={data}
            options={optionsChartOptions(optionsSymbol)}
          />
          <div className="w-full mb-4 pl-14 border-0 h-full">
            {priceChartData && priceChartData.length > 0 && (
              <div className="pr-2">
                <ReactSlider
                  className="horizontal-slider2"
                  thumbClassName="example-thumb2"
                  trackClassName="example-track2"
                  min={minDate}
                  max={maxDate}
                  defaultValue={[minDate, maxDate]}
                  value={sliderValues}
                  ariaValuetext={(state) =>
                    `Thumb value ${new Date(state.valueNow).toLocaleDateString()}`
                  }
                  renderThumb={(props, state) => <div {...props}>{}</div>}
                  pearling
                  minDistance={10}
                  onChange={(values) =>
                    setSliderValues(values as [number, number])
                  }
                />
                <div className="w-full h-full text-gray mt-10 flex items-center justify-center">
                  <span>{new Date(sliderValues[0]).toDateString()}</span>&nbsp;
                  - &nbsp;
                  <span>{new Date(sliderValues[1]).toDateString()}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-4 relative overflow-hidden">
        <div className="pl-6 flex gap-2 pt-4 text-xl-bold w-full pb-4 px-6.5">
          {commodityOptions.filter((item: any) => crop === item.value)[0].icon}
          All {crop} Options
        </div>
        <div className="overflow-x-auto">
          <Table
            configs={optionsTableConfigs}
            data={tableData}
            loading={false}
            highlightCells={highlightedCells}
            indexKey="optionDetails"
          />
        </div>
      </div>
    </>
  );
}

export default OptionsTable;
