import { ClimateTokenPayload } from '../types';
import apiClient, { apiClientWithoutToken } from './api-client';

export const getUserClimateApi = (userId: string) => {
  return apiClient.get(`/partner/climate?userId=${userId}`);
};

export const getPullBoundariesFromClimateApi = (userId: string) => {
  return apiClient.get(`/partner/climate/boundaries?userId=${userId}`);
};

export const getJDAuthorizationEndpointApi = () => {
  return apiClientWithoutToken.get(
    'https://signin.johndeere.com/oauth2/aus78tnlaysMraFhC1t7/.well-known/oauth-authorization-server'
  );
};

export const getClimateTokenApi = (payload: ClimateTokenPayload) => {
  return apiClient.post(`/partner/climate/get-token`, payload);
};
