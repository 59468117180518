import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import type { MapMouseEvent } from 'react-map-gl';
import { useControl } from 'react-map-gl';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';
import { PolygonStyles } from '../../../constants';
import DrawCircle from '../../mapbox-draw/draw/circle';
import DrawRectangle from '../../mapbox-draw/draw/rectangle';
import ActionButton from './ActionButton';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  onCreate?: (event: DrawCreateEvent) => void;
  onUpdate?: (event: DrawUpdateEvent) => void;
  onDelete?: (event: DrawDeleteEvent) => void;
  onClick?: (event: MapMouseEvent) => void;
  onSelectionChange?: (event: DrawSelectionChangeEvent) => void;
  onMouseMove?: (event: MapMouseEvent) => void;

  controls?: {
    name: string;
    icon: JSX.Element;
    action: () => void;
    tooltip?: string;
    activeTooltip?: string;
  }[];
  drawMode: string;
};

const DrawControl = forwardRef(
  ({ controls, drawMode, ...props }: DrawControlProps, ref) => {
    const mapRef = useRef<any>(null);

    const drawRef = useControl<MapboxDraw>(
      () => {
        const draw = new MapboxDraw({
          ...props,
          modes: {
            ...MapboxDraw.modes,
            draw_circle: DrawCircle,
            draw_rectangle: DrawRectangle,
          },
          defaultMode: 'simple_select',
          displayControlsDefault: false,
          styles: PolygonStyles,
          userProperties: true,
        });
        return draw;
      },
      (context: MapContextValue) => {
        const { map } = context;
        mapRef.current = map;
        map.on('draw.create', props.onCreate ?? (() => {}));
        map.on('draw.update', props.onUpdate ?? (() => {}));
        map.on('draw.delete', props.onDelete ?? (() => {}));
        map.on('click', props.onClick ?? (() => {}));
        map.on('draw.selectionchange', props.onSelectionChange ?? (() => {}));
        map.on('mousemove', props.onMouseMove ?? (() => {}));
      },
      (context: MapContextValue) => {
        const { map } = context;
        map.off('draw.create', props.onCreate);
        map.off('draw.update', props.onUpdate);
        map.off('draw.delete', props.onDelete);
      }
    );

    useImperativeHandle(ref, () => drawRef, [drawRef]);

    return (
      <div className="card absolute top-[50%] -translate-y-[50%] left-2 z-10 p-2 !rounded-lg flex flex-col space-y-4 items-center justify-between">
        {controls?.map((control) => (
          <ActionButton
            key={control.name}
            action={control}
            active={drawMode === control.name}
          />
        ))}
      </div>
    );
  }
);

export default DrawControl;
