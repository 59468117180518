import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createSoilSampleApi,
  deleteSoilSampleApi,
  getBLMApi,
  getSoilSamplesApi,
  updateSoilSampleApi,
} from '../../api/soil-sample-api';
import {
  CreateSoilSamplePayload,
  SoilSampleItem,
  UpdateSoilSamplePayload,
} from '../../types';
import { enqueueSnackbar } from 'notistack';
import { useFieldPolygonsByYear } from '../field/use-field-polygons';

export const useSoilSamples = (userId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['soil/samples', userId],
    queryFn: () => getSoilSamplesApi(userId),
    enabled: !!userId,
  });

  return {
    data: data?.data as SoilSampleItem[],
    ...rest,
  };
};

export const useCreateSoilSample = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: CreateSoilSamplePayload) =>
      createSoilSampleApi(payload),
    mutationKey: ['soil/samples/create'],
    onSuccess: () => {
      // enqueueSnackbar('Soil sample created', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['soil/samples'] });
    },
    onError: () => {
      // enqueueSnackbar('Failed to create soil sample', { variant: 'error' });
    },
  });
};

export const useUpdateSoilSample = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UpdateSoilSamplePayload) =>
      updateSoilSampleApi(payload),
    mutationKey: ['soil/samples/update'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['soil/samples'] });
    },
    onError: () => {},
  });
};

export const useDeleteSoilSample = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      user_id_legal_description_other_zone,
      date,
    }: {
      user_id_legal_description_other_zone: string;
      date: string;
    }) => deleteSoilSampleApi({ user_id_legal_description_other_zone, date }),
    mutationKey: ['soil/samples/delete'],
    onSuccess: () => {
      enqueueSnackbar('Soil sample deleted', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['soil/samples'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete soil sample', { variant: 'error' });
    },
  });
};

export function useSoilSampleZoneFieldOptions(userId: string, year: string) {
  const { data: fields, ...rest } = useFieldPolygonsByYear(userId, year);
  const fieldOptions = fields?.map((field) => {
    return {
      value: JSON.stringify({
        legal_description: field?.legal_description || '',
        range: field?.rangeno || '',
        township: field?.township || '',
        section: field?.section || '',
        geometry: JSON.parse(field?.geojson)?.features?.[0]?.geometry,
      }),
      label: field.field,
    };
  });

  const zoneOptions = fields?.map((field) => ({
    value: JSON.stringify({
      legal_description: field?.legal_description || '',
      range: field?.rangeno || '',
      township: field?.township || '',
      section: field?.section || '',
      geometry: JSON.parse(field?.geojson)?.features?.[0]?.geometry,
    }),
    label: field.field,
  }));

  return { fieldOptions, zoneOptions, ...rest };
}

export const useBLMData = (lat: string, lon: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['BLM', lat, lon],
    queryFn: () => getBLMApi(lat, lon),
    enabled: !!lat && !!lon,
  });

  if (!data?.data['features'].length)
    return {
      data: null,
      ...rest,
    };

  const attributes = data?.data['features']?.[0]?.['attributes'];
  const section = attributes?.['FRSTDIVNO'];
  const township = attributes?.['TWNSHPNO'];
  const range = attributes?.['RANGENO'];
  const legal_description = `${section}-${township}-${range}`;

  return {
    data: {
      section,
      township,
      range,
      legal_description,
    },
    ...rest,
  };
};
