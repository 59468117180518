import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createStorageApi,
  deleteStorageApi,
  getStoragesApi,
  updateStorageApi,
} from '../../api/storage-api';
import { CreateStoragePayload, StorageItem } from '../../types/storage';
import { enqueueSnackbar } from 'notistack';

export const useStorages = ({
  userId,
  year,
  scenarioId,
}: {
  userId: string;
  year: string;
  scenarioId?: string;
}) => {
  const userIdYear = `${userId}_${year}`;
  const { data, ...rest } = useQuery({
    queryKey: ['storages', userIdYear, scenarioId],
    queryFn: () => getStoragesApi(userIdYear, scenarioId),
    enabled: !!userId && !!year,
  });

  return {
    data: data?.data as StorageItem[],
    ...rest,
  };
};

export const useCreateStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['storage/create'],
    mutationFn: (payload: CreateStoragePayload) => createStorageApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['storages'] });
    },
  });
};

export const useUpdateStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['storages/update'],
    mutationFn: (payload: CreateStoragePayload) => updateStorageApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['storages'] });
    },
  });
};

export const useDeleteStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['storages/delete'],
    mutationFn: (payload: StorageItem) => deleteStorageApi(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['storages'] });
      enqueueSnackbar(`Successfully delete storage`, { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(`Failed to delete storage`, { variant: 'error' });
    },
  });
};
