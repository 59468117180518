import { Dispatch, SetStateAction, useState } from 'react';
import FlipIcon from '../../../assets/icons/FlipIcons';

interface AddOptionsLayoutProps {
  disabled?: boolean;
  children: ({
    side,
    setSide,
  }: {
    side: 'front' | 'back';
    setSide: Dispatch<SetStateAction<'front' | 'back'>>;
  }) => JSX.Element;
}

export default function FlipLayout(props: AddOptionsLayoutProps) {
  const [side, setSide] = useState<'front' | 'back'>('front');
  const { children, disabled } = props;

  return (
    <div className="relative">
      {!disabled && (
        <button
          className="absolute right-0 top-1.5"
          onClick={() =>
            setSide((prev) => (prev === 'front' ? 'back' : 'front'))
          }
        >
          <FlipIcon />
        </button>
      )}

      {children({ side, setSide })}
    </div>
  );
}
