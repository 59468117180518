import { useEffect, useState } from 'react';
import { getWxDataViewBounds } from '../../../utils';

export const useViewBounds = (wData: any) => {
  const [viewBounds, setViewBounds] = useState<any>();
  useEffect(() => {
    if (!viewBounds && wData) {
      const wxViewBounds = getWxDataViewBounds(wData);
      setViewBounds(wxViewBounds);
    }
  }, [wData]);

  return {
    viewBounds,
  };
};
