import { Navigate } from 'react-router-dom';
import Map from '../../pages/dashboard/map';

const mapRoutes = [
  {
    path: 'map',
    // default route
    element: <Navigate to="revenue" />,
  },
  { path: 'map/:type', element: <Map /> },
];

export default mapRoutes;
